/* eslint-disable react/jsx-no-useless-fragment */
import { ReactNode, useRef, useState } from 'react';

export function CommonTooltip({
  icon,
  width,
  height,
  visibleStyle,
  hiddenStyle,
  time,
  children,
}: {
  icon?: 'question' | 'reboot' | 'tag' | 'asset';
  width?: string;
  height?: string;
  time?: number;
  visibleStyle?: React.CSSProperties;
  hiddenStyle?: React.CSSProperties;
  children?: ReactNode;
}): React.ReactElement {
  const [isShow, setShow] = useState(false);

  const timer = useRef<NodeJS.Timeout | null>(null);

  const setTimer = (): void => {
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setShow(false);
    }, time || 800);
  };

  const defaultSyles: React.CSSProperties = {
    marginTop: '-28px',
    marginLeft: '24px',
    padding: '4px',
    fontSize: '12px',
    fontWeight: '400',
    whiteSpace: 'break-spaces',
    width: 'fit-content',
    textTransform: 'none',
  };

  let src = `${process.env.PUBLIC_URL}/images/question.svg`;
  switch (icon) {
    case 'reboot':
      src = `${process.env.PUBLIC_URL}/images/restart.svg`;
      break;

    case 'tag':
      src = `${process.env.PUBLIC_URL}/images/tag.svg`;
      break;

    case 'asset':
      src = `${process.env.PUBLIC_URL}/images/left_menu_icons/hosts-menu-icon.svg`;
      break;

    default:
      src = `${process.env.PUBLIC_URL}/images/question.svg`;
      break;
  }

  return (
    <div>
      <div
        onMouseEnter={(): void => {
          if (timer.current) clearTimeout(timer.current);
          setShow(true);
        }}
        onMouseLeave={setTimer}
        style={visibleStyle}
      >
        <img
          style={{
            display: 'flex',
            width: width || '16px',
            height: height || '16px',
            margin: '0 4px',
          }}
          src={src}
          alt=""
        />
      </div>
      {isShow && (
        <div className="common-tooltip-wrap" style={{ ...defaultSyles, ...hiddenStyle }}>
          {children}
        </div>
      )}
    </div>
  );
}
