import { Form, FormElement } from '@progress/kendo-react-form';
import { useEffect, useState } from 'react';
import {
  TabStrip,
  TabStripTab,
  type TabStripSelectEventArguments,
} from '@progress/kendo-react-layout';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Button } from '../../../common/baseElements/Button';
import localeSettings from '../../../../utils/i18n/settingsLocales/settings.json';
import localeButtons from '../../../../utils/i18n/commonLocales/buttons.json';
import { useNetDeviceContext } from '../../../../hooks/useNetDeviceContext';
import { WhiteListFormMainPage } from './WhiteListFormMainPage';
import styles from '../WhiteListTabEl.module.scss';
import { useHostModalContext } from '../../../../hooks/useHostModalContext';
import { getUserData } from '../../../../services/local-storage-service';
import { useWhiteListContext } from '../../../../hooks/useWhiteListContext';
import { BulletinsListForForm } from './BulletinsListForForm';
import { HostListForForm } from '../../../common/form/HostListForForm';
import { GroupListForForm } from '../../../common/form/GroupListForForm';
import { DeviceListForForm } from '../../Devices/DeviceListForForm';
import { ImageListForForm } from '../../Settings/ApiKeys/form/ImageListForForm';
import { HostsMutableBlock } from '../../../common/form/HostsMutableBlock';
import { GroupMutableBlock } from '../../../common/form/GroupMutableBlock';
import { DeviceMutableBlock } from '../../../common/form/DeviceMutableBlock';
import { ImageMutableBlock } from '../../Settings/ApiKeys/form/ImageMutableBlock';
import { useRegisterContext } from '../../../../hooks/useRegisterContext';
import { useAccountModalContext } from '../../../../hooks/useAccountModalContext';
import { WhiteListFormAssetType } from './WhiteListFormAssetType';
import { DynamicGroupListForForm } from '../../../common/form/DynamicGroupListForForm';
import { DynamicGroupMutableBlock } from '../../../common/form/DynamicGroupMutableBlock';
import { BulletinMutableBlockWhiteList } from '../../../common/form/BulletinMutableBlockWhiteList';
import { SoftwareMutableBlockWhiteList } from '../../../common/form/SoftwareMutableBlockWhiteList';
import type { IDataHooks } from '../../../../utils/helpers/types';
import { SoftwareListForFormTab } from '../../../common/form/SoftwareListForFormTab';
import { useWhiteListMethods } from '../../../../hooks/components/useWhiteListMethods';

export function AddWhiteListForm(): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeSettings;

  const dataForHooks: IDataHooks = {
    name: '',
    componentName: 'AddWhiteListForm',
  };

  const currentUser = getUserData();

  const [selected, setSelected] = useState<number>(0);

  const [isHosts, setHosts] = useState(false);

  const [isBulletins, setBulletins] = useState(false);

  const [isGroups, setGroups] = useState(false);

  const [isImages, setImages] = useState(false);

  const [isDevices, setDevices] = useState(false);

  const [isAssetType, setIsAssetType] = useState(false);

  const [isSoftware, setSoftware] = useState(false);

  const handleSelect = (e: TabStripSelectEventArguments): void => {
    setSelected(e.selected);
  };

  const { isOpen, isOpenGroupList, isOpenDynamicGroupList } = useHostModalContext();

  const { isAddDevice } = useNetDeviceContext();

  const {
    name,
    isAddBulletin,
    whitelistUpdate,
    errorMutation,
    setErrorMutation,
    isAddSoftwareList,
  } = useWhiteListContext();

  const { onSubmit, cancelWhiteListForm } = useWhiteListMethods();

  useEffect(() => {
    return () => setErrorMutation('');
  }, []);

  const { accountId } = useAccountModalContext();

  const valuesForCheckbox = {
    isBulletins,
    setBulletins,
    isHosts,
    setHosts,
    isDevices,
    setDevices,
    isImages,
    setImages,
    isGroups,
    setGroups,
    isAssetType,
    setIsAssetType,
    isSoftware,
    setSoftware,
  };

  const { isAddImage } = useRegisterContext();

  return (
    <Dialog
      title={
        !whitelistUpdate
          ? localeSettings[currentLocale].whiteList.addWhiteList
          : localeSettings[currentLocale].whiteList.editWhiteList
      }
      onClose={cancelWhiteListForm}
      className={styles.whiteListAddForm}
    >
      <Form
        render={(): React.ReactElement => (
          <FormElement>
            <div className={`form-content ${styles.formContent}`}>
              <TabStrip selected={selected} onSelect={handleSelect}>
                <TabStripTab title={localeSettings[currentLocale].apiKey.form.tabs.main}>
                  <WhiteListFormMainPage valuesForCheckbox={valuesForCheckbox} />
                </TabStripTab>
                {isBulletins && (
                  <TabStripTab title={localeSettings[currentLocale].apiKey.form.tabs.bulletin}>
                    <BulletinMutableBlockWhiteList dataForHooks={dataForHooks} />
                  </TabStripTab>
                )}
                {isSoftware && (
                  <TabStripTab title={localeSettings[currentLocale].apiKey.form.tabs.software}>
                    <SoftwareMutableBlockWhiteList />
                  </TabStripTab>
                )}
                {isHosts && (
                  <TabStripTab title={localeSettings[currentLocale].apiKey.form.tabs.host}>
                    <HostsMutableBlock />
                    <GroupMutableBlock />
                    <DynamicGroupMutableBlock />
                  </TabStripTab>
                )}
                {isDevices && (
                  <TabStripTab title={localeSettings[currentLocale].apiKey.form.tabs.device}>
                    <DeviceMutableBlock />
                  </TabStripTab>
                )}
                {isImages && (
                  <TabStripTab title={localeSettings[currentLocale].apiKey.form.tabs.image}>
                    <ImageMutableBlock />
                  </TabStripTab>
                )}
                {isAssetType && (
                  <TabStripTab title={localeSettings[currentLocale].apiKey.form.tabs.assetType}>
                    <WhiteListFormAssetType />
                  </TabStripTab>
                )}
              </TabStrip>
            </div>
            <div className="error-common-field">
              <span>{errorMutation}</span>
            </div>
            <div className="k-form-buttons">
              <Button
                type="submit"
                onClick={onSubmit}
                disabled={
                  !whitelistUpdate &&
                  ((currentUser?.role === 'super_admin' &&
                    accountId.id === '000' &&
                    !whitelistUpdate) ||
                    !name)
                }
              >
                {whitelistUpdate
                  ? localeButtons[currentLocale].edit
                  : localeButtons[currentLocale].create}
              </Button>
              <Button type="button" onClick={cancelWhiteListForm}>
                {localeButtons[currentLocale].cancel}
              </Button>
            </div>
          </FormElement>
        )}
      />
      {isOpen && <HostListForForm />}
      {isOpenGroupList && <GroupListForForm />}
      {isOpenDynamicGroupList && <DynamicGroupListForForm />}
      {isAddDevice && <DeviceListForForm />}
      {isAddImage && <ImageListForForm />}
      {isAddBulletin && <BulletinsListForForm />}
      {isAddSoftwareList && <SoftwareListForFormTab />}
    </Dialog>
  );
}
