import { Form, FormElement } from '@progress/kendo-react-form';
import { useEffect } from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Filter } from '@progress/kendo-react-data-tools';
import { LocalizationProvider, IntlProvider } from '@progress/kendo-react-intl';
import { Button } from '../../../common/baseElements/Button';
import localeSettings from '../../../../utils/i18n/settingsLocales/settings.json';
import styles from '../WhiteListTabEl.module.scss';
import { useWhiteListContext } from '../../../../hooks/useWhiteListContext';
import { useWhiteList } from '../../../../hooks/components/useWhiteList';
import type { IDataHooks } from '../../../../utils/helpers/types';
import { getUrlList } from '../../../../utils/helpers/getUrl-list-helper';
import { INITIAL_CREATED_AT_SORT } from '../../../../utils/helpers/constants';
import { CommonWhiteList } from '../../../common/CommonWhiteList';
import { useAssetCreatePropContext } from '../../../../hooks/useAssetCreatePropContext';
import { BulletinMutableBlock } from '../../../common/form/BulletinMutableBlock';
import { useHostModalContext } from '../../../../hooks/useHostModalContext';
import { useWhiteListMethods } from '../../../../hooks/components/useWhiteListMethods';
import { FILTER_WHITE_LIST } from '../../../../utils/helpers/constant-serv-filter-host';
import { Input } from '../../../common/baseElements/Input';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;

const dataForHooks: IDataHooks = {
  name: 'name',
  componentName: 'AddVulnToWLForm',
  urlPath: '',
};

export function AddVulnToWLForm({ title }: { title?: string | undefined }): React.ReactElement {
  const urlParams = getUrlList('WhiteList', INITIAL_CREATED_AT_SORT);

  const { selectedVulns } = useAssetCreatePropContext();

  const { errorMutation, setErrorMutation, selectedWhitelist, setSelectedWhitelist } =
    useWhiteListContext();

  const { closeAddVulnToWLForm, handleNewWl, onSubmitVuln } = useWhiteListMethods();

  const { missedKbs } = useHostModalContext();

  const {
    sort,
    page,
    setPage,
    total,
    pageChange,
    setSort,
    data,
    filter,
    onFilterChange,
    customSearch,
    handleCustomSearch,
  } = useWhiteList(urlParams, dataForHooks);

  useEffect(() => {
    setPage({
      skip: 0,
      take: 15,
    });

    setSelectedWhitelist(null);

    return () => setErrorMutation('');
  }, []);

  return (
    <Dialog
      title={localeSettings[currentLocale].AddVulnToWLFormTitle}
      onClose={closeAddVulnToWLForm}
      className={styles.AddVulnToWLForm}
    >
      <Form
        render={(): React.ReactElement => (
          <FormElement>
            <div className={`form-content ${styles.formContent}`}>
              {(selectedVulns || missedKbs) && <BulletinMutableBlock title={title} />}
              <div
                className={
                  !selectedVulns
                    ? `${styles.mt} ${styles.mb} k-form-buttons`
                    : `k-form-buttons ${styles.mb}`
                }
              >
                <Button onClick={handleNewWl}>
                  {localeSettings[currentLocale].addToNewExceptionList}
                </Button>
              </div>
              <LocalizationProvider language={currentLocale}>
                <IntlProvider locale={currentLocale.substring(0, 2)}>
                  <div className={styles.whiteListFilterLine}>
                    <Filter
                      value={filter}
                      onChange={onFilterChange}
                      fields={FILTER_WHITE_LIST[currentLocale]}
                    />
                    {filter.filters.length === 0 && (
                      <Input
                        className="whitelist-filter"
                        name="customSearch"
                        placeholder={localeSettings[currentLocale].whiteList.grid.listPlaceHolder}
                        value={customSearch}
                        onChange={(e): void => handleCustomSearch(e)}
                      />
                    )}
                  </div>
                </IntlProvider>
              </LocalizationProvider>
              <CommonWhiteList
                sort={sort}
                data={data}
                page={page}
                total={total}
                pageChange={pageChange}
                setSort={setSort}
                dataForHooks={dataForHooks}
              />
            </div>
            <div className="error-common-field">
              <span>{errorMutation}</span>
            </div>
            <div className="k-form-buttons">
              <Button type="submit" onClick={onSubmitVuln} disabled={!selectedWhitelist}>
                {localeSettings[currentLocale].apiKey.btnAdd}
              </Button>
              <Button type="button" onClick={closeAddVulnToWLForm}>
                {localeSettings[currentLocale].apiKey.btnCancel}
              </Button>
            </div>
          </FormElement>
        )}
      />
    </Dialog>
  );
}
