import type { TicketDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/ticketsDashboardResponse.v1';
import { ticketActionLocale, ticketPriorityLocale } from '../../../../../utils/helpers/constants';
import localeTicket from '../../../../../utils/i18n/security/vulnerabilities.json';
import styles from '../ticket.module.scss';

export function TableSTicketMetaBlock({
  data,
  currentLocale,
}: {
  data: TicketDashboardResponse | undefined;
  currentLocale: 'ru-RU' | 'en-EN';
}): React.ReactElement {
  return (
    <table className="column column-first">
      <tbody>
        <tr>
          <td>
            <span>{localeTicket[currentLocale].tickets.description.metablock.actionType}</span>
          </td>
          <td>{data?.type ? ticketActionLocale[data?.type] : '-'}</td>
        </tr>
        <tr>
          <td>
            <span>{localeTicket[currentLocale].tickets.description.metablock.priority}</span>
          </td>
          <td>
            {data?.priority ? (
              <div
                title={ticketPriorityLocale[data?.priority]}
                className={`${styles.ticketPriorityWrap} ${styles[data?.priority]}`}
              >
                <svg
                  viewBox="0 0 512 512"
                  width={15}
                  height={15}
                  className={`${styles[data?.priority]}`}
                >
                  <path d="M256 32 0 480h512zm-32 160h64v160h-64zm0 256v-64h64v64z" />
                </svg>
                <span className={styles.spanPriority}>{ticketPriorityLocale[data?.priority]}</span>
              </div>
            ) : (
              <span>-</span>
            )}
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeTicket[currentLocale].tickets.description.metablock.lastModified}</span>
          </td>
          <td>{data?.modifiedAt ? new Date(data?.modifiedAt).toLocaleString() : '-'}</td>
        </tr>
        <tr>
          <td>
            <span>{localeTicket[currentLocale].tickets.description.metablock.ticketSystem}</span>
          </td>
          <td>{data?.ticketSystem.name || '-'}</td>
        </tr>
      </tbody>
    </table>
  );
}
