import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { HostMetaBlock } from './HostMetaBlock';
import { useHostCreateContext } from '../../../../hooks/useHostCreateContext';
import { ReinstallHostForm } from '../forms/ReinstallHostForm';
import { useAuditModalContext } from '../../../../hooks/useAuditModalContext';
import { TabsHostTop } from './TabsHostTop';
import { useHostModalContext } from '../../../../hooks/useHostModalContext';
import { AddHostToGroupModal } from './AddHostToGroupModal';
import { EditPortModal } from '../../../common/modal/EditPortModal';
import { HostConfirmDel } from './HostConfirmDel';
import { DifferenceAuditModal } from '../DifferenceModal/Audit/DifferenceAuditModal';
import { useDifferenceModalContext } from '../../../../hooks/useDifferenceModalContext';
import { MissingKbModal } from '../../Task/taskVmDetails/vmDetailsComponents/MissingKbModal';
import { DifferenceInvModal } from '../DifferenceModal/Inventorization/DifferenceInvModal';
import { HostConfirmDelTag } from './HostConfirmDelTag';
import { useAssetCreatePropContext } from '../../../../hooks/useAssetCreatePropContext';
import { useHostDescription } from '../../../../hooks/components/useHostDescription';
import { MaintenanceListModal } from '../MaintenanceWindows/MaintenanceListModal';
import { useMaintenanceWindowsContext } from '../../../../hooks/useMaintenanceWindowsContext';
import type {
  AssetUpdateRequest,
  MaintenanceWindowsField,
} from '../../../../types/__generated/on-premise-solution/api/assetUpdateRequest.v1';
import { editAsset } from '../../../../services/asset-service';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { useSoftwareManagementContext } from '../../../../hooks/useSoftwareManagementContext';
import { EntityDescription } from '../../../templates/EntityDescription';
import { useTagContext } from '../../../../hooks/useTagContext';
import { TagHeader } from '../../../common/tag/TagHeader';
import { HostDescriptionTopactions } from './HostDescriptionTopactions';
import localeHostDescription from '../../../../utils/i18n/assetLocales/hostDescription.json';
import { useAccountModalContext } from '../../../../hooks/useAccountModalContext';
import { useCommonContext } from '../../../../hooks/useCommonContext';

export function HostDescription(): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeHostDescription;

  const [isConfirm, setConfirm] = useState(false);

  const { assetId } = useParams<{ assetId: string }>();

  const { hostCreateData } = useHostCreateContext();

  const { idKb } = useAuditModalContext();

  const {
    isAuditOpen: isDiffAuditOpen,
    setAuditOpen,
    isInvOpen: isDiffInvOpen,
    setInvOpen,
  } = useDifferenceModalContext();

  const {
    isOpenHostGroups,
    isEditPort,
    hostForm,
    setHostForm,
    setSelectedPkgs,
    setSelectedPkgsWl,
    setMissedKbs,
    setKbsInWl,
    setWinSoftwareUpdateState,
    setWinSoftwareUpdateStateWl,
    setSoftwareInWl,
    setAuditDataExists,
  } = useHostModalContext();

  const { isTableOpen, closeWindow, setPatchFunction, setMaintenanceWindowsList } =
    useMaintenanceWindowsContext();

  const { setSelectedVulns, setErrCreateHostMutation } = useAssetCreatePropContext();

  const { tagDataForDel, closeTagWindow, setEditTagColor, setShowAddTag } = useTagContext();

  const { assetIdData, hostname, queryDashboardAssetId } = useHostDescription();

  const { setAccountId } = useAccountModalContext();

  const { setSelectedSoftwareForUpdate } = useSoftwareManagementContext();

  const { setAssetId } = useCommonContext();

  const queryClient = useQueryClient();

  const mutation = useMutation<
    AssetUpdateRequest,
    ApiError,
    { payloadAssetId: string; payload: AssetUpdateRequest }
  >(({ payloadAssetId, payload }) => editAsset(payloadAssetId, payload), {
    onSuccess: () => {
      closeWindow();
      queryClient.invalidateQueries('dashboardAssetId');
    },
  });

  useEffect(() => {
    document.addEventListener('click', closeTagWindow);
    setSelectedSoftwareForUpdate({});
    setPatchFunction((maintenancePayload: MaintenanceWindowsField | undefined): void => {
      mutation.mutateAsync({
        payloadAssetId: assetId,
        payload: {
          type: 'host',
          maintenanceWindows: maintenancePayload,
        },
      });
    });

    return () => {
      document.removeEventListener('click', closeTagWindow);
      setEditTagColor(null);
      setShowAddTag(false);
      setAuditOpen(false);
      setInvOpen(false);
      setMaintenanceWindowsList(undefined);
      setErrCreateHostMutation('');
      setAccountId({
        id: '000',
        name: localeHostDescription[currentLocale].titleCheckboxAccount,
      });
      setHostForm(null);
      setSelectedPkgs(null);
      setSelectedPkgsWl(null);
      setWinSoftwareUpdateState(null);
      setWinSoftwareUpdateStateWl(null);
      setSelectedSoftwareForUpdate({});
      setSelectedVulns(null);
      setKbsInWl(null);
      setMissedKbs(null);
      setAssetId(null);
      setSoftwareInWl(null);
      setAuditDataExists(false);
    };
  }, []);

  useEffect(() => {
    if (hostname && !hostForm) setHostForm([{ assetId, hostname }]);
  }, [assetId, hostname, setHostForm, hostForm]);

  return (
    <EntityDescription
      isNotFound={queryDashboardAssetId.error?.code === '404'}
      isLoading={queryDashboardAssetId.isLoading}
      entity={{
        id: assetId,
        type: 'host',
        name: hostname,
      }}
      className="hostdescription"
      tagBlock={
        <TagHeader
          tags={assetIdData?.tags}
          tagIds={assetIdData?.tagIds}
          entityId={assetId}
          entityType="host"
          updateEntityTagList={(): void => {
            queryDashboardAssetId.refetch();
          }}
        />
      }
      topactions={
        <HostDescriptionTopactions
          setConfirm={setConfirm}
          currentAssetAccount={assetIdData?.account}
        />
      }
    >
      {idKb && <MissingKbModal />}
      {isTableOpen && <MaintenanceListModal assetId={assetId} />}
      <div className="hostdescription">
        {isDiffAuditOpen && (
          <DifferenceAuditModal
            ip={assetIdData?.latestInventorization?.ip}
            system={`${assetIdData?.latestInventorization?.os.name} ${assetIdData?.latestInventorization?.os.version}`}
          />
        )}
        {isDiffInvOpen && (
          <DifferenceInvModal
            ip={assetIdData?.latestInventorization?.ip}
            system={`${assetIdData?.latestInventorization?.os.name} ${assetIdData?.latestInventorization?.os.version}`}
          />
        )}
        {isConfirm && (
          <HostConfirmDel
            setConfirm={setConfirm}
            assetName={
              assetIdData?.type === 'host' && assetIdData?.latestInventorization?.hostname
                ? assetIdData?.latestInventorization?.hostname
                : undefined
            }
          />
        )}
        {!isConfirm && tagDataForDel && <HostConfirmDelTag />}
        {hostCreateData && typeof hostCreateData.hostname !== 'undefined' && <ReinstallHostForm />}
        {isOpenHostGroups && (
          <AddHostToGroupModal
            assetId={assetIdData?.type === 'host' ? assetIdData?.assetId : undefined}
            hostname={hostname}
            startGroupsIds={assetIdData?.type === 'host' ? assetIdData?.assetGroupIds : null}
          />
        )}
        {isEditPort && (
          <EditPortModal assetId={assetId} assetType="host" sshPortCurrent={assetIdData?.sshPort} />
        )}
        {assetIdData && <HostMetaBlock data={assetIdData} />}
        <TabsHostTop />
      </div>
    </EntityDescription>
  );
}
