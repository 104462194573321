import { Loader } from '@progress/kendo-react-indicators';
import styles from '../TicketSystem.module.scss';
import { useTicketContext } from '../../../../../hooks/useTicketContext';
import localeTicket from '../../../../../utils/i18n/security/vulnerabilities.json';
import { getUserData } from '../../../../../services/local-storage-service';
import { useTicketSystemContext } from '../../../../../hooks/useTicketSystemContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeTicket;

export function TicketMetaInfoForm(): React.ReactElement {
  const { ticketSystemType } = useTicketContext();

  const { isJiraMetaLoading, errorJiraMetaRequest, isSuccessJiraMetaRequest } =
    useTicketSystemContext();

  const currentUser = getUserData();

  return (
    <>
      {isJiraMetaLoading && (
        <div className={styles.loaderWrap}>
          <Loader className={styles.loaderInline} size="small" type="converging-spinner" />
          <div className={styles.loaderInfo}>
            <span>{localeTicket[currentLocale].ticketSystem.form.loaderInfo}</span>
          </div>
        </div>
      )}
      {!isJiraMetaLoading && (
        <div className={`error-common-field ${styles.errorJiraMetaRequestWrap}`}>
          <span>{errorJiraMetaRequest}</span>
        </div>
      )}
      {currentUser?.role === 'super_admin' &&
        !errorJiraMetaRequest &&
        !isJiraMetaLoading &&
        !isSuccessJiraMetaRequest && (
          <div className={styles.jiraMetaInfo}>
            <span>
              {ticketSystemType === 'jira'
                ? localeTicket[currentLocale].ticketSystem.form.jiraMetaInfoSuperAdmin
                : localeTicket[currentLocale].ticketSystem.form.jiraMetaInfoSuperAdminYandex}
            </span>
          </div>
        )}
      {currentUser?.role !== 'super_admin' && !errorJiraMetaRequest && !isSuccessJiraMetaRequest && (
        <div className={styles.jiraMetaInfo}>
          <span>
            {ticketSystemType === 'jira'
              ? localeTicket[currentLocale].ticketSystem.form.jiraMetaInfo
              : localeTicket[currentLocale].ticketSystem.form.jiraMetaInfoYandex}
          </span>
        </div>
      )}
      {!isJiraMetaLoading && !errorJiraMetaRequest && isSuccessJiraMetaRequest && (
        <div className={`${styles.jiraMetaInfo} ${styles.jiraMetaSuccessWrap}`}>
          <span className={`k-button-icon k-icon k-i-check ${styles.spanCheck}`} />
          <span className={styles.spanContent}>
            {localeTicket[currentLocale].ticketSystem.form.jiraMetaSuccess}
          </span>
        </div>
      )}
    </>
  );
}
