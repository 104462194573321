/* eslint-disable react/jsx-no-useless-fragment */
import type { GridCellProps } from '@progress/kendo-react-grid';

export function CellVersionWl(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const calcVersion = (value: string[]): string | undefined => {
    let version = '';

    if (value && value.length > 0) {
      version = value.join('=').replace(/(=)|(<=)|(>=)/, '');
    }

    return version || undefined;
  };

  return (
    <td>
      {calcVersion(dataItem.versionRanges) ? (
        <span>{calcVersion(dataItem.versionRanges)}</span>
      ) : (
        <span>-</span>
      )}
    </td>
  );
}
