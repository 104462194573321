/* eslint-disable react/jsx-pascal-case */
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { ErrorBoundary } from 'react-error-boundary';
import { Filter } from '@progress/kendo-react-data-tools';
import { Typography } from '@progress/kendo-react-common';
import type { IDataHooks } from '../../../../utils/helpers/types';
import { getUrlList } from '../../../../utils/helpers/getUrl-list-helper';
import { BoundaryErrorComponent } from '../../../common/BoundaryErrorComponent';
import { СommonTableLoader } from '../../../common/tableLoader/CommonTableLoader';
import {
  INITIAL_CREATED_AT_SORT,
  urlOfApiMethodForTicketSys,
} from '../../../../utils/helpers/constants';
import localeTicket from '../../../../utils/i18n/security/vulnerabilities.json';
import { Input } from '../../../common/baseElements/Input';
import { FILTER_TICKET_SYSTEM_LIST } from '../../../../utils/helpers/constant-serv-filter-host';
import { Button } from '../../../common/baseElements/Button';
import { getUserData } from '../../../../services/local-storage-service';
import { useTicketContext } from '../../../../hooks/useTicketContext';
import { useTicketSystemList } from '../../../../hooks/components/useTicketSystemList';
import { CommonTicketSystemList } from '../../../common/CommonTicketSystemList';
import { AddTicketSystemForm } from './form/AddTicketSystemForm';
import { useCommonContext } from '../../../../hooks/useCommonContext';
import { CommonWebhook } from '../../../common/CommonWebhook';
import { useTicketSystemContext } from '../../../../hooks/useTicketSystemContext';

export function TicketSystemList(): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeTicket;

  const currentUser = getUserData();

  const urlParams = getUrlList('TicketSystemList', INITIAL_CREATED_AT_SORT);

  const dataForHooks: IDataHooks = {
    name: 'name',
    componentName: 'TicketSystemList',
  };

  const {
    filter,
    onFilterChange,
    sort,
    page,
    total,
    pageChange,
    setSort,
    isError,
    errorMessage,
    data,
    handleCustomSearch,
    customSearch,
    handleRefresh,
    isLoading,
  } = useTicketSystemList(urlParams, dataForHooks);

  const { ticketSystem } = useTicketContext();

  const { isAddTicketSystem, setAddTicketSystem, isMutationTicketSystem, name } =
    useTicketSystemContext();

  const { isShowCommonWebHook } = useCommonContext();

  return (
    <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
      <div className="ticket-list">
        <div className="common-header-page">
          <Typography.h3>{localeTicket[currentLocale].ticketSystem.titleList}</Typography.h3>
          <div className="topactions">
            {currentUser?.role !== 'user' && currentUser?.role !== 'reader_user' && (
              <Button fill="action" onClick={(): void => setAddTicketSystem(true)}>
                {localeTicket[currentLocale].ticketSystem.headerBtns.addTicketBtn}
              </Button>
            )}
            <Button onClick={handleRefresh} icon="refresh">
              {localeTicket[currentLocale].ticketSystem.headerBtns.refreshTicket}
            </Button>
          </div>
        </div>
        <LocalizationProvider language={currentLocale}>
          <IntlProvider locale={currentLocale.substring(0, 2)}>
            <div className="filter-line">
              <Filter
                value={filter}
                onChange={onFilterChange}
                fields={FILTER_TICKET_SYSTEM_LIST[currentLocale]}
              />
              {filter.filters.length === 0 && (
                <Input
                  name="customSearch"
                  placeholder={localeTicket[currentLocale].ticketSystem.grid.placeholderFilter}
                  value={customSearch}
                  onChange={(e): void => handleCustomSearch(e)}
                />
              )}
            </div>
          </IntlProvider>
        </LocalizationProvider>
        <CommonTicketSystemList
          sort={sort}
          data={data}
          page={page}
          total={total}
          pageChange={pageChange}
          setSort={setSort}
          dataForHooks={dataForHooks}
          conditionSort={15}
        />
        {isAddTicketSystem && <AddTicketSystemForm />}
        {isShowCommonWebHook && (
          <CommonWebhook
            title={localeTicket[currentLocale].ticketSystem.form.titleWebHookModal}
            id={ticketSystem?.id || ''}
            name={name || ''}
            urlOfApiMethod={urlOfApiMethodForTicketSys}
            description={localeTicket[currentLocale].ticketSystem.grid.menu.webHookDescription}
          />
        )}
        {isLoading && <СommonTableLoader queryData={isLoading} filteringData={isLoading} />}
        {isMutationTicketSystem && (
          <СommonTableLoader
            queryData={isMutationTicketSystem}
            filteringData={isMutationTicketSystem}
          />
        )}
        {isError && <div className="info alert alert-danger">Error: {errorMessage}</div>}
      </div>
    </ErrorBoundary>
  );
}
