/* eslint-disable no-nested-ternary */
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import type { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import type { RadioButtonChangeEvent } from '@progress/kendo-react-inputs';
import type {
  TTicketSystemCreationRequest,
  TUseTicketSystemMethods,
} from '../../utils/helpers/types';
import type { ApiError } from '../../types/__generated/on-premise-solution/api/apiError.v1';
import {
  createTicketSystem,
  deleteTicketSystem,
  editTicketSystem,
} from '../../services/tickets-service';
import type { TicketSystemCreationResponse } from '../../types/__generated/on-premise-solution/api/ticketSystemCreationResponse.v1';
import type { TicketSystemCreationRequest } from '../../types/__generated/on-premise-solution/api/ticketSystemCreationRequest.v1';
import { useTicketContext } from '../useTicketContext';
import { useAccountModalContext } from '../useAccountModalContext';
import { useTaskAddContext } from '../useTaskAddContext';
import type { TicketSystemDeletionResponse } from '../../types/__generated/on-premise-solution/api/ticketSystemDeletionResponse.v1';
import localeTicket from '../../utils/i18n/security/vulnerabilities.json';
import type { TicketSystemJiraUpdateRequest } from '../../types/__generated/on-premise-solution/api/ticketSystemJiraUpdateRequest.v1';
import type { TicketSystemUpdateResponse } from '../../types/__generated/on-premise-solution/api/ticketSystemUpdateResponse.v1';
import { useCommonContext } from '../useCommonContext';
import type { TicketSystemYandexTrackerUpdateRequest } from '../../types/__generated/on-premise-solution/api/ticketSystemUpdateRequest.v1';
import { useTicketSystemContext } from '../useTicketSystemContext';

export function useTicketSystemMethods(): TUseTicketSystemMethods {
  const currentLocale =
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN';

  const queryClient = useQueryClient();

  const history = useHistory();

  const { setErrorMutation, setTicketSystem, setTicketSystemType, ticketSystemType } =
    useTicketContext();

  const {
    subType,
    name,
    setName,
    isDefault,
    descriptionTs,
    jiraProjectId,
    setJiraProjectId,
    apiBaseUrl,
    setApiBaseUrl,
    jiraStatusMapping,
    yandexStatusMapping,
    jiraPriorityMapping,
    yandexPriorityMapping,
    credentialTicket,
    setCredentialTicket,
    isUseCron,
    setEditTicketSystemData,
    setJiraProjectIds,
    setYandexTrackerQueueIds,
    setStatuses,
    setPriorities,
    editTicketSystemData,
    setErrorJiraMetaRequest,
    setUseCron,
    setDefault,
    setSuccessJiraMetaRequest,
    setMutationTicketSystem,
    priorities,
    statuses,
    setOrganizationId,
    setJiraMetaLoading,
    yandexTrackerQueueId,
    organizationId,
    isOnlyYandexCloudOrganizationUsed,
    setSubType,
    setAddTicketSystem,
    setDescriptionTs,
  } = useTicketSystemContext();

  const { cronValue } = useTaskAddContext();

  const { accountId, setAccountId } = useAccountModalContext();

  const { setShowCommonWebHook } = useCommonContext();

  const handleClose = (): void => {
    setAddTicketSystem(false);
    setAccountId({
      id: '000',
      name: localeTicket[currentLocale].tickets.form.placeholderAccountName,
    });
    setName('');
    setApiBaseUrl('');
    setCredentialTicket(undefined);
    setJiraProjectId('');
    setJiraProjectIds(null);
    setYandexTrackerQueueIds(null);
    setStatuses(null);
    setPriorities(null);
    setEditTicketSystemData(null);
    setErrorJiraMetaRequest('');
    setSuccessJiraMetaRequest(false);
    setUseCron(false);
    setDefault(false);
    setOrganizationId('');
    setDescriptionTs('');
  };

  const mutationNewTicketSystem = useMutation<
    TicketSystemCreationResponse,
    ApiError,
    TicketSystemCreationRequest
  >((payload) => createTicketSystem(payload), {
    onSuccess: () => {
      queryClient.invalidateQueries('ticketSystems');
      handleClose();
      setMutationTicketSystem(false);
    },
    onError: (err) => {
      setErrorMutation(err.message);
      setMutationTicketSystem(false);
    },
  });

  const mutationEditTickeSystem = useMutation<
    TicketSystemUpdateResponse | TicketSystemYandexTrackerUpdateRequest,
    ApiError,
    {
      ticketSystemId: string;
      payload: TicketSystemJiraUpdateRequest | TicketSystemYandexTrackerUpdateRequest;
    }
  >(({ ticketSystemId, payload }) => editTicketSystem(ticketSystemId, payload), {
    onSuccess: () => {
      queryClient.invalidateQueries('ticketSystems');
      handleClose();
      setMutationTicketSystem(false);
    },
    onError: (resp) => {
      setErrorMutation(resp.message);
      setMutationTicketSystem(false);
    },
  });

  const mutationDelTicketSystem = useMutation<
    TicketSystemDeletionResponse | null,
    ApiError,
    TicketSystemDeletionResponse['ticketSystemId']
  >((payload) => deleteTicketSystem(payload), {
    onSuccess: () => {
      queryClient.invalidateQueries('ticketSystems');
      history.push('/lk/ticket-systems');
      setMutationTicketSystem(false);
    },
    onError: (resp) => {
      setErrorMutation(resp.message);
      setMutationTicketSystem(false);
    },
  });

  const setDefaultTicketSystem = (
    ticketSystemId: string,
    subTypeValue: string,
    typeValue: string,
  ): void => {
    const payload = {
      type: typeValue,
      isDefault: true,
      subType: subTypeValue,
    } as TicketSystemJiraUpdateRequest;

    mutationEditTickeSystem.mutate({
      ticketSystemId,
      payload,
    });

    setMutationTicketSystem(true);
  };

  const handleSubmit = (): void => {
    if (editTicketSystemData) {
      const payload = {
        type: ticketSystemType,
        subType: ticketSystemType === 'jira' ? subType : undefined,
        accountId: accountId.id,
        name:
          name && editTicketSystemData.name && editTicketSystemData.name !== name
            ? name
            : undefined,
        isDefault: editTicketSystemData.isDefault !== isDefault ? isDefault : undefined,
        description:
          editTicketSystemData.description &&
          descriptionTs &&
          descriptionTs?.length > 0 &&
          editTicketSystemData.description !== descriptionTs
            ? descriptionTs
            : !editTicketSystemData.description && descriptionTs && descriptionTs.length > 0
            ? descriptionTs
            : editTicketSystemData.description && !descriptionTs
            ? null
            : undefined,
        jiraProjectId:
          editTicketSystemData.type === 'jira' &&
          editTicketSystemData.jiraProjectId &&
          jiraProjectId &&
          editTicketSystemData.jiraProjectId !== jiraProjectId
            ? jiraProjectId
            : undefined,
        apiBaseUrl: editTicketSystemData.apiBaseUrl !== apiBaseUrl ? apiBaseUrl : undefined,
        isOnlyYandexCloudOrganizationUsed:
          ticketSystemType === 'yandex_tracker' ? isOnlyYandexCloudOrganizationUsed : undefined,
        yandexTrackerQueueId:
          ticketSystemType === 'yandex_tracker' ? yandexTrackerQueueId : undefined,
        organizationId: ticketSystemType === 'yandex_tracker' ? organizationId : undefined,
        statusMapping:
          ticketSystemType === 'jira' && Object.keys(jiraStatusMapping).length > 0
            ? jiraStatusMapping
            : ticketSystemType === 'yandex_tracker' &&
              yandexStatusMapping &&
              Object.keys(yandexStatusMapping).length > 0
            ? yandexStatusMapping
            : undefined,
        priorityMapping:
          ticketSystemType === 'jira' && Object.keys(jiraPriorityMapping).length > 0
            ? jiraPriorityMapping
            : ticketSystemType === 'yandex_tracker' &&
              yandexPriorityMapping &&
              Object.keys(yandexPriorityMapping).length > 0
            ? yandexPriorityMapping
            : undefined,
        refreshTicketsSystemTask: isUseCron
          ? {
              cron: cronValue.current,
            }
          : null,
        credentialId:
          editTicketSystemData.credentialId !== credentialTicket?.credentialId
            ? credentialTicket?.credentialId
            : undefined,
      } as TicketSystemJiraUpdateRequest | TicketSystemYandexTrackerUpdateRequest;
      mutationEditTickeSystem.mutate({
        ticketSystemId: editTicketSystemData.ticketSystemId,
        payload,
      });
      setMutationTicketSystem(true);
    } else {
      const payload = {
        type: ticketSystemType,
        subType: ticketSystemType === 'jira' ? subType : undefined,
        accountId: accountId.id,
        name,
        isDefault,
        description: descriptionTs || undefined,
        jiraProjectId: ticketSystemType === 'jira' ? jiraProjectId : undefined,
        apiBaseUrl,
        yandexTrackerQueueId:
          ticketSystemType === 'yandex_tracker' ? String(yandexTrackerQueueId) : undefined,
        isOnlyYandexCloudOrganizationUsed:
          ticketSystemType === 'yandex_tracker' ? isOnlyYandexCloudOrganizationUsed : undefined,
        organizationId: ticketSystemType === 'yandex_tracker' ? organizationId : undefined,
        statusMapping:
          ticketSystemType === 'jira' && Object.keys(jiraStatusMapping).length > 0
            ? jiraStatusMapping
            : ticketSystemType === 'yandex_tracker' &&
              yandexStatusMapping &&
              Object.keys(yandexStatusMapping).length > 0
            ? yandexStatusMapping
            : undefined,
        priorityMapping:
          ticketSystemType === 'jira' && Object.keys(jiraPriorityMapping).length > 0
            ? jiraPriorityMapping
            : ticketSystemType === 'yandex_tracker' &&
              yandexPriorityMapping &&
              Object.keys(yandexPriorityMapping).length > 0
            ? yandexPriorityMapping
            : undefined,
        refreshTicketsSystemTask: isUseCron
          ? {
              cron: cronValue.current,
            }
          : undefined,
        credentialId: credentialTicket?.credentialId || '',
      } as TicketSystemCreationRequest;
      mutationNewTicketSystem.mutate(payload);
      setMutationTicketSystem(true);
    }
  };

  const hadleDeleteTickeSystem = (
    ticketId: string,
    setConfirmDel: React.Dispatch<React.SetStateAction<boolean>>,
  ): void => {
    mutationDelTicketSystem.mutate(ticketId);
    setConfirmDel(false);
    setMutationTicketSystem(true);
  };

  const handleEdit = (dataItem: { [key: string]: any }): void => {
    const data = dataItem as TTicketSystemCreationRequest['editTicketSystemData'];
    setEditTicketSystemData(data);
    setAddTicketSystem(true);
  };

  const setDefaultPriorityName = (keyValue: string): string | undefined => {
    let priorityName: string | undefined;
    if (priorities) {
      priorities.forEach((p) => {
        if (typeof p.id === 'string' && p.id === keyValue) priorityName = p.name;
        if (typeof p.id === 'number' && String(p.id) === keyValue) priorityName = p.name;
      });
    }

    return priorityName;
  };

  const setDefaultStatusName = (keyValue: string): string | undefined => {
    let statusName: string | undefined;
    if (statuses) {
      statuses.forEach((s) => {
        if (typeof s.id === 'string' && s.id === keyValue) statusName = s.name;
        if (typeof s.id === 'number' && String(s.id) === keyValue) statusName = s.name;
      });
    }

    return statusName;
  };

  const handleShowTicketHook = (ticketSystemId: string, nameValue: string): void => {
    setShowCommonWebHook(true);
    setTicketSystem({ id: ticketSystemId, name: '', type: '' });
    setName(nameValue);
  };

  const handleCloseTicketHook = (): void => {
    setShowCommonWebHook(false);
    setTicketSystem(null);
    setName('');
  };

  const changeDropDownType = (event: DropDownListChangeEvent): void => {
    setJiraProjectIds(null);
    setYandexTrackerQueueIds(null);
    setStatuses(null);
    setPriorities(null);
    setJiraMetaLoading(false);
    setErrorJiraMetaRequest('');
    setErrorMutation('');
    setCredentialTicket(undefined);
    setSuccessJiraMetaRequest(false);
    setApiBaseUrl('');

    setTicketSystemType(event.target.value);
  };

  const handleChangeSubType = (e: RadioButtonChangeEvent): void => {
    setSubType(e.value);
  };

  return {
    handleSubmit,
    hadleDeleteTickeSystem,
    isErrorDelTicketSystem: mutationDelTicketSystem.isError,
    errorMessageDelTicketSystem: mutationDelTicketSystem.error?.message,
    handleEdit,
    handleClose,
    setDefaultPriorityName,
    setDefaultStatusName,
    setDefaultTicketSystem,
    handleShowTicketHook,
    handleCloseTicketHook,
    changeDropDownType,
    handleChangeSubType,
  };
}
