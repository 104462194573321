import { Dialog } from '@progress/kendo-react-dialogs';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { useEffect, useRef, useState } from 'react';
import { Button } from '../baseElements/Button';
import { useHostModalContext } from '../../../hooks/useHostModalContext';
import localeNewTask from '../../../utils/i18n/taskLocale/newTask.json';
import { getUserData } from '../../../services/local-storage-service';
import { useWhiteListContext } from '../../../hooks/useWhiteListContext';
import localeSettings from '../../../utils/i18n/settingsLocales/settings.json';
import { SoftwareListForForm } from './SoftwareListForForm';
import localeSoftware from '../../../utils/i18n/software/software.json';
import styles from '../../features/WhiteList/WhiteListTabEl.module.scss';

export function SoftwareListForFormTab(): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeSettings;

  const currentUser = getUserData();

  const { softwareInWl, setSoftwareInWl } = useHostModalContext();

  // const winCount = useRef<number>(0);

  // const linCount = useRef<number>(0);

  const [linCount, setLinCount] = useState(0);

  const [winCount, setWinCount] = useState(0);

  const { setAddSoftwareList, isWhiteListForm, setWhiteListForm } = useWhiteListContext();

  const handleSoftware = (): void => {
    setAddSoftwareList(false);
    if (isWhiteListForm) {
      setWhiteListForm(true);
    }
  };

  const closeThisModal = (): void => {
    setAddSoftwareList(false);
    setSoftwareInWl(null);
  };

  const [selected, setSelected] = useState(0);

  useEffect(() => {
    if (softwareInWl) {
      const win = Object.values(softwareInWl).filter((w) => w.osType === 'windows');
      setWinCount(win && win.length > 0 ? win.length : 0);

      const lin = Object.values(softwareInWl).filter((l) => l.osType === 'linux');
      setLinCount(lin && lin.length > 0 ? lin.length : 0);
    }

    // if (softwareInWl) {
    //   const lin = Object.values(softwareInWl).filter((l) => l.osType === 'linux');

    //   // linCount.current = lin && lin.length > 0 ? lin.length : 0;
    //   setLinCount(lin && lin.length > 0 ? lin.length : 0);
    // }
  }, [softwareInWl]);

  return (
    <Dialog
      title={localeSettings[currentLocale].whiteList.software.addSoftWareTitle}
      onClose={closeThisModal}
      className={
        currentUser?.role === 'super_admin'
          ? `host-list modal ${styles.softwareListForFormTabWrap}`
          : ''
      }
    >
      <div className="k-form">
        <div className="form-content select-asset">
          <TabStrip
            className={styles.tabStripWrap}
            selected={selected}
            onSelect={(e): void => setSelected(e.selected)}
          >
            <TabStripTab
              title={
                linCount && linCount > 0
                  ? `${localeSoftware[currentLocale].linuxTab} (${linCount})`
                  : localeSoftware[currentLocale].linuxTab
              }
            >
              <SoftwareListForForm type="linux" />
            </TabStripTab>
            <TabStripTab
              title={
                winCount && winCount > 0
                  ? `${localeSoftware[currentLocale].windowsTab} (${winCount})`
                  : localeSoftware[currentLocale].windowsTab
              }
            >
              <SoftwareListForForm type="windows" />
            </TabStripTab>
          </TabStrip>
        </div>
        <div className="k-form-buttons">
          <Button
            type="submit"
            disabled={!softwareInWl || (softwareInWl && Object.keys(softwareInWl).length === 0)}
            onClick={handleSoftware}
          >
            {localeNewTask[currentLocale].form.btnAddAsset}{' '}
            {softwareInWl &&
              Object.keys(softwareInWl).length > 0 &&
              `(${Object.keys(softwareInWl).length})`}
          </Button>
          <Button type="button" onClick={closeThisModal}>
            {localeNewTask[currentLocale].form.btnCancel}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
