/* eslint-disable no-nested-ternary */
import qs from 'qs';
import type { SortDescriptor } from '@progress/kendo-data-query';
import type { AxiosResponse } from 'axios';
import type { IEditRegister, IPageState } from '../utils/helpers/types';
import { history } from '../utils/history';
import type { AssetsImagesDashboardResponse } from '../types/__generated/on-premise-solution/api/assetsImagesDashboardResponse.v1';
import type { AgentsStatusRequest } from '../types/__generated/on-premise-solution/api/agentsStatusRequest.v1';
import type { AgentsStatusResponse } from '../types/__generated/on-premise-solution/api/agentsStatusResponse.v1';
import { dashboardInstance } from '../lib/axios/hooks/useDashboardAxios';
import type { AssetOs } from '../types/__generated/on-premise-solution/api/assetOs.v1';
import type { AgentStatuses } from '../types/__generated/on-premise-solution/api/agentStatuses.v1';
import type {
  OSLinuxPlatforms,
  OSWindowsPlatforms,
} from '../types/__generated/on-premise-solution/api/oSPlatformsResponse.v1';
import type { InventorizationAssetsAge } from '../types/__generated/on-premise-solution/api/inventorizationAssetsAge.v1';
import type { AssetsResponse } from '../types/__generated/on-premise-solution/api/assetsResponse.v1';
import type { VulnerableAssetsResponse } from '../types/__generated/on-premise-solution/api/vulnerableAssetsResponse.v1';
import type { CvssScores } from '../types/__generated/on-premise-solution/api/cvssScores.v1';
import type { VulnerableSoftware } from '../types/__generated/on-premise-solution/api/vulnerableSoftware.v1';
import type { BulletinsWidgetResponse } from '../types/__generated/on-premise-solution/api/bulletinsWidgetResponse.v1';
import { vmInstance } from '../lib/axios/hooks/useVmAxios';
import type { IFilterVal } from '../utils/filtering-in-table-test';
import { prepareFilterVal } from './filterVal-for-service';
import type { AssetsHostsDashboardResponse } from '../types/__generated/on-premise-solution/api/assetsHostsDashboardResponse.v1';
import type { AssetImageDashboardResponse } from '../types/__generated/on-premise-solution/api/assetImageDashboardResponse.v1';
import type { AssetHostsAuditType } from '../types/__generated/on-premise-solution/api/assetHostsAuditType.v1';
import type { ContainerRegistriesDashboardResponse } from '../types/__generated/on-premise-solution/api/containerRegistriesDashboardResponse.v1';
import type { ContainerRegistryCreationRequest } from '../types/__generated/on-premise-solution/api/containerRegistryCreationRequest.v1';
import type { ContainerRegistryCreationResponse } from '../types/__generated/on-premise-solution/api/containerRegistryCreationResponse.v1';
import type { ContainerRegistryUpdateResponse } from '../types/__generated/on-premise-solution/api/containerRegistryUpdateResponse.v1';
import type { ContainerRegistryRepositoriesDashboardResponse } from '../types/__generated/on-premise-solution/api/containerRegistryRepositoriesDashboardResponse.v1';
import type { AssetDeletionResponse } from '../types/__generated/on-premise-solution/api/assetDeletionResponse.v1';
import type { ContainerRegistryDashboardResponse } from '../types/__generated/on-premise-solution/api/containerRegistryDashboardResponse.v1';
import type { AssetRouterDashboardResponse } from '../types/__generated/on-premise-solution/api/assetRouterDashboardResponse.v1';
import type {
  AssetFirewallDashboardResponse,
  AssetHypervisorDashboardResponse,
  AssetsDevicesDashboardResponse,
} from '../types/__generated/on-premise-solution/api/assetsDevicesDashboardResponse.v1';
import type { AssetHostDashboardResponse } from '../types/__generated/on-premise-solution/api/assetHostDashboardResponse.v1';
import type { AssetSwitchDashboardResponse } from '../types/__generated/on-premise-solution/api/assetDeviceDashboardResponse.v1';
import type { TotalAssetVulnerabillitiesResponse } from '../types/__generated/on-premise-solution/api/totalAssetVulnerabillitiesResponse.v1';
import type { AuditAssetsAgeResponse } from '../types/__generated/on-premise-solution/api/auditAssetsAgeResponse.v1';
import type { TotalAssetCount } from '../types/__generated/on-premise-solution/api/totalAssetCount.v1';
import type { SensorsResponse } from '../types/__generated/on-premise-solution/api/sensorsResponse.v1';
import type { SensorCreationRequest } from '../types/__generated/on-premise-solution/api/sensorCreationRequest.v1';
import type { SensorCreationResponse } from '../types/__generated/on-premise-solution/api/sensorCreationResponse.v1';
import type { SensorDashboardResponse } from '../types/__generated/on-premise-solution/api/sensorDashboardResponse.v1';
import type { SensorsDashboardResponse } from '../types/__generated/on-premise-solution/api/sensorsDashboardResponse.v1';
import type { SensorsDashboardResponseTree } from '../types/__generated/on-premise-solution/api/sensorsDashboardResponseTree.v1';
import type { SensorStatuses } from '../types/__generated/on-premise-solution/api/sensorStatuses.v1';
import type { UserCountGroupedByTypeResponse } from '../types/__generated/on-premise-solution/api/userCountGroupedByTypeResponse.v1';

export async function getAssetList(
  page: IPageState,
  filterVal: IFilterVal[],
  sort: Array<SortDescriptor>,
  assetHostIds: string[] | undefined | null,
  osType: string | undefined,
  accountId?: string,
  assetId?: string,
): Promise<AssetsHostsDashboardResponse> {
  const paramsData = {
    skip: page.skip,
    limit: page.take,
    sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
    sortOrder: sort[0].dir,
    ...prepareFilterVal(filterVal),
  };

  let result: AxiosResponse<AssetsHostsDashboardResponse, any>;

  if (!osType && document.location.pathname.includes('assets/images') && assetHostIds) {
    result = await dashboardInstance.post<AssetsHostsDashboardResponse>(
      '/assets/hosts',
      {
        assetId:
          assetHostIds && assetHostIds.length > 0
            ? {
                $in: assetHostIds && assetHostIds.length > 0 ? [...assetHostIds] : null,
              }
            : ' ',
      },
      {
        params: paramsData,

        paramsSerializer: (params) => {
          const stringifiedParams = qs.stringify(params, { encode: false });

          return stringifiedParams;
        },
      },
    );
  } else if (
    (!osType && document.location.pathname.includes('assets/groups') && assetHostIds) ||
    assetHostIds
  ) {
    result = await dashboardInstance.post<AssetsHostsDashboardResponse>(
      '/assets/hosts',
      {
        assetId:
          assetHostIds.length > 0
            ? {
                $in: assetHostIds && assetHostIds.length > 0 ? [...assetHostIds] : null,
              }
            : ' ',
      },
      {
        params: paramsData,
        paramsSerializer: (params) => {
          const stringifiedParams = qs.stringify(params, { encode: false });

          return stringifiedParams;
        },
      },
    );
  } else if (osType) {
    result = await dashboardInstance.get<AssetsHostsDashboardResponse>('/assets/hosts', {
      params: paramsData,
      paramsSerializer: (params) => {
        const stringifiedParams = qs.stringify(params, { encode: false });

        return `${stringifiedParams}&latestInventorization.os.type[$eq]=${osType}`;
      },
    });
  } else if (
    (accountId && accountId !== '000' && assetId) ||
    (accountId && accountId !== '000' && document.location.pathname.includes('groups')) ||
    (accountId && accountId !== '000' && document.location.pathname.includes('security/'))
  ) {
    result = await dashboardInstance.get<AssetsHostsDashboardResponse>('/assets/hosts', {
      params: paramsData,
      paramsSerializer: (params) => {
        const stringifiedParams = qs.stringify(params, { encode: false });

        return `${stringifiedParams}&accountId[$eq]=${accountId}`;
      },
    });
  } else {
    result = await dashboardInstance.get<AssetsHostsDashboardResponse>('/assets/hosts', {
      params: paramsData,

      paramsSerializer: (params) => {
        const stringifiedParams = qs.stringify(params, { encode: false });
        if (document.location.pathname.includes('assets/hosts')) {
          history.replace({
            pathname: `/lk/assets/hosts?${stringifiedParams}`,
          });
        }

        return stringifiedParams;
      },
    });
  }

  return result.data;
}

export async function getSensorList(
  page: IPageState,
  filterVal: IFilterVal[],
  sort: Array<SortDescriptor>,
  sensorIds?: string[] | null,
): Promise<SensorsDashboardResponse> {
  const params = {
    skip: page.skip,
    limit: page.take,
    sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
    sortOrder: sort[0].dir,
    ...prepareFilterVal(filterVal),
  };

  let result: AxiosResponse<SensorsDashboardResponse, any>;

  if (document.location.pathname.includes('sensors/') && sensorIds) {
    result = await dashboardInstance.post<SensorsDashboardResponse>(
      '/sensors',
      {
        sensorId:
          sensorIds && sensorIds.length > 0
            ? {
                $in: sensorIds && sensorIds.length > 0 ? [...sensorIds] : null,
              }
            : ' ',
      },
      {
        params,

        paramsSerializer: (p) => {
          const stringifiedParams = qs.stringify(p, { encode: false });

          return stringifiedParams;
        },
      },
    );
  } else if (document.location.pathname.includes('sensors') && sensorIds) {
    result = await dashboardInstance.post<SensorsDashboardResponse>(
      '/sensors',
      {
        sensorId:
          sensorIds && sensorIds.length > 0
            ? {
                $in: sensorIds && sensorIds.length > 0 ? [...sensorIds] : null,
              }
            : ' ',
      },
      {
        params,

        paramsSerializer: (p) => {
          const stringifiedParams = qs.stringify(p, { encode: false });
          history.replace({
            pathname: `/lk/sensors?${stringifiedParams}`,
          });

          return stringifiedParams;
        },
      },
    );
  } else {
    result = await dashboardInstance.get<SensorsDashboardResponse>('/sensors', {
      params,

      paramsSerializer: (p) => {
        const stringifiedParams = qs.stringify(p, { encode: false });
        history.replace({
          pathname: `/lk/sensors?${stringifiedParams}`,
        });

        return stringifiedParams;
      },
    });
  }

  return result.data;
}

export async function getSensorTreeList(
  page: IPageState,
  filterVal: IFilterVal[],
  sort: Array<SortDescriptor>,
): Promise<SensorsDashboardResponseTree> {
  const params = {
    skip: page.skip,
    limit: page.take,
    sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
    sortOrder: sort[0].dir,
    ...prepareFilterVal(filterVal),
  };

  const result = await dashboardInstance.get<SensorsDashboardResponseTree>(
    '/sensors/tree',

    {
      params,

      paramsSerializer: (p) => {
        const stringifiedParams = qs.stringify(p, { encode: false });

        return stringifiedParams;
      },
    },
  );

  return result.data;
}

export async function getAllSensors(
  filterVal?: IFilterVal[],
  sort?: Array<SortDescriptor>,
): Promise<SensorsResponse> {
  const result = await vmInstance.get<SensorsResponse>('/sensors', {
    // params: {
    //   skip: page.skip,
    //   limit: page.take,
    //   sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
    //   sortOrder: sort[0].dir,
    //   ...prepareFilterVal(filterVal),
    // },
    // paramsSerializer: (params) => {
    //   const stringifiedParams = qs.stringify(params, { encode: false });
    //   history.replace({
    //     pathname: `/lk/sensors?${stringifiedParams}`,
    //   });
    //   return stringifiedParams;
    // },
  });

  return result.data;
}

export async function getNetworkDevices(
  page: IPageState,
  filterVal: IFilterVal[],
  sort: Array<SortDescriptor>,
  assetDeviceIds: string[] | undefined | null,
  accountId?: string,
  assetId?: string,
): Promise<AssetsDevicesDashboardResponse> {
  let result: AxiosResponse<AssetsDevicesDashboardResponse, any>;

  const paramsData = {
    skip: page.skip,
    limit: page.take,
    sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
    sortOrder: sort[0].dir,
    ...prepareFilterVal(filterVal),
  };

  const paramsSerializer = document.location.pathname.includes('assets/devices')
    ? (p: any): string => {
        const stringifiedParams = qs.stringify(p, { encode: false });
        history.replace({
          pathname: `/lk/assets/devices?${stringifiedParams}`,
        });

        return stringifiedParams;
      }
    : (p: any): string => {
        const stringifiedParams = qs.stringify(p, { encode: false });

        return stringifiedParams;
      };

  if (assetDeviceIds) {
    result = await dashboardInstance.post<AssetsDevicesDashboardResponse>(
      '/assets/devices',
      {
        assetId:
          assetDeviceIds && assetDeviceIds.length > 0
            ? {
                $in: assetDeviceIds && assetDeviceIds.length > 0 ? [...assetDeviceIds] : null,
              }
            : ' ',
      },
      {
        params: paramsData,
        paramsSerializer,
      },
    );
  } else if (
    (accountId && accountId !== '000' && assetId) ||
    (accountId && accountId !== '000' && document.location.pathname.includes('groups')) ||
    (accountId && accountId !== '000' && document.location.pathname.includes('security/tickets'))
  ) {
    result = await dashboardInstance.get<AssetsDevicesDashboardResponse>('/assets/devices', {
      params: paramsData,

      paramsSerializer: (params) => {
        const stringifiedParams = qs.stringify(params, { encode: false });

        return `${stringifiedParams}&accountId[$eq]=${accountId}`;
      },
    });
  } else {
    result = await dashboardInstance.get<AssetsDevicesDashboardResponse>('/assets/devices', {
      params: paramsData,
      paramsSerializer,
    });
  }

  return result.data;
}

export async function getImageList(
  page: IPageState,
  filterVal: IFilterVal[],
  sort: Array<SortDescriptor>,
  assetImageIds: string[] | undefined | null,
  accountId?: string,
  assetId?: string,
): Promise<AssetsImagesDashboardResponse> {
  let result: AxiosResponse<AssetsImagesDashboardResponse, any>;

  const paramsData = {
    skip: page.skip,
    limit: page.take,
    sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
    sortOrder: sort[0].dir,
    ...prepareFilterVal(filterVal),
  };

  if (document.location.pathname.includes('assets/images')) {
    result = await dashboardInstance.get<AssetsImagesDashboardResponse>('/assets/images', {
      params: paramsData,

      paramsSerializer: (params) => {
        const stringifiedParams = qs.stringify(params, { encode: false });
        history.replace({
          pathname: `/lk/assets/images?${stringifiedParams}`,
        });

        return stringifiedParams;
      },
    });
  } else if (
    (accountId && accountId !== '000' && assetId) ||
    (accountId && accountId !== '000' && document.location.pathname.includes('groups')) ||
    (accountId && accountId !== '000' && document.location.pathname.includes('security/tickets'))
  ) {
    result = await dashboardInstance.get<AssetsImagesDashboardResponse>('/assets/images', {
      params: paramsData,

      paramsSerializer: (params) => {
        const stringifiedParams = qs.stringify(params, { encode: false });

        return `${stringifiedParams}&accountId[$eq]=${accountId}`;
      },
    });
  } else if (assetImageIds && typeof assetImageIds !== undefined) {
    result = await dashboardInstance.post<AssetsImagesDashboardResponse>(
      '/assets/images',
      {
        assetId:
          assetImageIds && assetImageIds.length > 0
            ? {
                $in: assetImageIds && assetImageIds.length > 0 ? [...assetImageIds] : null,
              }
            : ' ',
      },
      {
        params: paramsData,

        paramsSerializer: (params) => {
          const stringifiedParams = qs.stringify(params, { encode: false });

          return stringifiedParams;
        },
      },
    );
  } else {
    result = await dashboardInstance.get<AssetsImagesDashboardResponse>('/assets/images', {
      params: {
        skip: page.skip,
        limit: page.take,
        sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
        sortOrder: sort[0].dir,
        ...prepareFilterVal(filterVal),
      },

      paramsSerializer: (params) => {
        const stringifiedParams = qs.stringify(params, { encode: false });

        return stringifiedParams;
      },
    });
  }

  return result.data;
}

export async function deleteImage(assetId: string): Promise<AssetDeletionResponse | null> {
  const result = await vmInstance.delete<AssetDeletionResponse>(`/assets/${assetId}`);

  return result.data;
}
export async function getBulletinImages(
  page: IPageState,
  filterVal: IFilterVal[],
  sort: Array<SortDescriptor>,
  bulletinId: string | null,
): Promise<AssetsImagesDashboardResponse> {
  const result = await dashboardInstance.get<AssetsImagesDashboardResponse>('/assets/images', {
    params: {
      skip: page.skip,
      limit: page.take,
      sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
      sortOrder: sort[0].dir,
      '&bulletinId[$eq]': `${bulletinId}`,
      ...prepareFilterVal(filterVal),
    },

    paramsSerializer: (params) => {
      const stringifiedParams = qs.stringify(params, { encode: false });

      return stringifiedParams;
    },
  });

  return result.data;
}
export async function getBulletinImagesWl(
  page: IPageState,
  filterVal: IFilterVal[],
  sort: Array<SortDescriptor>,
  bulletinId: string | null,
): Promise<AssetsImagesDashboardResponse> {
  const result = await dashboardInstance.get<AssetsImagesDashboardResponse>('/assets/images', {
    params: {
      skip: page.skip,
      limit: page.take,
      sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
      sortOrder: sort[0].dir,
      '&whitelistedBulletinId[$eq]': `${bulletinId}`,
      ...prepareFilterVal(filterVal),
    },

    paramsSerializer: (params) => {
      const stringifiedParams = qs.stringify(params, { encode: false });

      return stringifiedParams;
    },
  });

  return result.data;
}
export async function getRegistryList(
  page: IPageState,
  filterVal: IFilterVal[],
  sort: Array<SortDescriptor>,
  registryIds: string[] | null | undefined,
): Promise<ContainerRegistriesDashboardResponse> {
  let result: AxiosResponse<ContainerRegistriesDashboardResponse, any>;

  if (document.location.pathname.includes('assets/images/')) {
    result = await dashboardInstance.get<ContainerRegistriesDashboardResponse>(
      'container-registries',
      {
        params: {
          skip: page.skip,
          limit: page.take,
          sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
          sortOrder: sort[0].dir,
          registryId: { $in: registryIds },
          ...prepareFilterVal(filterVal),
        },

        paramsSerializer: (params) => {
          const stringifiedParams = qs.stringify(params, { encode: false });

          return stringifiedParams;
        },
      },
    );
  } else if (document.location.pathname.includes('assets/registries/')) {
    result = await dashboardInstance.get<ContainerRegistriesDashboardResponse>(
      'container-registries',
      {
        params: {
          skip: page.skip,
          limit: page.take,
          sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
          sortOrder: sort[0].dir,
          ...prepareFilterVal(filterVal),
        },

        paramsSerializer: (params) => {
          const stringifiedParams = qs.stringify(params, { encode: false });
          history.replace({
            pathname: `/lk/assets/registries?${stringifiedParams}`,
          });

          return stringifiedParams;
        },
      },
    );
  } else {
    result = await dashboardInstance.get<ContainerRegistriesDashboardResponse>(
      'container-registries',
      {
        params: {
          skip: page.skip,
          limit: page.take,
          sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
          sortOrder: sort[0].dir,
          ...prepareFilterVal(filterVal),
        },

        paramsSerializer: (params) => {
          const stringifiedParams = qs.stringify(params, { encode: false });

          return stringifiedParams;
        },
      },
    );
  }

  return result.data;
}

export async function createRegistry(
  registerData: ContainerRegistryCreationRequest,
): Promise<ContainerRegistryCreationResponse> {
  const result = await vmInstance.post<ContainerRegistryCreationResponse>(
    'container-registries',
    registerData,
  );

  return result.data;
}

export async function createSensor(
  sensorData: SensorCreationRequest,
): Promise<SensorCreationResponse> {
  const result = await vmInstance.post<SensorCreationResponse>('sensors', sensorData);

  return result.data;
}

export async function editRegistry(
  registerData: IEditRegister,
): Promise<ContainerRegistryCreationResponse> {
  const result = await vmInstance.patch<ContainerRegistryUpdateResponse>(
    `container-registries/${registerData.registryId}`,
    registerData.payload,
  );

  return result.data;
}

export async function getTotalAssets(accountId?: string): Promise<AssetsResponse> {
  const result = await vmInstance.get<AssetsResponse>(
    `/assets?type=host${accountId ? `&accountId=${accountId}` : ''}`,
    {
      params: {
        limit: 0,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { encode: false });
      },
    },
  );

  return result.data;
}

export async function getAllTotalInfo(accountId?: string): Promise<TotalAssetCount> {
  const result = await dashboardInstance.get<TotalAssetCount>(
    `/widgets/assets/count${accountId ? `?accountId=${accountId}` : ''}`,
  );

  return result.data;
}

export async function getAgentsStatus(
  agentsIdArr: AgentsStatusRequest,
): Promise<AgentsStatusResponse> {
  if (agentsIdArr.length < 1) return [];
  const result = await dashboardInstance.post<AgentsStatusResponse>('/agents/status', agentsIdArr);

  return result.data;
}

export async function getAssetDashboard(assetId: string): Promise<AssetHostDashboardResponse> {
  const result = await dashboardInstance.get<AssetHostDashboardResponse>(
    `/assets/hosts/${assetId}`,
  );

  return result.data;
}

export async function getSensor(sensorId: string): Promise<SensorDashboardResponse> {
  const result = await dashboardInstance.get<SensorDashboardResponse>(`/sensors/${sensorId}`);

  return result.data;
}

export async function getImage(assetId: string): Promise<AssetImageDashboardResponse> {
  const result = await dashboardInstance.get<AssetImageDashboardResponse>(
    `/assets/images/${assetId}`,
  );

  return result.data;
}

export async function getRegistry(
  registryId: string | undefined,
): Promise<ContainerRegistryDashboardResponse> {
  const result = await dashboardInstance.get<ContainerRegistryDashboardResponse>(
    `/container-registries/${registryId}`,
  );

  return result.data;
}

export async function getDeviceRouter(assetId: string): Promise<AssetRouterDashboardResponse> {
  const result = await dashboardInstance.get<AssetRouterDashboardResponse>(
    `/assets/routers/${assetId}`,
  );

  return result.data;
}

export async function getDeviceFirewall(assetId: string): Promise<AssetFirewallDashboardResponse> {
  const result = await dashboardInstance.get<AssetFirewallDashboardResponse>(
    `/assets/firewalls/${assetId}`,
  );

  return result.data;
}

export async function getDeviceSwitch(assetId: string): Promise<AssetSwitchDashboardResponse> {
  const result = await dashboardInstance.get<AssetSwitchDashboardResponse>(
    `/assets/switches/${assetId}`,
  );

  return result.data;
}

export async function getDeviceHypervisor(
  assetId: string,
): Promise<AssetHypervisorDashboardResponse> {
  const result = await dashboardInstance.get<AssetHypervisorDashboardResponse>(
    `/assets/hypervisors/${assetId}`,
  );

  return result.data;
}

export async function getRepositories(
  registryId: string | undefined,
  page: IPageState,
  filterVal: IFilterVal[],
  sort: SortDescriptor[],
): Promise<ContainerRegistryRepositoriesDashboardResponse> {
  const result = await dashboardInstance.get<ContainerRegistryRepositoriesDashboardResponse>(
    `/container-registries/${registryId}/repositories`,
    {
      params: {
        skip: page.skip,
        limit: page.take,
        sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
        sortOrder: sort[0].dir,
        ...prepareFilterVal(filterVal),
      },

      paramsSerializer: (params) => {
        const stringifiedParams = qs.stringify(params, { encode: false });
        if (document.location.pathname.includes('repositories')) {
          history.replace({
            pathname: `/lk/assets/registries/${registryId}/repositories?${stringifiedParams}`,
          });
        }

        return stringifiedParams;
      },
    },
  );

  return result.data;
}

export async function getAssetDashboardOs(): Promise<AssetOs> {
  const result = await dashboardInstance.get<AssetOs>('/widgets/assets/hosts/asset-os');

  return result.data;
}

export async function getAgentStatusesDashboard(accountId?: string): Promise<AgentStatuses> {
  const result = await dashboardInstance.get<AgentStatuses>(
    `/widgets/agent-statuses${accountId ? `?accountId=${accountId}` : ''}`,
  );

  return result.data;
}
export async function getSensorStatusesDashboard(accountId?: string): Promise<SensorStatuses> {
  const result = await dashboardInstance.get<SensorStatuses>(
    `/widgets//sensors/statuses${accountId ? `?accountId=${accountId}` : ''}`,
  );

  return result.data;
}

export async function getUsersDashboard(
  accountId?: string,
): Promise<UserCountGroupedByTypeResponse> {
  const result = await dashboardInstance.get<UserCountGroupedByTypeResponse>(
    `/widgets/users/types${accountId ? `?accountId=${accountId}` : ''}`,
  );

  return result.data;
}

export async function getOsPlatformsLinux(): Promise<OSLinuxPlatforms> {
  const result = await dashboardInstance.get<OSLinuxPlatforms>(
    '/widgets/assets/hosts/os-platforms/linux',
    {
      params: {
        limit: 10,
        sortOrder: 'desc',
      },
    },
  );

  return result.data;
}

export async function getOsPlatformsWindows(): Promise<OSWindowsPlatforms> {
  const result = await dashboardInstance.get<OSWindowsPlatforms>(
    '/widgets/assets/hosts/os-platforms/windows',
    {
      params: {
        limit: 10,
        sortOrder: 'desc',
      },
    },
  );

  return result.data;
}

export async function getInventarizationAssetsAge(
  accountId?: string,
): Promise<InventorizationAssetsAge> {
  const result = await dashboardInstance.get<InventorizationAssetsAge>(
    `/widgets/assets/hosts/inventorization-assets-age${accountId ? `?accountId=${accountId}` : ''}`,
  );

  return result.data;
}

// export async function getAssetsTypes(): Promise<AssetTypes> {
//   const result = await dashboardInstance.get<AssetTypes>('/widgets/asset-types');

//   return result.data;
// }

export async function getAssetHostsAuditType(): Promise<AssetHostsAuditType> {
  const result = await dashboardInstance.get<AssetHostsAuditType>(
    '/widgets/assets/hosts/audit-types',
  );

  return result.data;
}

export async function getVulnerableAssets(
  sortField?: string,
  accountId?: string,
): Promise<VulnerableAssetsResponse> {
  const params: {
    limit: number;
    sortOrder: string;
    sortField?: string;
    accountId?: string;
  } = {
    limit: 10,
    sortOrder: 'desc',
    accountId,
  };
  if (sortField) params.sortField = sortField;
  const result = await dashboardInstance.get<VulnerableAssetsResponse>(
    '/widgets/assets/hosts/vulnerable-assets',
    {
      params,
    },
  );

  return result.data;
}

export async function getCvssScore(): Promise<CvssScores> {
  const result = await dashboardInstance.get<CvssScores>('/widgets/assets/cvss-scores');

  return result.data;
}

export async function getVulnerableSoftware(): Promise<VulnerableSoftware> {
  const result = await dashboardInstance.get<VulnerableSoftware>(
    '/widgets/assets/hosts/vulnerable-software',
    {
      params: {
        limit: 10,
        sortOrder: 'desc',
      },
    },
  );

  return result.data;
}

export async function getBulletins(accountId?: string): Promise<BulletinsWidgetResponse> {
  const result = await dashboardInstance.get<BulletinsWidgetResponse>(
    '/widgets/assets/hosts/bulletins',
    {
      params: {
        limit: 10,
        sortOrder: 'desc',
        accountId,
      },
    },
  );

  return result.data;
}

export async function getTotalVulnerabilities(
  accountId?: string,
): Promise<TotalAssetVulnerabillitiesResponse> {
  const result = await dashboardInstance.get<TotalAssetVulnerabillitiesResponse>(
    `/widgets/assets/total-vulnerabillities${accountId ? `?accountId=${accountId}` : ''}`,
  );

  return result.data;
}

export async function getAuditAssetAge(accountId?: string): Promise<AuditAssetsAgeResponse> {
  const result = await dashboardInstance.get<AuditAssetsAgeResponse>(
    `/widgets/assets/audit-age${accountId ? `?accountId=${accountId}` : ''}`,
  );

  return result.data;
}
