/* eslint-disable react/jsx-no-useless-fragment */
import { NavLink } from 'react-router-dom';
import styles from '../TaskList.module.scss';

export function ScopeElement({
  element,
  className,
}: {
  element: {
    id: string;
    name: string | null;
    taskType?: string | undefined;
    deviceType?: string | undefined;
  };
  className?: string;
}): React.ReactElement {
  if (
    element.taskType === 'device' ||
    element.taskType === 'switch' ||
    element.deviceType === 'firewall' ||
    element.deviceType === 'router' ||
    element.deviceType === 'hypervisor'
  )
    return (
      <div className={`${styles.scope} ${className || ''}`} title={element.name || element.id}>
        <span className={`k-icon devices-menu-icon ${styles.scope_type}`} />
        <NavLink to={`/lk/assets/devices/${element.deviceType}/${element.id}`}>
          <span>{element.name || element.id}</span>
        </NavLink>
      </div>
    );

  if (element.taskType === 'assetgroup')
    return (
      <div className={`${styles.scope} ${className || ''}`} title={element.name || element.id}>
        <span className={`k-icon host-groups-menu-icon ${styles.scope_type}`} />
        <NavLink to={`/lk/assets/groups/static/${element.id}`}>
          <span>{element.name || element.id}</span>
        </NavLink>
      </div>
    );

  if (element.taskType === 'assetDynamicgroup')
    return (
      <div className={`${styles.scope} ${className || ''}`} title={element.name || element.id}>
        <span className={`k-icon host-groups-menu-icon ${styles.scope_type}`} />
        <NavLink to={`/lk/assets/groups/dynamic/${element.id}`}>
          <span>{element.name || element.id}</span>
        </NavLink>
      </div>
    );

  if (element.taskType === 'assetDynamicgroupId')
    return (
      <div className={`${styles.scope} ${className || ''}`} title={element.name || element.id}>
        <span className={`k-icon host-groups-menu-icon ${styles.scope_type}`} />
        <span>{element.name || element.id}</span>
      </div>
    );

  if (element.taskType === 'registry')
    return (
      <div className={`${styles.scope} ${className || ''}`} title={element.name || element.id}>
        <span className={`k-icon images-menu-icon ${styles.scope_type}`} />
        <NavLink to={`/lk/assets/registries/${element.id}/repositories`}>
          <span>{element.name || element.id}</span>
        </NavLink>
      </div>
    );

  if (element.taskType === 'report' && element.deviceType === 'image')
    return (
      <div className={`${styles.scope} ${className || ''}`} title={element.name || element.id}>
        <span className={`k-icon images-menu-icon ${styles.scope_type}`} />
        <NavLink to={`/lk/assets/images/${element.id}`}>
          <span>{element.name || element.id}</span>
        </NavLink>
      </div>
    );

  if (element.taskType === 'asset' && element.deviceType === 'image')
    return (
      <div className={`${styles.scope} ${className || ''}`} title={element.name || element.id}>
        <span className={`k-icon images-menu-icon ${styles.scope_type}`} />
        <NavLink to={`/lk/assets/images/${element.id}`}>
          <span>{element.name || element.id}</span>
        </NavLink>
      </div>
    );

  return (
    <div className={`${styles.scope} ${className || ''}`} title={element.name || element.id}>
      <span className={`k-icon hosts-menu-icon ${styles.scope_type}`} />
      <NavLink to={`/lk/assets/hosts/${element.id}`}>
        <span>{element.name || element.id}</span>
      </NavLink>
    </div>
  );
}
