import React, {
  createContext,
  useState,
  useMemo,
  useContext,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useMutation, useQueryClient } from 'react-query';
import type { RadioButtonChangeEvent } from '@progress/kendo-react-inputs';
import type { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import type {
  IChildrenNodes,
  IDeviceForm,
  INetDeviceData,
  TAddDeviceData,
  TNetDeviceContent,
} from '../utils/helpers/types';
import type { ApiError } from '../types/__generated/on-premise-solution/api/apiError.v1';
import { getAccountId } from '../services/local-storage-service';
import { useCommonContext } from './useCommonContext';
import type { AssetCreationRequest } from '../types/__generated/on-premise-solution/api/assetCreationRequest.v1';
import { createNetDevice } from '../services/asset-service';
import { useCredentialContext } from './useCredentialContext';
import properties from '../types/__generated/on-premise-solution/api/assetsDevicesDashboardResponse.v1.schema.json';
import { useAssetCreatePropContext } from './useAssetCreatePropContext';
import localeDeviceForm from '../utils/i18n/devices/devices.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeDeviceForm;

const NetDeviceContext = createContext<TNetDeviceContent | null>(null);

export function NetDeviceProvider({ children }: IChildrenNodes): React.ReactElement {
  const queryClient = useQueryClient();

  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const accountId = getAccountId();

  const devResponse = properties.properties.data.items.oneOf;

  const [vendor, setVendor] = useState<string>(localeDeviceForm[currentLocale].form.selectClass);

  const [typeOfDevice, setTypeOfDevice] = useState('router');

  const [routerVendor, setRouterVendor] = useState<string[]>([]);

  const [switchVendor, setSwitchVendor] = useState<string[]>([]);

  const [firewallVendor, setFirewallVendor] = useState<string[]>([]);

  const [hypervisorVendor, setHypervisorVendor] = useState<string[]>([]);

  const [ip, setIp] = useState<string>('');

  const [isAddNetDevice, setAddNetDevice] = useState(false);
  const [netDeviceModalData, setNetDeviceModalData] = useState<TAddDeviceData>(undefined);

  const [netDeviceData, setNetDeviceData] = useState<INetDeviceData | null>(null); // registerData for edit, need change type interface;

  const [name, setName] = useState('');

  const [description, setDescription] = useState<string>('');

  const [deviceForm, setDeviceForm] = useState<IDeviceForm[] | null>(null);

  const [deviceFormTemp, setDeviceFormTemp] = useState<IDeviceForm[] | null>(null);

  const [isAddDevice, setAddDevice] = useState(false);

  const [errorMutation, setErrorMutation] = useState('');

  const initialNetDeviceFields = useMemo(
    () => ({
      type: netDeviceData ? netDeviceData.type : '',
      class: netDeviceData ? netDeviceData.class : '',
      ip: netDeviceData ? netDeviceData.ip : '',
    }),
    [netDeviceData],
  );

  const { setResultCheckIp } = useCommonContext();

  const { setCheckCredentilsResp, credentialDev, setCredentialDev } = useCredentialContext();

  const { selectedImportance, perimeterImpact } = useAssetCreatePropContext();

  useEffect(() => {
    if (devResponse) {
      devResponse.forEach((a) => {
        if (a.allOf) {
          a.allOf.forEach((h) => {
            if (h.title === 'AssetHypervisor') {
              setHypervisorVendor(h.properties.class.enum);
            }
          });
        }
        a.oneOf?.forEach((val) => {
          val.allOf.forEach((v) => {
            if (v.title === 'AssetRouter') {
              setRouterVendor(v.properties.class.enum);
            }
            if (v.title === 'AssetSwitch') {
              setSwitchVendor(v.properties.class.enum);
            }
            if (v.title === 'AssetFirewall') {
              setFirewallVendor(v.properties.class.enum);
            }
          });
        });
      });
    }
  }, [devResponse]);

  const mutationAdd = useMutation<
    Omit<AssetCreationRequest, 'AssetHostCreationRequest'>,
    ApiError,
    Omit<AssetCreationRequest, 'AssetHostCreationRequest'>
  >((payload) => createNetDevice(payload), {
    onSuccess: () => {
      setAddNetDevice(false);
      setNetDeviceModalData(undefined);
      setNetDeviceData(null);
      setCredentialDev(undefined);
      setIp('');
      setCheckCredentilsResp(undefined);
      setTypeOfDevice('router');
      queryClient.invalidateQueries('NetworkDevices');
      queryClient.invalidateQueries('sensorDescription');
      if (document.location.pathname.includes('/accounts/')) {
        queryClient.invalidateQueries('accountDescription');
        queryClient.invalidateQueries('allTotalAssetsByAccount');
      }
      setVendor(localeDeviceForm[currentLocale].form.selectClass);
    },
    onError: (resp) => {
      setErrorMutation(resp.message);
    },
  });

  const onSubmitDevice = useCallback(
    (dataItem: any, sensorId?: string | null): void => {
      const netDevicePayload = {
        type: typeOfDevice,
        class: vendor,
        accountId: netDeviceModalData?.accountId || accountId,
        ip,
        credentialId: credentialDev?.credentialId,
        importance: selectedImportance?.val,
        perimeterImpact: perimeterImpact ? 1 : 0.5,
        subType: typeOfDevice === 'hypervisor' ? 'server' : 'network_device',
        sensorId,
      } as Omit<AssetCreationRequest, 'AssetHostCreationRequest'>;
      // if (netDevicePayload) {
      //   // mutationEdit.mutate({
      //   //   payload: containerRegistryCreationData,
      //   //   registryId: netDeviceData.registryId,
      //   // });
      // } else {
      //   // mutationAdd.mutate(containerRegistryCreationData);
      // }
      mutationAdd.mutate(netDevicePayload);
    },
    [
      accountId,
      credentialDev?.credentialId,
      ip,
      mutationAdd,
      netDeviceModalData?.accountId,
      perimeterImpact,
      selectedImportance?.val,
      typeOfDevice,
      vendor,
    ],
  );

  const cancelAddEdit = useCallback((): void => {
    setAddNetDevice(false);
    setCredentialDev(undefined);
    setNetDeviceData(null);
    setIp('');
    setResultCheckIp(null);
    setCheckCredentilsResp(undefined);
    setTypeOfDevice('router');
    setVendor(localeDeviceForm[currentLocale].form.selectClass);

    setNetDeviceModalData(undefined);
  }, [setCheckCredentilsResp, setCredentialDev, setResultCheckIp]);

  const editIp = useCallback(
    (val: string): void => {
      setResultCheckIp(null);
      setIp(val);
    },
    [setResultCheckIp],
  );

  // const setCredential = useCallback(
  //   (credential: ISelectedCredential | undefined): void => {
  //     // setCheckCredentilsResp(undefined);
  //     setSelectedCredential(credential);
  //   },
  //   [setSelectedCredential],
  // );

  // const changeType = useCallback((e: RadioButtonChangeEvent) => {
  //   setTypeOfDevice(e.value);
  //   setVendor(localeDeviceForm[currentLocale].form.selectClass);
  // }, []);
  const changeType = useCallback((e: RadioButtonChangeEvent) => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    setTypeOfDevice(e.value);

    setVendor(localeDeviceForm[currentLocale].form.selectClass);

    timer.current = setTimeout(() => {
      setAddNetDevice(false);
      setAddNetDevice(true);
    }, 0);
  }, []);

  const exitDeviceFromTask = useCallback(() => {
    setDeviceFormTemp(null);
    setAddDevice(false);
  }, []);

  const delAllDevices = useCallback((): void => {
    setDeviceFormTemp(null);
    setDeviceForm(null);
  }, []);

  const deleteDeviceFromTask = useCallback(
    (assetId: string | undefined, event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      event.stopPropagation();
      if (deviceForm && deviceForm.length > 0) {
        setDeviceForm(deviceForm?.filter((device) => device.assetId !== assetId));
      }
      if (deviceFormTemp && deviceFormTemp.length > 0) {
        setDeviceFormTemp(deviceFormTemp?.filter((device) => device.assetId !== assetId));
      }
    },
    [deviceForm, deviceFormTemp],
  );

  const addSelectedDevices = useCallback((): void => {
    setDeviceForm(deviceFormTemp);
    setAddDevice(false);
  }, [deviceFormTemp]);

  const changeVendor = useCallback((event: DropDownListChangeEvent): void => {
    setVendor(event.target.value);
  }, []);

  const selectDeviceInHeaderMenunu = useCallback((deviceType: string): void => {
    setTypeOfDevice(deviceType);
    setAddNetDevice(true);
  }, []);

  const value = useMemo(
    () => ({
      isAddNetDevice,
      setAddNetDevice,
      netDeviceModalData,
      setNetDeviceModalData,
      netDeviceData,
      setNetDeviceData,
      initialNetDeviceFields,
      name,
      setName,
      onSubmitDevice,
      description,
      setDescription,
      cancelAddEdit,
      ip,
      editIp,
      typeOfDevice,
      changeType,
      deviceForm,
      setDeviceForm,
      exitDeviceFromTask,
      delAllDevices,
      isAddDevice,
      setAddDevice,
      deleteDeviceFromTask,
      deviceFormTemp,
      setDeviceFormTemp,
      addSelectedDevices,
      changeVendor,
      errorMutation,
      setErrorMutation,
      routerVendor,
      switchVendor,
      firewallVendor,
      hypervisorVendor,
      vendor,
      setVendor,
      selectDeviceInHeaderMenunu,
    }),
    [
      isAddNetDevice,
      netDeviceModalData,
      netDeviceData,
      initialNetDeviceFields,
      name,
      onSubmitDevice,
      description,
      cancelAddEdit,
      ip,
      editIp,
      typeOfDevice,
      changeType,
      deviceForm,
      exitDeviceFromTask,
      delAllDevices,
      isAddDevice,
      deleteDeviceFromTask,
      deviceFormTemp,
      addSelectedDevices,
      changeVendor,
      errorMutation,
      routerVendor,
      switchVendor,
      firewallVendor,
      hypervisorVendor,
      vendor,
      selectDeviceInHeaderMenunu,
    ],
  );

  return <NetDeviceContext.Provider value={value}>{children}</NetDeviceContext.Provider>;
}
export const useNetDeviceContext = (): TNetDeviceContent => {
  const context = useContext(NetDeviceContext);
  if (context === null) {
    throw new Error('NetDeviceContext must be used inside the ModalContext.Provider.');
  }

  return context;
};
