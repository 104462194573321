import type { GridCellProps } from '@progress/kendo-react-grid';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Button } from '../../../../common/baseElements/Button';
import { deleteAsset } from '../../../../../services/asset-service';
import type { ApiError } from '../../../../../types/__generated/on-premise-solution/api/apiError.v1';
import type { Asset } from '../../../../../types/__generated/on-premise-solution/api/asset.v1';
import type { AssetDeletionResponse } from '../../../../../types/__generated/on-premise-solution/api/assetDeletionResponse.v1';
import localeAsset from '../../../../../utils/i18n/assetLocales/assetList.json';
import { useCreateTask } from '../../../../../hooks/components/useCreateTask';
import { getUserData } from '../../../../../services/local-storage-service';
import { DeleteConfirmModal } from '../../../../common/modal/DeleteConfirmModal';
import { useTicketContext } from '../../../../../hooks/useTicketContext';
import { useHostModalContext } from '../../../../../hooks/useHostModalContext';
import { useAccountModalContext } from '../../../../../hooks/useAccountModalContext';
import { useCommonContext } from '../../../../../hooks/useCommonContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAsset;

export function MenuHostList(props: GridCellProps): React.ReactElement {
  const currentUser = getUserData();

  const { dataItem } = props;

  const { account } = dataItem;

  const queryClient = useQueryClient();

  const [isConfirm, setConfirm] = useState(false);

  const mutation = useMutation<AssetDeletionResponse | null, ApiError, Asset['assetId']>(
    (payload) => deleteAsset(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('hostList');
      },
    },
  );

  const handleConfirm = (): void => {
    setConfirm(true);
  };

  const handleDelete = (assetId: string): void => {
    mutation.mutate(assetId);
    setConfirm(false);
  };

  const { createTaskForAsset } = useCreateTask();

  const { setAddTicket } = useTicketContext();

  const { setHostForm } = useHostModalContext();

  const { setAccountId } = useAccountModalContext();

  const { setAssetId } = useCommonContext();

  const createTicket = (): void => {
    setAccountId({ id: account.accountId || '', name: account.name });
    setAssetId(dataItem.assetId);
    setAddTicket(true);
    setHostForm([
      {
        assetId: dataItem.assetId,
        hostname: dataItem.latestInventorization
          ? dataItem.latestInventorization.hostname
          : dataItem.assetId,
      },
    ]);
  };

  return (
    <>
      {isConfirm && (
        <DeleteConfirmModal
          type="asset"
          name={
            dataItem?.latestInventorization && dataItem?.latestInventorization.hostname
              ? dataItem?.latestInventorization.hostname
              : dataItem?.assetId
          }
          handleDelete={(): void => handleDelete(dataItem.assetId)}
          onClose={(): void => setConfirm(false)}
          errorMessage={mutation.error?.message}
        />
      )}
      <td className="cell_menu">
        <div className="groups__menu">
          <div className="dropdown__container">
            <span className="k-icon k-i-more-vertical" />
            <div className="dropdown__content">
              <Button
                fill="menu"
                onClick={(): void => {
                  createTaskForAsset({
                    val: 'inventorization',
                    data: dataItem,
                    assetId: dataItem.assetId,
                  });
                }}
              >
                {localeAsset[currentLocale].btnCreateTask}
              </Button>
              <Button fill="menu" onClick={createTicket}>
                {localeAsset[currentLocale].btnCreateTicket}
              </Button>
              {currentUser?.role !== 'reader_user' && (
                <Button
                  fill="menu"
                  onClick={handleConfirm}
                  disabled={
                    currentUser?.role === 'user' && currentUser.id !== dataItem.createdBy.id
                  }
                >
                  {localeAsset[currentLocale].btnDelete}
                </Button>
              )}
            </div>
          </div>
        </div>
      </td>
    </>
  );
}
