/* eslint-disable react/jsx-no-useless-fragment */
import { Field } from '@progress/kendo-react-form';
import { DropDownSelect } from '../../../../common/form/DropDownSelect';
import styles from '../TicketSystem.module.scss';
import { useTicketContext } from '../../../../../hooks/useTicketContext';
import { CommonTooltip } from '../../../../common/CommonTooltip';
import localeTicket from '../../../../../utils/i18n/security/vulnerabilities.json';
import { useTicketSystemContext } from '../../../../../hooks/useTicketSystemContext';

export function TicketYandexTrackerQueueNameForm(): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeTicket;

  const { ticketSystemType } = useTicketContext();

  const {
    editTicketSystemData,
    setYandexTrackerQueueId,
    yandexTrackerQueueId,
    yandexTrackerQueueIds,
  } = useTicketSystemContext();

  return (
    <>
      {ticketSystemType === 'yandex_tracker' && (
        <>
          <div className={styles.jiraProjectIdsLabelWrap}>
            <span className={!editTicketSystemData ? 'required-label' : ''}>
              {localeTicket[currentLocale].ticketSystem.form.yandexTrackerQueueName}
            </span>
            <CommonTooltip
              visibleStyle={{ marginLeft: '8px' }}
              hiddenStyle={{ marginLeft: '32px', marginBottom: '20px' }}
            >
              {localeTicket[currentLocale].ticketSystem.form.yaQueueNameInfo}
            </CommonTooltip>
          </div>
          <Field
            name="yandexTrackerQueueId"
            component={DropDownSelect}
            onChange={(e): void => setYandexTrackerQueueId(e.target.value.id)}
            data={yandexTrackerQueueIds?.filter((d) => d.id !== yandexTrackerQueueId)}
            textField="name"
            dataItemKey="id"
            defaultValue={{ name: '-', id: '-' }}
            cls="form-field-wrapper"
            currentValue={
              yandexTrackerQueueId &&
              yandexTrackerQueueIds &&
              yandexTrackerQueueIds.filter((j) => String(j.id) === String(yandexTrackerQueueId))
                .length > 0
                ? {
                    name: yandexTrackerQueueIds.filter(
                      (j) => String(j.id) === String(yandexTrackerQueueId),
                    )[0].name,
                    id: yandexTrackerQueueIds.filter(
                      (j) => String(j.id) === String(yandexTrackerQueueId),
                    )[0].id,
                  }
                : { name: '-', id: '-' }
            }
            disabled={!yandexTrackerQueueIds}
          />
        </>
      )}
    </>
  );
}
