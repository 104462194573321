import { Field } from '@progress/kendo-react-form';
import { useEffect } from 'react';
import localeSettings from '../../../../utils/i18n/settingsLocales/settings.json';
import styles from '../WhiteListTabEl.module.scss';
import { useWhiteListContext } from '../../../../hooks/useWhiteListContext';
import { FormInput } from '../../../common/form/FormInput';
import { FormTextArea } from '../../../common/form/FormTextArea';
import { useAccounts } from '../../../../hooks/components/useAccounts';
import { DropDownSelect } from '../../../common/form/DropDownSelect';
import { useAccountModalContext } from '../../../../hooks/useAccountModalContext';
import { getUserData } from '../../../../services/local-storage-service';
import localeUser from '../../../../utils/i18n/userLocales/userList.json';
import { checkLength } from '../../../../utils/helpers/validation';
import { useHostModalContext } from '../../../../hooks/useHostModalContext';
import { useNetDeviceContext } from '../../../../hooks/useNetDeviceContext';
import { useRegisterContext } from '../../../../hooks/useRegisterContext';
import type { TAssets, TValuesForCheckbox } from '../../../../utils/helpers/types';
import { useCommonContext } from '../../../../hooks/useCommonContext';
import { useGroupContext } from '../../../../hooks/useGroupContext';
import { WhiteListScopeCheckboxBlock } from './WhiteListScopeCheckboxBlock';
import { SoftwareCheckboxBlock } from './SoftwareCheckboxBlock';
import { useWhiteListMethods } from '../../../../hooks/components/useWhiteListMethods';

export function WhiteListFormMainPage({
  valuesForCheckbox,
}: {
  valuesForCheckbox: TValuesForCheckbox;
}): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeSettings;

  const currentUser = getUserData();

  const { setHosts, setDevices, setImages, setIsAssetType, setBulletins, setSoftware } =
    valuesForCheckbox;

  const { changeDropDownAccount, accountId, setAccountId } = useAccountModalContext();

  const { destructuringAccounts } = useAccounts();

  const destructuredAccounts = destructuringAccounts();

  const { imageForm } = useRegisterContext();

  const { whitelistUpdate } = useWhiteListContext();

  const {
    name,
    setName,
    description,
    setDescription,
    whiteListStatus,
    whiteListStatusValues,
    assetType,
    bulletinForm,
  } = useWhiteListContext();

  const { changeDropDownStatus } = useWhiteListMethods();

  const { accountOfAsset } = useCommonContext();

  const { hostForm, softwareInWl } = useHostModalContext();

  const { deviceForm } = useNetDeviceContext();

  const { dynamicGroupForm } = useGroupContext();

  useEffect(() => {
    if (
      (document.location.pathname.includes('/lk/assets/') && accountOfAsset) ||
      (document.location.pathname.includes('/lk/task/actions/') && accountOfAsset)
    ) {
      setAccountId({
        id: accountOfAsset?.accountId || '',
        name: accountOfAsset?.name || '',
      });
    }
  }, [accountOfAsset, accountOfAsset?.accountId, accountOfAsset?.name, setAccountId]);

  useEffect(() => {
    if (bulletinForm && bulletinForm.length > 0) {
      setBulletins(true);
    }
    if ((hostForm && hostForm.length > 0) || (dynamicGroupForm && dynamicGroupForm.length > 0)) {
      setHosts(true);
    }
    if (deviceForm && deviceForm.length > 0) {
      setDevices(true);
    }
    if (imageForm && imageForm.length > 0) {
      setImages(true);
    }
    if (softwareInWl && Object.keys(softwareInWl).length > 0) {
      setSoftware(true);
    }
  }, [
    bulletinForm,
    deviceForm,
    dynamicGroupForm,
    hostForm,
    imageForm,
    setBulletins,
    setDevices,
    setHosts,
    setImages,
    setSoftware,
    softwareInWl,
  ]);

  useEffect(() => {
    if (whitelistUpdate) {
      if (whitelistUpdate.scope.assets) {
        whitelistUpdate.scope.assets.forEach((a: TAssets) => {
          if (a.type === 'host' && hostForm) {
            setHosts(true);
          }
          if (a.type === 'image' && imageForm) {
            setImages(true);
          }
          if ((a.type === 'firewall' || a.type === 'router' || a.type === 'switch') && deviceForm) {
            setDevices(true);
          }
        });
      }
      if (whitelistUpdate.scope.assetGroupIds && hostForm) {
        setHosts(true);
      }
      if (whitelistUpdate.scope.assetDynamicGroups && dynamicGroupForm) {
        setHosts(true);
      }
      if (
        whitelistUpdate.scope.assetTypes &&
        (assetType?.all ||
          assetType?.firewall ||
          assetType?.host ||
          assetType?.image ||
          assetType?.router ||
          assetType?.switch)
      ) {
        setIsAssetType(true);
      }
    }
  }, []);

  return (
    <>
      <div className={`form-block ${styles.formFieldWidth}`}>
        {!whitelistUpdate && currentUser?.role === 'super_admin' && (
          <Field
            name="accountId"
            component={DropDownSelect}
            label={localeUser[currentLocale].modal.accountName}
            onChange={changeDropDownAccount}
            data={destructuredAccounts?.filter((a) => a.id !== accountId.id)}
            textField="name"
            dataItemKey="id"
            defaultValue={{
              id: '000',
              name: localeUser[currentLocale].modal.placeholderAccountName,
            }}
            cls="form-field-wrapper"
            classForLabel={!whitelistUpdate ? 'required-label' : ''}
            currentValue={accountId}
            disabled={!destructuredAccounts}
          />
        )}
        <Field
          name="name"
          component={FormInput}
          id="name"
          label={localeSettings[currentLocale].whiteList.form.name}
          onChange={(e): void => {
            setName(e.target.value.trimStart());
          }}
          inputValue={name}
          className="form-field-wrapper"
          classForLabel={!whitelistUpdate ? 'required-label' : ''}
          customValidator={checkLength}
          type="text"
          required
        />
        <Field
          name="isDisabled"
          component={DropDownSelect}
          label={localeUser[currentLocale].modal.status}
          onChange={changeDropDownStatus}
          data={whiteListStatusValues
            .filter((s) => s.text !== whiteListStatus.text)
            .map((v) => v.text)}
          defaultValue={localeUser[currentLocale].active}
          cls="form-field-wrapper"
          currentValue={whiteListStatus.text}
        />
      </div>
      <SoftwareCheckboxBlock valuesForCheckbox={valuesForCheckbox} />
      <WhiteListScopeCheckboxBlock valuesForCheckbox={valuesForCheckbox} />
      <div className={styles.apiDescriptionField}>
        <Field
          name="description"
          component={FormTextArea}
          id="description"
          label={localeSettings[currentLocale].apiKey.form.description}
          onChange={(e): void => {
            setDescription(e.value);
          }}
          inputValue={description || ''}
        />
      </div>
    </>
  );
}
