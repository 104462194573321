/* eslint-disable no-nested-ternary */
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { useCallback, useEffect, useState } from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Button } from '../../../common/baseElements/Button';
import locale from '../../../../utils/i18n/credentials/credentials.json';
import localeButtons from '../../../../utils/i18n/commonLocales/buttons.json';
import { useCredential } from '../../../../hooks/components/useCredential';
import { HostsMutableBlock } from '../../../common/form/HostsMutableBlock';
import { HostListForForm } from '../../../common/form/HostListForForm';
import { useHostModalContext } from '../../../../hooks/useHostModalContext';
import { LoginPassForm } from './LoginPassForm';
import { PrivateKeyForm } from './PrivateKeyForm';
import { useCommonContext } from '../../../../hooks/useCommonContext';
import styles from '../../Host/forms/AddAssetForm.module.scss';
import { useCredentialContext } from '../../../../hooks/useCredentialContext';
import { DropDownSelect } from '../../../common/form/DropDownSelect';
import { FormInput } from '../../../common/form/FormInput';
import { checkLength } from '../../../../utils/helpers/validation';
import { FormTextArea } from '../../../common/form/FormTextArea';
import { useVault } from '../../../../hooks/components/useVault';
import { TokenForm } from './TokenForm';
import { CredentialFormWinrm } from './CredentialFormWinrm';
import { CheckCredentialBlock } from './CheckCredentialBlock';
import { useHostCreateContext } from '../../../../hooks/useHostCreateContext';
import { SnmpFields } from './SnmpFields';
import { LdapPassForm } from './LdapPassForm';
import { ServersMutableBlock } from '../../../common/form/ServersMutableBlock';
import { useServerContext } from '../../../../hooks/useServerContext';
import { ServerListForForm } from '../../Devices/ServerListForForm';
import { CheckCredentialInfo } from '../../../common/CheckCredentialInfo';
import { authTypeJiraValue, authTypeYandexValue } from '../../../../utils/helpers/constants';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function AddCredentialForm(): React.ReactElement {
  const [selected, setSelected] = useState<number>(0);

  const handleSelect = useCallback(
    (e: TabStripSelectEventArguments): void => {
      setSelected(e.selected);
    },
    [setSelected],
  );

  const { isOpen, setSshPort, setSmbPort } = useHostModalContext();

  const { setResultCheckIp, changeDropDownCommonMethod } = useCommonContext();

  useEffect(() => {
    return () => setResultCheckIp(null);
  }, [setResultCheckIp]);

  const { handleSubmit, handleWindow } = useCredential();

  const {
    nameCred,
    setNameCred,
    credTypeValForSelect,
    description,
    setDescription,
    credentialData,
    typeCred,
    username,
    password,
    passwordConfirm,
    nameCredStorage,
    token,
    privateKey,
    passphrase,
    isEdit,
    errorMutation,
    setErrorMutation,
    authType,
    ipKdc,
    realm,
    setPassword,
    setPasswordConfirm,
    setToken,
    setPrivateKey,
    setPassphrase,
    setUsername,
    setIpKdc,
    setRealm,
    setAuthType,
    setNameCredStorage,
    setTypeCred,
    version,
    versionList,
    community,
    setCommunity,
    uid,
    dn,
    authTypeJira,
    setAuthTypeJira,
    authTypeYandex,
    setAuthTypeYandex,
  } = useCredentialContext();

  const { hostCreateData } = useHostCreateContext();

  const { isOpenSrvMutableBlock, serverForm } = useServerContext();

  useEffect(() => {
    if (hostCreateData?.assetType) {
      setNameCred('');
      setDescription(null);
      setPassword('');
      setPrivateKey('');
      setPassphrase('');
      setUsername('');
      setPasswordConfirm('');
      setToken('');
      setIpKdc('');
      setRealm('');
      setAuthType('');
      setSshPort('');
      setSmbPort('');
      setNameCredStorage({
        id: '000',
        name: locale[currentLocale].form.selectVaultName,
      });
      setTypeCred('');
    }
  }, []);

  useEffect(() => {
    if (
      (authTypeJira === 'bearerToken' && username) ||
      (typeCred === 'yandex_tracker' && username)
    ) {
      setUsername('');
    }

    if (
      (authTypeJira === 'bearerToken' && password) ||
      (typeCred === 'yandex_tracker' && username)
    ) {
      setPassword('');
    }

    if (
      (authTypeJira === 'bearerToken' && passwordConfirm) ||
      (typeCred === 'yandex_tracker' && username)
    ) {
      setPasswordConfirm('');
    }
  }, [authTypeJira, typeCred]);

  const { vaultIdList } = useCredential();

  const { changeStorageName, changeCredType, changeSnmpVersion } = useVault();

  useEffect(() => {
    return setErrorMutation('');
  }, []);

  return (
    <Dialog
      title={
        credentialData && !hostCreateData?.assetType
          ? `${locale[currentLocale].form.editCredential} "${nameCred}"`
          : locale[currentLocale].form.add
      }
      onClose={handleWindow}
      className="add-edit-credential modal small"
    >
      <Form
        render={(): React.ReactElement => (
          <FormElement>
            {vaultIdList && (
              <div className="form-content">
                <Field
                  name="vaultId"
                  component={DropDownSelect}
                  textField="name"
                  dataItemKey="id"
                  label={locale[currentLocale].form.vaultId}
                  onChange={changeStorageName}
                  data={vaultIdList.filter((v) => v.id !== nameCredStorage?.id)}
                  defaultValue={{ id: '000', name: locale[currentLocale].form.selectVaultName }}
                  disabled={vaultIdList && Array.isArray(vaultIdList) && vaultIdList.length === 1}
                  cls="form-field-wrapper"
                  classForLabel={credentialData ? '' : 'required-label'}
                  currentValue={nameCredStorage}
                />
                <Field
                  name="name"
                  component={FormInput}
                  id="name"
                  label={locale[currentLocale].form.name}
                  onChange={(e): void => {
                    setNameCred(e.target.value);
                  }}
                  inputValue={nameCred}
                  className="form-field-wrapper"
                  customValidator={credentialData ? '' : checkLength}
                  type="text"
                  classForLabel={credentialData ? '' : 'required-label'}
                />
                <Field
                  name="type"
                  component={DropDownSelect}
                  label={locale[currentLocale].form.type}
                  onChange={changeCredType}
                  data={credTypeValForSelect.filter((t) => t !== typeCred)}
                  defaultValue={locale[currentLocale].form.selectType}
                  cls="form-field-wrapper"
                  classForLabel="required-label"
                  currentValue={typeCred}
                />
                {(typeCred === 'jira' || typeCred === 'yandex_tracker') && (
                  <Field
                    name="authTypeOfTicketSystem"
                    component={DropDownSelect}
                    label={locale[currentLocale].form.authTypeJira}
                    onChange={(e): void =>
                      changeDropDownCommonMethod(
                        e,
                        typeCred === 'jira' ? setAuthTypeJira : setAuthTypeYandex,
                      )
                    }
                    data={
                      typeCred === 'jira'
                        ? authTypeJiraValue.filter((a) => a !== authTypeJira)
                        : authTypeYandexValue.filter((a) => a !== authTypeYandex)
                    }
                    defaultValue={typeCred === 'jira' ? authTypeJira : authTypeYandex}
                    cls="form-field-wrapper"
                    classForLabel="required-label"
                    disabled={typeCred === 'yandex_tracker'}
                  />
                )}
                {typeCred === 'snmp' && (
                  <Field
                    name="version"
                    component={DropDownSelect}
                    label={locale[currentLocale].form.version}
                    onChange={changeSnmpVersion}
                    data={versionList.filter((t) => t !== version)}
                    defaultValue={locale[currentLocale].form.selectSnmpVersion}
                    cls="form-field-wrapper"
                    classForLabel={credentialData ? '' : 'required-label'}
                    currentValue={version}
                  />
                )}
                <CredentialFormWinrm />
                {(typeCred === 'winrm' || typeCred === 'container_registry') && setSelected(0)}
                <div className={styles.authBlock}>
                  {typeCred === 'snmp' && version === 'v3' && <SnmpFields />}
                  {typeCred === 'snmp' && (version === 'v1' || version === 'v2c') && (
                    <Field
                      name="community"
                      component={FormInput}
                      id="community"
                      label={locale[currentLocale].form.SNMPCommunity}
                      onChange={(e): void => {
                        setCommunity(e.target.value);
                      }}
                      inputValue={community}
                      className="form-field-wrapper"
                      customValidator={credentialData ? '' : checkLength}
                      type="text"
                    />
                  )}
                  {authTypeJira !== 'bearerToken' &&
                    typeCred !== 'snmp' &&
                    typeCred !== 'yandex_tracker' && (
                      <TabStrip selected={selected} onSelect={handleSelect}>
                        <TabStripTab
                          title={
                            typeCred !== 'hashicorp_vault_token'
                              ? locale[currentLocale].form.tabs.loginPass
                              : locale[currentLocale].form.tabs.token
                          }
                        >
                          {typeCred !== 'hashicorp_vault_token' && typeCred !== 'ldap' && (
                            <LoginPassForm />
                          )}
                          {typeCred === 'ldap' && <LdapPassForm />}
                          {typeCred === 'hashicorp_vault_token' && <TokenForm />}
                        </TabStripTab>
                        {typeCred === 'ssh' && (
                          <TabStripTab title={locale[currentLocale].form.tabs.privateKey}>
                            <PrivateKeyForm />
                          </TabStripTab>
                        )}
                      </TabStrip>
                    )}
                  {(typeCred === 'yandex_tracker' || authTypeJira === 'bearerToken') && (
                    <TokenForm title={locale[currentLocale].form.yandexToken} />
                  )}
                </div>
                {!credentialData &&
                  typeCred !== 'container_registry' &&
                  typeCred !== 'hashicorp_vault_userpass' &&
                  typeCred !== 'hashicorp_vault_token' &&
                  typeCred !== 'ldap' &&
                  typeCred !== 'activedirectory' &&
                  typeCred !== 'jira' &&
                  typeCred !== 'yandex_tracker' && <HostsMutableBlock />}
                {(typeCred === 'ldap' || typeCred === 'activedirectory') && <ServersMutableBlock />}
                {(typeCred === 'ldap' || typeCred === 'activedirectory') &&
                  credentialData &&
                  serverForm &&
                  serverForm.length > 0 &&
                  serverForm[0].ldapId && (
                    <CheckCredentialInfo
                      ip={undefined}
                      port={undefined}
                      сredentialId={credentialData?.credentialId}
                      ldapId={serverForm[0].ldapId}
                    />
                  )}
                <Field
                  name="description"
                  component={FormTextArea}
                  id="description"
                  label={locale[currentLocale].form.description}
                  onChange={(e): void => {
                    setDescription(e.value);
                  }}
                  placeholder={locale[currentLocale].form.desPlaceholderCred}
                  inputValue={description}
                />
                {typeCred !== 'jira' &&
                  typeCred !== 'yandex_tracker' &&
                  typeCred !== 'ldap' &&
                  typeCred !== 'activedirectory' && <CheckCredentialBlock />}
              </div>
            )}
            <div className="error-common-field">
              <span>{errorMutation}</span>
            </div>
            <div className="k-form-buttons">
              <Button
                type="submit"
                onClick={handleSubmit}
                className="form-button-type"
                disabled={
                  !credentialData
                    ? (vaultIdList &&
                        Array.isArray(vaultIdList) &&
                        vaultIdList.length > 1 &&
                        nameCredStorage.id === '000') ||
                      !nameCred ||
                      (typeCred === 'winrm' && !username) ||
                      (typeCred === 'ssh' && !username) ||
                      (typeCred === 'hashicorp_vault_userpass' && !username) ||
                      (typeCred === 'container_registry' && !username) ||
                      (typeCred === 'snmp' && version === 'v3' && !username) ||
                      !typeCred ||
                      typeCred === locale[currentLocale].form.selectType ||
                      (typeCred === 'winrm' && !password) ||
                      (typeCred === 'winrm' && authType === 'kerberos' && (!ipKdc || !realm)) ||
                      (typeCred === 'ssh' &&
                        ((selected === 0 && password !== passwordConfirm) ||
                          (selected === 0 && !isEdit && !credentialData && !password) ||
                          (selected === 1 &&
                            !isEdit &&
                            !credentialData &&
                            !privateKey &&
                            !passphrase))) ||
                      (typeCred === 'hashicorp_vault_userpass' && !password) ||
                      (typeCred === 'container_registry' && !password) ||
                      (typeCred === 'winrm' && password !== passwordConfirm) ||
                      (typeCred === 'hashicorp_vault_userpass' && password !== passwordConfirm) ||
                      (typeCred === 'container_registry' && !password) ||
                      (typeCred === 'hashicorp_vault_token' && !token) ||
                      (typeCred === 'ldap' && !uid) ||
                      (typeCred === 'ldap' && !dn) ||
                      (typeCred === 'ldap' && !password) ||
                      (typeCred === 'ldap' && password !== passwordConfirm) ||
                      (typeCred === 'activedirectory' && password !== passwordConfirm) ||
                      (typeCred === 'activedirectory' && !password) ||
                      (typeCred === 'jira' && authTypeJira === 'basic' && !password) ||
                      (typeCred === 'jira' &&
                        authTypeJira === 'basic' &&
                        password !== passwordConfirm) ||
                      (typeCred === 'activedirectory' && !username) ||
                      (typeCred === 'jira' && authTypeJira === 'bearerToken' && !token) ||
                      (typeCred === 'yandex_tracker' && !token)
                    : false
                }
              >
                {localeButtons[currentLocale].add}
              </Button>
              <Button type="button" onClick={handleWindow}>
                {localeButtons[currentLocale].cancel}
              </Button>
            </div>
          </FormElement>
        )}
      />
      {isOpen && <HostListForForm />}
      {isOpenSrvMutableBlock && <ServerListForForm />}
    </Dialog>
  );
}
