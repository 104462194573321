import type { GridCellProps } from '@progress/kendo-react-grid';
import { RadioButton } from '@progress/kendo-react-inputs';
import { useUserModalContext } from '../../../../../hooks/useUserModalContext';
import { useTicketSystemContext } from '../../../../../hooks/useTicketSystemContext';

export function CellUserListRadioBtn(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { username, setUsername, setUid, setEmail, setLogin } = useUserModalContext();

  const { setSource } = useTicketSystemContext();

  const createUserData = (): void => {
    if (dataItem.name) setUsername(dataItem.name);

    if (dataItem.userId) setUid(dataItem.userId);

    if (dataItem.id) setUid(dataItem.id);

    if (dataItem.email) setEmail(dataItem.email);

    if (dataItem.login) setLogin(dataItem.login);

    if (dataItem.type) {
      setSource(dataItem.type);
    } else {
      setSource('user');
    }
  };

  return (
    <td style={{ textOverflow: 'clip' }}>
      <RadioButton
        name="credentialGroup"
        value={username}
        // disabled={!username}
        checked={(username && username === dataItem.name) || false}
        onChange={createUserData}
      />
    </td>
  );
}
