import { ProgressBar } from '@progress/kendo-react-progressbars';
import { Loader } from '@progress/kendo-react-indicators';
import { useEffect, useRef, useState } from 'react';
import type { TicketDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/ticketsDashboardResponse.v1';
import { ticketStatusLocale } from '../../../../../utils/helpers/constants';
import styles from '../ticket.module.scss';
import localeTicket from '../../../../../utils/i18n/security/vulnerabilities.json';
import { useTicketContext } from '../../../../../hooks/useTicketContext';
import { ErrorMutationTicketStatus } from '../../../../common/ErrorMutationTicketStatus';

export function TableFTicketMetaBlock({
  data,
  currentLocale,
}: {
  data: TicketDashboardResponse | undefined;
  currentLocale: 'ru-RU' | 'en-EN';
}): React.ReactElement {
  const [isErrorChangeStatus, setErrorChangeStatus] = useState(false);

  const timer = useRef<NodeJS.Timeout | null>(null);

  const [progressValue, setProgressValue] = useState<number | string>(0);

  useEffect(() => {
    if (
      data &&
      data.type === 'fix_vulnerabilities' &&
      data.actionsProgress &&
      typeof progressValue === 'number'
    ) {
      const value = Number.isInteger(data.actionsProgress)
        ? data.actionsProgress
        : data.actionsProgress.toFixed(1);

      setProgressValue(value);
    }
  }, []);

  const setTimerErrorChangeStatus = (): void => {
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setErrorChangeStatus(false);
    }, 800);
  };

  useEffect(() => {
    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
  }, []);

  const { isTicketStatusLoading, errorMutationTicketStatus } = useTicketContext();

  return (
    <table className="column column-first">
      <tbody>
        <tr>
          <td>
            <span>{localeTicket[currentLocale].tickets.description.metablock.actionsProgress}</span>
          </td>
          <td>
            {data?.type === 'fix_vulnerabilities' ? (
              <div>
                <ProgressBar
                  labelVisible={false}
                  value={Number(progressValue)}
                  className={styles.progressBar}
                />
                <span className={styles.spanProgressBar}>{progressValue} %</span>
              </div>
            ) : (
              '-'
            )}
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeTicket[currentLocale].tickets.description.metablock.status}</span>
          </td>
          <td>
            {data?.status ? (
              <div className={styles.ticketDesStatusWrap}>
                {data?.status === 'open' && (
                  <img
                    src={`${process.env.PUBLIC_URL}/images/openTicketStatus.svg`}
                    alt=""
                    className={`${styles[data?.status]} ${styles.statusIcon}`}
                  />
                )}
                {data?.status === 'in-progress' && (
                  <span
                    className={`${styles.spanIcon} ${styles[data?.status]} k-icon ${[
                      styles.spanIcon,
                      styles.inProgress,
                    ].join(' ')}`}
                  />
                )}
                {data?.status === 'closed' && (
                  <span
                    className={`${styles.spanIcon} ${
                      styles[data?.status]
                    } k-icon k-i-check k-i-checkmark k-i-tick`}
                  />
                )}
                {data?.status === 'in-review' && (
                  <span className={`${styles.spanIcon} ${styles[data?.status]} k-icon k-i-clock`} />
                )}
                {data?.status === 'undefined' && (
                  <span
                    className={`${styles.spanIcon} ${
                      styles[data?.status]
                    } k-icon k-i-question-circle k-i-question k-i-help`}
                  />
                )}
                <div className={styles.ticketStatusValueWrap}>
                  <span
                    className={`${styles[data?.status]} ${styles.spanContent}`}
                    style={{ fontWeight: data?.status === 'in-progress' ? '600' : '' }}
                  >
                    {ticketStatusLocale[data?.status]}
                  </span>
                  {errorMutationTicketStatus &&
                    errorMutationTicketStatus.find((f) => f.id === data.ticketId) && (
                      <img
                        src={`${process.env.PUBLIC_URL}/images/warning-circle.svg`}
                        className={styles.statusIcon}
                        alt=""
                        onMouseEnter={(): void => {
                          if (timer.current) clearTimeout(timer.current);
                          setErrorChangeStatus(true);
                        }}
                        onMouseLeave={setTimerErrorChangeStatus}
                      />
                    )}
                </div>
                {isTicketStatusLoading && (
                  <div className={styles.loaderWrap}>
                    <Loader
                      className={styles.statusLoader}
                      size="small"
                      type="converging-spinner"
                    />
                  </div>
                )}
                {isErrorChangeStatus && (
                  <ErrorMutationTicketStatus
                    inlineStyle={{ left: '320px', top: '174px' }}
                    ticketId={data.ticketId}
                  />
                )}
              </div>
            ) : (
              '-'
            )}
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeTicket[currentLocale].tickets.description.metablock.assignedTo}</span>
          </td>
          <td>
            {data?.assignedTo instanceof Object &&
            'name' in data.assignedTo &&
            data?.assignedTo.name
              ? data?.assignedTo?.name
              : '-'}
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeTicket[currentLocale].tickets.description.metablock.deadline}</span>
          </td>
          <td>
            {data?.deadlineDate
              ? `${
                  new Date(data?.deadlineDate).getDate() < 10
                    ? `0${new Date(data?.deadlineDate).getDate()}`
                    : new Date(data?.deadlineDate).getDate()
                }.${
                  new Date(data?.deadlineDate).getMonth() + 1 < 10
                    ? `0${new Date(data?.deadlineDate).getMonth() + 1}`
                    : new Date(data?.deadlineDate).getMonth() + 1
                }.${new Date(data?.deadlineDate).getFullYear()}`
              : '-'}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
