import React, { useCallback, useState } from 'react';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import localeVmTask from '../../../../utils/i18n/taskLocale/vmTask.json';
import { HostVulnsMetaBlock } from './HostVulnsMetaBlock';
import { TabsHostBottom } from './TabsHostBottom';
import { TabsIDetails } from '../../Task/taskIDetails/iDetailsComponents/TabsIDetails';
import { HostTaskHistory } from './HostTaskHistory';
import { HostInvMetaBlock } from './HostInvMetaBlock';
import { useHostDescription } from '../../../../hooks/components/useHostDescription';

export function TabsHostTop(): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeVmTask;

  const {
    assetIdData,
    hostname: hostName,
    auditIdData: data,
    inventorizationIdData: invData,
  } = useHostDescription();

  const inventorizationId =
    assetIdData?.type === 'host' ? assetIdData.latestInventorization?.inventorizationId : undefined;

  const maxVFstec = assetIdData?.latestAudit?.maxVFstec;

  const [selected, setSelected] = useState<number>(0);

  const handleSelect = useCallback(
    (e: TabStripSelectEventArguments): void => {
      setSelected(e.selected);
    },
    [setSelected],
  );

  const countVulns = data?.result?.cumulativeData.vulns
    ? (data?.result?.cumulativeData.vulns as object)
    : 0;

  return (
    <div className="top-tabstrip">
      <TabStrip selected={selected} onSelect={handleSelect}>
        <TabStripTab
          title={`${localeVmTask[currentLocale].tabs.vulnerabilities} (${
            Object.values(countVulns).length || 0
          })`}
        >
          <HostVulnsMetaBlock queryAuditInfo={data} hostName={hostName} maxVFstec={maxVFstec} />
          <TabsHostBottom data={data} hostName={hostName} />
        </TabStripTab>
        <TabStripTab title={localeVmTask[currentLocale].tabs.inventorization}>
          <HostInvMetaBlock invData={invData} inventorizationId={inventorizationId} />
          <TabsIDetails data={invData} hostName={hostName} />
        </TabStripTab>
        <TabStripTab title={localeVmTask[currentLocale].tabs.taskHistory}>
          <HostTaskHistory />
        </TabStripTab>
      </TabStrip>
    </div>
  );
}
