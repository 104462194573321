/* eslint-disable react/jsx-no-useless-fragment */
import { nanoid } from 'nanoid';
import localeTaskDescription from '../../../../../utils/i18n/taskLocale/taskDescription.json';
import styles from '../../../Task/TaskDescription.module.scss';
import { ScopeElement } from '../../../Task/cellsTaskList/ScopeElement';
import { ScopeNotification } from '../../../Task/cellsTaskList/ScopeNotification';
import type { TicketDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/ticketsDashboardResponse.v1';
import { ScopeBulletin } from './ScopeBulletin';
import { ScopeNotificationBulletin } from './ScopeNotificationBulletin';

export function TicketScopeColumn({
  currentLocale,
  scoreData,
  bulletinData,
  isShowScope,
  setShow,
  isShowBulletin,
  setShowBulletin,
  type,
}: {
  currentLocale: 'ru-RU' | 'en-EN';
  scoreData: TicketDashboardResponse['scope'] | undefined;
  bulletinData: string[] | null;
  isShowScope: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  isShowBulletin: boolean;
  setShowBulletin: React.Dispatch<React.SetStateAction<boolean>>;
  type: 'fix_vulnerabilities' | 'custom' | undefined;
}): React.ReactElement {
  let data;

  if (scoreData?.assets) {
    data = scoreData?.assets
      ?.map((asset) => ({
        id: asset.assetId,
        name:
          // eslint-disable-next-line no-nested-ternary
          'imageNames' in asset && asset.imageNames?.length
            ? asset.imageNames[0]
            : 'hostname' in asset
            ? asset.hostname
            : null,
        taskType: 'asset',
        deviceType: asset.type,
      }))
      .concat(
        scoreData?.assetGroups?.map((assetGroup) => ({
          id: assetGroup.assetGroupId,
          name: assetGroup.name,
          taskType: 'assetgroup',
          deviceType: assetGroup.type,
        })) || [],
      )
      .concat(
        scoreData?.assetDynamicGroups?.map((assetDynamicGroup) => ({
          id: assetDynamicGroup.assetDynamicGroupId,
          name: assetDynamicGroup.name,
          taskType: 'assetDynamicgroup',
          deviceType: assetDynamicGroup.type,
        })) || [],
      );
  } else if (scoreData?.assetGroups) {
    data = scoreData?.assetGroups
      ?.map((assetGroup) => ({
        id: assetGroup.assetGroupId,
        name: assetGroup.name,
        taskType: 'assetgroup',
        deviceType: assetGroup.type,
      }))
      .concat(
        scoreData?.assetDynamicGroups?.map((assetDynamicGroup) => ({
          id: assetDynamicGroup.assetDynamicGroupId,
          name: assetDynamicGroup.name,
          taskType: 'assetDynamicgroup',
          deviceType: assetDynamicGroup.type,
        })) || [],
      );
  } else {
    data = scoreData?.assetDynamicGroups?.map((assetDynamicGroup) => ({
      id: assetDynamicGroup.assetDynamicGroupId,
      name: assetDynamicGroup.name,
      taskType: 'assetDynamicgroup',
      deviceType: assetDynamicGroup.type,
    }));
  }

  return (
    <table className="column">
      <tbody>
        <tr>
          <td>{localeTaskDescription[currentLocale].summary.scope}</td>
          <td>
            {data ? (
              <>
                {data?.slice(0, 2).map((se) => (
                  <ScopeElement element={se} key={nanoid()} />
                ))}
                {data && data.length > 2 && (
                  <>
                    <span
                      onMouseEnter={(): void => setShow(true)}
                      onMouseLeave={(): void => setShow(false)}
                    >
                      {localeTaskDescription[currentLocale].summary.andMore} {data.length - 2}
                      <ScopeNotification
                        className={styles.asset_scope}
                        isShow={isShowScope}
                        data={data}
                        sliceValue={2}
                      />
                    </span>
                  </>
                )}
              </>
            ) : (
              '-'
            )}
          </td>
        </tr>
        {type === 'fix_vulnerabilities' && (
          <tr>
            <td>{localeTaskDescription[currentLocale].summary.scopeVulns}</td>
            <td>
              {bulletinData?.slice(0, 1).map((bulletin) => (
                <ScopeBulletin bulletin={bulletin} key={nanoid()} />
              ))}
              {bulletinData && bulletinData.length > 1 && (
                <>
                  <span
                    onMouseEnter={(): void => setShowBulletin(true)}
                    onMouseLeave={(): void => setShowBulletin(false)}
                  >
                    {localeTaskDescription[currentLocale].summary.andMore} {bulletinData.length - 1}
                    <ScopeNotificationBulletin
                      className={styles.asset_scope}
                      isShowBulletin={isShowBulletin}
                      data={bulletinData}
                      sliceValue={1}
                    />
                  </span>
                </>
              )}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
