import type { GridPageChangeEvent } from '@progress/kendo-react-grid';
import { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import type { FilterChangeEvent } from '@progress/kendo-react-data-tools';
import { handleError } from '../../utils/errors';
import { handleFilteringTest } from '../../utils/filtering-in-table-test';
import type { IUseTicketList } from '../../utils/helpers/types';
import { useNotificationContext } from '../useNotificationContext';
import { useCustomSearch } from './useCustomSearch';
import { useFilter } from './useFilter';
import { usePageChange } from './usePageChange';
import type { ApiError } from '../../types/__generated/on-premise-solution/api/apiError.v1';
import { useStatesForList } from './useStatesForList';
import type { IUrlParamsList } from '../../utils/helpers/getUrl-list-helper';
import type { TicketsDashboardResponse } from '../../types/__generated/on-premise-solution/api/ticketsDashboardResponse.v1';
import { getTickets } from '../../services/tickets-service';
import { fillFiltersTicketList } from '../../utils/helpers/ticket-list-fill-helper';

export function useTicketList(
  urlParams: IUrlParamsList,
  dataForHooks: {
    name: string;
    componentName: string;
  },
): IUseTicketList {
  const queryClient = useQueryClient();

  const {
    customSearch,
    setCustomSearch,
    sort,
    setSort,
    page,
    setPage,
    filter,
    setFilter,
    filterStatus,
    setFilterStatus,
    filterVal,
    setFilterVal,
    filterValue,
    filterRef,
  } = useStatesForList(urlParams);

  const { createNotification } = useNotificationContext();

  const debouncedCustomSearch = useCustomSearch(
    setFilterStatus,
    setCustomSearch,
    filter,
    setFilterVal,
    setPage,
    page,
    dataForHooks,
  );

  const filterSearch = useFilter(
    filterValue,
    setFilter,
    customSearch,
    setCustomSearch,
    setFilterVal,
    filterRef,
    filter,
    setPage,
    page,
    dataForHooks,
  );

  const getPage = usePageChange(setPage, urlParams, dataForHooks);

  const query = useQuery<TicketsDashboardResponse, ApiError>(
    ['tickets', page, filterVal, sort],
    () => getTickets(page, filterVal, sort),
    {
      keepPreviousData: true,
      enabled:
        (filterVal && filterVal.length > 0) ||
        document.location.pathname.includes('lk/security/tickets'),
    },
  );

  useEffect(() => {
    fillFiltersTicketList(urlParams, filterValue, setFilter, setFilterStatus);
  }, []);

  useEffect(() => {
    queryClient.invalidateQueries('tickets');
  }, [queryClient]);

  useEffect(() => {
    let delaySearch: NodeJS.Timeout;

    if (filter?.filters?.length > 0) {
      filterRef.current = filter;
      delaySearch = setTimeout(() => {
        handleFilteringTest(filterRef.current, setFilterVal);
        setFilterStatus(false);
      }, 300);
    }

    return () => clearTimeout(delaySearch);
  }, [filter]);

  const pageChange = (event: GridPageChangeEvent): void => {
    getPage(event);
  };

  useEffect(() => {
    if (query.error) {
      handleError(query.error, createNotification);
    }
  }, [query.isError, createNotification, query.error]);

  const onFilterChange = (event: FilterChangeEvent): void => {
    filterSearch(event);
  };

  function handleCustomSearch(e: React.ChangeEvent<HTMLInputElement>): void {
    debouncedCustomSearch(e);
  }

  const handleRefresh = (): void => {
    setFilterStatus(true);
    queryClient.invalidateQueries('tickets').then(() => setFilterStatus(false));
  };

  return {
    filter,
    onFilterChange,
    customSearch,
    handleCustomSearch,
    sort,
    data: query.data,
    page,
    setPage,
    total: query.data?.total,
    pageChange,
    setSort,
    dataForHooks,
    isLoading: query.isLoading,
    isFetching: query.isFetching,
    filterStatus,
    isError: query.isError,
    errorMessage: query?.error?.message,
    filterValue,
    setFilter,
    setFilterStatus,
    handleRefresh,
  };
}
