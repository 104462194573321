import CopyToClipboard from 'react-copy-to-clipboard';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Button } from './baseElements/Button';
import styles from './CommonWebhook.module.scss';
import localeCommon from '../../utils/i18n/common/common.json';
import localeToken from '../../utils/i18n/settingsLocales/settings.json';
import { useCommonContext } from '../../hooks/useCommonContext';
import { useApiKeyContext } from '../../hooks/useApiKeyContext';
import { AddApiKeyForm } from '../features/Settings/ApiKeys/form/AddApiKeyForm';
import { ApiKeyValue } from '../features/Settings/ApiKeys/modal/ApiKeyValue';

export function CommonWebhook({
  title,
  id,
  name,
  urlOfApiMethod,
  description,
}: {
  title: string;
  id: string;
  name: string;
  urlOfApiMethod: string;
  description: string;
}): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeCommon;

  const { handleCopied, copied, setCopied, setShowCommonWebHook } = useCommonContext();

  const { isApiKeyForm, setApiKeyForm, apiKeyValue } = useApiKeyContext();

  const url = `${window.location.origin}${urlOfApiMethod}/${id}`;

  const curlExample = `curl -H "x-api-key: <API_KEY>" ${window.location.origin}${urlOfApiMethod}/${id}`;

  const wgetExample = `wget --header "x-api-key: <API_KEY>" ${window.location.origin}${urlOfApiMethod}/${id}`;

  return (
    <>
      {isApiKeyForm && <AddApiKeyForm />}
      {apiKeyValue && <ApiKeyValue />}
      <Dialog
        title={`${title} ${name}`}
        onClose={(): void => setShowCommonWebHook(false)}
        className="small"
      >
        <div className="k-form">
          <div className={`form-content ${styles.webhookWrapper}`}>
            <div className={styles.webhookContent}>
              {copied && (
                <NotificationGroup
                  style={{
                    right: '80px',
                    top: '188px',
                    alignItems: 'flex-start',
                    flexWrap: 'wrap-reverse',
                  }}
                >
                  <Notification
                    closable
                    type={{ icon: false }}
                    onClose={(): void => setCopied(false)}
                  >
                    <span>{localeCommon[currentLocale].webHook.copyToClipboardSaved}</span>
                  </Notification>
                </NotificationGroup>
              )}
              <div
                // className={styles.webhookUrl}
                className={`code-container ${styles.webhookUrl}`}
              >
                <span>{url}</span>
                <CopyToClipboard text={url} onCopy={(): void => setCopied(true)}>
                  <Button onClick={handleCopied} fill="copy">
                    {/* {localeCommon[currentLocale].webHook.copyToClipboard} */}
                  </Button>
                </CopyToClipboard>
              </div>
              <div className={styles.webhookDescriptionTitle}>
                <span>{localeCommon[currentLocale].webHook.description}</span>
              </div>
              <div className={styles.webhookDescription}>
                <span className={styles.webhookDescriptionInfo}>{description}</span>
                <span className={styles.webhookDescriptionInfo2}>
                  {localeCommon[currentLocale].webHook.registry.info2}
                </span>
                <span>
                  {localeCommon[currentLocale].webHook.registry.p1}
                  {urlOfApiMethod}
                </span>
                <div>
                  <Button className={styles.webhookBtn} onClick={(): void => setApiKeyForm(true)}>
                    {localeToken[currentLocale].apiKey.add}
                  </Button>
                </div>
                <span>{localeCommon[currentLocale].webHook.registry.p2}</span>
              </div>
              <div className={styles.webhookDescriptionTitle}>
                <span>{localeCommon[currentLocale].webHook.example}</span>
              </div>
              <div>
                <div className={styles.webhookCurlExample}>
                  <span>{curlExample}</span>
                </div>
                <div className={styles.webhookCurlExample}>
                  <span>{wgetExample}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="k-form-buttons">
            <Button type="button" onClick={(): void => setShowCommonWebHook(false)}>
              {localeCommon[currentLocale].btnClose}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}
