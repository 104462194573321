import React, { createContext, useState, useMemo, useContext, useCallback } from 'react';
import type {
  IChildrenNodes,
  IHostForm,
  THostData,
  THostModalContent,
  TSetStateUpdate,
  TStateUpdate,
  TVulnPkgForAllSelect,
  TWinSoftwareTaskUpdate,
} from '../utils/helpers/types';
import { useCredentialContext } from './useCredentialContext';
import { useSoftwareManagementContext } from './useSoftwareManagementContext';
import type { AuditWindowsResult } from '../types/__generated/on-premise-solution/api/audit.v1';

const HostModalContext = createContext<THostModalContent | null>(null);

export function HostModalProvider({ children }: IChildrenNodes): React.ReactElement {
  const { setCheckErrorMutation } = useCredentialContext();

  const [isOpen, setOpen] = useState(false);

  const [hostDetailById, setHostDetailById] = useState<string | null>(null);

  const [isOpenGroupList, setOpenGroupList] = useState(false);

  const [isOpenDynamicGroupList, setOpenDynamicGroupList] = useState(false);

  const [isOpenHostGroups, setOpenHostGroups] = useState(false);

  const [hostData, setHostData] = useState<THostData | null>(null);

  const [hostForm, setHostForm] = useState<IHostForm[] | null>(null);

  const [hostFormTemp, setHostFormTemp] = useState<IHostForm[] | null>(null);

  const [groupsIds, setGroupsIds] = useState<string[]>([]);

  const [ipHost, setIpHost] = useState('');

  const [hostName, setHostName] = useState('');

  const [isAssetName, setAssetName] = useState(false);

  const [sshPort, setSshPort] = useState<string>('22');

  const [smbPort, setSmbPort] = useState<string>('445');

  const [winrmPort, setWinrmPort] = useState<string>('5985');

  const [isEditPort, setEditPort] = useState(false);

  const [selectedPkgs, setSelectedPkgs] = useState<{ name: string; version?: string }[] | null>(
    null,
  );

  const [selectedPkgsWl, setSelectedPkgsWl] = useState<{ name: string; version?: string }[] | null>(
    null,
  );

  const [winSoftwareUpdateState, setWinSoftwareUpdateState] = useState<Record<
    string,
    TWinSoftwareTaskUpdate
  > | null>(null);

  const [winSoftwareUpdateStateWl, setWinSoftwareUpdateStateWl] = useState<Record<
    string,
    TWinSoftwareTaskUpdate
  > | null>(null);

  const [softwareInWl, setSoftwareInWl] = useState<Record<
    string,
    { name: string; version: string; osType?: string; versionRanges?: string[] }
  > | null>(null);

  const [missedKbs, setMissedKbs] = useState<{ id: string; title: string }[] | null>(null);

  const [kbsInWl, setKbsInWl] = useState<{ id: string; title: string }[] | null>(null);

  const [isKbWlTask, setKbWlTask] = useState(false);

  const [tagListInHostList, setTagListInHostList] = useState('');

  const [isPkgWlTask, setPkgWlTask] = useState(false);

  const [isAuditDataExists, setAuditDataExists] = useState(false);

  const [isExceptionAssetVulnTable, setExceptionAssetVulnTable] = useState(false);

  const [availableUpdateModalData, setAvailableUpdateModalData] = useState<{
    softwareCodename: string;
    version: string;
  } | null>(null);

  const { selectedSoftwareForUpdate, setSelectedSoftwareForUpdate } =
    useSoftwareManagementContext();

  const deletePkgFromSelectedPkgs = useCallback(
    (
      name: string,
      version: string | undefined,
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ): void => {
      event.stopPropagation();
      if (selectedPkgs && selectedPkgs.length > 0) {
        setSelectedPkgs(
          selectedPkgs?.filter((pkg) => pkg.name !== name || pkg.version !== version),
        );
      }
    },
    [selectedPkgs],
  );

  const editHostName = useCallback(
    (val: string): void => {
      setCheckErrorMutation('');
      setHostName(val);
    },
    [setCheckErrorMutation],
  );

  const deleteKbFromSelectedKbs = useCallback(
    (
      id: string,
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      kbInWl?: boolean | undefined,
    ): void => {
      event.stopPropagation();
      if (!kbInWl && missedKbs && missedKbs.length > 0) {
        setMissedKbs(missedKbs.filter((kbs) => kbs.id !== id));
      }
      if (kbInWl && kbsInWl && kbsInWl.length > 0) {
        setMissedKbs(kbsInWl?.filter((kbs) => kbs.id !== id));
      }
    },
    [kbsInWl, missedKbs],
  );

  const delSelectedWinSoftFrom = useCallback(
    (
      softwareName: string,
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      isWL?: boolean | undefined,
    ): void => {
      event.stopPropagation();
      if (!isWL && winSoftwareUpdateState && Object.values(winSoftwareUpdateState).length > 0) {
        const mutableState = winSoftwareUpdateState;
        delete mutableState[softwareName];
        setWinSoftwareUpdateState({ ...mutableState });
      }
      if (isWL && winSoftwareUpdateStateWl && Object.values(winSoftwareUpdateStateWl).length > 0) {
        const mutableState = winSoftwareUpdateStateWl;
        delete mutableState[softwareName];
        setWinSoftwareUpdateStateWl({ ...mutableState });
      }
      if (selectedSoftwareForUpdate && Object.values(selectedSoftwareForUpdate).length > 0) {
        const mutableState = selectedSoftwareForUpdate;
        delete mutableState[softwareName];
        setSelectedSoftwareForUpdate({ ...mutableState });
      }
    },
    [
      selectedSoftwareForUpdate,
      setSelectedSoftwareForUpdate,
      winSoftwareUpdateState,
      winSoftwareUpdateStateWl,
    ],
  );

  const delKbFromMutableBlock = useCallback(
    (kbId: string | undefined, event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      event.stopPropagation();
      if (missedKbs && missedKbs.length > 0) {
        setMissedKbs(missedKbs?.filter((kb) => kb.id !== kbId));
      }
    },
    [missedKbs],
  );

  const selectAllPkgOnPage = useCallback(
    (
      pkgsOnPage: TVulnPkgForAllSelect[] | undefined,
      osType: string | undefined,
      type?: string,
    ): void => {
      if (type && osType && osType === 'linux') {
        if (selectedPkgs && pkgsOnPage) {
          const selectedAndOnPage = [...selectedPkgs, ...pkgsOnPage];

          const duplicatValChecked = selectedAndOnPage.filter(
            (pkg, i, arr) =>
              arr.findIndex((p) => p.version === pkg.version && p.name === pkg.name) === i,
          );

          setSelectedPkgs(duplicatValChecked);
        }
        if (!selectedPkgs && pkgsOnPage) setSelectedPkgs(pkgsOnPage);
      }
      if (!type && osType && osType === 'linux') {
        if (selectedPkgsWl && pkgsOnPage) {
          const selectedAndOnPage = [...selectedPkgsWl, ...pkgsOnPage];

          const duplicatValChecked = selectedAndOnPage.filter(
            (pkg, i, arr) =>
              arr.findIndex((p) => p.version === pkg.version && p.name === pkg.name) === i,
          );

          setSelectedPkgsWl(duplicatValChecked);
        }
        if (!selectedPkgsWl && pkgsOnPage) setSelectedPkgsWl(pkgsOnPage);
      }
      if (type && osType && osType === 'linux') {
        if (pkgsOnPage) {
          const winSoftwareData = Object.assign(
            {},
            ...pkgsOnPage.map((n) => ({
              [n.name]: { name: n.name, version: n.version, osType },
            })),
          );
          setSoftwareInWl(winSoftwareData);
        }
      }
    },
    [selectedPkgs, selectedPkgsWl],
  );

  const selectAllWinSoftwareOnPage = useCallback(
    (
      dataOnPage:
        | { softwareCodename: string; softwareName: string; name: string; version: string }[]
        | undefined,
      osType: string | undefined,
      type?: string,
    ): void => {
      if (type && osType && osType === 'windows') {
        if (dataOnPage && dataOnPage.find((s) => s.softwareName && s.softwareName.length > 0)) {
          const winSoftwareData = Object.assign(
            {},
            ...dataOnPage
              .filter((s) => s.softwareName && s.softwareName.length > 0)
              .map((n) => ({
                [n.softwareCodename]: { softwareName: n.softwareName },
              })),
          );
          setWinSoftwareUpdateState(winSoftwareData);
        }
      }
      if (!type && osType && osType === 'windows') {
        if (dataOnPage && dataOnPage.find((s) => s.softwareName && s.softwareName.length > 0)) {
          const winSoftwareData = Object.assign(
            {},
            ...dataOnPage
              .filter((s) => s.softwareName && s.softwareName.length > 0)
              .map((n) => ({
                [n.softwareCodename]: { softwareName: n.softwareName },
              })),
          );
          setWinSoftwareUpdateStateWl(winSoftwareData);
        }
      }
      if (type && osType && osType === 'windows') {
        if (dataOnPage) {
          const winSoftwareData = Object.assign(
            {},
            ...dataOnPage.map((n) => ({
              [n.name]: { name: n.name, version: n.version, osType },
            })),
          );
          setSoftwareInWl(winSoftwareData);
        }
      }
    },
    [],
  );

  const cancelSelectedInTable = useCallback(
    (osType: string | undefined, id: string | undefined): void => {
      if (osType && osType === 'linux' && !id && selectedPkgs && selectedPkgs.length > 0) {
        setSelectedPkgs(null);
        setSoftwareInWl(null);
      }
      if (osType && osType === 'linux' && id && selectedPkgsWl && selectedPkgsWl.length > 0) {
        setSelectedPkgsWl(null);
      }
      if (osType && osType === 'windows' && !id) {
        setWinSoftwareUpdateState(null);
        setSoftwareInWl(null);
        setSelectedPkgsWl(null);
      }
      if (osType && osType === 'windows' && id) {
        setWinSoftwareUpdateStateWl(null);
        setSelectedPkgs(null);
      }
    },
    [selectedPkgs, selectedPkgsWl],
  );

  const addSoftwareToTask = useCallback(
    (
      softwareCodeName: string,
      softwareName: string,
      setState: (
        value: React.SetStateAction<Record<string, TWinSoftwareTaskUpdate> | null>,
      ) => void,
      state: Record<string, TWinSoftwareTaskUpdate> | null,
    ) => {
      if (state && state[softwareCodeName]) {
        const softwareState = state;
        delete softwareState[softwareCodeName];
        setState({ ...softwareState });
      } else {
        setState((current) => ({
          [softwareCodeName]: { softwareName },
          ...current,
        }));
      }
    },
    [],
  );

  const modifySoftwareInWl = useCallback(
    (
      name: string,
      version: string,
      type: string | undefined,
      state: TStateUpdate,
      setState: TSetStateUpdate,
    ) => {
      if (state && state[name]) {
        const copy = state;
        delete copy[name];
        setState({ ...copy });
      } else {
        setState((current) => ({
          [name]: { name, version, osType: type },
          ...current,
        }));
      }
    },
    [],
  );

  const handleSoftwareAction = useCallback(
    (data: AuditWindowsResult['vulnerableObjects'][0], type: string, value: string): void => {
      if (value === 'default') {
        if (data.availableUpdate) {
          addSoftwareToTask(
            data.availableUpdate.softwareCodename,
            data.availableUpdate.softwareName,
            setWinSoftwareUpdateState,
            winSoftwareUpdateState,
          );
        }
        modifySoftwareInWl(data.name, data.version, type, softwareInWl, setSoftwareInWl);
      }
      //  else {
      //   if (data.availableUpdate) {
      //     addSoftwareToTask(
      //       data.availableUpdate.softwareCodename,
      //       data.availableUpdate.softwareName,
      //       setWinSoftwareUpdateStateWl,
      //       winSoftwareUpdateStateWl,
      //     );
      //   }
      //   modifySoftwareInWl(data.name, data.version, type, softwareWlInWl, setSoftwareWlInWl);
      // }
    },
    [addSoftwareToTask, modifySoftwareInWl, softwareInWl, winSoftwareUpdateState],
  );

  const value = useMemo(
    () => ({
      isOpen,
      setOpen,
      isOpenGroupList,
      setOpenGroupList,
      isOpenHostGroups,
      setOpenHostGroups,
      hostData,
      setHostData,
      hostForm,
      setHostForm,
      groupsIds,
      setGroupsIds,
      ipHost,
      setIpHost,
      hostName,
      setHostName,
      hostDetailById,
      setHostDetailById,
      hostFormTemp,
      setHostFormTemp,
      sshPort,
      setSshPort,
      smbPort,
      setSmbPort,
      winrmPort,
      setWinrmPort,
      isEditPort,
      setEditPort,
      selectedPkgs,
      setSelectedPkgs,
      selectedPkgsWl,
      setSelectedPkgsWl,
      missedKbs,
      setMissedKbs,
      deletePkgFromSelectedPkgs,
      deleteKbFromSelectedKbs,
      editHostName,
      tagListInHostList,
      setTagListInHostList,
      kbsInWl,
      setKbsInWl,
      isKbWlTask,
      setKbWlTask,
      isPkgWlTask,
      setPkgWlTask,
      delKbFromMutableBlock,
      isAssetName,
      setAssetName,
      selectAllPkgOnPage,
      cancelSelectedInTable,
      selectAllWinSoftwareOnPage,
      delSelectedWinSoftFrom,
      winSoftwareUpdateState,
      setWinSoftwareUpdateState,
      winSoftwareUpdateStateWl,
      setWinSoftwareUpdateStateWl,
      addSoftwareToTask,
      availableUpdateModalData,
      setAvailableUpdateModalData,
      isOpenDynamicGroupList,
      setOpenDynamicGroupList,
      softwareInWl,
      setSoftwareInWl,
      handleSoftwareAction,
      modifySoftwareInWl,
      isExceptionAssetVulnTable,
      setExceptionAssetVulnTable,
      isAuditDataExists,
      setAuditDataExists,
    }),
    [
      isOpen,
      isOpenGroupList,
      isOpenHostGroups,
      hostData,
      hostForm,
      groupsIds,
      ipHost,
      hostName,
      hostDetailById,
      hostFormTemp,
      sshPort,
      smbPort,
      winrmPort,
      isEditPort,
      selectedPkgs,
      selectedPkgsWl,
      missedKbs,
      deletePkgFromSelectedPkgs,
      deleteKbFromSelectedKbs,
      editHostName,
      tagListInHostList,
      kbsInWl,
      isKbWlTask,
      isPkgWlTask,
      delKbFromMutableBlock,
      isAssetName,
      selectAllPkgOnPage,
      cancelSelectedInTable,
      selectAllWinSoftwareOnPage,
      delSelectedWinSoftFrom,
      winSoftwareUpdateState,
      winSoftwareUpdateStateWl,
      addSoftwareToTask,
      availableUpdateModalData,
      isOpenDynamicGroupList,
      softwareInWl,
      handleSoftwareAction,
      modifySoftwareInWl,
      isExceptionAssetVulnTable,
      isAuditDataExists,
    ],
  );

  return <HostModalContext.Provider value={value}>{children}</HostModalContext.Provider>;
}
export const useHostModalContext = (): THostModalContent => {
  const context = useContext(HostModalContext);
  if (context === null) {
    throw new Error('useHostModalContext must be used inside the ModalContext.Provider.');
  }

  return context;
};
