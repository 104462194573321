import type { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { memo } from 'react';
import { useHostModalContext } from '../../../../hooks/useHostModalContext';
import type { InstalledSoftwareReadResponse } from '../../../../types/__generated/on-premise-solution/api/installedSoftwareListDashboardResponse.v1';

function CellSelectSoft(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { name }: InstalledSoftwareReadResponse = dataItem;

  const { softwareInWl, handleSoftwareAction } = useHostModalContext();

  return (
    <td>
      <Checkbox
        checked={softwareInWl && name in softwareInWl}
        onClick={(): void => handleSoftwareAction(dataItem, dataItem.osType, 'default')}
      />
    </td>
  );
}

export default memo(CellSelectSoft);
