import locale from '../i18n/taskLocale/inventorizationTask.json';
import type { ILinSoftList } from './types';

export interface IPrepareLinuxData {
  name: string;
  version: string;
  arch: string;
  size?: number | string | null | undefined;
  url?: string | null | undefined;
  installedAt?: string | null | undefined;
  publisher?: string | null | undefined;
}

export function modifySizeValue(size: number | null | undefined): string {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof locale;

  let sizeValue = '';

  if (size && size > 1024) {
    sizeValue = `${Math.round(Number(size) / 1024)} ${locale[currentLocale].sizeKb}`;
  } else if (size && size <= 1024) {
    sizeValue = `${size} ${locale[currentLocale].byte}`;
  } else {
    sizeValue = '-';
  }

  return sizeValue;
}

export function prepareLinuxData(linuxData: ILinSoftList[]): IPrepareLinuxData[] {
  const linuxSoftware = linuxData?.map((m) => {
    if ('size' in m) {
      return {
        ...m,
        size: modifySizeValue(m.size),
      };
    }

    return m;
  });

  return linuxSoftware;
}

export function getMainPartOfOsName(os: string): string {
  let osName = '';

  switch (os.toLowerCase().indexOf('') !== -1) {
    case os.toLowerCase().indexOf('checkpoint') !== -1:
      osName = 'checkpoint';
      break;
    case os.toLowerCase().indexOf('msvsphere') !== -1:
      osName = 'msvsphere';
      break;
    case os.toLowerCase().indexOf('ubuntu') !== -1:
      osName = 'ubuntu';
      break;
    case os.toLowerCase().indexOf('debian') !== -1:
      osName = 'debian';
      break;
    case os.toLowerCase().indexOf('centos') !== -1:
      osName = 'centos';
      break;
    case os.toLowerCase().indexOf('fedora') !== -1:
      osName = 'fedora';
      break;
    case os.toLowerCase().indexOf('redhat') !== -1 ||
      os.toLowerCase().indexOf('red hat') !== -1 ||
      os.toLowerCase().indexOf('rhel') !== -1:
      osName = 'redhat';
      break;
    case os.toLowerCase().indexOf('altlinux') !== -1 || os.toLowerCase().indexOf('alt') !== -1:
      osName = 'altlinux';
      break;
    case os.toLowerCase().indexOf('manjaro') !== -1:
      osName = 'manjaro';
      break;
    case os.toLowerCase().indexOf('arch') !== -1:
      osName = 'arch';
      break;
    case os.toLowerCase().indexOf('astralinux') !== -1 ||
      os.toLowerCase().indexOf('astra linux') !== -1 ||
      os.toLowerCase().indexOf('astra') !== -1 ||
      os.toLowerCase().indexOf('astra_smolensk') !== -1:
      osName = 'astralinux';
      break;
    case os.toLowerCase().indexOf('mintlinux') !== -1:
      osName = 'mintlinux';
      break;
    case os.toLowerCase().indexOf('amazon') !== -1 || os.toLowerCase().indexOf('amzn') !== -1:
      osName = 'amazon';
      break;
    case os.toLowerCase().indexOf('rocky') !== -1:
      osName = 'rocky';
      break;
    case os.toLowerCase().indexOf('alpine') !== -1:
      osName = 'alpine';
      break;
    case os.toLowerCase().indexOf('gentoo') !== -1:
      osName = 'gentoo';
      break;
    case os.toLowerCase().indexOf('sled') !== -1 || os.toLowerCase().indexOf('suse') !== -1:
      osName = 'suse';
      break;
    case os.toLowerCase().indexOf('osnova') !== -1:
      osName = 'osnova';
      break;
    case os.toLowerCase().indexOf('rosa') !== -1 ||
      os.toLowerCase().indexOf('rosa_cobalt_desktop') !== -1 ||
      os.toLowerCase().indexOf('rosa_enterprise_desktop') !== -1 ||
      os.toLowerCase().indexOf('rosa_enterprise_server') !== -1 ||
      os.toLowerCase().indexOf('rosa_fresh_desktop') !== -1 ||
      os.toLowerCase().indexOf('rosa_helium_desktop') !== -1 ||
      os.toLowerCase().indexOf('rosa_helium_server') !== -1 ||
      os.toLowerCase().indexOf('rels') !== -1:
      osName = 'rosa';
      break;
    case os.toLowerCase().indexOf('red os') !== -1:
      osName = 'redos';
      break;

    case os.toLowerCase().indexOf('microsoft') !== -1:
      osName = 'windows';
      break;
    case os.toLowerCase().indexOf('майкрософт') !== -1:
      osName = 'windows';
      break;
    case os.toLowerCase().indexOf('windows') !== -1:
      osName = 'windows';
      break;
    case os.toLowerCase().indexOf('virtuozzo') !== -1:
      osName = 'virtuozzo';
      break;
    case os.toLowerCase().indexOf('sberlinux') !== -1:
      osName = 'sberlinux';
      break;
    case os.toLowerCase().indexOf('oracle') !== -1 || os.toLowerCase().indexOf('ol') !== -1:
      osName = 'oracle';
      break;

    case os.toLowerCase().indexOf('mageia') !== -1:
      osName = 'mageia';
      break;

    default:
      osName = os;
  }

  return osName;
}
