/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
import type { GridCellProps } from '@progress/kendo-react-grid';
import type { TreeListCellProps } from '@progress/kendo-react-treelist';
import { useCallback, useEffect, type ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import localeHost from '../../../../utils/i18n/assetLocales/hostList.json';
import { useSensorContext } from '../../../../hooks/useSensorContext';
import { TagList } from '../../../common/tag/TagList';
import styles from '../../../common/tag/Tag.module.scss';
import { useHostModalContext } from '../../../../hooks/useHostModalContext';
import { useTagContext } from '../../../../hooks/useTagContext';
import type { SensorsDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/sensorsDashboardResponse.v1';
import type { SensorsDashboardResponseTree } from '../../../../types/__generated/on-premise-solution/api/sensorsDashboardResponseTree.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHost;

function ExpandItem(props: TreeListCellProps): ReactElement {
  const { dataItem, level, onExpandChange, expanded, hasChildren } = props;

  // eslint-disable-next-line no-nested-ternary
  const iconName = hasChildren
    ? expanded
      ? 'k-i-arrow-60-down'
      : 'k-i-arrow-60-right'
    : 'k-i-none';

  return (
    <>
      {/* {level.map(() => (
        <span className="k-icon tree-line-icon" />
      ))} */}
      {level.map((l, ind) => {
        let verticalLineHeight = '40px';
        if (ind === level.length - 1 && dataItem?.isLastElement[ind]) verticalLineHeight = '20px';
        else if (dataItem?.isLastElement[ind]) verticalLineHeight = '0';

        return (
          <>
            <span
              style={{
                position: 'absolute',
                height: verticalLineHeight,
                width: '24px',
                top: '0',
              }}
              className={`k-icon tree-line-icon level_${ind}`}
            />
            <span
              style={{
                width: '24px',
                display: 'inline-block',
              }}
            />
            {ind === level.length - 1 && (
              <span
                className="k-icon tree-line-horizontal-icon"
                style={{
                  position: 'absolute',
                  height: '40px',
                  top: '0',
                  left: `${(ind + 1) * 24}px`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  width: hasChildren ? '12px' : '28px',
                }}
              />
            )}
          </>
        );
      })}
      {/* {level.length === 1 && (
        <span
          className="k-icon tree-line-horizontal-icon"
          style={{
            position: 'absolute',
            height: '40px',
            top: '0',
            left: '22px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            width: hasChildren ? '12px' : '28px',
          }}
        />
      )} */}
      <span
        className={`k-icon ${iconName}`}
        style={{
          padding: '4px',
          // marginLeft: `${(level.length - 1) * 20}px`,
          cursor: hasChildren ? 'pointer' : 'default',
        }}
        onClick={(e): void => onExpandChange(e, dataItem, level)}
      />
    </>
  );
}

export function CellSensorName(props: GridCellProps | TreeListCellProps): ReactElement {
  const { sensorNameLastCells } = useSensorContext();
  const { closeTagWindow } = useTagContext();
  const { tagListInHostList, setTagListInHostList } = useHostModalContext();
  const { dataItem, level } = props;

  const isTreeView = level && Array.isArray(level);

  const {
    sensorId,
    tagIds,
    tags,
    name,
  }: SensorsDashboardResponse['data'][0] | SensorsDashboardResponseTree['data'][0] = dataItem;

  const handleKeydown = useCallback((e: KeyboardEvent): void => {
    if (e.key === 'Escape') {
      setTagListInHostList('');
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', closeTagWindow);

    return () => {
      document.removeEventListener('click', closeTagWindow);
      document.removeEventListener('keydown', handleKeydown);
      setTagListInHostList('');
    };
  }, []);

  const nameCell = (
    <td
      style={
        isTreeView
          ? {
              position: 'relative',
            }
          : undefined
      }
    >
      <div className="name-wrap">
        {'expandable' in props && <ExpandItem {...props} />}
        {sensorId ? (
          <span style={{ display: 'inline-block' }}>
            {!isTreeView ? (
              <div className="domainname-wrap">
                <NavLink to={`/lk/sensors/${sensorId}`}>
                  {name || localeHost[currentLocale].form.waitingForData}
                </NavLink>
                <div className={styles.tagBlockHostList}>
                  <TagList
                    entityId={sensorId}
                    tags={tags}
                    tagIds={tagIds}
                    componentName={Array.isArray(level) ? 'SensorTreeList' : 'SensorClassicList'}
                    domainNameLength={name.length}
                    entityType="sensor"
                  />
                  {tags && tags.length >= 2 && (
                    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
                    <div
                      className={styles.tagsCount}
                      onMouseOver={(): void => setTagListInHostList(sensorId)}
                    >
                      <span>+ {tags.length - 1}</span>
                    </div>
                  )}
                </div>
                {tagListInHostList && tagListInHostList === sensorId && (
                  <div
                    className={styles.allTagBlockHostListWrap}
                    onMouseLeave={(): void => setTagListInHostList('')}
                  >
                    <div className={styles.allTagBlockHostList}>
                      <TagList
                        entityId={sensorId}
                        tags={tags}
                        tagIds={tagIds}
                        componentName="SensorListAllTag"
                        entityType="sensor"
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <NavLink to={`/lk/sensors/${sensorId}`}>
                {name || localeHost[currentLocale].form.waitingForData}
              </NavLink>
            )}
          </span>
        ) : (
          name || '-'
        )}
      </div>
    </td>
  );

  if (isTreeView) sensorNameLastCells.current[level.length - 1] = nameCell;

  return nameCell;
}
