import type { GridCellProps } from '@progress/kendo-react-grid';
import cronstrue from 'cronstrue/i18n';
import { getUTCForTaskList } from '../../../../utils/helpers/add-task-helper';
import localeTask from '../../../../utils/i18n/taskLocale/task.json';
import type { TaskHostDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/tasksDashboardResponse.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeTask;
export function Schedule(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { schedule }: TaskHostDashboardResponse = dataItem;

  return (
    <>
      {schedule && 'repeat' in schedule && (
        <td
          title={cronstrue.toString(getUTCForTaskList(schedule.repeat), {
            locale: currentLocale.slice(0, 2),
          })}
        >
          {'useAssetLocalTime' in schedule && schedule.useAssetLocalTime ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {cronstrue.toString(getUTCForTaskList(schedule.repeat), {
                  locale: currentLocale.slice(0, 2),
                })}
              </div>
              <div>
                <img
                  title={localeTask[currentLocale].inAssetLocale}
                  style={{
                    display: 'flex',
                    width: '16px',
                    height: '16px',
                    margin: '0 4px',
                  }}
                  src={`${process.env.PUBLIC_URL}/images/time-zone-icon.svg`}
                  alt=""
                />
              </div>
            </div>
          ) : (
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {cronstrue.toString(getUTCForTaskList(schedule.repeat), {
                locale: currentLocale.slice(0, 2),
              })}
            </div>
          )}
        </td>
      )}
      {schedule && 'startAt' in schedule && (
        <td>
          {'useAssetLocalTime' in schedule && schedule.useAssetLocalTime ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {new Date(schedule.startAt).toLocaleString(currentLocale, { timeZone: 'UTC' })}
              <div>
                <img
                  title={localeTask[currentLocale].inAssetLocale}
                  style={{
                    display: 'flex',
                    width: '16px',
                    height: '16px',
                    margin: '0 4px',
                  }}
                  src={`${process.env.PUBLIC_URL}/images/time-zone-icon.svg`}
                  alt=""
                />
              </div>
            </div>
          ) : (
            new Date(schedule.startAt).toLocaleString()
          )}
        </td>
      )}
      {!schedule && <td>-</td>}
    </>
  );
}
