import React, { useCallback, useEffect, useState } from 'react';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import localeVmTask from '../../../../../utils/i18n/taskLocale/vmTask.json';
import '../../TaskDescription.module.scss';
import { VulnerabilitiesList } from './VulnerabilitiesList';
import type {
  AuditDashboardResponse,
  AuditNetworkDeviceResult,
} from '../../../../../types/__generated/on-premise-solution/api/auditDashboardResponse.v1';
import { VulnSoftware } from './VulnSoftware';
import { useAssetCreatePropContext } from '../../../../../hooks/useAssetCreatePropContext';
import { useHostModalContext } from '../../../../../hooks/useHostModalContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeVmTask;

export function TabsVmNetDevDetails({
  data,
  hostName,
}: {
  data: AuditDashboardResponse | undefined;
  hostName: string | undefined | false | null;
}): React.ReactElement {
  const [selected, setSelected] = useState<number>(0);

  const { setSelectedPkgs, setSelectedPkgsWl, setMissedKbs, setKbsInWl } = useHostModalContext();

  const { setSelectedVulns } = useAssetCreatePropContext();

  const handleSelect = useCallback(
    (e: TabStripSelectEventArguments): void => {
      setSelected(e.selected);
    },
    [setSelected],
  );

  const countVulnerableObjects = data?.result?.vulnerableObjects
    ? data.result?.vulnerableObjects.length
    : 0;

  const countVulns = data?.result?.cumulativeData.vulns
    ? Object.keys(data?.result.cumulativeData.vulns).length
    : 0;

  const auditNetworkDeviceResult = data?.result as AuditNetworkDeviceResult;

  const classOfDvice: string | undefined =
    (data &&
      data.type === 'network_device' &&
      data.asset &&
      'class' in data.asset &&
      data.asset.class) ||
    undefined;

  useEffect(() => {
    return () => {
      setSelectedPkgs(null);
      setSelectedPkgsWl(null);
      setSelectedVulns(null);
      setKbsInWl(null);
      setMissedKbs(null);
    };
  }, []);

  return (
    <TabStrip selected={selected} onSelect={handleSelect}>
      <TabStripTab
        title={`${localeVmTask[currentLocale].tabs.vulnSoftWin} (${countVulnerableObjects})`}
      >
        <VulnSoftware
          type={data?.type}
          dataResult={auditNetworkDeviceResult}
          hostName={hostName}
          classOfDvice={classOfDvice}
        />
      </TabStripTab>
      <TabStripTab title={`${localeVmTask[currentLocale].tabs.vulnerabilities} (${countVulns})`}>
        <VulnerabilitiesList dataResult={data?.result} hostName={hostName} />
      </TabStripTab>
    </TabStrip>
  );
}
