/* eslint-disable react/jsx-pascal-case */
import { useCallback } from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Button } from '../../../common/baseElements/Button';
import localeUser from '../../../../utils/i18n/userLocales/userList.json';
import localeNewTask from '../../../../utils/i18n/taskLocale/newTask.json';
import type { IDataHooks } from '../../../../utils/helpers/types';
import { getUrlUser } from '../../../../utils/helpers/user-list-helper';
import { CommonUserList } from '../../../common/CommonUserList';
import { useUserList } from '../../../../hooks/components/useUserList';
import { useUserModalContext } from '../../../../hooks/useUserModalContext';
import { Input } from '../../../common/baseElements/Input';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeUser;

export function UserListForm({
  parentComponent,
}: {
  parentComponent?: string;
}): React.ReactElement {
  const urlParams = getUrlUser('addUserList');

  const dataForHooks: IDataHooks = {
    name: 'name',
    componentName: 'AddUserList',
  };

  const {
    setAddUserEmail,
    userTaskEmail,
    userTaskEmailTemp,
    setUserTaskEmailTemp,
    addAllUserEmail,
    delAllUserEmail,
    addSelectedUsers,
    username,
    setUsername,
    setUid,
  } = useUserModalContext();

  const { sort, page, total, pageChange, setSort, data, handleCustomSearch, customSearch } =
    useUserList(urlParams, dataForHooks, undefined, parentComponent, parentComponent);

  const handleExit = useCallback(() => {
    setUserTaskEmailTemp(null);
    setAddUserEmail(false);
    setUsername('');
    setUid('');
  }, [setAddUserEmail, setUid, setUserTaskEmailTemp, setUsername]);

  return (
    <Dialog
      title={localeUser[currentLocale].userList}
      onClose={handleExit}
      className="user-list modal"
    >
      {/* <div className={parentComponent !== 'addTicket' ? 'k-form' : 'modal-k-form-mt'}> */}
      <div className="k-form">
        <div className="form-content groups">
          {parentComponent !== 'addTicket' && (
            <div className="k-form-select-buttons">
              <Button onClick={(): void => addAllUserEmail(data)}>
                {localeNewTask[currentLocale].form.btnAddAllAssets}
              </Button>
              {userTaskEmailTemp && userTaskEmailTemp.length > 0 && (
                <Button onClick={delAllUserEmail}>
                  {localeNewTask[currentLocale].form.btnDelAllUsers} ({userTaskEmailTemp?.length})
                </Button>
              )}
            </div>
          )}
          <div
            className={
              parentComponent !== 'addTicket'
                ? 'user-list-for-form-search-mt28'
                : 'user-list-for-form-search-mt8'
            }
          >
            <Input
              className="k-input custom-search"
              name="customSearch"
              placeholder={localeUser[currentLocale].modal.placeHolderCustomSearch}
              value={customSearch}
              onChange={(e): void => handleCustomSearch(e)}
            />
          </div>
          <CommonUserList
            sort={sort}
            data={data}
            page={page}
            total={total}
            pageChange={pageChange}
            setSort={setSort}
            dataForHooks={dataForHooks}
            parentComponent={parentComponent}
          />
        </div>
        <div className="k-form-buttons">
          <Button
            type="submit"
            disabled={
              (parentComponent !== 'addTicket' &&
                !userTaskEmailTemp &&
                userTaskEmail?.length === 0) ||
              (parentComponent !== 'addTicket' && !userTaskEmailTemp && userTaskEmail === null) ||
              (parentComponent !== 'addTicket' &&
                !userTaskEmail &&
                userTaskEmailTemp?.length === 0) ||
              (parentComponent !== 'addTicket' && !userTaskEmail && userTaskEmailTemp === null) ||
              (parentComponent === 'addTicket' && !username)
            }
            onClick={addSelectedUsers}
          >
            {localeNewTask[currentLocale].form.btnAddAsset}{' '}
          </Button>
          <Button type="button" onClick={handleExit}>
            {localeNewTask[currentLocale].form.btnCancel}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
