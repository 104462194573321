import type { GridCellProps } from '@progress/kendo-react-grid';
import { RadioButton } from '@progress/kendo-react-inputs';
import { useEffect } from 'react';
import { useHostActions } from '../../../../hooks/components/useHostActions';
import { useCredentialContext } from '../../../../hooks/useCredentialContext';
import { useRegisterContext } from '../../../../hooks/useRegisterContext';
import { useServerContext } from '../../../../hooks/useServerContext';
import { useTaskAddContext } from '../../../../hooks/useTaskAddContext';
import { useTicketSystemContext } from '../../../../hooks/useTicketSystemContext';

export function CellCheckCredential(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { selectCredential } = useHostActions();

  const { credentialReg } = useRegisterContext();

  const { selectedCredential, setSelectedCredential, credentialHost, credentialDev } =
    useCredentialContext();

  const { credentialSrv } = useServerContext();

  const { commonCredInTask } = useTaskAddContext();

  const { credentialTicket } = useTicketSystemContext();

  useEffect(() => {
    if (credentialHost && credentialHost.credentialId)
      setSelectedCredential({ ...selectedCredential, credentialId: credentialHost.credentialId });

    if (credentialDev && credentialDev.credentialId)
      setSelectedCredential({ ...selectedCredential, credentialId: credentialDev.credentialId });

    if (credentialReg && credentialReg.credentialId)
      setSelectedCredential({ ...selectedCredential, credentialId: credentialReg.credentialId });

    if (credentialSrv && credentialSrv.credentialId)
      setSelectedCredential({ ...selectedCredential, credentialId: credentialSrv.credentialId });

    if (credentialTicket && credentialTicket.credentialId)
      setSelectedCredential({ ...selectedCredential, credentialId: credentialTicket.credentialId });

    if (
      commonCredInTask &&
      commonCredInTask.find((c) => c.type === dataItem.type && c.credentialId)
    )
      setSelectedCredential({
        ...selectedCredential,
        credentialId: commonCredInTask.filter((c) => c.type === dataItem.type && c.credentialId)[0]
          .credentialId,
      });
  }, []);

  return (
    <td style={{ textOverflow: 'clip' }}>
      <RadioButton
        name="credentialGroup"
        value={dataItem.credentialId}
        checked={selectedCredential?.credentialId === dataItem.credentialId}
        onChange={(): void =>
          selectCredential({
            ...selectedCredential,
            credentialId: dataItem.credentialId,
            credentialName: dataItem.name,
            type: dataItem.type,
          })
        }
      />
    </td>
  );
}
