import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Dialog } from '@progress/kendo-react-dialogs';
import { useEffect } from 'react';
import { checkLength } from '../../../../../utils/helpers/validation';
import localeUserList from '../../../../../utils/i18n/userLocales/userList.json';
import localeTicket from '../../../../../utils/i18n/security/vulnerabilities.json';
import { FormInput } from '../../../../common/form/FormInput';
import { DropDownSelect } from '../../../../common/form/DropDownSelect';
import { useAccounts } from '../../../../../hooks/components/useAccounts';
import { FormTextArea } from '../../../../common/form/FormTextArea';
import { СommonTableLoader } from '../../../../common/tableLoader/CommonTableLoader';
import { useAccountModalContext } from '../../../../../hooks/useAccountModalContext';
import {
  getAccountId,
  getAccountName,
  getUserData,
} from '../../../../../services/local-storage-service';
import styles from '../TicketSystem.module.scss';
import { useTicketContext } from '../../../../../hooks/useTicketContext';
import { ticketSystemTypeValue } from '../../../../../utils/helpers/constants';
import { CheckBox } from '../../../../common/form/CheckBox';
import { StatusMappingBlock } from './StatusMappingBlock';
import { PriorityMappingBlock } from './PriorityMappingBlock';
import CronTask from '../../../Task/form/CronTask';
import { DeploymentMethodBlock } from './DeploymentMethodBlock';
import { useTicketSystemMethods } from '../../../../../hooks/components/useTicketSystemMethods';
import { CredentialsTicketForm } from './CredentialsTicketForm';
import { TicketMetaInfoForm } from './TicketMetaInfoForm';
import { TicketJiraProjectNameForm } from './TicketJiraProjectNameForm';
import { TicketYandexTrackerQueueNameForm } from './TicketYandexTrackerQueueNameForm';
import { TicketButtonsForm } from './TicketButtonsForm';
import { useTicketSystemContext } from '../../../../../hooks/useTicketSystemContext';

export function AddTicketSystemForm(): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeTicket;

  const currentUser = getUserData();

  const { changeDropDownAccount, accountId, setAccountId } = useAccountModalContext();

  const { ticketSystemType, errorMutation, setErrorMutation } = useTicketContext();

  const {
    name,
    setName,
    descriptionTs,
    setDescriptionTs,
    editTicketSystemData,
    isDefault,
    setDefault,
    apiBaseUrl,
    setApiBaseUrl,
    isUseCron,
    setUseCron,
    organizationId,
    setOrganizationId,
    isOnlyYandexCloudOrganizationUsed,
    setOnlyYandexCloudOrganizationUsed,
  } = useTicketSystemContext();

  useEffect(() => {
    if (accountId.id === '000') {
      const currentAccouintId = getAccountId();
      const currentAccountName = getAccountName();
      setAccountId({ id: currentAccouintId || '', name: currentAccountName || '' });
    }

    return () => setErrorMutation('');
  }, []);

  const { destructuringAccounts, isLoading } = useAccounts();

  const destructuredAccounts = destructuringAccounts();

  const { handleClose, changeDropDownType } = useTicketSystemMethods();

  return (
    <Dialog
      title={
        !editTicketSystemData
          ? localeTicket[currentLocale].ticketSystem.form.title
          : `${localeTicket[currentLocale].ticketSystem.form.titleEdit} ${name}`
      }
      onClose={handleClose}
      className="modal small"
    >
      <Form
        render={(): JSX.Element => (
          <FormElement>
            <div className="form-content">
              {!editTicketSystemData && currentUser?.role === 'super_admin' && (
                <Field
                  name="accountId"
                  component={DropDownSelect}
                  label={localeTicket[currentLocale].tickets.form.accountName}
                  onChange={changeDropDownAccount}
                  data={destructuredAccounts?.filter((a) => a.id !== accountId.id)}
                  textField="name"
                  dataItemKey="id"
                  defaultValue={{
                    id: '000',
                    name: localeUserList[currentLocale].modal.placeholderAccountName,
                  }}
                  cls="form-field-wrapper"
                  currentValue={accountId}
                  disabled={!destructuredAccounts}
                  classForLabel={!editTicketSystemData ? 'required-label' : ''}
                />
              )}
              <Field
                name="name"
                component={FormInput}
                id="name"
                label={localeTicket[currentLocale].ticketSystem.form.name}
                onChange={(e): void => {
                  setName(e.target.value.trimStart());
                }}
                inputValue={name}
                className="form-field-wrapper"
                customValidator={!editTicketSystemData && checkLength}
                type="text"
                classForLabel={!editTicketSystemData ? 'required-label' : ''}
              />
              <Field
                name="ticketSystemType"
                component={DropDownSelect}
                label={localeTicket[currentLocale].tickets.form.ticketSystemType}
                onChange={(e): void => changeDropDownType(e)}
                data={ticketSystemTypeValue.filter((d) => d !== ticketSystemType)}
                defaultValue={ticketSystemType}
                className="form-field-wrapper"
                currentValue={ticketSystemType}
                classForLabel={!editTicketSystemData ? 'required-label' : ''}
              />
              {ticketSystemType === 'jira' && <DeploymentMethodBlock />}
              <Field
                name="apiBaseUrl"
                component={FormInput}
                id="apiBaseUrl"
                label={localeTicket[currentLocale].ticketSystem.form.apiBaseUrl}
                onChange={(e): void => {
                  setApiBaseUrl(e.target.value);
                }}
                inputValue={apiBaseUrl}
                className="form-field-wrapper"
                customValidator={!editTicketSystemData && checkLength}
                type="text"
                classForLabel={!editTicketSystemData ? 'required-label' : ''}
              />
              {ticketSystemType === 'yandex_tracker' && (
                <>
                  <Field
                    name="organizationId"
                    component={FormInput}
                    id="organizationId"
                    label={localeTicket[currentLocale].ticketSystem.form.organizationId}
                    onChange={(e): void => {
                      setOrganizationId(e.target.value.trimStart());
                    }}
                    inputValue={organizationId}
                    className="form-field-wrapper"
                    customValidator={!editTicketSystemData && checkLength}
                    type="text"
                    classForLabel={!editTicketSystemData ? 'required-label' : ''}
                  />
                  <Field
                    name="isOnlyYandexCloudOrganizationUsed"
                    component={CheckBox}
                    checked={isOnlyYandexCloudOrganizationUsed}
                    value={isOnlyYandexCloudOrganizationUsed}
                    label="Yandex Cloud Organization"
                    onChange={(e): void => {
                      setOnlyYandexCloudOrganizationUsed(e.target.value);
                    }}
                  />
                </>
              )}
              <CredentialsTicketForm />
              <TicketMetaInfoForm />
              <TicketJiraProjectNameForm />
              {ticketSystemType === 'yandex_tracker' && <TicketYandexTrackerQueueNameForm />}
              <StatusMappingBlock />
              <PriorityMappingBlock />
              <Field
                name="isUseCron"
                component={CheckBox}
                checked={isUseCron}
                value={isUseCron}
                label={localeTicket[currentLocale].ticketSystem.form.isCron}
                onChange={(): void => setUseCron(!isUseCron)}
              />
              {isUseCron && (
                <div className={styles.cronTaskWrap}>
                  <CronTask label={localeTicket[currentLocale].ticketSystem.form.cron} />
                </div>
              )}
              <Field
                name="description"
                component={FormTextArea}
                id="description"
                label={localeTicket[currentLocale].tickets.form.description}
                onChange={(e): void => {
                  setDescriptionTs(e.value);
                }}
                placeholder={localeTicket[currentLocale].ticketSystem.form.desPlaceholder}
                inputValue={descriptionTs}
              />
              <Field
                name="isDefault"
                component={CheckBox}
                checked={isDefault}
                value={isDefault}
                label={localeTicket[currentLocale].ticketSystem.form.isDefault}
                onChange={(e): void => {
                  setDefault(e.target.value);
                }}
              />
            </div>
            <div className="error-common-field">
              <span>{errorMutation}</span>
            </div>
            <TicketButtonsForm />
          </FormElement>
        )}
      />
      <СommonTableLoader queryData={isLoading} filteringData={isLoading} />
    </Dialog>
  );
}
