import { useCallback, useRef, useState, type ReactElement } from 'react';
import { Input as KendoInput, NumericTextBox, RadioButton } from '@progress/kendo-react-inputs';
import { useParams } from 'react-router';
import { useQueryClient } from 'react-query';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { Button } from '../../../common/baseElements/Button';
import localeButtons from '../../../../utils/i18n/commonLocales/buttons.json';
import localeAccount from '../../../../utils/i18n/accountLocales/accountDescription.json';
import localeSettings from '../../../../utils/i18n/settingsLocales/settings.json';
import type { AccountDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/accountsDashboardResponse.v1';
import type { AccountUpdateRequest } from '../../../../types/__generated/on-premise-solution/api/accountUpdateRequest.v1';
import { useAccountModalContext } from '../../../../hooks/useAccountModalContext';
import settingsStyles from '../../Settings/Settings.module.scss';
import styles from '../AccountList.module.scss';
import type { ConfigResponse } from '../../../../types/__generated/on-premise-solution/api/configResponse.v1';
import { Input } from '../../../common/baseElements/Input';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAccount;

export function SecurityTab({
  data,
  config,
}: {
  data: AccountDashboardResponse['settings']['userAuth'] | undefined;
  config: ConfigResponse | undefined;
}): ReactElement {
  const { accountId } = useParams<{ accountId: string }>();
  const { mutationEditAccount } = useAccountModalContext();
  const queryClient = useQueryClient();

  const [isInherited, setInherited] = useState(!!data?.useInherited);
  const [captchaRequired, setCaptchaRequired] = useState(
    (
      data?.value?.user_max_unsuccessful_login_attempts ||
      config?.api.user_max_unsuccessful_login_attempts ||
      ''
    ).toString(),
  );
  const [blockRequired, setBlockRequired] = useState(
    (
      data?.value?.user_max_unsuccessful_login_attempts_before_block ||
      config?.api.user_max_unsuccessful_login_attempts_before_block ||
      ''
    ).toString(),
  );
  const [blockDuration, setBlockDuration] = useState(
    (data?.value?.user_login_block_mins || config?.api.user_login_block_mins || '').toString(),
  );
  const [passwordAge, setPasswordAge] = useState(
    (data?.value?.user_password_max_age !== undefined
      ? data.value.user_password_max_age
      : config?.api.user_password_max_age || ''
    ).toString(),
  );
  const [passMaxNumber, setPassMaxNumber] = useState(
    (
      data?.value?.user_used_passwords_max_number ||
      config?.api.user_used_passwords_max_number ||
      ''
    ).toString(),
  );

  const timer = useRef<NodeJS.Timeout | null>(null);
  const [isShowSavedMessage, setShowSavedMessage] = useState(false);

  const showSavedMessage = (): void => {
    setShowSavedMessage(true);
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setShowSavedMessage(false);
    }, 3000);
  };

  const timeoutValidator = (value: string): string => {
    if (/^[1-9]{1}[0-9]*$/.test(value)) return '';

    return localeAccount[currentLocale].settingsTab.NaNerror;
  };

  const timeoutValidatorZero = (value: string): string => {
    if (/^[0-9]+$/.test(value)) return '';

    return localeAccount[currentLocale].settingsTab.NaNerror;
  };

  const handleSave = (): void => {
    const payload: AccountUpdateRequest = {
      'settings.userAuth.useInherited': isInherited,
    };
    if (!isInherited) {
      payload['settings.userAuth.value.user_max_unsuccessful_login_attempts'] = parseInt(
        captchaRequired,
        10,
      );
      payload['settings.userAuth.value.user_max_unsuccessful_login_attempts_before_block'] =
        parseInt(blockRequired, 10);
      payload['settings.userAuth.value.user_login_block_mins'] = parseInt(blockDuration, 10);
      payload['settings.userAuth.value.user_password_max_age'] = parseInt(passwordAge, 10);
      payload['settings.userAuth.value.user_used_passwords_max_number'] = parseInt(
        passMaxNumber,
        10,
      );
    }

    mutationEditAccount
      .mutateAsync({
        accountId,
        payload,
      })
      .then(() => {
        showSavedMessage();
        queryClient.invalidateQueries('accountDescription');
      });
  };

  const isDisabled = useCallback((): boolean => {
    if (
      data?.useInherited !== isInherited ||
      (!isInherited &&
        ((data.value?.user_max_unsuccessful_login_attempts || '').toString() !== captchaRequired ||
          (data.value?.user_max_unsuccessful_login_attempts_before_block || '').toString() !==
            blockRequired ||
          (data.value?.user_login_block_mins || '').toString() !== blockDuration ||
          (data.value?.user_password_max_age || '').toString() !== passwordAge ||
          (data.value?.user_used_passwords_max_number || '').toString() !== passMaxNumber))
    )
      return false;

    return true;
  }, [
    blockDuration,
    blockRequired,
    captchaRequired,
    data?.useInherited,
    data?.value?.user_login_block_mins,
    data?.value?.user_max_unsuccessful_login_attempts,
    data?.value?.user_max_unsuccessful_login_attempts_before_block,
    data?.value?.user_password_max_age,
    data?.value?.user_used_passwords_max_number,
    isInherited,
    passMaxNumber,
    passwordAge,
  ]);

  return (
    <Form
      onSubmit={handleSave}
      initialValues={{
        captchaRequired,
        blockRequired,
        blockDuration,
        passwordAge,
        passMaxNumber,
      }}
      render={(formRenderProps: FormRenderProps): JSX.Element => (
        <FormElement className="full-size">
          <h2>{localeAccount[currentLocale].settingsTab.passwordPoliсy}</h2>
          <table className={styles.settingsTable}>
            <tr>
              <td />
              <td>
                <Field
                  name="use_global_config"
                  checked={isInherited}
                  onChange={(): void => setInherited(true)}
                  label={localeAccount[currentLocale].settingsTab.useGlobalParameters}
                  component={RadioButton}
                  value={isInherited}
                />
              </td>
              <td>
                <Field
                  name="use_manual_config"
                  onChange={(): void => setInherited(false)}
                  value
                  checked={!isInherited}
                  label={localeAccount[currentLocale].settingsTab.useCustomParameters}
                  component={RadioButton}
                />
              </td>
            </tr>
            <tr>
              <td>{localeAccount[currentLocale].settingsTab.captchaBlockAttemptsNumber}</td>
              <td>
                <span>
                  <Input value={config?.api.user_max_unsuccessful_login_attempts} disabled />
                </span>
              </td>
              <td>
                <span>
                  <Field
                    name="captchaRequired"
                    component={Input}
                    value={captchaRequired}
                    disabled={isInherited}
                    onChange={(e): void => setCaptchaRequired(e.target.value as string)}
                    defaultValue={captchaRequired}
                    validator={!isInherited ? timeoutValidator : undefined}
                    valid={!isInherited}
                    required={!isInherited}
                  />
                </span>
              </td>
            </tr>
            <tr>
              <td>{localeAccount[currentLocale].settingsTab.userBlockAttemptsNumber}</td>
              <td>
                <span>
                  <Input
                    value={config?.api.user_max_unsuccessful_login_attempts_before_block}
                    disabled
                  />
                </span>
              </td>
              <td>
                <span>
                  <Field
                    // label={localeAccount[currentLocale].settingsTab.userBlockAttemptsNumber}
                    name="blockRequired"
                    component={Input}
                    value={blockRequired}
                    disabled={isInherited}
                    onChange={(e): void => setBlockRequired(e.target.value as string)}
                    defaultValue={blockRequired}
                    validator={!isInherited ? timeoutValidator : undefined}
                    valid={!isInherited}
                    required={!isInherited}
                  />
                </span>
              </td>
            </tr>
            <tr>
              <td>{localeAccount[currentLocale].settingsTab.blockDuration}</td>
              <td>
                <span>
                  <Input value={config?.api.user_login_block_mins} disabled />
                </span>
              </td>
              <td>
                <span>
                  <Field
                    name="blockDuration"
                    component={Input}
                    value={blockDuration}
                    disabled={isInherited}
                    onChange={(e): void => setBlockDuration(e.target.value as string)}
                    defaultValue={blockDuration}
                    validator={!isInherited ? timeoutValidator : undefined}
                    valid={!isInherited}
                    required={!isInherited}
                  />
                </span>
              </td>
            </tr>
            <tr>
              <td>{localeAccount[currentLocale].settingsTab.passwordLifetime}</td>
              <td>
                <span>
                  <Input value={config?.api.user_password_max_age} disabled />
                </span>
              </td>
              <td>
                <span>
                  <Field
                    name="passwordAge"
                    component={Input}
                    value={passwordAge}
                    disabled={isInherited}
                    onChange={(e): void => setPasswordAge(e.target.value as string)}
                    defaultValue={passwordAge}
                    validator={!isInherited ? timeoutValidatorZero : undefined}
                    valid={!isInherited}
                    required={!isInherited}
                  />
                </span>
              </td>
            </tr>
            <tr>
              <td>{localeAccount[currentLocale].settingsTab.passwordsInStore}</td>
              <td>
                <span>
                  <Input value={config?.api.user_used_passwords_max_number} disabled />
                </span>
              </td>
              <td>
                <span>
                  <Field
                    name="passMaxNumber"
                    component={Input}
                    value={passMaxNumber}
                    disabled={isInherited}
                    onChange={(e): void => setPassMaxNumber(e.target.value as string)}
                    defaultValue={passMaxNumber}
                    validator={!isInherited ? timeoutValidator : undefined}
                    valid={!isInherited}
                    required={!isInherited}
                  />
                </span>
              </td>
            </tr>
          </table>
          <div className={`k-form-buttons ${settingsStyles.save_button_block}`}>
            <Button
              fill="action"
              type="submit"
              disabled={!formRenderProps.allowSubmit || !formRenderProps.valid || isDisabled()}
            >
              {localeButtons[currentLocale].save}
            </Button>
            {isShowSavedMessage && (
              <span className={settingsStyles.saved_message}>
                <i>{localeSettings[currentLocale].formSmtp.savedMessage}</i>
              </span>
            )}
          </div>
        </FormElement>
      )}
    />
  );
}
