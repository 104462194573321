import { Filter } from '@progress/kendo-react-data-tools';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { СommonTableLoader } from '../tableLoader/CommonTableLoader';
import { FILTER_ALL_SOFTWARELIST } from '../../../utils/helpers/constant-serv-filter-host';
import { Input } from '../baseElements/Input';
import { getUrlList } from '../../../utils/helpers/getUrl-list-helper';
import { CommonSoftwaresList } from '../CommonSoftwaresList';
import type localeSettings from '../../../utils/i18n/settingsLocales/settings.json';
import localeFilters from '../../../utils/i18n/commonLocales/filters.json';
import { SoftwaresInfoModal } from '../../features/Software/SoftwaresInfoModal';
import { useSoftwaresContext } from '../../../hooks/useSoftwaresContext';
import { useSoftwareVulnerableList } from '../../../hooks/components/useSoftwareVulnerableList';

export function SoftwareListForForm({ type }: { type: 'linux' | 'windows' }): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeSettings;

  const dataForHooks = {
    name: 'name',
    componentName: 'SoftwareListForForm',
    moreData: type,
  };

  const urlParams = getUrlList('SoftwareListForForm');

  const { softwaresModalData } = useSoftwaresContext();

  const {
    filter,
    onFilterChange,
    sort,
    page,
    total,
    pageChange,
    setSort,
    data,
    handleCustomSearch,
    customSearch,
    isLoading,
  } = useSoftwareVulnerableList(urlParams, dataForHooks);

  return (
    <>
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <div className="filter-line">
            <Filter
              value={filter}
              onChange={onFilterChange}
              fields={FILTER_ALL_SOFTWARELIST[currentLocale]}
            />
            {filter.filters.length === 0 && (
              <Input
                name="customSearch"
                placeholder={`${localeFilters[currentLocale].customSerachBy} ${
                  type === 'windows'
                    ? localeFilters[currentLocale].customSearchFields.software
                    : localeFilters[currentLocale].customSearchFields.package
                }`}
                value={customSearch}
                onChange={(e): void => handleCustomSearch(e)}
              />
            )}
          </div>
        </IntlProvider>
      </LocalizationProvider>
      <CommonSoftwaresList
        data={data}
        sort={sort}
        page={page}
        total={total}
        pageChange={pageChange}
        setSort={setSort}
        dataForHooks={dataForHooks}
        conditionSort={15}
        isVulnerableList={false}
      />
      <СommonTableLoader queryData={isLoading} filteringData={isLoading} />
      {softwaresModalData && <SoftwaresInfoModal />}
    </>
  );
}
