/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import { Button } from '../../../common/baseElements/Button';
import localeNewTask from '../../../../utils/i18n/taskLocale/newTask.json';
import { useTaskAddContext } from '../../../../hooks/useTaskAddContext';
import { useUserModalContext } from '../../../../hooks/useUserModalContext';
import { emailValidator } from '../../../../utils/helpers/validation';
import { Input } from '../../../common/baseElements/Input';
import styles from './NewTask.module.scss';
// import { useTicketContext } from '../../../../hooks/useTicketContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeNewTask;

export function UserEmailBlockTask({ type }: { type: string }): React.ReactElement {
  const { deleteEmailFromTask, deleteEmailInputTask } = useTaskAddContext();

  const {
    userTaskEmail,
    userTaskInputEmail,
    setUserTaskInputEmail,
    setAddUserEmail,
    username,
    setUsername,
  } = useUserModalContext();

  // const { editTicketData, ticketSystem } = useTicketContext();

  const [emailValue, setEmailValue] = useState('');

  const [checkEmail, setCheckEmail] = useState(false);

  const onChangeEmail = (val: string): void => {
    const resultEmailValidator = emailValidator(val);
    setCheckEmail(!resultEmailValidator);
    setEmailValue(val);
  };

  const AddInputEmail = (): void => {
    if (userTaskInputEmail && userTaskInputEmail.length > 0) {
      setUserTaskInputEmail([...userTaskInputEmail, emailValue]);
    } else {
      setUserTaskInputEmail([emailValue]);
    }
    setEmailValue('');
  };

  return (
    <div className="select-email">
      <div className="title-select-email" style={{ position: 'relative' }}>
        {/* <span className={type === 'email' || editTicketData ? '' : 'required-label'}> */}
        <span>
          {type === 'email'
            ? localeNewTask[currentLocale].form.reports.sendToEmail
            : localeNewTask[currentLocale].form.assignUser}
          :
        </span>
        <Button onClick={(): void => setAddUserEmail(true)} type="button">
          {type === 'email'
            ? localeNewTask[currentLocale].form.selectEmail
            : localeNewTask[currentLocale].form.select}
        </Button>
      </div>
      {type === 'email' && (
        <div className={styles.select_email_input}>
          <Input
            name="email"
            id="name"
            onChange={(e): void => onChangeEmail(e.target.value)}
            value={emailValue}
            className="k-input add-user-email-input"
            type="email"
          />
          <Button type="button" onClick={AddInputEmail} disabled={!checkEmail}>
            {localeNewTask[currentLocale].form.applyForAsset.emailBtnAdd}
          </Button>
        </div>
      )}
      <div className={type === 'user' ? 'list-assets small-list-assets' : 'list-assets'}>
        {userTaskEmail?.map((u) => {
          if (u.email) {
            return (
              <Button
                type="button"
                fill="task_asset"
                onClick={(e): void => deleteEmailFromTask(u.userId, e)}
              >
                {u.email}
              </Button>
            );
          }

          return null;
        })}
        {userTaskInputEmail?.map((u) => {
          return (
            <Button
              type="button"
              fill="task_asset"
              onClick={(e): void => deleteEmailInputTask(u, e)}
            >
              {u}
            </Button>
          );
        })}
        {(userTaskEmail?.length === 0 || userTaskEmail === null) &&
          (userTaskInputEmail === null || userTaskInputEmail?.length === 0) && (
            <span className="placeholder">
              {type === 'email'
                ? localeNewTask[currentLocale].form.applyForAsset.emailPlaceholder
                : !username
                ? localeNewTask[currentLocale].form.applyForAsset.userPlaceholder
                : null}
            </span>
          )}
        {type === 'user' && (
          <Button type="button" fill="task_asset" onClick={(): void => setUsername('')}>
            {username}
          </Button>
        )}
      </div>
    </div>
  );
}
