/* eslint-disable react/jsx-pascal-case */
import { Grid, GridColumn as Column, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { cvssScoreWidth, PAGEABLE_DATA } from '../../utils/helpers/constants';
import type { ICommonHostList } from '../../utils/helpers/types';
import { handleSort } from '../../utils/sorting-in-table';
import localeHost from '../../utils/i18n/assetLocales/hostList.json';
import { Ip } from '../features/Host/hostList/cellHostList/Ip';
import { Vulnerabilities } from '../features/Host/hostList/cellHostList/Vulnerabilities';
import { Updates } from '../features/Host/hostList/cellHostList/Updates';
import { AgentStatus } from '../features/Host/hostList/cellHostList/AgentStatus';
import { System } from '../features/Host/hostList/cellHostList/System';
import CellSelectHost from '../features/Task/form/cellHostName/CellSelectHost';
import { DomainName } from '../features/Host/hostList/cellHostList/DomainName';
import { MenuHostList } from '../features/Host/hostList/cellHostList/MenuHostList';
import { ScoreCvssCell } from './cells/ScoreCvssCell';
import { getUserData } from '../../services/local-storage-service';
import { VHeaderCell } from './VHeaderCell';
import { ScoreVCell } from './cells/ScoreVCell';
import { AgentStatusHeaderCell } from './AgentStatusHeaderCell';
import { AccountCell } from '../features/Host/hostList/cellHostList/AccountCell';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHost;

export function CommonHostList(props: ICommonHostList): React.ReactElement {
  const { sort, dataDashboard, page, total, pageChange, setSort, dataForHooks, conditionSort } =
    props;

  const currentUser = getUserData();

  return (
    <div className="host-list">
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <Grid
            pageable={total && total > conditionSort ? PAGEABLE_DATA : false}
            sortable
            sort={sort}
            data={dataDashboard}
            skip={page.skip}
            take={page.take}
            total={total || 0}
            onPageChange={pageChange}
            onSortChange={(e: GridSortChangeEvent): void => handleSort(e, setSort)}
            scrollable="none"
            className={
              total &&
              total < conditionSort &&
              dataForHooks.componentName !== 'VulnModalHosts' &&
              dataForHooks.componentName !== 'SoftwareHostsTab'
                ? 'no-pageable-k-grid'
                : ''
            }
          >
            {(dataForHooks.componentName === 'AddHostList' ||
              dataForHooks.componentName === 'GroupDescription') && (
              <Column field="addAsset" title=" " cell={CellSelectHost} width="40px" />
            )}
            <Column
              field="latestAudit.maxCvssScore"
              title={localeHost[currentLocale].form.cvssScore}
              filter="text"
              cell={ScoreCvssCell}
              width={cvssScoreWidth.sort}
            />
            <Column
              field="latestAudit.maxVFstec"
              title="v"
              filter="text"
              cell={ScoreVCell}
              width={cvssScoreWidth.sort}
              headerCell={VHeaderCell}
            />
            {dataForHooks.componentName === 'AddHostList' ? (
              <Column
                field="latestInventorization.hostname"
                title={localeHost[currentLocale].form.domainName}
                filter="text"
                width="200px"
              />
            ) : (
              <Column
                field="latestInventorization.hostname"
                title={localeHost[currentLocale].form.domainName}
                filter="text"
                cell={DomainName}
                width="354px"
              />
            )}
            <Column
              field="latestInventorization.ip"
              title={localeHost[currentLocale].form.ip}
              filter="text"
              cell={Ip}
              width="124px"
            />
            <Column
              field="latestAudit.vulnerabilities.count"
              title={localeHost[currentLocale].form.vulnerabilities}
              filter="numeric"
              cell={Vulnerabilities}
              width={dataForHooks.componentName === 'VulnModalHosts' ? '118px' : '132px'}
            />
            {dataForHooks.componentName !== 'SoftwareHostsTab' && (
              <Column
                field="latestAudit.updates.count"
                title={localeHost[currentLocale].form.updates}
                filter="text"
                cell={Updates}
                width={dataForHooks.componentName === 'VulnModalHosts' ? '116px' : '132px'}
              />
            )}
            {dataForHooks.componentName !== 'SoftwareHostsTab' && (
              <Column
                title={localeHost[currentLocale].scanType}
                filterable={false}
                cell={AgentStatus}
                width="200px"
                headerCell={AgentStatusHeaderCell}
                field="agentSoftwareVersion"
              />
            )}
            <Column
              field="latestInventorization.os.name"
              title={localeHost[currentLocale].form.system}
              filter="text"
              cell={System}
              width={currentUser?.role === 'super_admin' ? '300px' : 'auto'}
            />
            {currentUser?.role === 'super_admin' &&
              dataForHooks.componentName !== 'VulnModalHosts' && (
                <Column
                  field="account.name"
                  title={localeHost[currentLocale].form.accountName}
                  filter="text"
                  cell={dataForHooks.componentName === 'HostList' ? AccountCell : undefined}
                />
              )}
            {dataForHooks.componentName === 'HostList' && (
              <Column cell={MenuHostList} width="60px" />
            )}
          </Grid>
        </IntlProvider>
      </LocalizationProvider>
    </div>
  );
}
