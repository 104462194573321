/* eslint-disable react/jsx-pascal-case */
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { ErrorBoundary } from 'react-error-boundary';
import { Filter } from '@progress/kendo-react-data-tools';
import { Typography } from '@progress/kendo-react-common';
import type { IDataHooks } from '../../../../utils/helpers/types';
import { getUrlList } from '../../../../utils/helpers/getUrl-list-helper';
import { BoundaryErrorComponent } from '../../../common/BoundaryErrorComponent';
import { СommonTableLoader } from '../../../common/tableLoader/CommonTableLoader';
import { INITIAL_CREATED_AT_SORT } from '../../../../utils/helpers/constants';
import localeTicket from '../../../../utils/i18n/security/vulnerabilities.json';
import { Input } from '../../../common/baseElements/Input';
import {
  FILTER_TICKET_LIST,
  FILTER_TICKET_LIST_NOT_SUPER_ADMIN,
} from '../../../../utils/helpers/constant-serv-filter-host';
import { Button } from '../../../common/baseElements/Button';
import { getUserData } from '../../../../services/local-storage-service';
import { useTicketList } from '../../../../hooks/components/useTicketList';
import { CommonTicketList } from '../../../common/CommonTicketList';
import { useTicketContext } from '../../../../hooks/useTicketContext';

export function TicketList(): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeTicket;

  const currentUser = getUserData();

  const urlParams = getUrlList('TicketList', INITIAL_CREATED_AT_SORT);

  const dataForHooks: IDataHooks = {
    name: 'summary',
    componentName: 'TicketList',
  };

  const {
    filter,
    onFilterChange,
    sort,
    page,
    total,
    pageChange,
    setSort,
    filterStatus,
    isError,
    errorMessage,
    data,
    handleCustomSearch,
    customSearch,
    handleRefresh,
  } = useTicketList(urlParams, dataForHooks);

  const { setAddTicket } = useTicketContext();

  return (
    <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
      <div className="ticket-list">
        <div className="common-header-page">
          <Typography.h3>{localeTicket[currentLocale].tickets.titleList}</Typography.h3>
          <div className="topactions">
            {currentUser?.role !== 'reader_user' && (
              <Button fill="action" onClick={(): void => setAddTicket(true)}>
                {localeTicket[currentLocale].tickets.headerBtns.addTicketBtn}
              </Button>
            )}
            <Button onClick={handleRefresh} icon="refresh">
              {localeTicket[currentLocale].tickets.headerBtns.refreshTicket}
            </Button>
          </div>
        </div>
        <LocalizationProvider language={currentLocale}>
          <IntlProvider locale={currentLocale.substring(0, 2)}>
            <div className="filter-line">
              <Filter
                value={filter}
                onChange={onFilterChange}
                fields={
                  currentUser?.role === 'super_admin'
                    ? FILTER_TICKET_LIST[currentLocale]
                    : FILTER_TICKET_LIST_NOT_SUPER_ADMIN[currentLocale]
                }
              />
              {filter.filters.length === 0 && (
                <Input
                  name="customSearch"
                  placeholder={localeTicket[currentLocale].tickets.grid.placeholderFilter}
                  value={customSearch}
                  onChange={(e): void => handleCustomSearch(e)}
                />
              )}
            </div>
          </IntlProvider>
        </LocalizationProvider>
        <CommonTicketList
          sort={sort}
          data={data}
          page={page}
          total={total}
          pageChange={pageChange}
          setSort={setSort}
          dataForHooks={dataForHooks}
          conditionSort={15}
        />
        <СommonTableLoader queryData={filterStatus} filteringData={filterStatus} />{' '}
        {isError && <div className="info alert alert-danger">Error: {errorMessage}</div>}
      </div>
    </ErrorBoundary>
  );
}
