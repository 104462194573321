/* eslint-disable no-nested-ternary */
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import type { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { useHostModalContext } from '../useHostModalContext';
import { getAccountId, getUserData } from '../../services/local-storage-service';
import { useWhiteListContext } from '../useWhiteListContext';
import { useAssetCreatePropContext } from '../useAssetCreatePropContext';
import type { BulletinWhitelistCreationResponse } from '../../types/__generated/on-premise-solution/api/bulletinWhitelistCreationResponse.v1';
import type { ApiError } from '../../types/__generated/on-premise-solution/api/apiError.v1';
import type { BulletinWhitelistCreationRequest } from '../../types/__generated/on-premise-solution/api/bulletinWhitelistCreationRequest.v1';
import { createWhiteList, deleteWhiteList, updateWhiteList } from '../../services/settings-service';
import { useAccountModalContext } from '../useAccountModalContext';
import localeUserList from '../../utils/i18n/userLocales/userList.json';
import { useNetDeviceContext } from '../useNetDeviceContext';
import { useRegisterContext } from '../useRegisterContext';
import { useGroupContext } from '../useGroupContext';
import type { BulletinWhitelistUpdateResponse } from '../../types/__generated/on-premise-solution/api/bulletinWhitelistUpdateResponse.v1';
import type { BulletinWhitelistUpdateRequest } from '../../types/__generated/on-premise-solution/api/bulletinWhitelistUpdateRequest.v1';
import type { BulletinWhitelistDeletionResponse } from '../../types/__generated/on-premise-solution/api/bulletinWhitelistDeletionResponse.v1';
import {
  prepareAssetType,
  prepareWhiteListSubmit,
  prepareWhitelistUpdate,
  usedEditAssetType,
} from '../../utils/helpers/settings-helpers';
import { prepareSoftwareList } from '../../utils/helpers/wl-list-helper';
import type {
  BulletinWhitelistDashboardResponse,
  WhitelistedBulletin,
  WhitelistedSoftware1,
} from '../../types/__generated/on-premise-solution/api/bulletinWhitelistDashboardResponse.v1';

type TUseWhiteListMethods = {
  cancelWhiteListForm: () => void;
  closeAddVulnToWLForm: () => void;
  mutationNewWhiteList: UseMutationResult<
    BulletinWhitelistCreationResponse,
    ApiError,
    BulletinWhitelistCreationRequest,
    unknown
  >;
  delBulletinFromMutableBlock: (
    bulletin: string | undefined,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  handleStatusWhiteList: (val: boolean, id: string) => void;
  editWhiteList: (val: BulletinWhitelistDashboardResponse) => void;
  mutationDeleteWhiteList: UseMutationResult<
    BulletinWhitelistDeletionResponse | null,
    ApiError,
    string,
    unknown
  >;
  handleNewWl: () => void;
  onSubmitVuln: () => void;
  onSubmit: () => void;
  addSelectedBulletins: () => void;
  changeDropDownStatus: (event: DropDownListChangeEvent) => void;
};

export function useWhiteListMethods(): TUseWhiteListMethods {
  const currentLocale =
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN';

  const queryClient = useQueryClient();

  const currentUser = getUserData();

  const currentAccountId = getAccountId();

  const { accountId, setAccountId } = useAccountModalContext();

  const {
    setBulletinForm,
    setWhiteListForm,
    setErrorMutation,
    setOnSubmitVulnSuccess,
    name,
    setName,
    setDescription,
    setBulletinFormTemp,
    setWhitelistUpdate,
    assetType,
    setAssetType,
    whitelistUpdate,
    description,
    bulletinForm,
    whiteListStatus,
    setSelectedWhitelist,
    initialState,
    bulletinFormTemp,
    setAddBulletin,
    setWhiteListStatus,
    selectedWhitelist,
    assetsAlreadyInWl,
  } = useWhiteListContext();

  const {
    setMissedKbs,
    setSoftwareInWl,
    setWinSoftwareUpdateState,
    setWinSoftwareUpdateStateWl,
    setSelectedPkgs,
    setSelectedPkgsWl,
    hostForm,
    setHostForm,
    setHostFormTemp,
    missedKbs,
    softwareInWl,
    isExceptionAssetVulnTable,
  } = useHostModalContext();

  const { setAddVulnToWLForm, setSelectedVulns, selectedVulns } = useAssetCreatePropContext();

  const { deviceForm, setDeviceForm, setDeviceFormTemp } = useNetDeviceContext();

  const { imageForm, setImageForm } = useRegisterContext();

  const { setDynamicGroupForm, dynamicGroupForm } = useGroupContext();

  const cancelWhiteListForm = (): void => {
    setWhiteListForm(false);
    setName('');
    setDescription('');
    setAccountId({
      id: '000',
      name: localeUserList[currentLocale].modal.placeholderAccountName,
    });
    setBulletinForm(null);
    setBulletinFormTemp(null);
    setDeviceForm(null);
    setDeviceFormTemp(null);
    setHostForm(null);
    setHostFormTemp(null);
    setImageForm(null);
    setWhitelistUpdate(null);
    setAssetType({
      host: false,
      image: false,
      router: false,
      switch: false,
      firewall: false,
      hypervisor: false,
      all: false,
    });
    setSelectedVulns(null);
    setMissedKbs(null);
    setDynamicGroupForm(null);
    setSoftwareInWl(null);
    setWinSoftwareUpdateState(null);
    setWinSoftwareUpdateStateWl(null);
    setSelectedPkgs(null);
    setSelectedPkgsWl(null);
  };

  const closeAddVulnToWLForm = (): void => {
    setSelectedVulns(null);
    setBulletinForm(null);
    setAddVulnToWLForm(false);
    setWhiteListForm(false);
    setMissedKbs(null);
    setSoftwareInWl(null);
    setWinSoftwareUpdateState(null);
    setWinSoftwareUpdateStateWl(null);
    setSelectedPkgs(null);
    setSelectedPkgsWl(null);
  };

  const mutationNewWhiteList = useMutation<
    BulletinWhitelistCreationResponse,
    ApiError,
    BulletinWhitelistCreationRequest
  >((payload) => createWhiteList(payload), {
    onSuccess: () => {
      queryClient.invalidateQueries('WhiteList');
      cancelWhiteListForm();
      setOnSubmitVulnSuccess(true);
    },
    onError: (resp) => {
      setErrorMutation(resp.message);
    },
  });

  const mutationEditWhiteList = useMutation<
    BulletinWhitelistUpdateResponse,
    ApiError,
    { id: string; payload: BulletinWhitelistUpdateRequest }
  >(({ id, payload }) => updateWhiteList(id, payload), {
    onSuccess: () => {
      if (selectedVulns || missedKbs || softwareInWl) {
        setOnSubmitVulnSuccess(true);
      }
      queryClient.invalidateQueries('WhiteList');
      queryClient.invalidateQueries('bulletinWhitelistId');
      cancelWhiteListForm();
      closeAddVulnToWLForm();
    },
    onError: (resp) => {
      setErrorMutation(resp.message);
    },
  });

  const mutationDeleteWhiteList = useMutation<
    BulletinWhitelistDeletionResponse | null,
    ApiError,
    BulletinWhitelistDeletionResponse['bulletinWhitelistId']
  >((payload) => deleteWhiteList(payload), {
    onSuccess: () => {
      queryClient.invalidateQueries('WhiteList');
    },
    onError: (resp) => {
      setErrorMutation(resp.message);
    },
  });

  const onSubmit = (): void => {
    if (!whitelistUpdate) {
      const { assets, assetGroups, assetDynamicGroups } = prepareWhiteListSubmit(
        hostForm,
        deviceForm,
        imageForm,
        dynamicGroupForm,
      );

      const onSubmitData = {
        name,
        accountId:
          currentUser && currentUser.role === 'super_admin' ? accountId.id : currentAccountId || '',
        description: description || null,
        scope: {
          assets,
          assetGroups,
          assetTypes: assetType && prepareAssetType(assetType),
          assetDynamicGroups:
            assetDynamicGroups && assetDynamicGroups.length > 0 ? assetDynamicGroups : null,
        },
        bulletins: bulletinForm ? [...bulletinForm] : null,
        softwareList: prepareSoftwareList(softwareInWl, null, isExceptionAssetVulnTable),
        isDisabled: whiteListStatus.val,
      } as BulletinWhitelistCreationRequest;
      setSelectedWhitelist([
        {
          name,
          bulletinWhitelistId: '',
        },
      ]);
      mutationNewWhiteList.mutate({ ...onSubmitData });
    } else {
      const { assets, assetGroups, assetDynamicGroups } = prepareWhiteListSubmit(
        hostForm,
        deviceForm,
        imageForm,
        dynamicGroupForm,
      );
      const onSubmitData = {
        name: name && name !== initialState?.name ? name : undefined,
        description:
          description !== initialState?.description && description && description.length > 0
            ? description
            : description === initialState?.description
            ? undefined
            : null,
        scope: {
          assets: assets && assets.length > 0 ? assets : null,
          assetTypes: assetType && prepareAssetType(assetType),
          assetGroups: assetGroups && assetGroups.length > 0 ? assetGroups : null,
          assetDynamicGroups:
            assetDynamicGroups && assetDynamicGroups.length > 0 ? assetDynamicGroups : null,
        },
        bulletins:
          bulletinForm &&
          bulletinForm.length > 0 &&
          JSON.stringify(bulletinForm) !== JSON.stringify(initialState?.bulletins)
            ? bulletinForm?.map((b) => ({
                addedAt: b.addedAt,
                addedBy: {
                  id: b.addedBy.source === 'user' && b.addedBy.id,
                  source: 'user',
                },
                bulletinId: b.bulletinId,
              }))
            : bulletinForm &&
              bulletinForm.length > 0 &&
              JSON.stringify(bulletinForm) === JSON.stringify(initialState?.bulletins)
            ? undefined
            : null,
        softwareList: prepareSoftwareList(softwareInWl, null, isExceptionAssetVulnTable)
          ? prepareSoftwareList(softwareInWl, null, isExceptionAssetVulnTable)
          : null,
        isDisabled:
          whiteListStatus.val !== initialState?.isDisabled ? whiteListStatus.val : undefined,
      } as BulletinWhitelistUpdateRequest;
      mutationEditWhiteList.mutateAsync({
        id: whitelistUpdate.auditWhitelistId,
        payload: onSubmitData,
      });
    }
  };

  const delBulletinFromMutableBlock = (
    bulletin: string | undefined,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    event.stopPropagation();
    if (bulletinForm && bulletinForm.length > 0) {
      setBulletinForm(bulletinForm?.filter((b) => b.bulletinId !== bulletin));
    }
    if (bulletinFormTemp && bulletinFormTemp.length > 0) {
      setBulletinFormTemp(bulletinFormTemp?.filter((b) => b.bulletinId !== bulletin));
    }
  };

  const addSelectedBulletins = (): void => {
    setAddBulletin(false);
    setBulletinForm(bulletinFormTemp);
  };

  const changeDropDownStatus = (event: DropDownListChangeEvent): void => {
    if (event.target.value === localeUserList[currentLocale].active) {
      setWhiteListStatus({ text: localeUserList[currentLocale].active, val: false });
    } else {
      setWhiteListStatus({ text: localeUserList[currentLocale].notActive, val: true });
    }
  };

  const editWhiteList = (val: BulletinWhitelistDashboardResponse): void => {
    prepareWhitelistUpdate(
      val,
      setHostForm,
      setDeviceForm,
      setImageForm,
      setBulletinForm,
      setDynamicGroupForm,
      setSoftwareInWl,
    );
    setWhitelistUpdate(val);
    setAssetType(usedEditAssetType(val.scope.assetTypes));
  };

  const handleStatusWhiteList = (val: boolean, id: string): void => {
    const payload = {
      isDisabled: !val,
    };
    mutationEditWhiteList.mutateAsync({ id, payload });
  };

  const handleNewWl = (): void => {
    setAddVulnToWLForm(false);
    setWhiteListForm(true);
    const bulletins = [] as WhitelistedBulletin[];
    if (selectedVulns) {
      selectedVulns.forEach((v) => {
        const t = {
          bulletinId: v.vulnId,
          addedBy: {
            source: 'user' as const,
            id: currentUser?.id || '',
          },
          addedAt: new Date().toISOString(),
        };
        bulletins.push(t);
      });
    }

    if (bulletins && bulletins.length > 0) setBulletinForm(bulletins);
    if (
      selectedVulns &&
      selectedVulns.length > 0 &&
      selectedVulns[0].assetId &&
      selectedVulns[0].hostname
    ) {
      if (document.location.pathname.includes('assets/hosts'))
        setHostForm([{ assetId: selectedVulns[0].assetId, hostname: selectedVulns[0].hostname }]);
      if (document.location.pathname.includes('assets/devices'))
        setDeviceForm([{ assetId: selectedVulns[0].assetId, hostname: selectedVulns[0].hostname }]);
    }
    if (
      selectedVulns &&
      selectedVulns.length > 0 &&
      selectedVulns[0].imageNames &&
      selectedVulns[0].assetId &&
      document.location.pathname.includes('assets/images')
    )
      setImageForm([
        {
          imageNames: selectedVulns[0].imageNames || '',
          assetId: selectedVulns[0].assetId || '',
        },
      ]);
  };

  const onSubmitVuln = (): void => {
    const bulletins = [] as WhitelistedBulletin[];

    const { assets, assetGroups, assetDynamicGroups } = prepareWhiteListSubmit(
      hostForm,
      deviceForm,
      imageForm,
      dynamicGroupForm,
    );

    if (selectedVulns && !missedKbs) {
      selectedVulns.forEach((v) => {
        const b = {
          bulletinId: v.vulnId,
          addedBy: {
            source: 'user' as const,
            id: currentUser?.id || '',
          },
          addedAt: new Date().toISOString(),
        };
        bulletins.push(b);
      });
    }

    if (!selectedVulns && missedKbs) {
      missedKbs.forEach((kb) => {
        const b = {
          bulletinId: kb.id,
          addedBy: {
            source: 'user' as const,
            id: currentUser?.id || '',
          },
          addedAt: new Date().toISOString(),
        };
        bulletins.push(b);
      });
    }

    const softwareListToAdd = prepareSoftwareList(
      softwareInWl,
      null,
      isExceptionAssetVulnTable,
    ) as WhitelistedSoftware1[];

    mutationEditWhiteList.mutateAsync({
      id: selectedWhitelist ? selectedWhitelist[0].bulletinWhitelistId : '',
      payload: {
        bulletinsToAdd: bulletins && bulletins.length > 0 ? bulletins : undefined,
        softwareListToAdd,
        scope: {
          assets: [...(assetsAlreadyInWl || []), ...(assets || [])],
          assetGroups: assetGroups && assetGroups.length > 0 ? assetGroups : null,
          assetDynamicGroups:
            assetDynamicGroups && assetDynamicGroups.length > 0 ? assetDynamicGroups : null,
          assetTypes: (assetType && prepareAssetType(assetType)) || null,
        },
      },
    });
    closeAddVulnToWLForm();
  };

  return {
    cancelWhiteListForm,
    closeAddVulnToWLForm,
    mutationNewWhiteList,
    delBulletinFromMutableBlock,
    handleStatusWhiteList,
    editWhiteList,
    mutationDeleteWhiteList,
    handleNewWl,
    onSubmitVuln,
    onSubmit,
    addSelectedBulletins,
    changeDropDownStatus,
  };
}
