import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTitle,
  ChartTooltip,
} from '@progress/kendo-react-charts';
import { memo } from 'react';
import type { IAllAgentStatus } from '../../../../utils/helpers/task-helpers';
import localeHost from '../../../../utils/i18n/assetLocales/hostList.json';
import type { SensorStatuses } from '../../../../types/__generated/on-premise-solution/api/sensorStatuses.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHost;

const labelContent = (props: any): string => {
  return `${props.dataItem.category}: ${props.dataItem.value}`;
};

export function SensorStatus({ data }: { data: SensorStatuses | undefined }): React.ReactElement {
  const series = [
    {
      category: `${localeHost[currentLocale].form.agentStatus.online} (${data?.online})`,
      value: data?.online,
      color: '#4caf50',
    },
    {
      category: `${localeHost[currentLocale].form.agentStatus.offline} (${data?.offline})`,
      value: data?.offline,
      color: '#ffc107',
    },
    {
      category: `${localeHost[currentLocale].form.agentStatus.notActivated} (${data?.notActivated})`,
      value: data?.notActivated,
      color: 'rgba(135,136,141,1)',
    },
    // {
    //   category: localeHost[currentLocale].form.agentStatus.disabled,
    //   value: data?.disabled,
    //   color: '#b5b0b0',
    // },
  ];

  return (
    <Chart>
      {/* <ChartTitle text="World Population by Broad Age Groups" /> */}
      <ChartTooltip
        render={(e: any) => {
          if (e.point?.category) return <span>{e.point.category}</span>;

          return '';
        }}
      />
      <ChartLegend position="bottom" />
      <ChartSeries>
        <ChartSeriesItem
          type="donut"
          data={series}
          field="value"
          tooltip={{ visible: true }}
          categoryField="category"
          // labels={{ visible: true, content: labelContent }}
        />
      </ChartSeries>
    </Chart>
  );
}

// export default memo(AgentStatus);
