/* eslint-disable react/jsx-no-bind */
import { useCallback, useEffect, useRef, useState, type ReactElement } from 'react';
import { Checkbox, RadioButton } from '@progress/kendo-react-inputs';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { useParams } from 'react-router';
import { useQueryClient } from 'react-query';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { Button } from '../../../common/baseElements/Button';
import localeButtons from '../../../../utils/i18n/commonLocales/buttons.json';
import localeAccount from '../../../../utils/i18n/accountLocales/accountDescription.json';
import localeSettings from '../../../../utils/i18n/settingsLocales/settings.json';
import type { AccountDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/accountsDashboardResponse.v1';
import { Input } from '../../../common/baseElements/Input';
import settingsStyles from '../../Settings/Settings.module.scss';
import { useAccountModalContext } from '../../../../hooks/useAccountModalContext';
import type { AccountUpdateRequest } from '../../../../types/__generated/on-premise-solution/api/accountUpdateRequest.v1';
import type { ConfigResponse } from '../../../../types/__generated/on-premise-solution/api/configResponse.v1';
import test from '../../../../types/__generated/on-premise-solution/api/configUpdateRequest.v1.schema.json';
import styles from '../AccountList.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAccount;

export function NetworkTab({
  dns,
  smtp,
  config,
}: {
  dns: AccountDashboardResponse['settings']['dnsList'] | undefined;
  smtp: AccountDashboardResponse['settings']['smtp'] | undefined;
  config: ConfigResponse | undefined;
}): ReactElement {
  const { accountId } = useParams<{ accountId: string }>();
  const queryClient = useQueryClient();
  const { mutationEditAccount } = useAccountModalContext();

  // DNS variables
  const [isDnsInherited, setDnsInherited] = useState(dns?.useInherited !== false);
  const [dnsList, setDnsList] = useState(dns?.value || config?.api.dns_list || '');

  // SMTP variables
  const [isSmtpInherited, setSmtpInherited] = useState(smtp?.useInherited !== false);
  const [smtpName, setSmtpName] = useState(
    smtp?.value?.sender?.name || config?.smtp?.sender?.name || '',
  );
  const [smtpMail, setSmtpMail] = useState(
    smtp?.value?.sender?.email || config?.smtp?.sender?.email || '',
  );
  const [smtpHost, setSmtpHost] = useState(
    smtp?.value?.transport.smtp?.host || config?.smtp?.transport.smtp?.host || '',
  );
  const [smtpPort, setSmtpPort] = useState(
    smtp?.value?.transport.smtp?.port || config?.smtp?.transport.smtp?.port || '',
  );
  const [smtpTransportService, setSmtpTransportService] = useState<{
    value:
      | Exclude<
          AccountDashboardResponse['settings']['smtp']['value'],
          undefined
        >['transport']['service']
      | '';
    text: string;
  }>({
    value: smtp?.value?.transport.service || config?.smtp?.transport.service || null,
    text:
      smtp?.value?.transport.service ||
      config?.smtp?.transport.service ||
      localeSettings[currentLocale].formSmtp.custom,
  });
  const [smtpUser, setSmtpUser] = useState(
    smtp?.value?.transport.auth?.user || config?.smtp?.transport.auth?.user || '',
  );
  const [smtpPassword, setSmtpPassword] = useState('');
  const [isSecureConnection, setIsSecureConnection] = useState(
    smtp?.value?.transport.smtp?.secure || config?.smtp?.transport.smtp?.secure || false,
  );

  const timer = useRef<NodeJS.Timeout | null>(null);
  const [isShowSavedMessage, setShowSavedMessage] = useState(false);

  const serviceList = test.properties['smtp.transport.service'].enum.map((service) => ({
    value: service || null,
    text: service || localeSettings[currentLocale].formSmtp.custom,
  }));

  const showSavedMessage = (): void => {
    setShowSavedMessage(true);
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setShowSavedMessage(false);
    }, 3000);
  };

  const validator = (value: string): string => {
    if (/^[0-9a-zA-Z., ]*$/.test(value)) return '';

    return localeAccount[currentLocale].settingsTab.notDnsError;
  };

  function handleSave(): void {
    const payload: AccountUpdateRequest = {
      'settings.dnsList.useInherited': isDnsInherited,
      'settings.smtp.useInherited': isSmtpInherited,
    };
    if (!isDnsInherited) {
      payload['settings.dnsList.value'] = dnsList;
    }

    if (!isSmtpInherited) {
      payload['settings.smtp.value.sender.name'] =
        smtpName ||
        //  || config?.smtp?.sender?.name
        undefined;
      payload['settings.smtp.value.sender.email'] = smtpMail || undefined;
      payload['settings.smtp.value.transport.auth.user'] = smtpUser || undefined;
      payload['settings.smtp.value.transport.smtp.host'] = smtpHost || undefined;
      payload['settings.smtp.value.transport.smtp.port'] = smtpPort || undefined;
      payload['settings.smtp.value.transport.smtp.secure'] = smtpTransportService.value
        ? undefined
        : isSecureConnection;
      if (smtpPassword)
        payload['settings.smtp.value.transport.auth.password'] = smtpPassword || undefined;
      if (smtpTransportService.value)
        payload['settings.smtp.value.transport.service'] = smtpTransportService.value || undefined;
      else {
        payload['settings.smtp.value.transport.service'] = null;
      }
    }

    mutationEditAccount
      .mutateAsync({
        accountId,
        payload,
      })
      .then(() => {
        showSavedMessage();
        queryClient.invalidateQueries('accountDescription');
      });
  }

  const serviceChange = (e: DropDownListChangeEvent): void => {
    setSmtpTransportService(e.target.value);
    setSmtpHost('');
    setSmtpPort('');
    setIsSecureConnection(false);
  };

  useEffect(() => {
    setDnsInherited(dns?.useInherited !== false);
    setDnsList(dns?.value || '');
  }, [dns]);

  useEffect(() => {
    setSmtpInherited(smtp?.useInherited !== false);
    setSmtpName(smtp?.value?.sender?.name || config?.smtp?.sender?.name || '');
    setSmtpMail(smtp?.value?.sender?.email || config?.smtp?.sender?.email || '');
    setSmtpHost(smtp?.value?.transport.smtp?.host || config?.smtp?.transport.smtp?.host || '');
    setSmtpPort(smtp?.value?.transport.smtp?.port || config?.smtp?.transport.smtp?.port || '');
    setSmtpUser(smtp?.value?.transport.auth?.user || config?.smtp?.transport?.auth.user || '');
    setIsSecureConnection(
      smtp?.value?.transport.smtp?.secure || config?.smtp?.transport?.smtp?.secure || false,
    );
    setSmtpTransportService({
      value: smtp?.value?.transport.service || config?.smtp?.transport.service || '',
      text:
        smtp?.value?.transport.service ||
        config?.smtp?.transport.service ||
        localeSettings[currentLocale].formSmtp.custom,
    });
  }, [smtp, config]);

  const isDisabled = useCallback((): boolean => {
    if (
      dns?.useInherited !== isDnsInherited ||
      (!isDnsInherited && (dns.value || '').toString() !== dnsList) ||
      smtp?.useInherited !== isSmtpInherited ||
      (!isSmtpInherited && (smtp.value?.sender?.name || '').toString() !== smtpName) ||
      (!isSmtpInherited && (smtp.value?.sender?.email || '').toString() !== smtpMail) ||
      (!isSmtpInherited &&
        (smtp.value?.transport?.service || '').toString() !== smtpTransportService.value) ||
      (!isSmtpInherited &&
        !smtpTransportService.value &&
        (smtp.value?.transport?.smtp?.host || '').toString() !== smtpHost) ||
      (!isSmtpInherited &&
        !smtpTransportService.value &&
        (smtp.value?.transport?.smtp?.port || '').toString() !== smtpPort) ||
      (!isSmtpInherited &&
        !smtpTransportService.value &&
        !!smtp.value?.transport?.smtp?.secure !== isSecureConnection) ||
      (!isSmtpInherited && (smtp.value?.transport?.auth?.user || '').toString() !== smtpUser) ||
      (!isSmtpInherited && smtpPassword)
    )
      return false;

    return true;
  }, [
    dns?.useInherited,
    dns?.value,
    dnsList,
    isDnsInherited,
    isSmtpInherited,
    smtp?.useInherited,
    smtp?.value?.sender?.email,
    smtp?.value?.sender?.name,
    smtp?.value?.transport?.auth?.user,
    smtp?.value?.transport?.service,
    smtp?.value?.transport?.smtp?.host,
    smtp?.value?.transport?.smtp?.port,
    smtp?.value?.transport?.smtp?.secure,
    smtpHost,
    smtpMail,
    smtpName,
    smtpPassword,
    smtpPort,
    smtpTransportService.value,
    isSecureConnection,
    smtpUser,
  ]);

  return (
    <Form
      initialValues={{
        dnsList,
        smtpName,
        smtpMail,
        smtpTransportService,
        smtpHost,
        smtpPort,
        smtpUser,
        smtpPassword,
        isSecureConnection,
      }}
      onSubmit={handleSave}
      render={(formRenderProps: FormRenderProps): JSX.Element => (
        <FormElement className="full-size">
          <div
            style={{
              marginBottom: '32px',
            }}
          >
            <h2 style={{ marginTop: '0' }}>
              {localeAccount[currentLocale].settingsTab.dnsServers}
            </h2>
            <table className={styles.settingsTable}>
              <tr>
                <td />
                <td>
                  <Field
                    name="use_global_config_dns"
                    checked={isDnsInherited}
                    onChange={(): void => setDnsInherited(true)}
                    label={localeAccount[currentLocale].settingsTab.useGlobalParameters}
                    component={RadioButton}
                  />
                </td>
                <td>
                  <Field
                    name="use_manual_config_dns"
                    onChange={(): void => setDnsInherited(false)}
                    value
                    checked={!isDnsInherited}
                    label={localeAccount[currentLocale].settingsTab.useCustomParameters}
                    component={RadioButton}
                  />
                </td>
              </tr>
              <tr>
                <td>{localeAccount[currentLocale].settingsTab.dnsServers}</td>
                <td>
                  <span>
                    <Input disabled value={config?.api.dns_list} />
                  </span>
                </td>
                <td>
                  <span>
                    <Field
                      name="dnsList"
                      component={Input}
                      value={dnsList}
                      disabled={isDnsInherited}
                      onChange={(e): void => setDnsList(e.target.value as string)}
                      defaultValue={dnsList}
                      validator={!isDnsInherited ? validator : undefined}
                      valid={!isDnsInherited}
                      required={!isDnsInherited}
                    />
                  </span>
                </td>
              </tr>
            </table>
          </div>
          <div>
            <h2>{localeAccount[currentLocale].settingsTab.smtp}</h2>
            <table className={styles.settingsTable}>
              <tr>
                <td />
                <td>
                  <Field
                    name="use_global_config_smtp"
                    checked={isSmtpInherited}
                    onChange={(): void => setSmtpInherited(true)}
                    label={localeAccount[currentLocale].settingsTab.useGlobalParameters}
                    component={RadioButton}
                  />
                </td>
                <td>
                  <Field
                    name="use_manual_config_smtp"
                    onChange={(): void => setSmtpInherited(false)}
                    value
                    checked={!isSmtpInherited}
                    label={localeAccount[currentLocale].settingsTab.useCustomParameters}
                    component={RadioButton}
                  />
                </td>
              </tr>
              <tr>
                <td>{localeSettings[currentLocale].formSmtp.name}</td>
                <td>
                  <span>
                    <Input disabled value={config?.smtp?.sender.name} />
                  </span>
                </td>
                <td>
                  <span>
                    <Field
                      name="smtpName"
                      component={Input}
                      value={smtpName}
                      disabled={isSmtpInherited}
                      onChange={(e): void => setSmtpName(e.target.value as string)}
                      defaultValue={smtpName}
                      valid={!isSmtpInherited}
                      required={!isSmtpInherited}
                    />
                  </span>
                </td>
              </tr>
              <tr>
                <td>{localeSettings[currentLocale].formSmtp.mail}</td>
                <td>
                  <span>
                    <Input disabled value={config?.smtp?.sender.email} />
                  </span>
                </td>
                <td>
                  <span>
                    <Field
                      name="smtpMail"
                      component={Input}
                      value={smtpMail}
                      disabled={isSmtpInherited}
                      onChange={(e): void => setSmtpMail(e.target.value as string)}
                      defaultValue={smtpMail}
                      valid={!isSmtpInherited}
                      required={!isSmtpInherited}
                    />
                  </span>
                </td>
              </tr>
              <tr>
                <td>{localeSettings[currentLocale].formSmtp.service}</td>
                <td>
                  <span>
                    <DropDownList
                      disabled
                      data={serviceList}
                      value={{
                        value: config?.smtp?.transport.service || null,
                        text:
                          config?.smtp?.transport.service ||
                          localeSettings[currentLocale].formSmtp.custom,
                      }}
                      textField="text"
                      // onChange={serviceChange}
                    />
                  </span>
                </td>
                <td>
                  <span>
                    <Field
                      name="smtpTransportService"
                      data={serviceList}
                      value={smtpTransportService}
                      defaultValue={smtpTransportService}
                      textField="text"
                      onChange={serviceChange}
                      component={DropDownList}
                      disabled={isSmtpInherited}
                    />
                  </span>
                </td>
              </tr>
              <tr>
                <td>{localeSettings[currentLocale].formSmtp.host}</td>
                <td>
                  <span>
                    <Input disabled value={config?.smtp?.transport.smtp?.host} />
                  </span>
                </td>
                <td>
                  <span>
                    <Field
                      name="smtpHost"
                      component={Input}
                      value={smtpHost}
                      disabled={smtpTransportService.value || isSmtpInherited}
                      onChange={(e): void => setSmtpHost(e.target.value as string)}
                      defaultValue={smtpHost}
                      validator={
                        smtpTransportService.value || !isSmtpInherited ? validator : undefined
                      }
                      valid={smtpTransportService.value || !isSmtpInherited}
                      required={smtpTransportService.value || !isSmtpInherited}
                    />
                  </span>
                </td>
              </tr>
              <tr>
                <td>{localeSettings[currentLocale].formSmtp.port}</td>
                <td>
                  <span>
                    <Input disabled value={config?.smtp?.transport.smtp?.port} />
                  </span>
                </td>
                <td>
                  <span>
                    <Field
                      name="smtpPort"
                      component={Input}
                      value={smtpPort}
                      disabled={smtpTransportService.value || isSmtpInherited}
                      onChange={(e): void => setSmtpPort(e.target.value as string)}
                      defaultValue={smtpPort}
                      validator={
                        smtpTransportService.value || !isSmtpInherited ? validator : undefined
                      }
                      valid={smtpTransportService.value || !isSmtpInherited}
                      required={smtpTransportService.value || !isSmtpInherited}
                    />
                  </span>
                </td>
              </tr>
              <tr>
                <td>{localeSettings[currentLocale].formSmtp.connectionType}</td>
                <td>
                  <span>
                    <Checkbox value={config?.smtp?.transport.smtp?.secure} disabled />
                  </span>
                </td>
                <td>
                  <span>
                    <Field
                      value={isSecureConnection}
                      checked={isSecureConnection}
                      onChange={(e): void => {
                        setIsSecureConnection(e.value);
                        setSmtpTransportService({
                          value: '',
                          text: localeSettings[currentLocale].formSmtp.custom,
                        });
                      }}
                      name="isSecureConnection"
                      component={Checkbox}
                      disabled={smtpTransportService.value || isSmtpInherited}
                      defaultValue={isSecureConnection}
                    />
                  </span>
                </td>
              </tr>
              <tr>
                <td>{localeSettings[currentLocale].formSmtp.user}</td>
                <td>
                  <span>
                    <Input disabled value={config?.smtp?.transport.auth.user} />
                  </span>
                </td>
                <td>
                  <span>
                    <Field
                      name="smtpUser"
                      component={Input}
                      value={smtpUser}
                      disabled={isSmtpInherited}
                      onChange={(e): void => setSmtpUser(e.target.value as string)}
                      defaultValue={smtpUser}
                      valid={!isSmtpInherited}
                      required={!isSmtpInherited}
                    />
                  </span>
                </td>
              </tr>
              <tr>
                <td>{localeSettings[currentLocale].formSmtp.password}</td>
                <td>
                  <span>
                    <Input type="password" value="notpassw" disabled />
                  </span>
                </td>
                <td>
                  <span>
                    <Field
                      name="smtpPassword"
                      component={Input}
                      type="password"
                      value={smtpPassword}
                      disabled={isSmtpInherited}
                      onChange={(e): void => setSmtpPassword(e.target.value as string)}
                      defaultValue={smtpPassword}
                    />
                  </span>
                </td>
              </tr>
            </table>
          </div>
          <div className={`k-form-buttons ${settingsStyles.save_button_block}`}>
            <Button
              fill="action"
              type="submit"
              disabled={!formRenderProps.allowSubmit || !formRenderProps.valid || isDisabled()}
            >
              {localeButtons[currentLocale].save}
            </Button>
            {isShowSavedMessage && (
              <span className={settingsStyles.saved_message}>
                <i>{localeSettings[currentLocale].formSmtp.savedMessage}</i>
              </span>
            )}
          </div>
        </FormElement>
      )}
    />
  );
}
