import qs from 'qs';
import type { SortDescriptor } from '@progress/kendo-data-query';
import type { IPageState } from '../utils/helpers/types';
import { history } from '../utils/history';
import { vmInstance } from '../lib/axios/hooks/useVmAxios';
import { dashboardInstance } from '../lib/axios/hooks/useDashboardAxios';
import { prepareFilterVal } from './filterVal-for-service';
import type { IFilterVal } from '../utils/filtering-in-table-test';
import type { TicketSystemsDashboardResponse } from '../types/__generated/on-premise-solution/api/ticketSystemsDashboardResponse.v1';
import type { TicketsDashboardResponse } from '../types/__generated/on-premise-solution/api/ticketsDashboardResponse.v1';
import type { TicketCreationRequest } from '../types/__generated/on-premise-solution/api/ticketCreationRequest.v1';
import type { TicketCreationResponse } from '../types/__generated/on-premise-solution/api/ticketCreationResponse.v1';
import type { TicketUpdateRequest } from '../types/__generated/on-premise-solution/api/ticketUpdateRequest.v1';
import type { TicketUpdateResponse } from '../types/__generated/on-premise-solution/api/ticketUpdateResponse.v1';
import type { TicketDeletionResponse } from '../types/__generated/on-premise-solution/api/ticketDeletionResponse.v1';
import type { TicketDashboardResponse } from '../types/__generated/on-premise-solution/api/ticketDashboardResponse.v1';
import type { TicketSystemCreationRequest } from '../types/__generated/on-premise-solution/api/ticketSystemCreationRequest.v1';
import type { TicketSystemCreationResponse } from '../types/__generated/on-premise-solution/api/ticketSystemCreationResponse.v1';
import type { TicketSystemDeletionResponse } from '../types/__generated/on-premise-solution/api/ticketSystemDeletionResponse.v1';
import type { TicketSystemJiraUpdateRequest } from '../types/__generated/on-premise-solution/api/ticketSystemJiraUpdateRequest.v1';
import type { TicketSystemUpdateResponse } from '../types/__generated/on-premise-solution/api/ticketSystemUpdateResponse.v1';
import type { TicketsResponse } from '../types/__generated/on-premise-solution/api/ticketsResponse.v1';
import type { TicketActionsDashboardResponse } from '../types/__generated/on-premise-solution/api/ticketActionsDashboardResponse.v1';
import type { TicketSystemUsersResponse } from '../types/__generated/on-premise-solution/api/ticketSystemUsersResponse.v1';
import type { TicketSystemMetaRequest } from '../types/__generated/on-premise-solution/api/ticketSystemMetaRequest.v1';
import type { TicketSystemMetaResponse } from '../types/__generated/on-premise-solution/api/ticketSystemMetaResponse.v1';
import type { TicketSystemYandexTrackerUpdateRequest } from '../types/__generated/on-premise-solution/api/ticketSystemUpdateRequest.v1';

export async function getTickets(
  page: IPageState,
  filterVal: IFilterVal[],
  sort: Array<SortDescriptor>,
): Promise<TicketsDashboardResponse> {
  const result = await dashboardInstance.get<TicketsDashboardResponse>('/tickets', {
    params: {
      skip: page.skip,
      limit: page.take,
      sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
      sortOrder: sort[0].dir,
      ...prepareFilterVal(filterVal),
    },

    paramsSerializer: (params) => {
      const stringifiedParams = qs.stringify(params, { encode: false });

      if (!document.location.pathname.includes('/tickets/')) {
        history.replace({
          pathname: `/lk/security/tickets?${stringifiedParams}`,
        });
      }

      return stringifiedParams;
    },
  });

  return result.data;
}

export async function getTicketListForDelTs(ticketSystemId: string): Promise<TicketsResponse> {
  const result = await vmInstance.get<TicketsResponse>(
    `/tickets?ticketSystemId[$eq]=${ticketSystemId}`,
  );

  return result.data;
}

export async function createTicket(
  payload: TicketCreationRequest,
): Promise<TicketCreationResponse> {
  const result = await vmInstance.post<TicketCreationResponse>('/tickets', payload);

  return result.data;
}

export async function relocateTicket(ticketId: string): Promise<TicketCreationResponse> {
  const result = await vmInstance.post<TicketCreationResponse>(`/tickets/${ticketId}/migrate`, {
    ticketId,
  });

  return result.data;
}

export async function editTicket(
  ticketId: string,
  payload: TicketUpdateRequest,
): Promise<TicketUpdateResponse> {
  const result = await vmInstance.patch<TicketUpdateResponse>(`/tickets/${ticketId}`, payload);

  return result.data;
}

export async function deleteTicket(ticketId: string): Promise<TicketDeletionResponse | null> {
  const result = await vmInstance.delete<TicketDeletionResponse>(`/tickets/${ticketId}`);

  return result.data;
}
export async function getTicketId(ticketId: string): Promise<TicketDashboardResponse> {
  const result = await dashboardInstance.get<TicketDashboardResponse>(`/tickets/${ticketId}`);

  return result.data;
}

export async function getTicketActionsByTicketId(
  page: IPageState,
  filterVal: IFilterVal[],
  sort: Array<SortDescriptor>,
  ticketId: string,
): Promise<TicketActionsDashboardResponse> {
  const result = await dashboardInstance.get<TicketActionsDashboardResponse>(
    `/ticket/actions?ticketId=${ticketId}`,
    {
      params: {
        skip: page.skip,
        limit: page.take,
        sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
        sortOrder: sort[0].dir,
        ...prepareFilterVal(filterVal),
      },

      paramsSerializer: (params) => {
        const stringifiedParams = qs.stringify(params, { encode: false });

        return stringifiedParams;
      },
    },
  );

  return result.data;
}

// ticket-systems:
export async function getTicketSystemList(
  page: IPageState,
  filterVal: IFilterVal[],
  sort: Array<SortDescriptor>,
): Promise<TicketSystemsDashboardResponse> {
  const result = await dashboardInstance.get<TicketSystemsDashboardResponse>('/ticket-systems', {
    params: {
      skip: page.skip,
      limit: document.location.pathname.includes('/ticket-systems') ? page.take : undefined, // check url for AddTicketForm -> need all list of TS,
      sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
      sortOrder: sort[0].dir,
      ...prepareFilterVal(filterVal),
    },

    paramsSerializer: (params) => {
      const stringifiedParams = qs.stringify(params, { encode: false });

      if (document.location.pathname.includes('/ticket-systems')) {
        history.replace({
          pathname: `/lk/ticket-systems?${stringifiedParams}`,
        });
      }

      return stringifiedParams;
    },
  });

  return result.data;
}

export async function createTicketSystem(
  payload: TicketSystemCreationRequest,
): Promise<TicketSystemCreationResponse> {
  const result = await vmInstance.post<TicketSystemCreationResponse>('/ticket-systems', payload);

  return result.data;
}

export async function editTicketSystem(
  ticketSystemId: string,
  payload: TicketSystemJiraUpdateRequest | TicketSystemYandexTrackerUpdateRequest,
): Promise<TicketSystemUpdateResponse> {
  const result = await vmInstance.patch<TicketSystemUpdateResponse>(
    `/ticket-systems/${ticketSystemId}`,
    payload,
  );

  return result.data;
}
export async function ticketSystemRequestFnMeta(
  payload: TicketSystemMetaRequest,
): Promise<TicketSystemMetaResponse> {
  const result = await vmInstance.post<TicketSystemMetaResponse>('/ticket-systems/meta', payload);

  return result.data;
}

export async function deleteTicketSystem(
  ticketSystemId: string,
): Promise<TicketSystemDeletionResponse | null> {
  const result = await vmInstance.delete<TicketSystemDeletionResponse>(
    `/ticket-systems/${ticketSystemId}`,
  );

  return result.data;
}

//
export async function getCommonUserListForTicket(
  page: IPageState,
  filterVal: IFilterVal[],
  sort: Array<SortDescriptor>,
  ticketSystemId: string,
): Promise<TicketSystemUsersResponse> {
  const result = await vmInstance.get<TicketSystemUsersResponse>(
    `ticket-systems/${ticketSystemId}/users`,
    {
      params: {
        skip: page.skip,
        limit: page.take,
        sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
        sortOrder: sort[0].dir,
        ...prepareFilterVal(filterVal),
      },

      paramsSerializer: (params) => {
        const stringifiedParams = qs.stringify(params, { encode: false });

        // if (!document.location.pathname.includes('/tickets/')) {
        //   history.replace({
        //     pathname: `/lk/security/tickets?${stringifiedParams}`,
        //   });
        // }

        return stringifiedParams;
      },
    },
  );

  return result.data;
}
