/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import type { GridHeaderCellProps } from '@progress/kendo-react-grid';
import styles from '../../../Task/TaskList.module.scss';
import localeTicket from '../../../../../utils/i18n/security/vulnerabilities.json';

export function TicketHeaderCell(props: GridHeaderCellProps): React.ReactElement {
  const { title, onClick, children } = props;

  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeTicket;

  return (
    <div onClick={onClick} className={`${styles.action_header_cell} k-cell-inner`}>
      {title}
      <span className={styles.types_info}>
        <div className={`${styles.types_info_content} k-link`}>
          <div className={styles.type_info}>
            {localeTicket[currentLocale].ticketSystem.grid.headerTicketNotClosed} /{' '}
            {localeTicket[currentLocale].ticketSystem.grid.headerTicketTotal}
          </div>
        </div>
        {children}
        <img
          src={`${process.env.PUBLIC_URL}/images/question.svg`}
          alt=""
          className="tooltip-question-images"
        />
      </span>
    </div>
  );
}
