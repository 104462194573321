import { TextFilter } from '@progress/kendo-react-data-tools';
import { FilterCalendarLocal } from '../../components/common/filterComponents/FilterCalendarLocal';
import { FilterInputInteger } from '../../components/common/filterComponents/FilterInputInteger';
import { FILTER_CUSTOM_OPERATORS, FILTER_OPERATORS } from './constant-filter-operators';
import { FilterStatusAndType } from '../../components/common/filterComponents/FilterStatusAndType';
import { FilterCalendar } from '../../components/common/filterComponents/FilterCalendar';
import { FilterInputCvssLocal } from '../../components/common/filterComponents/FilterInputCvssLocal';
import { FilterCalendarLocal2 } from '../../components/common/filterComponents/FilterCalendarLocal2';
import { FilterResultForSyncTask } from '../../components/common/filterComponents/FilterResultForSyncTask';

export const SERVICES_LOCAL_LIN = {
  'ru-RU': [
    {
      name: 'id',
      label: 'Имя',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'description',
      label: 'Описание',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'active_state',
      label: 'Статус',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'sub_state',
      label: 'Субстатус',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'load_state',
      label: 'Загружен',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'pid',
      label: 'PID',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'fragment_path',
      label: 'Путь к файлу описания',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
  ],
  'en-EN': [
    {
      name: 'id',
      label: 'Id',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'description',
      label: 'Description',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'active_state',
      label: 'Active state',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'sub_state',
      label: 'Sub state',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'load_state',
      label: 'Load state',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'pid',
      label: 'PID',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'fragment_path',
      label: 'Fragment path',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
  ],
};

export const SERVICES_LOCAL_WIN = {
  'ru-RU': [
    {
      name: 'name',
      label: 'Имя',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'display_name',
      label: 'Отображаемое имя',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'description',
      label: 'Описание',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'status',
      label: 'Статус',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'start_type',
      label: 'Тип запуска',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'pid',
      label: 'PID',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'user_account',
      label: 'Учётная запись пользователя',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },

    {
      name: 'path',
      label: 'Путь к исполнимому файлу',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
  ],
  'en-EN': [
    {
      name: 'name',
      label: 'Name',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'display_name',
      label: 'Display name',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'description',
      label: 'Description',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'status',
      label: 'Status',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'start_type',
      label: 'Start type',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'pid',
      label: 'PID',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'user_account',
      label: 'User account',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },

    {
      name: 'path',
      label: 'Path',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
  ],
};

export const FILTER_SOFTWARE_WIN = {
  'ru-RU': [
    {
      name: 'name',
      label: 'Имя',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'version',
      label: 'Версия',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'publisher',
      label: 'Издатель',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'installedAt',
      label: 'Время установки',
      filter: FilterCalendarLocal2,
      operators: FILTER_OPERATORS.date,
    },
    {
      name: 'installLocation',
      label: 'Место установки',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
  ],
  'en-EN': [
    {
      name: 'name',
      label: 'Name',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'version',
      label: 'Version',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'publisher',
      label: 'Publisher',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'installedAt',
      label: 'Installed at',
      filter: FilterCalendarLocal2,
      operators: FILTER_OPERATORS.date,
    },
    {
      name: 'installLocation',
      label: 'Install location',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
  ],
};

export const FILTER_SOFTWARE_LIN = {
  'ru-RU': [
    {
      name: 'name',
      label: 'Имя',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'version',
      label: 'Версия',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'size',
      label: 'Размер',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'arch',
      label: 'Архитектура',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'url',
      label: 'Сайт',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'installedAt',
      label: 'Время установки',
      filter: FilterCalendarLocal2,
      operators: FILTER_OPERATORS.date,
    },
  ],
  'en-EN': [
    {
      name: 'name',
      label: 'Name',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'version',
      label: 'Version',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'size',
      label: 'Size',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'arch',
      label: 'Arch',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'url',
      label: 'Site',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'installedAt',
      label: 'Installed at',
      filter: FilterCalendarLocal2,
      operators: FILTER_OPERATORS.date,
    },
  ],
};

export const VULNERABILITIES_LIN_SOFT = {
  'ru-RU': [
    {
      name: 'maxScore',
      label: 'CVSS Score',
      filter: FilterInputCvssLocal,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'name',
      label: 'Имя пакета',
      filter: TextFilter,
      operators: FILTER_OPERATORS.textLocal,
    },
    {
      name: 'version',
      label: 'Версия пакета',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'updateVersion',
      label: 'Версия исправления',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'totalVulns',
      label: 'Всего уязвимостей',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
  ],
  'en-EN': [
    {
      name: 'maxScore',
      label: 'CVSS Score',
      filter: FilterInputCvssLocal,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'name',
      label: 'Package Name',
      filter: TextFilter,
      operators: FILTER_OPERATORS.textLocal,
    },
    {
      name: 'version',
      label: 'Package Version',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'updateVersion',
      label: 'Patch Version',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'totalVulns',
      label: 'Total Vulnerabilities',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
  ],
};

export const LDAP_SYNC_RESULT = {
  'ru-RU': [
    {
      name: 'hostname',
      label: 'Доменное имя',
      filter: TextFilter,
      operators: FILTER_OPERATORS.textLocal,
    },
    {
      name: 'os.type',
      label: 'OC',
      filter: TextFilter,
      operators: FILTER_OPERATORS.textLocal,
    },
    {
      name: 'sourceLdapGroupName',
      label: 'Из группы AD/LDAP',
      filter: TextFilter,
      operators: FILTER_OPERATORS.textLocal,
    },
    {
      name: 'targetAssetGroup.name',
      label: 'В группу активов',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'summary',
      label: 'Результат',
      filter: FilterResultForSyncTask,
      operators: FILTER_CUSTOM_OPERATORS.text0,
    },
  ],
  'en-EN': [
    {
      name: 'hostname',
      label: 'Domain name',
      filter: TextFilter,
      operators: FILTER_OPERATORS.textLocal,
    },
    {
      name: 'os.type',
      label: 'OC',
      filter: TextFilter,
      operators: FILTER_OPERATORS.textLocal,
    },
    {
      name: 'sourceLdapGroupName',
      label: 'From AD/LDAP group',
      filter: TextFilter,
      operators: FILTER_OPERATORS.textLocal,
    },
    {
      name: 'targetAssetGroup.name',
      label: 'To the asset group',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'summary',
      label: 'Result',
      filter: FilterResultForSyncTask,
      operators: FILTER_CUSTOM_OPERATORS.text0,
    },
  ],
};
export const OPTIONS_SYNC_RESULT = {
  'ru-RU': [
    {
      name: 'ldapGroupName',
      label: 'Группа AD/LDAP ',
      filter: TextFilter,
      operators: FILTER_OPERATORS.textLocal,
    },
    {
      name: 'newGr',
      label: 'Создать группу',
      filter: TextFilter,
      operators: FILTER_OPERATORS.textLocal,
    },
    {
      name: 'assetGr.name',
      label: 'Добавить в группу',
      filter: TextFilter,
      operators: FILTER_OPERATORS.textLocal,
    },
  ],
  'en-EN': [
    {
      name: 'ldapGroupName',
      label: 'AD/LDAP group',
      filter: TextFilter,
      operators: FILTER_OPERATORS.textLocal,
    },
    {
      name: 'newGr',
      label: 'Create group',
      filter: TextFilter,
      operators: FILTER_OPERATORS.textLocal,
    },
    {
      name: 'assetGr.name',
      label: 'Add to group',
      filter: TextFilter,
      operators: FILTER_OPERATORS.textLocal,
    },
  ],
};
export const ASSET_DESCRIPTION = {
  'ru-RU': [
    {
      name: 'hostname',
      label: 'Имя',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'ip',
      label: 'IP',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'type',
      label: 'Тип актива',
      filter: FilterStatusAndType,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
    {
      name: 'addedBy.name',
      label: 'Кем добавлен',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'addedAt',
      label: 'Дата добавления',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
    {
      name: 'assetId',
      label: 'ID актива',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
  ],
  'en-EN': [
    {
      name: 'hostname',
      label: 'Name',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'ip',
      label: 'IP',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'type',
      label: 'Type of asset',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'addedBy.name',
      label: 'Added by',
      filter: FilterStatusAndType,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
    {
      name: 'addedAt',
      label: 'Дата добавления',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
    {
      name: 'assetId',
      label: 'Asset ID',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
  ],
};

export const WHITELIST_BULLETIN_DESCRIPTION = {
  'ru-RU': [
    {
      name: 'bulletinId',
      label: 'Бюллетень',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'addedBy.name',
      label: 'Кем добавлен',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'addedAt',
      label: 'Дата добавления',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
  ],
  'en-EN': [
    {
      name: 'bulletinId',
      label: 'Bulletin',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'addedBy.name',
      label: 'Added by',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'addedAt',
      label: 'Added at',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
  ],
};

export const WHITELIST_SOFTWARE_DESCRIPTION = {
  'ru-RU': [
    {
      name: 'name',
      label: 'Название',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'osType',
      label: 'ОС',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'addedBy.name',
      label: 'Кем добавлен',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'addedAt',
      label: 'Дата добавления',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
  ],
  'en-EN': [
    {
      name: 'Name',
      label: 'Name',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'osType',
      label: 'OS',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'addedBy.name',
      label: 'Added by',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'addedAt',
      label: 'Added at',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
  ],
};

export const GROUP_DESCRIPTION = {
  'ru-RU': [
    {
      name: 'name',
      label: 'Имя группы',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'totalAssets',
      label: 'Хостов',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'addedBy.name',
      label: 'Кем добавлен',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'addedAt',
      label: 'Дата добавления',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
    {
      name: 'assetGroupId',
      label: 'ID Группы',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
  ],
  'en-EN': [
    {
      name: 'Grouip name',
      label: 'Name',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'totalAssets',
      label: 'Total assets',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'addedBy.name',
      label: 'Added by',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'addedAt',
      label: 'Added at',
      filter: FilterCalendar,
      operators: FILTER_OPERATORS.date,
    },
    {
      name: 'assetGroupId',
      label: 'Group ID',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
  ],
};
export const PKG_UPDATE = {
  'ru-RU': [
    {
      name: 'name',
      label: 'Имя пакета',
      filter: TextFilter,
      operators: FILTER_OPERATORS.textLocal,
    },
    {
      name: 'oldVersion',
      label: 'Старая версия',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'newVersion',
      label: 'Новая версия',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'arch',
      label: 'Архитектура',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'status',
      label: 'Статус',
      filter: FilterStatusAndType,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
  ],
  'en-EN': [
    {
      name: 'name',
      label: 'Package Name',
      filter: TextFilter,
      operators: FILTER_OPERATORS.textLocal,
    },
    {
      name: 'oldVersion',
      label: 'Old version',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'newVersion',
      label: 'New version',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'arch',
      label: 'Architecture',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'status',
      label: 'Status',
      filter: FilterStatusAndType,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
  ],
};

export const SM_UPDATE = {
  'ru-RU': [
    {
      name: 'name',
      label: 'Имя пакета',
      filter: TextFilter,
      operators: FILTER_OPERATORS.textLocal,
    },
    {
      name: 'oldVersion',
      label: 'Старая версия',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'newVersion',
      label: 'Новая версия',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'status',
      label: 'Статус',
      filter: FilterStatusAndType,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
  ],
  'en-EN': [
    {
      name: 'name',
      label: 'Package Name',
      filter: TextFilter,
      operators: FILTER_OPERATORS.textLocal,
    },
    {
      name: 'oldVersion',
      label: 'Old version',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'newVersion',
      label: 'New version',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'status',
      label: 'Status',
      filter: FilterStatusAndType,
      operators: FILTER_CUSTOM_OPERATORS.text,
    },
  ],
};
export const VULNERABILITIES_WIN_SOFT = {
  'ru-RU': [
    {
      name: 'maxScore',
      label: 'CVSS Score',
      filter: FilterInputCvssLocal,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'name',
      label: 'Название',
      filter: TextFilter,
      operators: FILTER_OPERATORS.textLocal,
    },
    {
      name: 'version',
      label: 'Версия',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'updateVersion',
      label: 'Версия исправления',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'totalVulns',
      label: 'Всего уязвимостей',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
  ],
  'en-EN': [
    {
      name: 'maxScore',
      label: 'CVSS Score',
      filter: FilterInputCvssLocal,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'name',
      label: 'Name',
      filter: TextFilter,
      operators: FILTER_OPERATORS.textLocal,
    },
    {
      name: 'version',
      label: 'Version',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'updateVersion',
      label: 'Patch Version',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'totalVulns',
      label: 'Total Vulnerabilities',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
  ],
};

export const LOCAL_FILTER_VULNS_LIST = {
  'ru-RU': [
    {
      name: 'maxScore',
      label: 'CVSS Score',
      filter: FilterInputCvssLocal,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'VFstec',
      label: 'V',
      filter: FilterInputCvssLocal,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'id',
      label: 'ID',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'aliases',
      label: 'Альтернативные ID',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'description',
      label: 'Описание',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'cwe',
      label: 'CWE',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'date.published',
      label: 'Опубликована',
      filter: FilterCalendarLocal,
      operators: FILTER_OPERATORS.date,
    },
  ],
  'en-EN': [
    {
      name: 'maxScore',
      label: 'CVSS Score',
      filter: FilterInputCvssLocal,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'VFstec',
      label: 'V',
      filter: FilterInputCvssLocal,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'id',
      label: 'ID',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'aliases',
      label: 'Alternative IDs',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'description',
      label: 'Description',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'cwe',
      label: 'CWE',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'date.published',
      label: 'Published date',
      filter: FilterCalendarLocal,
      operators: FILTER_OPERATORS.date,
    },
  ],
};

export const FILTER_MISSING_KB = {
  'ru-RU': [
    {
      name: 'id',
      label: 'ID',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'title',
      label: 'Заголовок',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'date.published',
      label: 'Дата публикации',
      filter: FilterCalendarLocal,
      operators: FILTER_OPERATORS.dateLocal,
    },
    {
      name: 'date.modified',
      label: 'Дата изменения',
      filter: FilterCalendarLocal,
      operators: FILTER_OPERATORS.dateLocal,
    },
  ],
  'en-EN': [
    {
      name: 'id',
      label: 'ID',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'title',
      label: 'Title',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'date.modified',
      label: 'Date of change',
      filter: FilterCalendarLocal,
      operators: FILTER_OPERATORS.dateLocal,
    },
    {
      name: 'date.published',
      label: 'Publication date',
      filter: FilterCalendarLocal,
      operators: FILTER_OPERATORS.dateLocal,
    },
  ],
};

export const LOCAL_FILTER_PACKAGE_LIST = {
  'ru-RU': [
    {
      name: 'name',
      label: 'Имя',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'version',
      label: 'Версия',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'arch',
      label: 'Архитектура',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
  ],
  'en-EN': [
    {
      name: 'name',
      label: 'Имя',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'version',
      label: 'Version',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'arch',
      label: 'Architecture',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
  ],
};
export const FILTER_SOFT_WIN_LIST = {
  'ru-RU': [
    {
      name: 'name',
      label: 'Имя',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'publisher',
      label: 'Издатель',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'version',
      label: 'Версия',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
  ],
  'en-EN': [
    {
      name: 'name',
      label: 'Имя',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'publisher',
      label: 'Publisher',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'version',
      label: 'Version',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
  ],
};
export const LOCAL_FILTER_GROUPASSET_LIST = {
  'ru-RU': [
    {
      name: 'latestAudit.maxCvssScore',
      label: 'CVSS Score',
      filter: FilterInputCvssLocal,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestInventorization.hostname',
      label: 'Доменное имя',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestInventorization.ip',
      label: 'IP',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestAudit.vulnerabilities.count',
      label: 'Уязвимостей',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestAudit.updates.count',
      label: 'Обновлений',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestInventorization.os.name',
      label: 'Система',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
  ],
  'en-EN': [
    {
      name: 'latestAudit.maxCvssScore',
      label: 'CVSS Score',
      filter: FilterInputCvssLocal,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestInventorization.hostname',
      label: 'Domain name',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestInventorization.ip',
      label: 'IP',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
    {
      name: 'latestAudit.vulnerabilities.count',
      label: 'Vulnerabilities',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestAudit.updates.count',
      label: 'Updates',
      filter: FilterInputInteger,
      operators: FILTER_OPERATORS.numeric,
    },
    {
      name: 'latestInventorization.os.name',
      label: 'System',
      filter: TextFilter,
      operators: FILTER_OPERATORS.text,
    },
  ],
};
