/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { useEffect, useState } from 'react';
import {
  TabStrip,
  TabStripTab,
  type TabStripSelectEventArguments,
} from '@progress/kendo-react-layout';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Button } from '../../../../common/baseElements/Button';
import localeSettings from '../../../../../utils/i18n/settingsLocales/settings.json';
import localeButtons from '../../../../../utils/i18n/commonLocales/buttons.json';
import { useNetDeviceContext } from '../../../../../hooks/useNetDeviceContext';
import { ApiFormMain } from './ApiFormMain';
import styles from '../ApiKeyList.module.scss';
import { HostsMutableBlock } from '../../../../common/form/HostsMutableBlock';
import { useHostModalContext } from '../../../../../hooks/useHostModalContext';
import { HostListForForm } from '../../../../common/form/HostListForForm';
import { GroupMutableBlock } from '../../../../common/form/GroupMutableBlock';
import { GroupListForForm } from '../../../../common/form/GroupListForForm';
import { AccountMutableBlock } from './AccountMutableBlock';
import { useAccountModalContext } from '../../../../../hooks/useAccountModalContext';
import { AccountListForForm } from './AccountListForForm';
import { DeviceMutableBlock } from '../../../../common/form/DeviceMutableBlock';
import { DeviceListForForm } from '../../../Devices/DeviceListForForm';
import { MethodMutableBlock } from './MethodMutableBlock';
import { MethodListForForm } from './MethodListForForm';
import { useApiKeyContext } from '../../../../../hooks/useApiKeyContext';
import { ImageListForForm } from './ImageListForForm';
import { ImageMutableBlock } from './ImageMutableBlock';
import { IpRangesMutableBlock } from './IpRangesMutableBlock';
import { getAccountId, getAccountName } from '../../../../../services/local-storage-service';
import { useRegisterContext } from '../../../../../hooks/useRegisterContext';
import {
  urlOfApiMethodForReg,
  urlOfApiMethodForTicketSys,
} from '../../../../../utils/helpers/constants';

export function AddApiKeyForm(): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeSettings;

  const accountId = getAccountId();

  const accountName = getAccountName();

  const [selected, setSelected] = useState<number>(0);

  const [isAccounts, setAccounts] = useState(
    !!document.location.pathname.includes('repositories') ||
      !!document.location.pathname.includes('ticket-systems'),
  );

  const [isHosts, setHosts] = useState(false);

  const [isGroups, setGroups] = useState(false);

  const [isImages, setImages] = useState(false);

  const [isDevices, setDevices] = useState(false);

  const [isMethods, setMethods] = useState(
    !!document.location.pathname.includes('repositories') ||
      !!document.location.pathname.includes('ticket-systems'),
  );

  const [isIps, setIps] = useState(false);

  const handleSelect = (e: TabStripSelectEventArguments): void => {
    setSelected(e.selected);
  };

  const {
    isApiMethods,
    setApiKeyForm,
    apiMethodsForm,
    cancelApiKeyForm,
    ipRangesForm,
    onSubmit,
    name,
    setApiMethodsForm,
    errorMutation,
    setErrorMutation,
  } = useApiKeyContext();

  const { isAddImage, imageForm } = useRegisterContext();

  const { isAddDevice, deviceForm } = useNetDeviceContext();

  const valuesForCheckbox = {
    isAccounts,
    setAccounts,
    isHosts,
    setHosts,
    isGroups,
    setGroups,
    isImages,
    setImages,
    isDevices,
    setDevices,
    isMethods,
    setMethods,
    isIps,
    setIps,
  };

  const { isOpen, isOpenGroupList, hostForm } = useHostModalContext();

  const { accountForm, setAccountForm, isAddAccount } = useAccountModalContext();

  useEffect(() => {
    if (document.location.pathname.includes('repositories')) {
      setAccountForm([
        {
          accountId: accountId || '',
          name: accountName || undefined,
        },
      ]);
      setApiMethodsForm([{ path: urlOfApiMethodForReg, httpMethods: ['GET'] }]);
    }

    if (document.location.pathname.includes('ticket-systems')) {
      setAccountForm([
        {
          accountId: accountId || '',
          name: accountName || undefined,
        },
      ]);
      setApiMethodsForm([{ path: urlOfApiMethodForTicketSys, httpMethods: ['POST'] }]);
    }
  }, [accountId, accountName, setAccountForm, setApiMethodsForm]);

  useEffect(() => {
    return () => setErrorMutation('');
  }, []);

  return (
    <Dialog
      title={localeSettings[currentLocale].apiKey.form.title}
      onClose={(): void => setApiKeyForm(false)}
      className={
        document.location.pathname.includes('tokens')
          ? styles.apiAddKeyForm
          : styles.apiAddKeyFormLeft
      }
    >
      <Form
        render={(): React.ReactElement => (
          <FormElement>
            <div className={`form-content ${styles.formContent}`}>
              <TabStrip selected={selected} onSelect={handleSelect}>
                <TabStripTab title={localeSettings[currentLocale].apiKey.form.tabs.main}>
                  <ApiFormMain valuesForCheckbox={valuesForCheckbox} />
                </TabStripTab>
                {isAccounts && (
                  <TabStripTab title={localeSettings[currentLocale].apiKey.form.tabs.accounts}>
                    {(!accountForm || accountForm?.length === 0) && (
                      <div className={styles.titleTabInfo}>
                        <span>{localeSettings[currentLocale].apiKey.form.tabs.titleAccounts}</span>
                      </div>
                    )}
                    <AccountMutableBlock />
                  </TabStripTab>
                )}
                {isHosts && (
                  <TabStripTab title={localeSettings[currentLocale].apiKey.form.tabs.host}>
                    {(!hostForm || hostForm?.length === 0) && (
                      <div className={styles.titleTabInfo}>
                        <span>{localeSettings[currentLocale].apiKey.form.tabs.titleHostGroup}</span>
                      </div>
                    )}
                    <HostsMutableBlock />
                    <GroupMutableBlock />
                  </TabStripTab>
                )}
                {isMethods && (
                  <TabStripTab title={localeSettings[currentLocale].apiKey.form.tabs.methods}>
                    {(!apiMethodsForm || apiMethodsForm?.length === 0) && (
                      <div className={styles.titleTabInfo}>
                        <span>{localeSettings[currentLocale].apiKey.form.tabs.titleMethod}</span>
                      </div>
                    )}
                    <MethodMutableBlock />
                  </TabStripTab>
                )}
                {isDevices && (
                  <TabStripTab title={localeSettings[currentLocale].apiKey.form.tabs.device}>
                    {(!deviceForm || deviceForm?.length === 0) && (
                      <div className={styles.titleTabInfo}>
                        <span>{localeSettings[currentLocale].apiKey.form.tabs.titleDevice}</span>
                      </div>
                    )}
                    <DeviceMutableBlock />
                  </TabStripTab>
                )}
                {isImages && (
                  <TabStripTab title={localeSettings[currentLocale].apiKey.form.tabs.image}>
                    {(!imageForm || imageForm?.length === 0) && (
                      <div className={styles.titleTabInfo}>
                        <span>{localeSettings[currentLocale].apiKey.form.tabs.titleImage}</span>
                      </div>
                    )}
                    <ImageMutableBlock />
                  </TabStripTab>
                )}
                {isIps && (
                  <TabStripTab title={localeSettings[currentLocale].apiKey.form.tabs.ip}>
                    {(!ipRangesForm || ipRangesForm?.length === 0) && (
                      <div className={styles.titleTabInfo}>
                        <span>{localeSettings[currentLocale].apiKey.form.tabs.titleIp}</span>
                      </div>
                    )}
                    <IpRangesMutableBlock />
                  </TabStripTab>
                )}
              </TabStrip>
            </div>
            <div className="error-common-field">
              <span>{errorMutation}</span>
            </div>
            <div className="k-form-buttons">
              <Button type="submit" onClick={onSubmit} disabled={!name}>
                {localeButtons[currentLocale].create}
              </Button>
              <Button type="button" onClick={cancelApiKeyForm}>
                {localeButtons[currentLocale].cancel}
              </Button>
            </div>
          </FormElement>
        )}
      />
      {isOpen && <HostListForForm />}
      {isOpenGroupList && <GroupListForForm />}
      {isAddAccount && <AccountListForForm />}
      {isAddDevice && <DeviceListForForm />}
      {isApiMethods && <MethodListForForm />}
      {isAddImage && <ImageListForForm />}
    </Dialog>
  );
}
