import { Label } from '@progress/kendo-react-labels';
import type { FieldRenderProps } from '@progress/kendo-react-form';
import { DropDownList } from '@progress/kendo-react-dropdowns';

export function DropDownSelect(fieldRenderProps: FieldRenderProps): React.ReactElement {
  const {
    name,
    label,
    defaultValue,
    data,
    onChange,
    className,
    classForLabel,
    textField,
    currentValue,
    dataItemKey,
    disabled,
  } = fieldRenderProps;

  return (
    <div className={`${className || 'textarea-field'}`}>
      <Label className={classForLabel || ''}>{label}</Label>
      <DropDownList
        data={data}
        textField={textField}
        dataItemKey={dataItemKey}
        name={name}
        onChange={onChange}
        // defaultItem={
        //   currentValue
        //     ? {
        //         id: currentValue.id,
        //         name: currentValue.name,
        //       }
        //     : defaultValue
        // }
        defaultItem={currentValue || defaultValue}
        value={currentValue || defaultValue}
        disabled={disabled}
      />
    </div>
  );
}
