import { useCallback, useState, type ReactElement } from 'react';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { useParams } from 'react-router';
import { AccountSettingsTab } from './AccountSettingsTab';
import type { AccountDashboardResponse } from '../../../types/__generated/on-premise-solution/api/accountsDashboardResponse.v1';
import localeSettings from '../../../utils/i18n/settingsLocales/settings.json';
import localeAccount from '../../../utils/i18n/accountLocales/accountDescription.json';
import { SoftwareManagement } from '../SoftwareManagement/SoftwareManagement';
import type { ConfigResponse } from '../../../types/__generated/on-premise-solution/api/configResponse.v1';
import { LogsTab } from './Tabs/LogsTab';
import { DashboardTab } from './Tabs/DasboardTab';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAccount;

export function TabAccountDescription({
  data,
  config,
}: {
  data: AccountDashboardResponse | undefined;
  config: ConfigResponse | undefined;
}): ReactElement {
  const { accountId } = useParams<{ accountId: string }>();
  const [selected, setSelected] = useState<number>(0);

  const handleSelect = useCallback(
    (e: TabStripSelectEventArguments): void => {
      setSelected(e.selected);
    },
    [setSelected],
  );

  return (
    <TabStrip selected={selected} onSelect={handleSelect}>
      <TabStripTab title={localeAccount[currentLocale].mainTab}>
        <DashboardTab />
      </TabStripTab>
      <TabStripTab title={localeAccount[currentLocale].logsTab}>
        <LogsTab />
      </TabStripTab>
      <TabStripTab title={localeAccount[currentLocale].softwareManagementTab}>
        <SoftwareManagement accountId={accountId} />
      </TabStripTab>
      <TabStripTab title={localeSettings[currentLocale].settingsTitle}>
        <AccountSettingsTab settings={data?.settings} config={config} />
      </TabStripTab>
    </TabStrip>
  );
}
