/* eslint-disable react-hooks/exhaustive-deps */
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import type { TextFilterProps } from '@progress/kendo-react-data-tools/dist/npm/filter/filters/TextFilter';
import type localeSoftwareManagement from '../../../utils/i18n/assetLocales/softwareManagement.json';
import localeFilters from '../../../utils/i18n/commonLocales/filters.json';
import type { IDaraFilterSelect } from '../../../utils/helpers/types';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSoftwareManagement;

let dataStatus = [] as IDaraFilterSelect[];
export function FilterTicketSystemType(props: TextFilterProps): React.ReactElement {
  const { filter, onFilterChange } = props;

  dataStatus = [
    {
      text: localeFilters[currentLocale].notChanged,
      val: '',
    },
    {
      text: 'local',
      val: 'local',
    },
    {
      text: 'jira',
      val: 'jira',
    },
  ];

  const handleChange = (event: DropDownListChangeEvent): void => {
    onFilterChange({
      nextFilter: { ...filter, ...{ value: event.target.value.val, text: '' } },
    });
  };

  return (
    <DropDownList
      defaultValue={dataStatus[0]}
      data={dataStatus}
      textField="text"
      dataItemKey="val"
      onChange={handleChange}
    />
  );
}
