/* eslint-disable no-nested-ternary */
import type {
  IPayloadAddTask,
  TLdapGrFormValues,
  TWinSoftwareTaskUpdate,
  TЕransformWinSoftwareUpdateState,
} from './types';
import type localeNewTask from '../i18n/taskLocale/newTask.json';
import type { LdapGroupsResponse } from '../../types/__generated/on-premise-solution/api/ldapGroupsResponse.v1';

/* eslint-disable camelcase */

export function setCurrentLocale(): 'ru-RU' | 'en-EN' {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeNewTask;

  return currentLocale;
}

export function getUTCForCron(str: string): string {
  const cronValue = str.split(' ');
  const adjustmentTimezone = new Date().getTimezoneOffset() / 60;
  const february = cronValue[3];
  const modifiedCronValue = cronValue
    .map((c, i) => {
      if ((i === 1 && Number(c.slice(0, 2))) || (i === 1 && c === '0')) {
        let hour = Number(c.slice(0, 2)) - Math.abs(adjustmentTimezone);

        if (hour < 0) {
          hour += 24;
        }

        // if (hour === -1) {
        //   hour = 23;
        // } else if (hour === -2) {
        //   hour = 22;
        // } else if (hour === -3) {
        //   hour = 21;
        // } else if (hour === -4) {
        //   hour = 20;
        // } else if (hour === -5) {
        //   hour = 19;
        // }

        return String(hour <= 23 ? hour : 23);
      }
      if (i === 2 && Number(c) > 29 && Number(february) === 2) return String(29);

      return c;
    })
    .join(' ');

  return modifiedCronValue;
}

export function getUTCForTaskList(str: string): string {
  const cronValue = str.split(' ');
  // const adjustmentTimezone = new Date().getTimezoneOffset() / 60;
  const currentDate = new Date();
  const modifiedCronValue = cronValue.map((c, i) => {
    if (i === 1 && !Number.isNaN(Number(c))) {
      const utcDate = new Date(
        Date.UTC(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate(),
          Number(c),
          0,
          0,
          0,
        ),
      );

      return utcDate.getHours();
    }

    return c;
  });

  return modifiedCronValue.join(' ');
}

function setFileFormat(html: boolean, pdf: boolean): string[] {
  const fileFormat = [] as string[];

  if (html) fileFormat.push('html');

  if (pdf) fileFormat.push('pdf');

  return fileFormat;
}

export const dateWithoutTimezone = (date: Date): string => {
  const tzoffset = date.getTimezoneOffset() * 60000; // offset in milliseconds
  const withoutTimezone = new Date(date.valueOf() - tzoffset).toISOString().slice(0, -1);

  return withoutTimezone;
};

function transformWinSoftwareUpdateState(
  winSoftwareUpdateState: Record<string, TWinSoftwareTaskUpdate> | null,
): TЕransformWinSoftwareUpdateState[] {
  let transformData: TЕransformWinSoftwareUpdateState[] = [];
  if (winSoftwareUpdateState && Object.values(winSoftwareUpdateState).length > 0) {
    transformData = Object.values(winSoftwareUpdateState).map((v, i) => {
      if (v.fullId)
        return {
          fullId: v.fullId,
          softwareCodename: Object.keys(winSoftwareUpdateState)[i],
          softwareName:
            v.version && v.arch && v.language
              ? `${v.softwareName} ${v.version} ${v.arch} ${v.language}`
              : v.softwareName,
        };

      return {
        softwareCodename: Object.keys(winSoftwareUpdateState)[i],
        softwareName: v.softwareName,
      };
    });
  }

  return transformData;
}
export function submitAddTask(addTaskData: IPayloadAddTask): object {
  const actions = {
    audit: addTaskData.taskAudit
      ? {
          useBulletinWhitelists: !!addTaskData.taskWhiteList,
          runScanFirst: !addTaskData.runScanFirst,
        }
      : undefined,
    inventorization: addTaskData.taskInventorization ? {} : undefined,
    agent_update: addTaskData.taskAgentUpdate ? {} : undefined,
    system_reboot: addTaskData.taskSystemReboot
      ? { timeout: Number(addTaskData.taskSystemRebootValue) }
      : undefined,
    package_update:
      addTaskData.taskPackageUpdate && addTaskData.selectedPkgs
        ? {
            packages: addTaskData.selectedPkgs.map((p) => ({ name: p.name })),
            rebootBehaviorAfterUpdate: addTaskData.pkgRebootVal?.id || undefined,
          }
        : addTaskData.taskPackageUpdate && !addTaskData.selectedPkgs
        ? { rebootBehaviorAfterUpdate: addTaskData.pkgRebootVal?.id || undefined }
        : undefined,
    kb_install: addTaskData.taskKbUpdate
      ? {
          updateFrom: addTaskData.kbUpdateSrcVal?.id || undefined,
          rebootBehaviorAfterUpdate: addTaskData.kbRebootVal?.id || undefined,
          kbList: addTaskData.missedKbs && addTaskData.missedKbs.map((k) => ({ id: k.id })),
        }
      : addTaskData.taskKbUpdate && !addTaskData.missedKbs
      ? { kbList: null }
      : undefined,
    group_report: addTaskData.taskGroupReport
      ? {
          reportType: addTaskData.reportType.value.id,
          bulletinIds:
            addTaskData.reportType.value.id === 'bulletin_overview' ||
            addTaskData.reportType.value.id === 'top_bulletins'
              ? addTaskData.bulletinIds?.map((b) => b.id)
              : undefined,
          // fileFormat: [addTaskData.fileFormat.value.id],
          fileFormat: setFileFormat(addTaskData.isHtmlFormat, addTaskData.isPdfFormat),
          topN:
            (addTaskData.reportType.value.id === 'top_bulletins' && Number(addTaskData.topN)) ||
            (addTaskData.reportType.value.id === 'top_vulnerable_assets' &&
              Number(addTaskData.topN)) ||
            undefined,
        }
      : undefined,
    single_report: addTaskData.taskSingleReport
      ? {
          reportType: addTaskData.reportType.value.id,
          bulletinIds:
            addTaskData.reportType.value.id === 'bulletin_overview' ||
            addTaskData.reportType.value.id === 'top_bulletins'
              ? addTaskData.bulletinIds?.map((b) => b.id)
              : undefined,
          // fileFormat: [addTaskData.fileFormat.value.id],
          fileFormat: setFileFormat(addTaskData.isHtmlFormat, addTaskData.isPdfFormat),
          dateRange:
            addTaskData.reportType.value.id === 'differential_audit' &&
            addTaskData.fromDatePickerTaskAction &&
            addTaskData.toDatePickerTaskAction
              ? {
                  from: dateWithoutTimezone(addTaskData.fromDatePickerTaskAction),
                  to: dateWithoutTimezone(addTaskData.toDatePickerTaskAction),
                }
              : undefined,
        }
      : undefined,
    asset_ldap_synchronize:
      addTaskData.taskType.value.id === 'asset_synchronize'
        ? {
            ldapGroups: addTaskData.ldapGrForm?.map((l) => ({
              ldapGroupName: l.ldapGroupName,
              assetGroupPolicy: l.assetGroupPolicy,
              existingAssetGroupId: l.assetGr?.id,
            })),
            assetDuplicationsSearchPolicy:
              addTaskData.checkDuplicate === 'doNotCheck'
                ? 'ignore'
                : addTaskData.checkDuplicate === 'globalCheck'
                ? 'globally'
                : 'inside_asset_group',
            bindCredentialIds: addTaskData.commonCredInTask
              ? {
                  winrm:
                    (addTaskData.commonCredInTask.filter((c) => c.type === 'winrm')[0] &&
                      addTaskData.commonCredInTask.filter((c) => c.type === 'winrm')[0]
                        .credentialId) ||
                    undefined,
                  ssh:
                    (addTaskData.commonCredInTask.filter((c) => c.type === 'ssh')[0] &&
                      addTaskData.commonCredInTask.filter((c) => c.type === 'ssh')[0]
                        .credentialId) ||
                    undefined,
                }
              : undefined,
          }
        : undefined,
    audit_image: addTaskData.taskAuditImage
      ? {
          image_type: 'docker' || 'podman' || 'iso',
          image_id: 'all',
          // useBulletinWhitelists: !!addTaskData.taskWLImage,
        }
      : undefined,
    notification:
      addTaskData.userTaskEmail?.length || addTaskData.userTaskInputEmail?.length
        ? {
            email: {
              users:
                addTaskData.userTaskEmail && addTaskData.userTaskEmail.length > 0
                  ? addTaskData.userTaskEmail.map((u) => u.userId)
                  : undefined,
              recipientEmails:
                addTaskData.userTaskInputEmail && addTaskData.userTaskInputEmail.length > 0
                  ? addTaskData.userTaskInputEmail
                  : undefined,
            },
          }
        : undefined,
    windows_software_management:
      addTaskData.taskWinSoftUpdate && addTaskData.winSoftwareUpdateState
        ? {
            action: 'update',
            softwareList: addTaskData.winSoftwareUpdateState
              ? transformWinSoftwareUpdateState(addTaskData.winSoftwareUpdateState)
              : undefined,
          }
        : undefined,
  };

  interface IScope {
    assets: (string | undefined)[] | undefined;
    assetGroups: (string | undefined)[] | undefined;
    registries: string[] | undefined;
    ldapId: string | undefined;
    assetDynamicGroupIds: string[] | undefined;
  }

  let scope2: IScope | null = null;

  let assets: (string | undefined)[] | undefined;

  if (addTaskData && addTaskData.hostForm && addTaskData.hostForm.length > 0) {
    assets = addTaskData.hostForm.filter((asset) => asset.assetId).map((asset) => asset.assetId);
  }
  if (addTaskData.deviceForm && addTaskData.deviceForm.length > 0) {
    assets =
      assets && assets.length > 0
        ? [
            ...assets,
            ...addTaskData.deviceForm
              .filter((asset) => asset.assetId)
              .map((asset) => asset.assetId),
          ]
        : [
            ...addTaskData.deviceForm
              .filter((asset) => asset.assetId)
              .map((asset) => asset.assetId),
          ];
  }
  if (addTaskData.imageForm && addTaskData.imageForm.length > 0) {
    assets =
      assets && assets.length > 0
        ? [
            ...assets,
            ...addTaskData.imageForm.filter((asset) => asset.assetId).map((asset) => asset.assetId),
          ]
        : [...addTaskData.imageForm.filter((asset) => asset.assetId).map((asset) => asset.assetId)];
  }

  scope2 = {
    assets: assets && assets.length > 0 ? assets : undefined,
    assetGroups:
      addTaskData.taskType.value.id !== 'asset_synchronize' &&
      addTaskData.hostForm &&
      addTaskData.hostForm.length > 0 &&
      addTaskData.hostForm.find((asset) => asset.assetGroupId)
        ? addTaskData.hostForm
            ?.filter((asset) => asset.assetGroupId)
            .map((asset) => asset.assetGroupId)
        : undefined,
    registries:
      addTaskData.registryForm &&
      addTaskData.registryForm.length > 0 &&
      addTaskData.registryForm.find((registry) => registry.registryId)
        ? addTaskData.registryForm
            ?.filter((registry) => registry.registryId)
            .map((registry) => registry.registryId)
        : undefined,
    ldapId: (addTaskData.serverForm && addTaskData.serverForm[0].ldapId) || undefined,
    assetDynamicGroupIds:
      addTaskData.dynamicGroupForm && addTaskData.dynamicGroupForm.length > 0
        ? addTaskData.dynamicGroupForm
            .filter((g) => g.assetDynamicGroupId)
            .map((g) => g.assetDynamicGroupId)
        : undefined,
  };

  const taskData = {
    type: addTaskData.taskType.value.id,
    taskId: addTaskData.taskId,
    name: addTaskData.taskName.trimStart().trimEnd(),
    isEnabled: true,
    scope: scope2,
    actions,
    schedule:
      addTaskData.taskTime !== 'now' && addTaskData.taskTime === 'startAt' && addTaskData.datePicker
        ? {
            startAt: addTaskData.isAssetTimezone
              ? `${addTaskData.datePicker
                  .toLocaleString('sv', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                    fractionalSecondDigits: 3,
                  })
                  .replace(',', '.')
                  .replace(' ', 'T')}Z`
              : addTaskData.datePicker,
            useAssetLocalTime: addTaskData.isAssetTimezone,
          }
        : addTaskData.taskTime !== 'now' && addTaskData.taskTime !== 'startAt'
        ? { repeat: addTaskData.cronValue.current, useAssetLocalTime: addTaskData.isAssetTimezone } // ? { repeat: getUTCForCron(addTaskData.cronValue.current) }
        : undefined,
  };

  return taskData;
}

export function addAllLdapGr(
  ldapGr: LdapGroupsResponse['data'] | undefined,
  ldapGrForm: TLdapGrFormValues[] | null,
  setLdapGrForm: React.Dispatch<React.SetStateAction<TLdapGrFormValues[] | null>>,
): void {
  const ldapGrAllListForm = [] as TLdapGrFormValues[];

  ldapGr?.forEach((l) => {
    ldapGrAllListForm.push({
      ldapGroupName: l.commonName,
      assetGroupPolicy: 'do_not_create',
    });
  });
  if (ldapGrForm && ldapGrForm.length > 0) {
    const ldapGrFormAndSelected = [...ldapGrForm, ...ldapGrAllListForm];
    const duplicatValChecked = ldapGrFormAndSelected.filter(
      (l, i, arr) => arr.findIndex((val) => val.ldapGroupName === l.ldapGroupName) === i,
    );

    setLdapGrForm([...duplicatValChecked]);
  } else {
    setLdapGrForm([...ldapGrAllListForm]);
  }
}
