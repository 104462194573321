import { NotificationGroup, Notification } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import styles from '../../../Task/TaskList.module.scss';
import { ScopeBulletin } from './ScopeBulletin';

export function ScopeNotificationBulletin({
  data,
  isShowBulletin,
  className,
  sliceValue,
}: {
  data: string[] | undefined;
  isShowBulletin: boolean;
  className?: string;
  sliceValue: number;
}): React.ReactElement {
  return (
    <NotificationGroup className={`${styles.notification} ${className || ''}`}>
      <Fade>
        {isShowBulletin && data && data.length > 1 && (
          <Notification>
            {data.slice(sliceValue).map((b) => (
              <ScopeBulletin bulletin={b} />
            ))}
          </Notification>
        )}
      </Fade>
    </NotificationGroup>
  );
}
