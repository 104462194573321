/* eslint-disable import/no-cycle */
import type { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { getRefreshToken, removeAuthData, setTokens } from '../../services/local-storage-service';
import type { ApiError } from '../../types/__generated/on-premise-solution/api/apiError.v1';
import type { UserLoginResponse } from '../../types/__generated/on-premise-solution/api/userLoginResponse.v1';
import { history } from '../../utils/history';
import { ResponseApiError } from '../../utils/errors';
import { authInstance } from './hooks/useAuthAxios';
import type { AuditHistory } from '../../types/__generated/on-premise-solution/api/auditHistory.v1';

/**
 * Checks that error is Axios Error.
 *
 * @template T
 * @param {unknown} err - Error instance
 * @returns {err is T} True if isAxiosError
 */
export function isAxiosError<T = AxiosError>(err: unknown): err is T {
  return (err as AxiosError).isAxiosError !== undefined;
}

export interface IAxiosResponse<T = never> {
  data: T;
  status: number;
  statusText: string;
  headers: Record<string, string>;
  config: AxiosRequestConfig<T>;
  request?: any;
}

export async function processingResError(
  error: AxiosError<ApiError>,
  setAccessToken: (v: string) => void,
  authInstanceT: AxiosInstance,
): Promise<AxiosError<ApiError>> {
  if (error instanceof Error) {
    if (isAxiosError<AxiosError<ApiError>>(error) && error.response) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.type === 'TokenExpired' &&
        !document.location.pathname.includes('/auth')
      ) {
        const { data } = await authInstance.post<UserLoginResponse>('/refresh', {
          refreshToken: getRefreshToken() || undefined,
        });
        setTokens(data);
        setAccessToken(data.accessToken);
      } else if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.type === 'Unauthorized'
      ) {
        removeAuthData();
        history.push('/auth');
      } else if (
        error.response &&
        error.response.status === 403 &&
        error.response.data.type === 'Forbidden' &&
        error.config?.url === '/refresh'
      ) {
        removeAuthData();
        history.push('/auth');
      } else if (error.response && error.response.status === 402) {
        console.log(error);
      }

      // if (error.response.data) window.location.reload();

      return Promise.reject(new ResponseApiError(error.response.data));
    }

    // if (error.message === 'Network Error') window.location.reload();

    return Promise.reject(new Error(error.message));
  }

  return Promise.reject(error);
}

export function returnClassName(score: string | undefined): string {
  if (Number(score) >= 0 && Number(score) <= 3.9) {
    return 'low-color-text';
  }
  if (Number(score) >= 3.9 && Number(score) <= 6.9) {
    return 'low-color-medium';
  }
  if (Number(score) >= 6.9 && Number(score) <= 8.9) {
    return 'low-color-high';
  }
  if (Number(score) > 8.9) {
    return 'low-color-critical';
  }

  return '';
}

export function calcPagination(value: number | null): { skip: number; take: number } {
  return {
    skip: 0,
    take: value || 15,
  };
}

export function transformBytesToHuman(size: number, radix?: number, locale?: string): string {
  const sizenames =
    locale === 'ru-RU' ? ['Б', 'Кб', 'Мб', 'Гб', 'Тб', 'Пб'] : ['B', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb'];
  let z = 0;
  let newSize = size;

  while (newSize >= 1024 && z < sizenames.length - 1) {
    newSize /= 1024;
    z += 1;
  }

  return `${radix ? newSize.toFixed(radix) : Math.floor(newSize)} ${
    z < sizenames.length ? sizenames[z] : sizenames[sizenames.length - 1]
  }`;
}

export function wordDeclension(
  number: number | undefined,
  variants: string[],
  lang?: 'ru-RU' | 'en-EN',
): string {
  if (!number) return variants[variants.length - 1];

  const n = Math.abs(number);

  // For en-EN lang
  if (lang === 'en-EN') return n === 1 ? variants[0] : variants[variants.length - 1];

  // For russian and other other
  if (!lang || lang === 'ru-RU') {
    const cases = [2, 0, 1, 1, 1, 2];

    return variants[n % 100 > 4 && n % 100 < 20 ? 2 : cases[n % 10 < 5 ? n % 10 : 5]];
  }

  return variants[variants.length - 1];
}

export function getAuditRangePoints(
  firstAuditId: string | undefined,
  secondAuditId: string | undefined,
  auditsDynamic: AuditHistory['dynamic'] | undefined,
): AuditHistory['dynamic'] | undefined {
  if (!auditsDynamic || !secondAuditId) return undefined;

  const firstPointIndex = firstAuditId
    ? auditsDynamic.findIndex((d) => d.auditId === firstAuditId)
    : auditsDynamic.length - 1;
  const secondPointIndex = auditsDynamic.findIndex((d) => d.auditId === secondAuditId);

  const dynamic = [...auditsDynamic].filter(
    (d, i) => i <= firstPointIndex && i >= secondPointIndex,
  );

  return dynamic.length ? dynamic : undefined;
}
