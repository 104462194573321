import type { GridCellProps } from '@progress/kendo-react-grid';
import { Button } from '../../../../../common/baseElements/Button';
import { useAuditModalContext } from '../../../../../../hooks/useAuditModalContext';
import type { ICollectVulnSoftData } from '../../../../../../utils/helpers/audit-helpers';

export function CellPackageName(props: GridCellProps): React.ReactElement {
  const { dataItem, className, format } = props;

  const { name, updateVersion, currentWinName, currentVersion }: ICollectVulnSoftData = dataItem;

  const { setOpen, setPkgName } = useAuditModalContext();

  const handleModal = (): void => {
    // setOpen(true);
    setPkgName({
      name: currentWinName || name,
      version: currentVersion,
      updateVersion,
      detectedName: name,
      wl: !!format,
    });
  };

  return (
    <td title={name} className={className || ''}>
      <Button fill="modal" onClick={handleModal}>
        <span>{name}</span>
      </Button>
    </td>
  );
}
