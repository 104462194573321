import { useQuery } from 'react-query';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { useRef } from 'react';
import type { SavedWindowsSoftwareUpdatesWithAvailableDashboardResponse } from '../../../types/__generated/on-premise-solution/api/savedWindowsSoftwareUpdatesWithAvailableDashboardResponse.v1';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
import { getSoftwareList } from '../../../services/softManagement-service';
import styles from '../../features/SoftwareManagement/SoftwareManagement.module.scss';
import { SoftwareRow } from '../../features/SoftwareManagement/SoftwareRow';
import { useStatesForList } from '../../../hooks/components/useStatesForList';
import { getUrlList } from '../../../utils/helpers/getUrl-list-helper';
import { SoftwareApproveModal } from '../../features/SoftwareManagement/SoftwareApproveModal';
import { SoftwareRemoveModal } from '../../features/SoftwareManagement/SoftwareRemoveModal';
import { SoftwareModal } from '../../features/SoftwareManagement/SoftwareModal';
import { useSoftwareManagementContext } from '../../../hooks/useSoftwareManagementContext';

export function SoftwareAvailableUpdatesTable({
  softwareCodename,
  version,
  accountId,
}: {
  softwareCodename: string | null;
  version?: string;
  accountId?: string | null;
}): React.ReactElement {
  const WindowsSoftwareResponse =
    useRef<SavedWindowsSoftwareUpdatesWithAvailableDashboardResponse['data']>();

  const urlParams = getUrlList('AvailableUpdatesTable');

  const { sort, page, filterVal } = useStatesForList(urlParams);
  const { isModalOpen, isApproveSubmitOpen, isRemoveSubmitOpen } = useSoftwareManagementContext();

  useQuery<SavedWindowsSoftwareUpdatesWithAvailableDashboardResponse | undefined, ApiError>(
    ['savedSoftwareList', page, filterVal, sort, accountId],
    () => getSoftwareList(page, filterVal, sort, softwareCodename, version, accountId),
    {
      onSuccess: (resp) => {
        if (resp?.data && resp.data.length > 0) WindowsSoftwareResponse.current = [resp.data[0]];
      },
    },
  );

  return (
    <div className={styles.softwareAvailableUpdatesTable}>
      {isApproveSubmitOpen && <SoftwareApproveModal />}
      {isRemoveSubmitOpen && <SoftwareRemoveModal />}
      {isModalOpen && <SoftwareModal />}
      <Grid
        className={`${styles.repositories_table} no-margin-top`}
        data={WindowsSoftwareResponse.current}
        scrollable="none"
      >
        <Column cell={SoftwareRow} />
      </Grid>
    </div>
  );
}
