import { Checkbox } from '@progress/kendo-react-inputs';
import localeSettings from '../../../../utils/i18n/settingsLocales/settings.json';
import styles from '../WhiteListTabEl.module.scss';
import { useWhiteListContext } from '../../../../hooks/useWhiteListContext';
import { useHostModalContext } from '../../../../hooks/useHostModalContext';
import { useNetDeviceContext } from '../../../../hooks/useNetDeviceContext';
import { useRegisterContext } from '../../../../hooks/useRegisterContext';
import { usedAssetType } from '../../../../utils/helpers/settings-helpers';
import { UsedAssetType } from './UsedAssetType';
import { useGroupContext } from '../../../../hooks/useGroupContext';
import type { TValuesForCheckbox } from '../../../../utils/helpers/types';

export function WhiteListScopeCheckboxBlock({
  valuesForCheckbox,
}: {
  valuesForCheckbox: TValuesForCheckbox;
}): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeSettings;

  const {
    isHosts,
    setHosts,
    isDevices,
    setDevices,
    isImages,
    setImages,
    isAssetType,
    setIsAssetType,
  } = valuesForCheckbox;

  const { imageForm, setImageForm } = useRegisterContext();

  const { setAssetType } = useWhiteListContext();

  const { assetType, whitelistUpdate } = useWhiteListContext();

  const { hostForm, setHostForm, setHostFormTemp } = useHostModalContext();

  const { deviceForm, setDeviceForm, setDeviceFormTemp } = useNetDeviceContext();

  const { dynamicGroupForm } = useGroupContext();

  const types = assetType ? usedAssetType(assetType) : null;

  const changeCheckbox = (text: string, val: boolean): void => {
    if (text === 'host') {
      setHosts(val);
      if (!val) {
        setHostForm(null);
        setHostFormTemp(null);
      }
    }
    if (text === 'device') {
      setDevices(val);
      if (!val) {
        setDeviceForm(null);
        setDeviceFormTemp(null);
      }
    }
    if (text === 'image') {
      setImages(val);
      if (!val) setImageForm(null);
    }
    if (text === 'assetType') {
      setIsAssetType(val);
      if (!val)
        setAssetType({
          host: false,
          image: false,
          router: false,
          switch: false,
          firewall: false,
          hypervisor: false,
          all: false,
        });
    }
  };

  return (
    <div className={styles.whitelistCheckboxWrapper}>
      <div className={styles.titleTabMain}>
        {/* <span className={!whitelistUpdate ? 'required-label' : ''}> */}
        <span>{localeSettings[currentLocale].whiteList.titleTabMain}</span>
      </div>
      <div className={styles.whitelistCheckboxContent}>
        <div className={styles.checkboxWrapperCount}>
          <Checkbox
            label={localeSettings[currentLocale].apiKey.form.checkbox.hostGroup}
            checked={isHosts}
            onChange={(): void => changeCheckbox('host', !isHosts)}
            className={styles.checkboxMb}
          />
          {!dynamicGroupForm && hostForm && hostForm.length > 0 && isHosts && (
            <div className={styles.checkboxCount}>
              <span>
                ({localeSettings[currentLocale].whiteList.form.selected} {hostForm?.length})
              </span>
            </div>
          )}
          {!hostForm && dynamicGroupForm && dynamicGroupForm.length > 0 && isHosts && (
            <div className={styles.checkboxCount}>
              <span>
                ({localeSettings[currentLocale].whiteList.form.selected} {dynamicGroupForm?.length})
              </span>
            </div>
          )}
          {hostForm &&
            dynamicGroupForm &&
            hostForm.length > 0 &&
            dynamicGroupForm.length > 0 &&
            isHosts && (
              <div className={styles.checkboxCount}>
                <span>
                  ({localeSettings[currentLocale].whiteList.form.selected}{' '}
                  {hostForm.length + dynamicGroupForm.length})
                </span>
              </div>
            )}
          {isHosts &&
            (!hostForm || hostForm.length === 0) &&
            (!dynamicGroupForm || dynamicGroupForm.length === 0) && (
              <div className={styles.checkboxCount}>
                <span>({localeSettings[currentLocale].whiteList.form.notSelected})</span>
              </div>
            )}
        </div>
        <div className={styles.checkboxWrapperCount}>
          <Checkbox
            label={localeSettings[currentLocale].apiKey.form.checkbox.deviceList}
            checked={isDevices}
            onChange={(): void => changeCheckbox('device', !isDevices)}
            className={styles.checkboxMb}
          />
          {deviceForm && deviceForm.length > 0 && isDevices && (
            <div className={styles.checkboxCount}>
              <span>
                ({localeSettings[currentLocale].whiteList.form.selected} {deviceForm?.length})
              </span>
            </div>
          )}
          {isDevices && (!deviceForm || deviceForm.length === 0) && (
            <div className={styles.checkboxCount}>
              <span>({localeSettings[currentLocale].whiteList.form.notSelected})</span>
            </div>
          )}
        </div>
        <div className={styles.checkboxWrapperCount}>
          <Checkbox
            label={localeSettings[currentLocale].apiKey.form.checkbox.imageList}
            checked={isImages}
            onChange={(): void => changeCheckbox('image', !isImages)}
            className={styles.checkboxMb}
          />
          {imageForm && imageForm.length > 0 && isImages && (
            <div className={styles.checkboxCount}>
              <span>
                ({localeSettings[currentLocale].whiteList.form.selected} {imageForm?.length})
              </span>
            </div>
          )}
          {isImages && (!imageForm || imageForm.length === 0) && (
            <div className={styles.checkboxCount}>
              <span>({localeSettings[currentLocale].whiteList.form.notSelected})</span>
            </div>
          )}
        </div>
        <div className={styles.checkboxWrapperCount}>
          <Checkbox
            label={localeSettings[currentLocale].apiKey.form.checkbox.assetType}
            checked={isAssetType}
            onChange={(): void => changeCheckbox('assetType', !isAssetType)}
          />
          {isAssetType &&
            !assetType.firewall &&
            !assetType.host &&
            !assetType.image &&
            !assetType.router &&
            !assetType.switch && (
              <div className={styles.checkboxCount}>
                <span>({localeSettings[currentLocale].whiteList.form.notSelected})</span>
              </div>
            )}
        </div>
        {types && isAssetType && <UsedAssetType types={types} />}
      </div>
    </div>
  );
}
