import { memo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BoundaryErrorComponent } from '../../../common/BoundaryErrorComponent';
import { CronCalculator } from '../../../common/cronCalculator/CronCalculator';

function CronTask({
  label,
  chooseAssetTimeZone,
}: {
  label: string;
  chooseAssetTimeZone?: boolean;
}): React.ReactElement {
  return (
    <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
      <CronCalculator label={label} chooseAssetTimeZone={chooseAssetTimeZone} />
    </ErrorBoundary>
  );
}

export default memo(CronTask);
