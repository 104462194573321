import type { GridCellProps } from '@progress/kendo-react-grid';
import type { TreeListCellProps } from '@progress/kendo-react-treelist';
import type { VulnerableSoftwareListDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/vulnerableSoftwareListDashboardResponse.v1';
import { useSoftwaresContext } from '../../../../hooks/useSoftwaresContext';
import { Button } from '../../../common/baseElements/Button';

export function CellTotalVulnerabilities(
  props: GridCellProps | TreeListCellProps,
): React.ReactElement {
  const { setSoftwaresModalData } = useSoftwaresContext();
  const { dataItem, className, level } = props;

  if (Array.isArray(level) && level.length <= 1) return <td />;

  const { assets, name, softwareId }: VulnerableSoftwareListDashboardResponse['data'][number] =
    dataItem;

  const bulletinIds = new Set<string>();
  for (let i = 0; i < assets.length; i++) {
    const asset = assets[i];
    if ('bulletins' in asset && asset.bulletins?.length) {
      asset.bulletins.forEach((bulletin) => {
        bulletinIds.add(bulletin.id);
      });
    }
  }

  const handleModal = (): void => {
    setSoftwaresModalData({
      softwareId,
      softwareName: name,
      defaultTabId: 2,
      fixes: dataItem.mergedUpdates,
      isVulnerableFlag: true,
    });
  };

  return (
    <td>
      <Button fill="modal" onClick={handleModal}>
        <span>{new Set(bulletinIds).size}</span>
      </Button>
    </td>
  );
}
