/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useEffect, useState } from 'react';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { useHistory } from 'react-router';
import { LicenseComponent } from './License';
import { UpdateTab } from './UpdateTab';
import { SystemTab } from './SystemTab';
import { SmtpTab } from './SmtpTab';
import localeSettings from '../../../utils/i18n/settingsLocales/settings.json';
import type { ConfigResponse } from '../../../types/__generated/on-premise-solution/api/configResponse.v1';
import styles from './Settings.module.scss';
import { TabApiKeyList } from './ApiKeys/TabApiKeyList';
import { getUserData } from '../../../services/local-storage-service';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;

export function TabsSettings({ data }: { data: ConfigResponse | undefined }): React.ReactElement {
  const currentUser = getUserData();

  const history = useHistory();

  const [selected, setSelected] = useState<number>(0);

  const handleSelect = useCallback(
    (e: TabStripSelectEventArguments): void => {
      if (e.selected === 0 && !document.location.pathname.includes('/license')) {
        history.replace('/lk/settings/license');
        setSelected(e.selected);
      }
      if (e.selected === 1 && !document.location.pathname.includes('/update')) {
        history.replace('/lk/settings/update');
        setSelected(e.selected);
      }
      if (e.selected === 2 && !document.location.pathname.includes('/system')) {
        history.replace('/lk/settings/system');
        setSelected(e.selected);
      }
      if (e.selected === 3 && !document.location.pathname.includes('/smtp')) {
        history.replace('/lk/settings/smtp');
        setSelected(e.selected);
      }
      if (e.selected === 4 && !document.location.pathname.includes('/tokens')) {
        history.replace('/lk/settings/tokens');
        setSelected(e.selected);
      }
      if (currentUser?.role === 'account_admin' && document.location.pathname.includes('/tokens')) {
        history.replace('/lk/settings/tokens');
        setSelected(2);
      }
      if (e.selected === 5 && !document.location.pathname.includes('/whitelist')) {
        history.replace('/lk/settings/whitelists');
        setSelected(e.selected);
      }
      if (
        currentUser?.role === 'account_admin' &&
        document.location.pathname.includes('/whitelist')
      ) {
        history.replace('/lk/settings/whitelists');
        setSelected(3);
      }
      setSelected(e.selected);
    },
    [currentUser?.role, history],
  );

  useEffect(() => {
    if (document.location.pathname.includes('/license')) {
      setSelected(0);
    }
    if (document.location.pathname.includes('/update')) {
      setSelected(1);
    }
    if (document.location.pathname.includes('/system')) {
      setSelected(2);
    }
    if (document.location.pathname.includes('/smtp')) {
      setSelected(3);
    }
    if (document.location.pathname.includes('/token') && currentUser?.role !== 'account_admin') {
      setSelected(4);
    }
    if (document.location.pathname.includes('/token') && currentUser?.role === 'account_admin') {
      setSelected(2);
    }
    // if (
    //   document.location.pathname.includes('/whitelist') &&
    //   currentUser?.role !== 'account_admin'
    // ) {
    //   setSelected(5);
    // }
    // if (
    //   document.location.pathname.includes('/whitelist') &&
    //   currentUser?.role === 'account_admin'
    // ) {
    //   setSelected(3);
    // }
  }, [currentUser?.role, history, document.location.pathname]);

  return (
    <TabStrip selected={selected} onSelect={handleSelect} className={styles.content}>
      <TabStripTab title={localeSettings[currentLocale].tabs.license}>
        <LicenseComponent />
      </TabStripTab>
      <TabStripTab title={localeSettings[currentLocale].tabs.updates}>
        <UpdateTab serverUpdateData={data?.cloud?.api} dataUpdater={data?.['data-updater']} />
      </TabStripTab>
      {currentUser?.role === 'super_admin' && (
        <TabStripTab title={localeSettings[currentLocale].tabs.system}>
          <SystemTab api={data?.api} log={data?.log} />
        </TabStripTab>
      )}
      {currentUser?.role === 'super_admin' && (
        <TabStripTab title={localeSettings[currentLocale].tabs.smtp}>
          <SmtpTab smtp={data?.smtp} />
        </TabStripTab>
      )}
      {currentUser?.role !== 'user' && currentUser?.role !== 'reader_user' && (
        <TabStripTab title={localeSettings[currentLocale].tabs.api}>
          <TabApiKeyList />
        </TabStripTab>
      )}
      {/* {currentUser?.role !== 'user' && currentUser?.role !== 'reader_user' && (
        <TabStripTab title={localeSettings[currentLocale].tabs.whitelist}>
          <WhiteListTabEl />
        </TabStripTab>
      )} */}
    </TabStrip>
  );
}
