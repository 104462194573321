import type { GridCellProps } from '@progress/kendo-react-grid';
import { NavLink } from 'react-router-dom';
import type { TicketSystemDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/ticketSystemsDashboardResponse.v1';

export function CellOpenAllInTicketSystem(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { ticketSystemId }: TicketSystemDashboardResponse = dataItem;

  return (
    <td>
      {'openTicketCount' in dataItem ? (
        <NavLink
          to={`/lk/security/tickets?skip=0&limit=15&sortOrder=desc&openTicketCount=${dataItem.openTicketCount}&ticketSystemId=${ticketSystemId}`}
        >
          {dataItem.openTicketCount}
        </NavLink>
      ) : (
        <span>-</span>
      )}{' '}
      /{' '}
      {'totalTicketCount' in dataItem ? (
        <NavLink
          to={`/lk/security/tickets?skip=0&limit=15&sortOrder=desc&ticketSystemId=${ticketSystemId}`}
        >
          {dataItem.totalTicketCount}
        </NavLink>
      ) : (
        <span>-</span>
      )}
    </td>
  );
}
