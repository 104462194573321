/* eslint-disable react/jsx-pascal-case */
import { Field } from '@progress/kendo-react-form';
import { loadMessages } from '@progress/kendo-react-intl';
import localeCommonTable from '../../../../utils/i18n/commonLocales/table.json';
import locale from '../../../../utils/i18n/credentials/credentials.json';
import { FormInput } from '../../../common/form/FormInput';
import { checkLength } from '../../../../utils/helpers/validation';
import { useCredentialContext } from '../../../../hooks/useCredentialContext';
import { FormTextArea } from '../../../common/form/FormTextArea';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

loadMessages(localeCommonTable[currentLocale], currentLocale);

export function PrivateKeyForm(): React.ReactElement {
  const {
    username,
    setUsername,
    privateKey,
    setPrivateKey,
    passphrase,
    setPassphrase,
    credentialData,
    isEdit,
  } = useCredentialContext();

  return (
    <div className="form-content">
      <Field
        name="username"
        component={FormInput}
        id="username"
        label={locale[currentLocale].form.userName}
        onChange={(e): void => {
          setUsername(e.target.value);
        }}
        inputValue={username}
        className="form-field-wrapper"
        customValidator={checkLength}
        type="text"
        classForLabel={credentialData ? '' : 'required-label'}
      />
      <Field
        name="privateKey"
        id="privateKey"
        component={FormTextArea}
        label={locale[currentLocale].form.sshKey}
        rows={6}
        maxLength={4096}
        defaultValue={credentialData ? '***********' : ''}
        inputValue={privateKey}
        onChange={(e): void => setPrivateKey(e.value)}
        tooltipText={locale[currentLocale].form.helperKey}
        customValidator={!credentialData && !isEdit && checkLength}
        classForLabel={credentialData ? '' : 'required-label'}
      />
      <Field
        name="passphrase"
        component={FormInput}
        id="passphrase"
        label={locale[currentLocale].form.passphrase}
        onChange={(e): void => {
          setPassphrase(e.target.value);
        }}
        inputValue={passphrase}
        className="form-field-wrapper"
        customValidator={!credentialData && !isEdit && checkLength}
        type="text"
        classForLabel={credentialData ? '' : 'required-label'}
        tooltipText={locale[currentLocale].form.helperPhrase}
      />
    </div>
  );
}
