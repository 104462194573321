/* eslint-disable no-nested-ternary */
import React, {
  createContext,
  useState,
  useMemo,
  useContext,
  ReactElement,
  JSXElementConstructor,
  useEffect,
  useCallback,
} from 'react';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { useCommonContext } from './useCommonContext';
import type { IFilterVal } from '../utils/filtering-in-table-test';
import type { AssetDynamicGroupUpdateResponse } from '../types/__generated/on-premise-solution/api/assetDynamicGroupUpdateResponse.v1';
import type { ApiError } from '../types/__generated/on-premise-solution/api/apiError.v1';
import type { AssetDynamicGroupUpdateRequest } from '../types/__generated/on-premise-solution/api/assetDynamicGroupUpdateRequest.v1';
import { editDynamicGroup, showDynamicGroupAfterUsedFilters } from '../services/asset-service';
import { getUserData } from '../services/local-storage-service';
import type { AssetDynamicGroup } from '../types/__generated/on-premise-solution/api/assetDynamicGroupsDashboardResponse.v1';
import type { AssetDynamicGroupFiltersTestRequest } from '../types/__generated/on-premise-solution/api/assetDynamicGroupFiltersTestRequest.v1';
import type { AssetDynamicGroupFiltersTestResponse } from '../types/__generated/on-premise-solution/api/assetDynamicGroupFiltersTestResponse.v1';
import { useHostModalContext } from './useHostModalContext';
import type { TDynamicGroupForm } from '../utils/helpers/types';

interface IGroupData {
  name: string;
  description: string | undefined | null;
}

interface IInitialStateGroup {
  name: string;
  description?: string | undefined | null;
}

export interface IGroupContext {
  isEdit: boolean | null;
  setIsEdit: (v: boolean | null) => void;
  hostId: string | null;
  setHostId: (v: string | null) => void;
  isOpen: boolean;
  setIsOpen: (v: boolean) => void;
  groupData: IGroupData | null;
  setGroupData: (v: IGroupData | null) => void;
  groupName: string;
  setGroupName: (v: string) => void;
  groupDescription: string | undefined | null;
  setGroupDescription: (v: string | undefined | null) => void;
  initialStateGr: IInitialStateGroup | undefined | null;
  setInitialStateGr: (v: IInitialStateGroup | undefined | null) => void;
  errorMutation: string;
  setErrorMutation: (v: string) => void;
  errorMutationDynamicGrEdit: string;
  setErrorMutationDynamicGrEdit: (v: string) => void;
  mutationEditDynamicGroup: UseMutationResult<
    AssetDynamicGroupUpdateResponse,
    ApiError,
    AssetDynamicGroupUpdateRequest & {
      assetDynamicGroupId: string;
    },
    unknown
  >;
  prepareDataForDynamicGroup: (
    assetDynamicGroupId: string,
    name: string,
    description: string | null,
    filters: AssetDynamicGroup['filters'] | [],
  ) => Promise<void>;
  filterInDynamicGrInListForEdit: AssetDynamicGroup['filters'] | null;
  setFilterInDynamicGrInListForEdit: (v: AssetDynamicGroup['filters'] | null) => void;
  dynamicGroupId: string | undefined;
  setDynamicGroupId: React.Dispatch<React.SetStateAction<string | undefined>>;
  editFilterInDynamicGroup: (
    assetDynamicGroupId: string,
    filter0RestApi: IFilterVal[] | undefined,
    filter1RestApi: IFilterVal[] | undefined,
    filter2RestApi: IFilterVal[] | undefined,
    filter3RestApi: IFilterVal[] | undefined,
    filter4RestApi: IFilterVal[] | undefined,
    type: string,
  ) => Promise<void>;
  markForStringOfDynamicGr: string[] | null;
  setMarkForStringOfDynamicGr: React.Dispatch<React.SetStateAction<string[] | null>>;
  isRefreshAssets: boolean;
  setRefreshAssets: React.Dispatch<React.SetStateAction<boolean>>;
  dynamicGroupForm: TDynamicGroupForm[] | null;
  setDynamicGroupForm: React.Dispatch<React.SetStateAction<TDynamicGroupForm[] | null>>;
  deleteDynamicGroupFromTask: (
    assetDynamicGroupId: string,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;

  dataAsset: string[] | null;
  setDataAsset: React.Dispatch<React.SetStateAction<string[] | null>>;
  applyMutationDynamicGrEdit: boolean;
  setApplyMutationDynamicGrEdit: React.Dispatch<React.SetStateAction<boolean>>;
  statusDynamicGroupTest: boolean;
  setStatusDynamicGroupTest: React.Dispatch<React.SetStateAction<boolean>>;
  useLatestAssets: boolean;
  setUseLatestAssets: React.Dispatch<React.SetStateAction<boolean>>;
  isCronTask: boolean;
  setCronTask: React.Dispatch<React.SetStateAction<boolean>>;
}

const GroupContext = createContext<IGroupContext | null>(null);

export function GroupProvider({
  children,
}: {
  children: ReactElement<string | JSXElementConstructor<string>>;
}): React.ReactElement {
  const queryClient = useQueryClient();

  const { setOpenForm } = useCommonContext();

  const [groupData, setGroupData] = useState<IGroupData | null>(null);

  const [hostId, setHostId] = useState<string | null>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [isEdit, setIsEdit] = useState<boolean | null>(null);

  const [groupName, setGroupName] = useState('');

  const [groupDescription, setGroupDescription] = useState<string | undefined | null>('');

  const [initialStateGr, setInitialStateGr] = useState<IInitialStateGroup | undefined | null>(null);

  const [errorMutation, setErrorMutation] = useState('');

  const [errorMutationDynamicGrEdit, setErrorMutationDynamicGrEdit] = useState('');

  const [applyMutationDynamicGrEdit, setApplyMutationDynamicGrEdit] = useState(false);

  const [statusDynamicGroupTest, setStatusDynamicGroupTest] = useState(false);

  const [markForStringOfDynamicGr, setMarkForStringOfDynamicGr] = useState<string[] | null>(null);

  const [isRefreshAssets, setRefreshAssets] = useState(false);

  const [dynamicGroupForm, setDynamicGroupForm] = useState<IGroupContext['dynamicGroupForm']>(null);

  const [useLatestAssets, setUseLatestAssets] = useState(false);

  const [isCronTask, setCronTask] = useState(false);

  const [filterInDynamicGrInListForEdit, setFilterInDynamicGrInListForEdit] = useState<
    AssetDynamicGroup['filters'] | null
  >(null);

  const [dynamicGroupId, setDynamicGroupId] = useState<string | undefined>(undefined);

  const [dataAsset, setDataAsset] = useState<string[] | null>(null);

  // Mutations

  const mutationEditDynamicGroup = useMutation<
    AssetDynamicGroupUpdateResponse,
    ApiError,
    AssetDynamicGroupUpdateRequest & { assetDynamicGroupId: string }
  >((payload) => editDynamicGroup(payload), {
    onSuccess: () => {
      queryClient.invalidateQueries('hostDynamicGroupDescription');
      // queryClient.invalidateQueries('hostDynamicGroupDescriptionRefresh');
      setRefreshAssets(true);
      queryClient.invalidateQueries('HostDynamicGroupList');
      setErrorMutationDynamicGrEdit('');
      setStatusDynamicGroupTest(false);
    },
    onError: (resp) => {
      setErrorMutationDynamicGrEdit(resp.message);
      setApplyMutationDynamicGrEdit(false);
      setStatusDynamicGroupTest(false);
    },
  });

  const mutationEditDynamicGroupTest = useMutation<
    AssetDynamicGroupFiltersTestResponse,
    ApiError,
    AssetDynamicGroupFiltersTestRequest & { assetDynamicGroupId: string }
  >((payload) => showDynamicGroupAfterUsedFilters(payload), {
    onSuccess: (resp) => {
      // queryClient.invalidateQueries('hostDynamicGroupDescription');
      // setRefreshAssets(true);
      // queryClient.invalidateQueries('HostDynamicGroupList');
      setErrorMutationDynamicGrEdit('');
      setDataAsset(resp.latestAssets.assetIds);
      setApplyMutationDynamicGrEdit(true);
      setStatusDynamicGroupTest(false);
    },
    onError: (resp) => {
      setErrorMutationDynamicGrEdit(resp.message);
      setApplyMutationDynamicGrEdit(false);
      setStatusDynamicGroupTest(false);
    },
  });

  // Functions

  const editFilterInDynamicGroup = useCallback(
    async (
      assetDynamicGroupId: string,
      filter0RestApi: IFilterVal[] | undefined,
      filter1RestApi: IFilterVal[] | undefined,
      filter2RestApi: IFilterVal[] | undefined,
      filter3RestApi: IFilterVal[] | undefined,
      filter4RestApi: IFilterVal[] | undefined,
      type: string,
    ): Promise<void> => {
      const currentUser = getUserData();

      const filters = [];

      if (filter0RestApi) {
        const data = {
          filter: filter0RestApi, // Object.assign({}, ...filter0RestApi.map((f) => f)), // filter0RestApi, //
          addedBy: { source: 'user', id: currentUser?.id },
          addedAt: new Date().toISOString(),
        };
        filters.push(data);
      }
      if (filter1RestApi) {
        const data = {
          filter: filter1RestApi, // Object.assign({}, ...filter1RestApi.map((f) => f)),
          addedBy: { source: 'user', id: currentUser?.id },
          addedAt: new Date().toISOString(),
        };
        filters.push(data);
      }
      if (filter2RestApi) {
        const data = {
          filter: filter2RestApi, // Object.assign({}, ...filter2RestApi.map((f) => f)),
          addedBy: { source: 'user', id: currentUser?.id },
          addedAt: new Date().toISOString(),
        };
        filters.push(data);
      }
      if (filter3RestApi) {
        const data = {
          filter: filter3RestApi, // Object.assign({}, ...filter3RestApi.map((f) => f)),
          addedBy: { source: 'user', id: currentUser?.id },
          addedAt: new Date().toISOString(),
        };
        filters.push(data);
      }
      if (filter4RestApi) {
        const data = {
          filter: filter4RestApi, // Object.assign({}, ...filter4RestApi.map((f) => f)),
          addedBy: { source: 'user', id: currentUser?.id },
          addedAt: new Date().toISOString(),
        };
        filters.push(data);
      }

      const data = {
        assetDynamicGroupId,
        name: initialStateGr?.name !== groupData?.name ? groupData?.name : undefined,
        description:
          groupData?.description &&
          groupData?.description.length > 0 &&
          initialStateGr?.description !== groupData?.description
            ? groupData?.description
            : groupData?.description?.length === 0
            ? null
            : undefined,

        filters,
      };

      if (type === 'apply') {
        await mutationEditDynamicGroup.mutateAsync({
          accountId: localStorage.getItem('ai') as string,
          type: 'host',
          ...data,
        } as AssetDynamicGroupUpdateRequest & { assetDynamicGroupId: string });
      }
      if (type === 'temp') {
        await mutationEditDynamicGroupTest.mutateAsync({
          ...data,
        } as AssetDynamicGroupFiltersTestRequest & { assetDynamicGroupId: string });
      }
    },
    [
      groupData?.description,
      groupData?.name,
      initialStateGr?.description,
      initialStateGr?.name,
      mutationEditDynamicGroup,
      mutationEditDynamicGroupTest,
    ],
  );

  const prepareDataForDynamicGroup = useCallback(
    async (
      assetDynamicGroupId: string,
      name: string,
      description: string | null,
      filters: AssetDynamicGroup['filters'] | [],
    ): Promise<void> => {
      const data = {
        name: name || '',
        description,
      };
      setGroupData(data);
      setIsOpen(true);
      setIsEdit(true);
      setDynamicGroupId(assetDynamicGroupId);
      if (filters) setFilterInDynamicGrInListForEdit(filters);
    },
    [],
  );

  const deleteDynamicGroupFromTask = useCallback(
    (assetDynamicGroupId: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      event.stopPropagation();
      if (dynamicGroupForm && dynamicGroupForm.length > 0) {
        setDynamicGroupForm(
          dynamicGroupForm?.filter((gr) => gr.assetDynamicGroupId !== assetDynamicGroupId),
        );
      }
    },
    [dynamicGroupForm],
  );

  // useEffects

  useEffect(() => {
    if (groupData) {
      setGroupName(groupData.name);
      setGroupDescription(groupData.description);
      setInitialStateGr({ name: groupData?.name || '', description: groupData?.description });
    }
  }, [groupData]);

  const value = useMemo(
    () => ({
      isEdit,
      setIsEdit,
      hostId,
      setHostId,
      isOpen,
      setIsOpen,
      groupData,
      setGroupData,
      groupName,
      setGroupName,
      groupDescription,
      setGroupDescription,
      initialStateGr,
      setInitialStateGr,
      errorMutation,
      setErrorMutation,
      mutationEditDynamicGroup,
      editFilterInDynamicGroup,
      prepareDataForDynamicGroup,
      filterInDynamicGrInListForEdit,
      dynamicGroupId,
      setDynamicGroupId,
      setFilterInDynamicGrInListForEdit,
      markForStringOfDynamicGr,
      setMarkForStringOfDynamicGr,
      errorMutationDynamicGrEdit,
      setErrorMutationDynamicGrEdit,
      isRefreshAssets,
      setRefreshAssets,
      dynamicGroupForm,
      setDynamicGroupForm,
      deleteDynamicGroupFromTask,
      dataAsset,
      setDataAsset,
      applyMutationDynamicGrEdit,
      setApplyMutationDynamicGrEdit,
      statusDynamicGroupTest,
      setStatusDynamicGroupTest,
      useLatestAssets,
      setUseLatestAssets,
      isCronTask,
      setCronTask,
    }),
    [
      isEdit,
      hostId,
      isOpen,
      groupData,
      groupName,
      groupDescription,
      initialStateGr,
      errorMutation,
      mutationEditDynamicGroup,
      editFilterInDynamicGroup,
      prepareDataForDynamicGroup,
      filterInDynamicGrInListForEdit,
      dynamicGroupId,
      markForStringOfDynamicGr,
      errorMutationDynamicGrEdit,
      isRefreshAssets,
      dynamicGroupForm,
      deleteDynamicGroupFromTask,
      dataAsset,
      applyMutationDynamicGrEdit,
      statusDynamicGroupTest,
      useLatestAssets,
      setUseLatestAssets,
      isCronTask,
      setCronTask,
    ],
  );

  useEffect((): void => {
    setOpenForm(isOpen);
  }, [isOpen, setOpenForm]);

  return <GroupContext.Provider value={value}>{children}</GroupContext.Provider>;
}

export const useGroupContext = (): IGroupContext => {
  const context = useContext(GroupContext);
  if (context === null) {
    throw new Error('useGroupEditContext must be used inside the GroupContext.Provider.');
  }

  return context;
};
