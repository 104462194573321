import './TaskIDetails.module.scss';
import type { InventorizationResponse } from '../../../../types/__generated/on-premise-solution/api/inventorizationResponse.v1';
import { Os } from './iDetailsComponents/Os';
import { Route } from './iDetailsComponents/Route';
import { Interface } from './iDetailsComponents/Interface';
import { Users } from './iDetailsComponents/Users';
import { Groups } from './iDetailsComponents/Groups';
import { Mount } from './iDetailsComponents/Mount';
import { SystemInfo } from './iDetailsComponents/SystemInfo';
import { Containers } from './iDetailsComponents/Containers';
import { ContainerImages } from './iDetailsComponents/ContainerImages';
import { OpenPorts } from './iDetailsComponents/OpenPorts';
import { UsersNetDevices } from './iDetailsComponents/UsersNetDevices';
import { GroupNetDevices } from './iDetailsComponents/GroupNetDevices';
import { VlanCommonNetDevice } from './iDetailsComponents/VlanCommonNetDevice';
import { FileSystemNetDevices } from './iDetailsComponents/FileSystemNetDevices';
import { CdpNetDevice } from './iDetailsComponents/CdpNetDevice';
import { VlanMikrotikNetDevice } from './iDetailsComponents/VlanMikrotikNetDevice';
import { SoftPackageNetDevices } from './iDetailsComponents/SoftPackageNetDevices';
import { VlanJuniperNetDevice } from './iDetailsComponents/VlanJuniperNetDevice';
import { FirewallRules } from './iDetailsComponents/FirewallRules';
import { HypervisorVirtImage } from './iDetailsComponents/HypervisorVirtImage';
import { SoftwareListNetDevices } from './iDetailsComponents/SoftwareListNetDevices';
import { BiosInfo } from './iDetailsComponents/BiosInfo';

export function SystemIDetails({
  data,
}: {
  data: InventorizationResponse | undefined;
}): React.ReactElement {
  return (
    <div className="inventorization-system">
      {data && (
        <>
          {data.result.system_info && <SystemInfo data={data} />}
          {data.result.bios && <BiosInfo data={data} />}
          {data.result.os && <Os data={data} />}
          {(data.type === 'linux' || data.type === 'hypervisor') && data.result.mounts && (
            <Mount data={data} />
          )}
          {(data.type === 'router' || data.type === 'firewall' || data.type === 'switch') &&
            data.result.file_system && <FileSystemNetDevices data={data} />}
          {data.result.routes && <Route data={data} />}
          {data.result.interface_details && <Interface data={data} />}
          {data.result.users &&
            data.type !== 'router' &&
            data.type !== 'firewall' &&
            data.type !== 'switch' && <Users data={data} />}
          {data.result.users &&
            (data.type === 'router' || data.type === 'firewall' || data.type === 'switch') && (
              <UsersNetDevices data={data} />
            )}
          {data.result.groups &&
            data.type !== 'router' &&
            data.type !== 'firewall' &&
            data.type !== 'switch' && <Groups data={data} />}
          {data.result.groups &&
            (data.type === 'router' || data.type === 'firewall' || data.type === 'switch') && (
              <GroupNetDevices data={data} />
            )}
          {data.result.container_images && <ContainerImages data={data} />}
          {data.result.containers && <Containers data={data} />}
          {data.result.open_ports && <OpenPorts data={data} />}
          {((data.type === 'router' &&
            (data.class === 'cisco' || data.class === 'huawei' || data.class === 'eltex')) ||
            (data.type === 'switch' && (data.class === 'eltex' || data.class === 'aruba')) ||
            (data.type === 'firewall' && data.class === 'checkpoint')) &&
            data.result.vlans && <VlanCommonNetDevice data={data} />}
          {data.type === 'router' && data.class === 'juniper' && data.result.vlans && (
            <VlanJuniperNetDevice data={data} />
          )}
          {data.type === 'router' && data.class === 'mikrotik' && data.result.vlans && (
            <VlanMikrotikNetDevice data={data} />
          )}
          {(data.type === 'router' || data.type === 'firewall' || data.type === 'hypervisor') &&
            data.result.packages && <SoftPackageNetDevices data={data} />}
          {data.type === 'firewall' && data.class === 'checkpoint' && data.result.softwareList && (
            <SoftwareListNetDevices data={data} />
          )}
          {((data.type === 'router' && data.class === 'cisco') ||
            (data.type === 'switch' && data.class === 'aruba')) &&
            data.result.cdp && <CdpNetDevice data={data} />}
          {data.result.firewall_rules && <FirewallRules data={data} />}
          {data.type === 'hypervisor' && data.result.virtual_images && (
            <HypervisorVirtImage data={data} />
          )}
        </>
      )}
    </div>
  );
}
