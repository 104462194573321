import type { ReactElement } from 'react';
import { Filter } from '@progress/kendo-react-data-tools';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { getUrlParamsSoftList } from '../../../services/softManagement-service';
import type { IDataHooks } from '../../../utils/helpers/types';
import { CommonSoftwaresList } from '../../common/CommonSoftwaresList';
import localeFilters from '../../../utils/i18n/commonLocales/filters.json';
import { Input } from '../../common/baseElements/Input';
import { FILTER_ALL_SOFTWARELIST } from '../../../utils/helpers/constant-serv-filter-host';
import { useSoftwareInstalledList } from '../../../hooks/components/useSoftwareInstalledList';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeFilters;

export function InstalledSoftwareList({ osType }: { osType?: 'linux' | 'windows' }): ReactElement {
  const urlParams = getUrlParamsSoftList('SoftwareList', [
    { field: 'maxMetric.cvss.score', dir: 'desc' },
  ]);
  const dataForHooks: IDataHooks = {
    name: 'name',
    componentName: 'SoftwareInstalledList',
    moreData: osType,
  };

  const {
    filter,
    onFilterChange,
    sort,
    page,
    total,
    pageChange,
    setSort,
    data,
    handleCustomSearch,
    customSearch,
  } = useSoftwareInstalledList(urlParams, dataForHooks);

  return (
    <div>
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
          >
            <div className="filter-line" style={{ flex: 'fit-content' }}>
              <Filter
                value={filter}
                onChange={onFilterChange}
                fields={
                  osType === 'windows'
                    ? FILTER_ALL_SOFTWARELIST[currentLocale].filter((f) => f.name !== 'arch')
                    : FILTER_ALL_SOFTWARELIST[currentLocale]
                }
              />
              {filter.filters.length === 0 && (
                <Input
                  name="customSearch"
                  placeholder={`${localeFilters[currentLocale].customSerachBy} ${
                    osType === 'windows'
                      ? localeFilters[currentLocale].customSearchFields.software
                      : localeFilters[currentLocale].customSearchFields.package
                  }`}
                  value={customSearch}
                  onChange={(e): void => handleCustomSearch(e)}
                />
              )}
            </div>
            {/* <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              export to excel
            </div> */}
          </div>
        </IntlProvider>
      </LocalizationProvider>
      <CommonSoftwaresList
        data={data}
        sort={sort}
        page={page}
        total={total}
        pageChange={pageChange}
        setSort={setSort}
        dataForHooks={dataForHooks}
        conditionSort={15}
      />
    </div>
  );
}
