import {
  Grid,
  GridColumn as Column,
  GridSortChangeEvent,
  GridRowProps,
} from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React from 'react';
import { PAGEABLE_DATA } from '../../utils/helpers/constants';
import localeUser from '../../utils/i18n/userLocales/userList.json';
import type { ICommonUserList } from '../../utils/helpers/types';
import { handleSort } from '../../utils/sorting-in-table';
import { CellUserMenu } from '../features/User/Cells/CellUserMenu';
import { CellSelectUser } from './CellSelectUser';
import { CellCommonDate } from './baseElements/CellCommonDate';
import { StandartCell } from './baseElements/StandartCell';
import { getUserData } from '../../services/local-storage-service';
import { CellAuth } from '../features/User/Cells/CellAuth';
import { TypeCell } from './cells/TypeCell';
import { CellUserRole } from '../features/User/Cells/CellUserRole';
import { RoleHeaderCell } from '../features/User/Cells/RoleHeaderCell';
import { StringWithTfaCell } from '../features/User/Cells/StringWithTfaCell';
import { CellUserListRadioBtn } from '../features/Security/Tickets/cells/CellUserListRadioBtn';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeUser;

export function CommonUserList(props: ICommonUserList): React.ReactElement {
  const { sort, data, page, total, pageChange, setSort, dataForHooks, parentComponent } = props;

  const currentUser = getUserData();

  const rowRender = (
    trElement: React.ReactElement<HTMLTableRowElement>,
    values: GridRowProps,
  ): React.ReactElement => {
    const notActive = !!values.dataItem.isDisabled;

    const trProps: any = { className: notActive ? 'row-grid-table' : '' };

    return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
  };

  return (
    <LocalizationProvider language={currentLocale}>
      <IntlProvider locale={currentLocale.substring(0, 2)}>
        <Grid
          pageable={total && total > 15 ? PAGEABLE_DATA : false}
          sortable
          data={data}
          sort={sort}
          scrollable="none"
          skip={page.skip}
          take={page.take}
          total={total}
          onPageChange={pageChange}
          onSortChange={(e: GridSortChangeEvent): void => handleSort(e, setSort)}
          rowRender={rowRender}
          className={total && total < 15 ? 'no-pageable-k-grid table users' : 'table users'}
        >
          {dataForHooks.componentName === 'AddUserList' && parentComponent !== 'addTicket' && (
            <Column field="addUser" title=" " cell={CellSelectUser} width="40px" />
          )}
          {parentComponent === 'addTicket' && (
            <Column field="addUser" title=" " cell={CellUserListRadioBtn} width="40px" />
          )}
          <Column
            field="name"
            title={localeUser[currentLocale].titleForColumnGrid.name}
            filter="text"
            width={
              parentComponent === 'addTicket' || parentComponent === 'addTask' ? '400px' : '260px'
            }
            cell={StringWithTfaCell}
          />
          {(dataForHooks.componentName === 'UserList' ||
            dataForHooks.componentName === 'UserListForServer') && (
            <Column field="userId" title="ID" filter="text" width="290px" />
          )}
          {(currentUser?.role === 'super_admin' || currentUser?.role === 'account_admin') && (
            <Column
              field="auth"
              title={localeUser[currentLocale].titleForColumnGrid.login}
              filter="text"
              width={
                parentComponent === 'addTicket' || parentComponent === 'addTask' ? '400px' : '200px'
              }
              cell={CellAuth}
              sortable={false}
            />
          )}
          <Column
            field="email"
            title={localeUser[currentLocale].titleForColumnGrid.email}
            filter="text"
            width={dataForHooks.componentName === 'AddUserList' ? 'auto' : '200px'}
            cell={StandartCell}
          />
          {(dataForHooks.componentName === 'UserList' ||
            dataForHooks.componentName === 'UserListForServer') && (
            <Column
              field="role"
              title={localeUser[currentLocale].titleForColumnGrid.role}
              filter="text"
              width="100px"
              cell={CellUserRole}
              headerCell={RoleHeaderCell}
            />
          )}
          {dataForHooks.componentName === 'UserList' && (
            <Column
              field="type"
              title={localeUser[currentLocale].titleForColumnGrid.type}
              filter="text"
              width="60px"
              cell={TypeCell}
            />
          )}
          {(dataForHooks.componentName === 'UserList' ||
            dataForHooks.componentName === 'UserListForServer') && (
            <Column
              cell={CellCommonDate}
              field="createdAt"
              title={localeUser[currentLocale].titleForColumnGrid.createdAt}
              filter="date"
              width="150px"
            />
          )}
          {(dataForHooks.componentName === 'UserList' ||
            dataForHooks.componentName === 'UserListForServer') && (
            <Column
              cell={CellCommonDate}
              field="lastActivityTime"
              title={localeUser[currentLocale].titleForColumnGrid.lastActivity}
              filter="date"
              width="204px"
            />
          )}
          {(dataForHooks.componentName === 'UserList' ||
            dataForHooks.componentName === 'UserListForServer') && (
            <Column
              cell={StandartCell}
              field="account.name"
              title={localeUser[currentLocale].titleForColumnGrid.account}
              filter="text"
            />
          )}
          {(dataForHooks.componentName === 'UserList' ||
            dataForHooks.componentName === 'UserListForServer') && (
            <Column cell={CellUserMenu} width="40px" />
          )}
        </Grid>
      </IntlProvider>
    </LocalizationProvider>
  );
}
