import type { GridCellProps } from '@progress/kendo-react-grid';
import { ticketActionLocale } from '../../../../../utils/helpers/constants';
import type { TicketDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/ticketsDashboardResponse.v1';

export function CellTicketAction(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { type }: TicketDashboardResponse = dataItem;

  return (
    <td>
      {type && typeof type === 'string' && type in ticketActionLocale ? (
        <span>{ticketActionLocale[type]}</span>
      ) : (
        <span>{type}</span>
      )}
    </td>
  );
}
