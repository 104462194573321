import type { GridCellProps } from '@progress/kendo-react-grid';
import { useQuery, useQueryClient } from 'react-query';
import { useState } from 'react';
import { Button } from '../baseElements/Button';
import localeAssetGroup from '../../../utils/i18n/assetLocales/assetGroups.json';
import localeMaintenance from '../../../utils/i18n/maintenanceWindows/maintenanceWindowsList.json';
import locale from '../../../utils/i18n/assetLocales/assetDescriptionGroup.json';
import { useGroupHook } from '../../../hooks/components/useGroupHook';
import { useTaskForGroup } from '../../../hooks/components/useTaskForGroup';
import { getUserData } from '../../../services/local-storage-service';
import type { EnrichedAssetGroupResponse } from '../../../types/__generated/on-premise-solution/api/enrichedAssetGroupResponse.v1';
import { DeleteConfirmModal } from '../modal/DeleteConfirmModal';
import { useGroupContext } from '../../../hooks/useGroupContext';
import type { AssetDynamicGroupAssetsRefreshResponse } from '../../../types/__generated/on-premise-solution/api/assetDynamicGroupAssetsRefreshResponse.v1';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
import { getDynamicGroupDescriptionRefresh } from '../../../services/asset-service';
import { useMaintenanceWindowsContext } from '../../../hooks/useMaintenanceWindowsContext';
import { useTicketContext } from '../../../hooks/useTicketContext';
import { useHostModalContext } from '../../../hooks/useHostModalContext';
import { useAccountModalContext } from '../../../hooks/useAccountModalContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAssetGroup;

export function CellMenuGroup(props: GridCellProps): React.ReactElement {
  const currentUser = getUserData();

  const queryClient = useQueryClient();

  const { dataItem } = props;

  const [assetDynamicGroupId, setAssetDynamicGroupId] = useState<string | null>(null);

  const { name, description, assetGroupId, assetCount, account }: EnrichedAssetGroupResponse =
    dataItem;

  const {
    isConfirm,
    setConfirm,
    editGroup,
    handleConfirm,
    handleDeleteDynamicGr,
    handleDelete,
    isError,
    errorMessage,
  } = useGroupHook(name, description, assetGroupId);

  const { prepareDataForDynamicGroup, setMarkForStringOfDynamicGr } = useGroupContext();

  const createTaskForGroup = useTaskForGroup(
    name,
    dataItem.assetGroupId ? assetGroupId : dataItem.assetDynamicGroupId || '',
    dataItem.assetGroupId ? 'static' : 'dynamic',
  );

  const { entityId, entityName, entityType, setTableOpen, setMaintenanceWindowsList } =
    useMaintenanceWindowsContext();

  useQuery<AssetDynamicGroupAssetsRefreshResponse, ApiError>(
    ['hostDynamicGroupDescriptionRefresh'],
    () => getDynamicGroupDescriptionRefresh(assetDynamicGroupId || ''),
    {
      keepPreviousData: true,
      onSuccess: (resp) => {
        // TODO: setMarkForStringOfDynamicGr(resp.assetDynamicGroupId);
        setMarkForStringOfDynamicGr((prevState) => [
          ...(prevState || []),
          resp.assetDynamicGroupId,
        ]);
        queryClient.invalidateQueries('HostDynamicGroupList');
        setAssetDynamicGroupId(null);
      },
      enabled: !!assetDynamicGroupId,
    },
  );

  const handleMaintenance = (): void => {
    setTableOpen(true);
    entityId.current = dataItem.assetFroupId;
    entityName.current = dataItem.name;
    entityType.current = 'group';
    setMaintenanceWindowsList(dataItem.maintenanceWindows);
  };

  const refreshAssetDynamicGroup = (): void => {
    if (dataItem.assetDynamicGroupId) {
      setAssetDynamicGroupId(dataItem.assetDynamicGroupId);
    }
  };

  const { setAddTicket } = useTicketContext();

  const { setHostForm } = useHostModalContext();
  const { setDynamicGroupForm } = useGroupContext();
  const { setAccountId } = useAccountModalContext();

  const createTicket = (): void => {
    setAccountId({ id: account.accountId || '', name: account.name });
    if (document.location.pathname.includes('assets/groups/static')) {
      setAddTicket(true);
      setHostForm([
        {
          assetGroupId: dataItem.assetGroupId,
          name: dataItem.name ? dataItem.name : dataItem.assetGroupId,
        },
      ]);
    } else {
      setAddTicket(true);
      setDynamicGroupForm([
        {
          assetDynamicGroupId: dataItem.assetDynamicGroupId,
          name: dataItem.name ? dataItem.name : dataItem.assetDynamicGroupId,
        },
      ]);
    }
  };

  return (
    <>
      {isConfirm && (
        <DeleteConfirmModal
          name={name}
          type="group"
          onClose={(): void => setConfirm(false)}
          handleDelete={
            document.location.pathname.includes('groups/static')
              ? (): void => handleDelete(assetGroupId)
              : (): void => handleDeleteDynamicGr(dataItem.assetDynamicGroupId)
          }
          errorMessage={isError ? errorMessage : undefined}
          countInside={assetCount}
        />
      )}
      <td className="cell_menu">
        <div className="groups__menu">
          <div className="dropdown__container">
            <span className="k-icon k-i-more-vertical" />
            <div className="dropdown__content">
              <Button
                fill="menu"
                onClick={(): void => {
                  createTaskForGroup('default');
                }}
              >
                {locale[currentLocale].btns.btnCreateTask}
              </Button>
              {document.location.pathname.includes('groups/dynamic') && (
                <Button fill="menu" onClick={refreshAssetDynamicGroup}>
                  {locale[currentLocale].btns.refreshAssets}
                </Button>
              )}
              {currentUser?.role !== 'reader_user' && (
                <Button
                  fill="menu"
                  onClick={
                    document.location.pathname.includes('groups/static')
                      ? (): void => editGroup()
                      : (): Promise<void> =>
                          prepareDataForDynamicGroup(
                            dataItem.assetDynamicGroupId,
                            dataItem.name,
                            dataItem.description,
                            dataItem.filters,
                          )
                  }
                  disabled={
                    currentUser?.role === 'user' && currentUser.id !== dataItem.createdBy.id
                  }
                >
                  {localeAssetGroup[currentLocale].menu.btnEdit}
                </Button>
              )}
              {/* {document.location.pathname.includes('groups/static') && (
                <Button fill="menu" onClick={handleMaintenance}>
                  {localeMaintenance[currentLocale].maintenanceWindows}
                </Button>
              )} */}
              <Button fill="menu" onClick={createTicket}>
                {localeAssetGroup[currentLocale].menu.createTicket}
              </Button>
              {currentUser?.role !== 'reader_user' && (
                <Button
                  fill="menu"
                  onClick={handleConfirm}
                  disabled={
                    currentUser?.role === 'user' && currentUser.id !== dataItem.createdBy.id
                  }
                >
                  {localeAssetGroup[currentLocale].menu.btnDelete}
                </Button>
              )}
            </div>
          </div>
        </div>
      </td>
    </>
  );
}
