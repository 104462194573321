import { nanoid } from 'nanoid';
import { Button } from '../baseElements/Button';
import localeNewTask from '../../../utils/i18n/taskLocale/newTask.json';
import { useNetDeviceContext } from '../../../hooks/useNetDeviceContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeNewTask;

export function DeviceMutableBlock(): React.ReactElement {
  const { setAddDevice, deviceForm, deleteDeviceFromTask } = useNetDeviceContext();

  return (
    <div className="apply-assets">
      <div className="title-apply-assets">
        <span>{localeNewTask[currentLocale].form.applyForDevice.title}</span>
        <Button onClick={(): void => setAddDevice(true)} type="button">
          {localeNewTask[currentLocale].form.AddNetworkDevice}
        </Button>
      </div>
      <div className="list-assets">
        {deviceForm?.map((d) => {
          return (
            <Button
              type="button"
              fill="task_asset"
              onClick={(e): void => deleteDeviceFromTask(d.assetId, e)}
              key={nanoid()}
            >
              {d.hostname || d.assetId}
            </Button>
          );
        })}
        {(deviceForm?.length === 0 || deviceForm === null) && (
          <span className="placeholder">
            {localeNewTask[currentLocale].form.applyForDevice.selectDevicePlaceholder}
          </span>
        )}
      </div>
    </div>
  );
}
