/* eslint-disable no-nested-ternary */
import { useLocation, useParams } from 'react-router';
import { UseQueryResult, useQuery } from 'react-query';
import { useEffect, useRef } from 'react';
import type {
  AssetFirewallDashboardResponse,
  AssetHypervisorDashboardResponse,
  AssetRouterDashboardResponse,
  AssetSwitchDashboardResponse,
} from '../../types/__generated/on-premise-solution/api/assetsDevicesDashboardResponse.v1';
import {
  getDeviceFirewall,
  getDeviceHypervisor,
  getDeviceRouter,
  getDeviceSwitch,
} from '../../services/dashboard-service';
import type { ApiError } from '../../types/__generated/on-premise-solution/api/apiError.v1';
import type { InventorizationResponse } from '../../types/__generated/on-premise-solution/api/inventorizationResponse.v1';
import { getTaskInventorizationId } from '../../services/task-service';
import { useCommonContext } from '../useCommonContext';
import { useMaintenanceWindowsContext } from '../useMaintenanceWindowsContext';

interface IUseDeviceDescription {
  queryDashboardAssetId: UseQueryResult<
    | AssetRouterDashboardResponse
    | AssetFirewallDashboardResponse
    | AssetSwitchDashboardResponse
    | AssetHypervisorDashboardResponse,
    ApiError
  >;
  queryInventorizationId: UseQueryResult<InventorizationResponse, ApiError>;
  devicename: string | undefined;
}
export function useDeviceDescription(): IUseDeviceDescription {
  const devicename = useRef<string | undefined>(undefined);

  const { assetId } = useParams<{ assetId: string }>();

  const { pathname } = useLocation();

  const { setAccountOfAsset, setAssetId } = useCommonContext();

  const { entityName, setMaintenanceWindowsList } = useMaintenanceWindowsContext();

  const assetType: 'firewall' | 'router' | 'switch' = pathname
    .replace('/lk/assets/devices/', '')
    .split('/')[0] as 'firewall' | 'router' | 'switch';

  useEffect(() => {
    if (assetId) {
      setAssetId(assetId);
    }
  }, []);

  const getFunction = (): Promise<
    | AssetRouterDashboardResponse
    | AssetFirewallDashboardResponse
    | AssetSwitchDashboardResponse
    | AssetHypervisorDashboardResponse
  > =>
    assetType === 'firewall'
      ? getDeviceFirewall(assetId)
      : assetType === 'router'
      ? getDeviceRouter(assetId)
      : assetType === 'switch'
      ? getDeviceSwitch(assetId)
      : getDeviceHypervisor(assetId);

  const queryDashboardAssetId = useQuery<
    | AssetRouterDashboardResponse
    | AssetFirewallDashboardResponse
    | AssetSwitchDashboardResponse
    | AssetHypervisorDashboardResponse,
    ApiError
  >(['deviceAssetId', assetId], getFunction, {
    onSuccess: (resp) => {
      devicename.current = resp.latestInventorization?.hostname || undefined;
      entityName.current = resp.latestInventorization?.hostname || undefined;
      setAccountOfAsset(resp.account);
      setMaintenanceWindowsList(resp.maintenanceWindows);
    },
    enabled: !!(assetId && document.location.pathname.includes('/assets/devices/')),
  });

  const queryInventorizationId = useQuery<InventorizationResponse, ApiError>(
    ['inventorizationId', queryDashboardAssetId.data?.latestInventorization?.inventorizationId],
    () =>
      getTaskInventorizationId(
        queryDashboardAssetId.data?.latestInventorization?.inventorizationId || undefined,
      ),
    {
      keepPreviousData: true,
      enabled: !!(
        queryDashboardAssetId.data?.latestInventorization?.inventorizationId || undefined
      ),
    },
  );

  return {
    queryDashboardAssetId,
    queryInventorizationId,
    devicename: devicename.current,
  };
}
