/* eslint-disable react/jsx-props-no-spreading */
import type { TreeListCellProps } from '@progress/kendo-react-treelist';
import type { ReactElement } from 'react';
import { Button } from '../../../common/baseElements/Button';
import { useSoftwaresContext } from '../../../../hooks/useSoftwaresContext';

function ExpandItem(props: TreeListCellProps): ReactElement {
  const { dataItem, level, onExpandChange, expanded, hasChildren } = props;

  // eslint-disable-next-line no-nested-ternary
  const iconName = hasChildren
    ? expanded
      ? 'k-i-arrow-60-down'
      : 'k-i-arrow-60-right'
    : 'k-i-none';

  return (
    <>
      {/* {level.map(() => (
          <span className="k-icon tree-line-icon" />
        ))} */}
      {level.map((l, ind) => {
        let verticalLineHeight = '40px';
        if (ind === level.length - 1 && dataItem.isLastElement[ind]) verticalLineHeight = '20px';
        else if (dataItem.isLastElement[ind]) verticalLineHeight = '0';

        return (
          <>
            <span
              style={{
                position: 'absolute',
                height: verticalLineHeight,
                width: '24px',
                top: '0',
              }}
              className={`k-icon tree-line-icon level_${ind}`}
            />
            <span
              style={{
                width: '24px',
                display: 'inline-block',
              }}
            />
            {ind === level.length - 1 && (
              <span
                className="k-icon tree-line-horizontal-icon"
                style={{
                  position: 'absolute',
                  height: '40px',
                  top: '0',
                  left: `${(ind + 1) * 24}px`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  width: hasChildren ? '12px' : '28px',
                }}
              />
            )}
          </>
        );
      })}
      {/* {level.length === 1 && (
          <span
            className="k-icon tree-line-horizontal-icon"
            style={{
              position: 'absolute',
              height: '40px',
              top: '0',
              left: '22px',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              width: hasChildren ? '12px' : '28px',
            }}
          />
        )} */}
      <span
        className={`k-icon ${iconName}`}
        style={{
          padding: '4px',
          // marginLeft: `${(level.length - 1) * 20}px`,
          cursor: hasChildren ? 'pointer' : 'default',
        }}
        onClick={(e): void => onExpandChange(e, dataItem, level)}
      />
    </>
  );
}

export function CellSoftwareNameOrVersion(props: TreeListCellProps): ReactElement {
  const { dataItem, className, level } = props;
  const { softwareId, name, version } = dataItem;

  const { setSoftwaresModalData } = useSoftwaresContext();
  const handleModal = (): void => {
    setSoftwaresModalData({
      softwareId,
      softwareName: name,
      fixes: dataItem.mergedUpdates,
      isVulnerableFlag: dataItem.isVulnerableFlag,
    });
  };

  return (
    <td
      style={{
        position: 'relative',
      }}
    >
      {'expandable' in props && <ExpandItem {...props} />}
      {level.length > 1 ? (
        <Button fill="modal" onClick={handleModal}>
          <span>{version}</span>
        </Button>
      ) : (
        name
      )}
    </td>
  );
}
