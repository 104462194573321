import type { GridCellProps } from '@progress/kendo-react-grid';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useState } from 'react';
import { Button } from '../../../../common/baseElements/Button';
import localeTaskDescription from '../../../../../utils/i18n/taskLocale/taskDescription.json';
import { saveLogString2Json } from '../../../../../utils/helpers/task-helpers';
import type { LogData } from '../../../../../utils/helpers/types';
import styles from '../../TaskDescription.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeTaskDescription;

export function CellLogsMenu(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const [copied, setCopied] = useState(false);

  const logData = {
    createdAt: dataItem.createdAt,
    level: dataItem.level,
    assetId: dataItem.asset && dataItem.asset.assetId ? dataItem.asset.assetId : '',
    message: dataItem.message,
    action: dataItem.taskAction && dataItem.taskAction.type ? dataItem.taskAction.type : '',
  } as LogData;

  const handleCopied = (): void => {
    setCopied(true);

    const timeout = setTimeout(() => {
      setCopied(false);
      clearTimeout(timeout);
    }, 590);
  };

  return (
    <td className={`${styles.logMenuColumn} cell_menu`}>
      <div className="task__menu">
        <div className="dropdown__container">
          <span className="k-icon k-i-more-vertical" />
          {!copied && (
            <div className="dropdown__content">
              <Button
                fill="menu"
                onClick={(): void =>
                  saveLogString2Json(
                    logData,
                    (dataItem.task && dataItem.task.name) || dataItem.ticketId,
                  )
                }
              >
                {localeTaskDescription[currentLocale].exportToJSON}
              </Button>
              <CopyToClipboard text={dataItem.message || ''} onCopy={handleCopied}>
                <Button fill="menu">{localeTaskDescription[currentLocale].copyToClipboard}</Button>
              </CopyToClipboard>
            </div>
          )}
        </div>
      </div>
      {copied && (
        <div className={styles.logCopiedInfoWrap}>
          <span>{localeTaskDescription[currentLocale].copied}</span>
        </div>
      )}
    </td>
  );
}
