/* eslint-disable react/jsx-pascal-case */
import { useEffect, useRef, useState, type ReactElement } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { extendDataItem, mapTree } from '@progress/kendo-react-common';
import { filterBy } from '@progress/kendo-react-data-tools';
import {
  TreeList,
  TreeListColumnProps,
  TreeListExpandChangeEvent,
} from '@progress/kendo-react-treelist';
import { BoundaryErrorComponent } from '../../common/BoundaryErrorComponent';
import localeSensor from '../../../utils/i18n/sensorLocales/sensor.json';
import { useSensorList } from '../../../hooks/components/useSensorList';
import { INITIAL_SORT } from '../../../utils/helpers/constants';
import { getUrlList } from '../../../utils/helpers/getUrl-list-helper';
import type { IDataHooks } from '../../../utils/helpers/types';
import { StandartCell } from '../../common/baseElements/StandartCell';
import { CellSensorDomain } from './cells/CellSensorDomain';
import { CellSensorStatus } from './cells/CellSensorStatus';
import { CellCommonDate } from '../../common/baseElements/CellCommonDate';
import { getUserData } from '../../../services/local-storage-service';
import { CellSensorMenu } from './cells/CellSensorMenu';
import type { SensorsDashboardResponseTree } from '../../../types/__generated/on-premise-solution/api/sensorsDashboardResponseTree.v1';
import { CellSensorName } from './cells/CellSensorName';
import { SensorStatusHeaderCell } from './tables/SensorStatusHeaderCell';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSensor;

function setLastItems(
  sensorLevelList:
    | SensorsDashboardResponseTree['data']
    | Exclude<SensorsDashboardResponseTree['data'][0]['children'], undefined>,
  parentsIsLast?: boolean[],
): SensorsDashboardResponseTree['data'] & { isLastElement?: boolean[] } {
  return sensorLevelList.map((sensor, ind) => {
    const isLast = ind === sensorLevelList.length - 1;
    const isLastElement = parentsIsLast ? parentsIsLast.concat(isLast) : [isLast];

    return sensor.children
      ? {
          ...sensor,
          children: setLastItems(
            sensor.children as Exclude<
              SensorsDashboardResponseTree['data'][0]['children'],
              undefined
            >,
            isLastElement,
          ),
          isLastElement,
        }
      : { ...sensor, isLastElement };
  }) as SensorsDashboardResponseTree['data'] & { isLastElement?: boolean[] };
}

function getSensorsIdWithChildren(
  tree: SensorsDashboardResponseTree['data'][0]['children'],
): string[] {
  const mp = tree?.filter((sensor) => sensor.children?.length);
  if (mp?.length)
    return mp
      .map((sensor) => sensor.sensorId)
      .concat(
        getSensorsIdWithChildren(
          mp.reduce(
            (acc, sensor) =>
              acc.concat(
                (sensor.children || []) as Exclude<
                  SensorsDashboardResponseTree['data'][0]['children'],
                  undefined
                >,
              ),
            [] as Exclude<SensorsDashboardResponseTree['data'][0]['children'], undefined>,
          ),
        ),
      );

  return [];
}

export function SensorTreeList(): ReactElement {
  const currentUser = getUserData();

  const urlParams = getUrlList('SensorTreeList', INITIAL_SORT);

  const dataForHooks: IDataHooks = {
    name: 'name',
    componentName: 'SensorTreeList',
  };

  const { queryTree } = useSensorList(urlParams, dataForHooks);
  const isFirstUpdated = useRef<boolean>(false);

  const [state, setState] = useState({
    data: queryTree.data?.data ? setLastItems(queryTree.data.data) : [],
    filter: [],
    expanded: queryTree.data?.data.length
      ? getSensorsIdWithChildren(queryTree.data?.data.filter((sensor) => sensor.children?.length))
      : [],
  });

  const subItemsField = 'children';
  const expandField = 'expanded';

  useEffect(() => {
    setState({
      data: queryTree.data?.data ? setLastItems(queryTree.data.data) : [],
      filter: [],
      expanded: isFirstUpdated.current
        ? state.expanded
        : getSensorsIdWithChildren(
            queryTree.data?.data.filter((sensor) => sensor.children?.length),
          ),
    });
    if (!isFirstUpdated.current && queryTree.data?.data) isFirstUpdated.current = true;
  }, [queryTree.data?.data]);

  const columns: TreeListColumnProps[] = [
    {
      field: 'name',
      title: localeSensor[currentLocale].mainList.name,
      expandable: true,
      cell: CellSensorName,
    },
    {
      field: 'hostname',
      title: localeSensor[currentLocale].mainList.domainName,
      width: '280px',
      // filterCell: TreeListDateFilter,
      cell: CellSensorDomain,
    },
    {
      field: 'ip',
      title: localeSensor[currentLocale].mainList.ip,
      width: '124px',
      cell: StandartCell,
    },
    {
      field: 'assetCount',
      title: localeSensor[currentLocale].mainList.assets,
      cell: StandartCell,
      width: '100px',
    },
    {
      field: 'status',
      title: localeSensor[currentLocale].mainList.status,
      width: '150px',
      cell: CellSensorStatus,
      headerCell: SensorStatusHeaderCell,
    },
    {
      field: 'createdAt',
      title: localeSensor[currentLocale].mainList.createdAt,
      cell: CellCommonDate,
      width: '160px',
    },
  ];

  if (currentUser?.role === 'super_admin' || currentUser?.role === 'account_admin')
    columns.push({
      field: 'account.name',
      title: localeSensor[currentLocale].mainList.account,
      cell: StandartCell,
      width: '140px',
    });

  if (dataForHooks.componentName === 'SensorTreeList')
    columns.push({
      width: '40px',
      title: ' ',
      cell: CellSensorMenu,
    });

  const onExpandChange = (e: TreeListExpandChangeEvent): void => {
    setState({
      ...state,
      expanded: e.value
        ? state.expanded.filter((id: string) => id !== e.dataItem.sensorId)
        : [...state.expanded, e.dataItem.sensorId],
    });
  };

  const addExpandField = (
    dataTree: SensorsDashboardResponseTree['data'],
  ): Array<SensorsDashboardResponseTree['data'][0] & { [expandField]: string[] }> => {
    const { expanded } = state;

    return mapTree(dataTree, subItemsField, (item: SensorsDashboardResponseTree['data'][0]) =>
      extendDataItem(item, subItemsField, {
        [expandField]: expanded.includes(item.sensorId),
      }),
    );
  };

  const processData = (): Array<
    SensorsDashboardResponseTree['data'][0] & { [expandField]: string[] }
  > => {
    const { data } = state;
    const filteredData: SensorsDashboardResponseTree['data'] = filterBy(
      data,
      state.filter,
      subItemsField,
    );

    return addExpandField(filteredData);
  };

  return (
    <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
      <div className="sensor-tree-list">
        {/* <LocalizationProvider language={currentLocale}>
          <IntlProvider locale={currentLocale.substring(0, 2)}>
            <div className="filter-line">
              <Filter
                value={filter}
                onChange={onFilterChange}
                fields={FILTER_SENSORLIST[currentLocale]}
              />
              {filter.filters.length === 0 && (
                <Input
                  name="customSearch"
                  placeholder={`${localeFilters[currentLocale].customSerachPlaceholder} ${localeFilters[currentLocale].name}`}
                  value={customSearch}
                  onChange={(e): void => handleCustomSearch(e)}
                />
              )}
            </div>
          </IntlProvider>
        </LocalizationProvider> */}
        <TreeList
          data={processData()}
          expandField={expandField}
          subItemsField={subItemsField}
          columns={columns}
          onExpandChange={onExpandChange}
          className="no-pageable-k-grid table"
        />
      </div>
    </ErrorBoundary>
  );
}
