/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import type { GridCellProps } from '@progress/kendo-react-grid';
import { Menu, MenuItem, MenuSelectEvent } from '@progress/kendo-react-layout';
import { useEffect, useRef, useState } from 'react';
import { Loader } from '@progress/kendo-react-indicators';
import { ticketStatusLocale, ticketStatusValueForm } from '../../../../../utils/helpers/constants';
import styles from '../ticket.module.scss';
import { useTicketContext } from '../../../../../hooks/useTicketContext';
import { useTicketMethods } from '../../../../../hooks/components/useTicketMethods';
import { ErrorMutationTicketStatus } from '../../../../common/ErrorMutationTicketStatus';
import type { TicketDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/ticketsDashboardResponse.v1';
import type { TicketActionDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/ticketActionsDashboardResponse.v1';

export function CellTicketStatus(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { status, ticketId, type }: TicketDashboardResponse | TicketActionDashboardResponse =
    dataItem;

  const [isShowStatusMenu, setShowStatusMenu] = useState(false);

  const [menuStyle, setMenuStyle] = useState<{ menu: string; item: string }>({
    menu: '',
    item: '',
  });

  const [isErrorChangeStatus, setErrorChangeStatus] = useState(false);

  const timer = useRef<NodeJS.Timeout | null>(null);

  const { isTicketStatusLoading, errorMutationTicketStatus, ticketIdVale } = useTicketContext();

  const { changeStatusTicket } = useTicketMethods();

  const onSelect = (e: MenuSelectEvent): void => {
    if (e.item.data) {
      const statusValue = e.item.data as string;

      if ((type === 'fix_vulnerabilities' || type === 'custom') && 'ticketSystemType' in dataItem) {
        const editData = {
          ticketId,
          status: statusValue,
          ticketSystemType: dataItem.ticketSystemType,
          type,
        };

        changeStatusTicket(editData);
      }
    }
  };

  useEffect(() => {
    if (status) {
      setMenuStyle({ menu: `${status}Menu`, item: status });
    }
  }, [status]);

  const setTimerErrorChangeStatus = (): void => {
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setErrorChangeStatus(false);
    }, 800);
  };

  useEffect(() => {
    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
  }, []);

  return (
    <td
      className={
        !document.location.pathname.includes('tickets/')
          ? [styles.cellStatusTicket, styles.cursorPointer].join(' ')
          : [styles.cellStatusTicket, styles.cursorInherit].join(' ')
      }
      onClick={
        !document.location.pathname.includes('tickets/')
          ? (): void => setShowStatusMenu(!isShowStatusMenu)
          : undefined
      }
      onMouseLeave={
        !document.location.pathname.includes('tickets/')
          ? (): void => setShowStatusMenu(false)
          : undefined
      }
    >
      {!isShowStatusMenu && (
        <div className={styles.cellStatusTicketWrap}>
          {status === 'completed' && <span className="k-icon k-i-check success completed" />}
          {status === 'uncompleted' && (
            <span className={`${styles.uncompleted} k-icon k-i-clock`} />
          )}
          {status === 'impossible' && <span className=" k-icon k-i-close k-i-x fail" />}
          {status && typeof status === 'string' && status in ticketStatusLocale ? (
            <div className={styles.statusContentWithIcon}>
              {status === 'open' && (
                <img
                  src={`${process.env.PUBLIC_URL}/images/openTicketStatus.svg`}
                  alt=""
                  className={`${styles[status]} ${styles.statusIcon}`}
                />
              )}
              {status === 'in-progress' && (
                <span className={[styles.spanIcon, styles.inProgress].join(' ')} />
              )}
              {status === 'closed' && (
                <span
                  className={`${styles.spanIcon} ${styles[status]} k-icon k-i-check k-i-checkmark k-i-tick`}
                />
              )}
              {status === 'in-review' && (
                <span className={`${styles.spanIcon} ${styles[status]} k-icon k-i-clock`} />
              )}
              {status === 'undefined' && (
                <span
                  className={`${styles.spanIcon} ${styles[status]} k-icon k-i-question-circle k-i-question k-i-help`}
                />
              )}
              <div className={styles.ticketStatusValueWrap}>
                <span
                  className={
                    !document.location.pathname.includes('tickets/')
                      ? [
                          styles[status],
                          styles.spanContent,
                          styles.textDecorationUnderline,
                          styles.textDecorationStyleDotted,
                        ].join(' ')
                      : [
                          styles[status],
                          styles.spanContent,
                          styles.textDecorationNone,
                          styles.textDecorationStyleInherit,
                        ].join(' ')
                  }
                >
                  {ticketStatusLocale[status]}
                </span>
                {errorMutationTicketStatus &&
                  errorMutationTicketStatus.find((f) => f.id === ticketId) &&
                  !document.location.pathname.includes('tickets/') && (
                    <img
                      src={`${process.env.PUBLIC_URL}/images/warning-circle.svg`}
                      className={styles.statusIcon}
                      alt=""
                      onMouseEnter={(): void => {
                        if (timer.current) clearTimeout(timer.current);
                        setErrorChangeStatus(true);
                      }}
                      onMouseLeave={setTimerErrorChangeStatus}
                    />
                  )}
              </div>
            </div>
          ) : (
            <span>{status}</span>
          )}
        </div>
      )}
      {isShowStatusMenu && (
        <Menu
          hoverOpenDelay={0}
          className={`${styles[menuStyle.menu]} btn-menu`}
          onSelect={onSelect}
        >
          <MenuItem
            text={ticketStatusLocale[status]}
            icon={
              status === 'closed'
                ? 'k-icon k-i-check'
                : status === 'open'
                ? 'k-icon k-i-file k-i-file-vertical k-i-page-portrait k-i-file-v'
                : status === 'in-progress'
                ? `k-icon ${[styles.spanIcon, styles.inProgress].join(' ')}`
                : status === 'in-review'
                ? 'k-icon k-i-clock'
                : ''
            }
          >
            {ticketStatusValueForm
              .filter((s) => s.id !== status)
              .map((t) => (
                <MenuItem
                  text={t.name}
                  data={t.id}
                  key={t.id}
                  cssClass={styles[t.id]}
                  icon={
                    t.id === 'closed'
                      ? 'k-icon k-i-check'
                      : t.id === 'open'
                      ? 'k-icon k-i-file k-i-file-vertical k-i-page-portrait k-i-file-v'
                      : t.id === 'in-progress'
                      ? `k-icon ${[styles.spanIcon, styles.inProgress].join(' ')}`
                      : t.id === 'in-review'
                      ? 'k-icon k-i-clock'
                      : ''
                  }
                />
              ))}
          </MenuItem>
        </Menu>
      )}
      {ticketIdVale.current === ticketId && isTicketStatusLoading && (
        <div className={styles.loaderWrap}>
          <Loader className={styles.statusLoader} size="small" type="pulsing" />
        </div>
      )}
      {isErrorChangeStatus && (
        <ErrorMutationTicketStatus inlineStyle={{ left: '148px' }} ticketId={ticketId} />
      )}
    </td>
  );
}
