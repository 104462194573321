import qs from 'qs';
import type { SortDescriptor } from '@progress/kendo-data-query';
import type { IPageState } from '../utils/helpers/types';
import { history } from '../utils/history';
import { vmInstance } from '../lib/axios/hooks/useVmAxios';
import { dashboardInstance } from '../lib/axios/hooks/useDashboardAxios';
import { prepareFilterVal } from './filterVal-for-service';
import type { IFilterVal } from '../utils/filtering-in-table-test';
import type { CredentialsDashboardResponse } from '../types/__generated/on-premise-solution/api/credentialsDashboardResponse.v1';
import type { VaultsResponse } from '../types/__generated/on-premise-solution/api/vaultsResponse.v1';
import type { CredentialCreationResponse } from '../types/__generated/on-premise-solution/api/credentialCreationResponse.v1';
import type { CredentialCreationRequest } from '../types/__generated/on-premise-solution/api/credentialCreationRequest.v1';
import type { CredentialCheckResponse } from '../types/__generated/on-premise-solution/api/credentialCheckResponse.v1';
import type { CredentialCheckRequest } from '../types/__generated/on-premise-solution/api/credentialCheckRequest.v1';
import type { VaultCreationRequest } from '../types/__generated/on-premise-solution/api/vaultCreationRequest.v1';
import type { VaultCreationResponse } from '../types/__generated/on-premise-solution/api/vaultCreationResponse.v1';
import type { CredentialDashboardResponse } from '../types/__generated/on-premise-solution/api/credentialDashboardResponse.v1';
import type { CredentialUpdateResponse } from '../types/__generated/on-premise-solution/api/credentialUpdateResponse.v1';
import type { CredentialUpdateRequest } from '../types/__generated/on-premise-solution/api/credentialUpdateRequest.v1';

export async function getCredentials(
  page: IPageState,
  filterVal: IFilterVal[],
  sort: Array<SortDescriptor>,
): Promise<CredentialsDashboardResponse> {
  const result = await dashboardInstance.get<CredentialsDashboardResponse>('/credentials', {
    params: {
      skip: page.skip,
      limit: page.take,
      sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
      sortOrder: sort[0].dir,
      ...prepareFilterVal(filterVal),
    },

    paramsSerializer: (params) => {
      const stringifiedParams = qs.stringify(params, { encode: false });

      if (document.location.pathname.includes('/credentials/list')) {
        history.replace({
          pathname: `/lk/credentials/list?${stringifiedParams}`,
        });
      }

      return stringifiedParams;
    },
  });

  return result.data;
}

export async function getCredentialId(
  сredentialId: string | null | undefined,
): Promise<CredentialDashboardResponse> {
  const result = await dashboardInstance.get<CredentialDashboardResponse>(
    `/credentials/${сredentialId}`,
  );

  return result.data;
}

export async function cheackCredential(
  сredentialId: string | null | undefined,
  payload: CredentialCheckRequest,
): Promise<CredentialCheckResponse> {
  const result = await vmInstance.post<CredentialCheckResponse>(
    `/credentials/${сredentialId}/check`,
    {
      ip: payload.ip,
      sshPort: payload.sshPort,
      winrmPort: payload.winrmPort,
      snmpPort: payload.snmpPort,
      hostname: payload.hostname,
      ldap: payload.ldap,
    },
  );

  return result.data;
}

export async function getVaults(
  page: IPageState,
  filterVal: IFilterVal[],
  sort: Array<SortDescriptor>,
): Promise<VaultsResponse> {
  const result = await dashboardInstance.get<VaultsResponse>('/vaults', {
    params: {
      skip: page.skip,
      limit: page.take,
      sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
      sortOrder: sort[0].dir,
      ...prepareFilterVal(filterVal),
    },

    paramsSerializer: (params) => {
      const stringifiedParams = qs.stringify(params, { encode: false });

      return stringifiedParams;
    },
  });

  return result.data;
}

export async function getVaultsList(): Promise<VaultsResponse> {
  const result = await vmInstance.get<VaultsResponse>('/vaults');

  return result.data;
}

export async function createVault(
  CredentialData: VaultCreationRequest,
): Promise<VaultCreationResponse> {
  const result = await vmInstance.post<VaultCreationResponse>('/vaults', {
    ...CredentialData,
  });

  return result.data;
}

export async function createCredentials(
  CredentialData: CredentialCreationRequest,
): Promise<CredentialCreationResponse> {
  const result = await vmInstance.post<CredentialCreationResponse>('/credentials', {
    ...CredentialData,
  });

  return result.data;
}

export async function editCredentials(
  credentialId: string | undefined,
  CredentialData: CredentialUpdateRequest,
): Promise<CredentialUpdateResponse> {
  const result = await vmInstance.patch<CredentialUpdateResponse>(`/credentials/${credentialId}`, {
    ...CredentialData,
  });

  return result.data;
}

export async function deleteCredentials(credentialId: string): Promise<CredentialCreationResponse> {
  const result = await vmInstance.delete<CredentialCreationResponse>(
    `/credentials/${credentialId}`,
  );

  return result.data;
}

export async function deleteVault(vaultId: string): Promise<VaultCreationResponse> {
  const result = await vmInstance.delete<VaultCreationResponse>(`/vaults/${vaultId}`);

  return result.data;
}
