/* eslint-disable react/jsx-props-no-spreading */
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { Grid, GridColumn as Column, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { State, process, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Filter } from '@progress/kendo-react-data-tools';
import { FILTER_INITIAL, PAGEABLE_DATA_LOCAL } from '../../../../utils/helpers/constants';
import localeSettings from '../../../../utils/i18n/settingsLocales/settings.json';
import styles from '../WhiteListTabEl.module.scss';
import { Input } from '../../../common/baseElements/Input';
import {
  handlerCustomSearch,
  onFilterChange,
} from '../../../../utils/helpers/local-filter-helpers';
import type { WhitelistedBulletin } from '../../../../types/__generated/on-premise-solution/api/bulletinWhitelistDashboardResponse.v1';
import { CellCommonDate } from '../../../common/baseElements/CellCommonDate';
import { CellBulletinId } from '../cells/CellBulletinId';
import { WHITELIST_BULLETIN_DESCRIPTION } from '../../../../utils/helpers/constant-local-filter-task';
import { useWhiteListContext } from '../../../../hooks/useWhiteListContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;

export function BulletinsDescriptionList({
  bulletins,
}: {
  bulletins: WhitelistedBulletin[] | null | undefined;
}): React.ReactElement {
  const pageLimitGrid: { [key: string]: string } =
    localStorage.getItem('pageLimitGrid') &&
    JSON.parse(localStorage.getItem('pageLimitGrid') || '');

  const initialDataState: State = useMemo(
    () => ({
      sort: [{ field: 'name', dir: 'desc' }],
      take: pageLimitGrid && pageLimitGrid.ApiMessods ? Number(pageLimitGrid.ApiMessods) : 10,
      skip: 0,
      filter: FILTER_INITIAL,
    }),
    [pageLimitGrid],
  );

  const fieldFindInTable = 'bulletinId';

  const [dataState, setDataState] = useState<State>(initialDataState);

  const [customSearch, setCustomSearch] = useState('');

  const [filter, setFilter] = useState(FILTER_INITIAL);

  const filterValue = useRef<CompositeFilterDescriptor | null>(null);

  const { whitelistUpdate } = useWhiteListContext();

  // useEffect(() => {
  //   const table = document.getElementsByClassName('whitelist-bulletins')[0].querySelector('table');

  //   if (table && bulletins && bulletins.length <= 10) {
  //     table.style.marginBottom = '18px';
  //   }

  //   if (table && bulletins && bulletins.length > 10) {
  //     table.style.marginBottom = '0px';
  //   }
  // }, [bulletins]);

  useEffect(() => {
    if (whitelistUpdate) {
      setDataState(initialDataState);
    }
  }, [whitelistUpdate]);

  return (
    <div className="whitelist-bulletins">
      {bulletins ? (
        <>
          <LocalizationProvider language={currentLocale}>
            <IntlProvider locale={currentLocale.substring(0, 2)}>
              <div className={styles.whiteListFilterLine}>
                <Filter
                  value={filter}
                  onChange={(event): void =>
                    onFilterChange(
                      event,
                      setCustomSearch,
                      setDataState,
                      dataState,
                      setFilter,
                      filterValue,
                      customSearch,
                      fieldFindInTable,
                    )
                  }
                  fields={WHITELIST_BULLETIN_DESCRIPTION[currentLocale]}
                />
                {filter.filters.length === 0 && (
                  <Input
                    className={`k-input k-input-md ${styles.apiKeyCustomSearch}`}
                    name="customSearch"
                    placeholder={localeSettings[currentLocale].whiteList.grid.bulletinPlaceholder}
                    value={customSearch}
                    onChange={(e): void =>
                      handlerCustomSearch(
                        e,
                        setCustomSearch,
                        setDataState,
                        filterValue,
                        dataState,
                        fieldFindInTable,
                      )
                    }
                  />
                )}
              </div>
            </IntlProvider>
          </LocalizationProvider>
          <LocalizationProvider language={currentLocale}>
            <IntlProvider locale={currentLocale.substring(0, 2)}>
              <Grid
                pageable={bulletins.length > 10 ? PAGEABLE_DATA_LOCAL : false}
                sortable
                data={process(bulletins || [], dataState)}
                {...dataState}
                onDataStateChange={(e: GridDataStateChangeEvent): void => {
                  setDataState(e.dataState);
                  if (pageLimitGrid) {
                    const pageLimit = {
                      ...pageLimitGrid,
                      componentName: String(e.dataState.take),
                    };
                    localStorage.setItem('pageLimitGrid', JSON.stringify(pageLimit));
                  } else {
                    const pageLimit = {
                      componentName: String(e.dataState.take),
                    };
                    localStorage.setItem('pageLimitGrid', JSON.stringify(pageLimit));
                  }
                }}
                scrollable="none"
                className={bulletins.length && bulletins.length < 10 ? 'no-pageable-k-grid' : ''}
              >
                <Column
                  field="bulletinId"
                  title={localeSettings[currentLocale].whiteList.description.bulletinId}
                  filter="text"
                  width="200px"
                  cell={CellBulletinId}
                />
                <Column
                  field="addedBy.name"
                  title={localeSettings[currentLocale].whiteList.grid.addedBy}
                  filter="text"
                  width="200px"
                />
                <Column
                  cell={CellCommonDate}
                  title={localeSettings[currentLocale].whiteList.grid.addedAt}
                  filter="text"
                  field="addedAt"
                  // width="180px"
                />
              </Grid>
            </IntlProvider>
          </LocalizationProvider>
        </>
      ) : (
        <div className={styles.titleDescriptionList}>
          <span>{localeSettings[currentLocale].whiteList.grid.noData}</span>
        </div>
      )}
    </div>
  );
}
