import type { GridCellProps } from '@progress/kendo-react-grid';
import { Button } from '../../../../../common/baseElements/Button';
import { useAuditModalContext } from '../../../../../../hooks/useAuditModalContext';
import type { IPkgVulns } from '../../../../../../utils/helpers/types';
import { handleVulnModal } from '../../../../../../utils/helpers/audit-helpers';

export function CellId(props: GridCellProps): React.ReactElement {
  const { dataItem, className } = props;

  const { id }: IPkgVulns = dataItem;

  const { bulletinId } = dataItem;

  const { setCveName, setOpen } = useAuditModalContext();

  return (
    <td className={`audit__vulns ${className || ''}`}>
      <Button
        fill="modal"
        onClick={(): void => handleVulnModal(id || bulletinId, setOpen, setCveName)}
      >
        {id || bulletinId}
      </Button>
    </td>
  );
}
