/* eslint-disable react/jsx-pascal-case */
import { useCallback, useEffect, useState, type ReactElement } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Typography } from '@progress/kendo-react-common';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { useHistory } from 'react-router';
import { useQueryClient } from 'react-query';
import { BoundaryErrorComponent } from '../../common/BoundaryErrorComponent';
import { SoftwareTabs } from './SoftwareTabs';
import { SoftwaresInfoModal } from './SoftwaresInfoModal';
import { useSoftwaresContext } from '../../../hooks/useSoftwaresContext';
import { SoftwareOsTabs } from './SoftwareOsTabs';
import { VulnModal } from '../../common/modal/vulnModal/VulnModal';
import { useAuditModalContext } from '../../../hooks/useAuditModalContext';
import localeSoftwareList from '../../../utils/i18n/software/software.json';
import localeButtons from '../../../utils/i18n/commonLocales/buttons.json';
import { Button } from '../../common/baseElements/Button';
import { AccountChangeDropDown } from '../../common/customElements/AccountChangeDropDown';
import { ToggleSwitch } from '../../common/baseElements/ToggleSwitch';
import { getUserData } from '../../../services/local-storage-service';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSoftwareList;

export function SectionSoftware(): ReactElement {
  const {
    softwaresModalData,
    setSoftwaresModalData,
    changedAccountId,
    setAccountId,
    isGroupped,
    setGroupped,
  } = useSoftwaresContext();
  const history = useHistory();
  const queryClient = useQueryClient();
  const currentUser = getUserData();
  const [selected, setSelected] = useState(0);

  const { cveName } = useAuditModalContext();

  const handleSelect = useCallback(
    (e: TabStripSelectEventArguments): void => {
      if (e.selected === 0 && !document.location.pathname.includes('/vulnerable')) {
        history.replace('/lk/software-list/vulnerable');
        setSelected(e.selected);
      }
      if (e.selected === 1 && !document.location.pathname.includes('/installed')) {
        history.replace('/lk/software-list/installed');
        setSelected(e.selected);
      }
      if (e.selected === 2 && !document.location.pathname.includes('/os')) {
        history.replace('/lk/software-list/os');
        setSelected(e.selected);
      }

      setSelected(e.selected);
    },
    [history],
  );
  const handleRefresh = (): void => {
    if (document.location.pathname.includes('/vulnerable'))
      queryClient.invalidateQueries(
        isGroupped ? 'VulnerableSoftwareListGroup' : 'VulnerableSoftwareList',
      );
    else if (document.location.pathname.includes('/installed'))
      queryClient.invalidateQueries(
        isGroupped ? 'InstalledSoftwareListGroup' : 'InstalledSoftwareList',
      );
    else if (document.location.pathname.includes('/os'))
      queryClient.invalidateQueries('SoftwareOsList');
  };

  useEffect(() => {
    if (document.location.pathname.includes('/software-list/vulnerable')) {
      setSelected(0);
    } else if (document.location.pathname.includes('/software-list/installed')) {
      setSelected(1);
    } else if (document.location.pathname.includes('/software-list/os')) {
      setSelected(2);
    }
  }, [history, document.location.pathname]);

  useEffect(() => {
    return () => setSoftwaresModalData(undefined);
  }, []);

  return (
    <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
      <div className="software-lists">
        <div className="common-header-page">
          <Typography.h3>{localeSoftwareList[currentLocale].title}</Typography.h3>
          <div
            className="topactions"
            style={{
              justifyContent: 'space-between',
            }}
          >
            <Button icon="refresh" onClick={handleRefresh}>
              {localeButtons[currentLocale].refresh}
            </Button>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {currentUser?.role === 'super_admin' && (
                <AccountChangeDropDown
                  selectedAccountId={changedAccountId}
                  hasAllAccounts
                  onChange={(e): void => {
                    setAccountId(e.target.value.id);
                    // handleRefresh();
                  }}
                />
              )}
              <div>
                <ToggleSwitch
                  leftLabel={localeSoftwareList[currentLocale].list}
                  rightLabel={localeSoftwareList[currentLocale].group}
                  checked={
                    document.location.pathname.includes('/software-list/os') ? false : isGroupped
                  }
                  setChecked={setGroupped}
                  disabled={document.location.pathname.includes('/software-list/os')}
                />
              </div>
            </div>
          </div>
        </div>
        <TabStrip selected={selected} onSelect={handleSelect}>
          <TabStripTab title={localeSoftwareList[currentLocale].vulnerableTab.title}>
            <SoftwareTabs isVulnerable />
          </TabStripTab>
          <TabStripTab title={localeSoftwareList[currentLocale].installedTab.title}>
            <SoftwareTabs />
          </TabStripTab>
          <TabStripTab title={localeSoftwareList[currentLocale].osTab.title}>
            <SoftwareOsTabs />
          </TabStripTab>
        </TabStrip>
      </div>
      {softwaresModalData && <SoftwaresInfoModal />}
      {cveName && <VulnModal />}
    </ErrorBoundary>
  );
}
