/* eslint-disable no-nested-ternary */
import {
  createContext,
  JSXElementConstructor,
  ReactElement,
  useContext,
  useMemo,
  useState,
} from 'react';
import type { VulnerableSoftwareDashboardResponse } from '../types/__generated/on-premise-solution/api/vulnerableSoftwareListDashboardResponse.v1';

export type TSoftwareModalData =
  | {
      softwareId: string;
      softwareName?: string;
      version?: string;
      arch?: string;
      language?: string;
      fullId?: string;
      osType?: 'windows' | 'linux';
      defaultTabId?: number;
      fixes?: VulnerableSoftwareDashboardResponse['mergedUpdates'];
      availableUpdate?: VulnerableSoftwareDashboardResponse['availableUpdate'];
      isVulnerableFlag?: boolean;
    }
  | undefined;
interface ISoftwares {
  softwaresModalData: TSoftwareModalData;
  setSoftwaresModalData: React.Dispatch<React.SetStateAction<TSoftwareModalData>>;
  changedAccountId: string | undefined;
  setAccountId: React.Dispatch<React.SetStateAction<string | undefined>>;
  isGroupped: boolean;
  setGroupped: React.Dispatch<React.SetStateAction<boolean>>;
}

const SoftwaresContext = createContext<ISoftwares | null>(null);

export function SoftwaresProvider({
  children,
}: {
  children: ReactElement<string | JSXElementConstructor<string>>;
}): React.ReactElement {
  const [softwaresModalData, setSoftwaresModalData] = useState<TSoftwareModalData>(undefined);
  const [changedAccountId, setAccountId] = useState<string | undefined>(undefined);
  const [isGroupped, setGroupped] = useState(false);

  const value = useMemo(
    () => ({
      softwaresModalData,
      setSoftwaresModalData,
      changedAccountId,
      setAccountId,
      isGroupped,
      setGroupped,
    }),
    [softwaresModalData, changedAccountId, isGroupped],
  );

  return <SoftwaresContext.Provider value={value}>{children}</SoftwaresContext.Provider>;
}

export const useSoftwaresContext = (): ISoftwares => {
  const context = useContext(SoftwaresContext);
  if (context === null) {
    throw new Error('useSoftwaresContext must be used inside the WebSocketContext.Provider.');
  }

  return context;
};
