import { Filter } from '@progress/kendo-react-data-tools';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import type { IDataHooks } from '../../../../utils/helpers/types';
import '../../Host/hostList/HostList.scss';
import { СommonTableLoader } from '../../../common/tableLoader/CommonTableLoader';
import localeHost from '../../../../utils/i18n/assetLocales/hostList.json';
import { CommonImageList } from '../../../common/CommonImageList';
import {
  FILTER_IMAGELIST,
  FILTER_IMAGELIST_NOT_SUPER_ADMIN,
} from '../../../../utils/helpers/constant-serv-filter-host';
import { useImagesList } from '../../../../hooks/components/useImageList';
import { Input } from '../../../common/baseElements/Input';
import { INITIAL_IMAGE_LIST } from '../../../../utils/helpers/constants';
import { getUrlParamsAddHosts } from '../../../../utils/helpers/add-host-list-helper';
import { getUserData } from '../../../../services/local-storage-service';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHost;

export function ImageList(): React.ReactElement {
  const currentUser = getUserData();

  const urlParams = getUrlParamsAddHosts('ImageList', INITIAL_IMAGE_LIST);

  const dataForHooks: IDataHooks = {
    name: 'imageNames',
    componentName: 'ImageList',
  };

  const {
    filter,
    onFilterChange,
    sort,
    page,
    total,
    pageChange,
    setSort,
    isLoading,
    isFetching,
    filterStatus,
    isError,
    errorMessage,
    dataImage,
    handleCustomSearch,
    customSearch,
  } = useImagesList(urlParams, dataForHooks);

  return (
    <div className="image-list">
      {/* <div className="common-header-page">
        <CommonErrorLicense />
        <Typography.h3>{localeHost[currentLocale].imageTitle}</Typography.h3>
        <div className="image__topactions">
          <Button fillMode="flat" icon="refresh" onClick={handleRefresh}>
            {localeHost[currentLocale].btnRefresh}
          </Button>
        </div>
      </div> */}
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <div className="filter-line">
            <Filter
              value={filter}
              onChange={onFilterChange}
              fields={
                currentUser?.role === 'super_admin'
                  ? FILTER_IMAGELIST[currentLocale]
                  : FILTER_IMAGELIST_NOT_SUPER_ADMIN[currentLocale]
              }
            />
            {filter.filters.length === 0 && (
              <Input
                name="customSearch"
                placeholder={localeHost[currentLocale].customSearchImage}
                value={customSearch}
                onChange={(e): void => handleCustomSearch(e)}
              />
            )}
          </div>
        </IntlProvider>
      </LocalizationProvider>
      <CommonImageList
        sort={sort}
        dataImage={dataImage}
        page={page}
        total={total}
        pageChange={pageChange}
        setSort={setSort}
        dataForHooks={dataForHooks}
        conditionSort={15}
      />
      <СommonTableLoader queryData={filterStatus} filteringData={filterStatus} />
      {isError && <div className="info alert alert-danger">Error: {errorMessage}</div>}
    </div>
  );
}
