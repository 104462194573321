import { Switch, SwitchChangeEvent } from '@progress/kendo-react-inputs';
import type { ReactElement } from 'react';
import styles from './ToggleSwitch.module.scss';

export function ToggleSwitch({
  leftLabel,
  rightLabel,
  checked,
  setChecked,
  disabled,
}: {
  leftLabel?: string;
  rightLabel?: string;
  checked: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
}): ReactElement {
  return (
    <div className={styles.viewSwitch}>
      {leftLabel}
      <Switch
        size="small"
        onLabel=""
        offLabel=""
        checked={checked}
        onChange={(e: SwitchChangeEvent): void => setChecked(e.target.value)}
        disabled={disabled}
      />
      {rightLabel}
    </div>
  );
}
