/* eslint-disable no-nested-ternary */
import { useMutation, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router';
import type { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { useEffect } from 'react';
import type { RadioButtonChangeEvent } from '@progress/kendo-react-inputs';
import type { TUseTicketMethods, TEditTicket } from '../../utils/helpers/types';
import type { ApiError } from '../../types/__generated/on-premise-solution/api/apiError.v1';
import {
  createTicket,
  deleteTicket,
  editTicket,
  relocateTicket,
} from '../../services/tickets-service';
import type { TicketCreationResponse } from '../../types/__generated/on-premise-solution/api/ticketCreationResponse.v1';
import type { TicketCreationRequest } from '../../types/__generated/on-premise-solution/api/ticketCreationRequest.v1';
import { useTicketContext } from '../useTicketContext';
import type { TicketUpdateResponse } from '../../types/__generated/on-premise-solution/api/ticketUpdateResponse.v1';
import type { TicketUpdateRequest } from '../../types/__generated/on-premise-solution/api/ticketUpdateRequest.v1';
import {
  createDataTicketEdit,
  returnIdFromAssetDynamicGroupId,
  returnIdFromAssetGroupId,
  returnIdFromAssetId,
  returnIdFromBulletinId,
} from '../../utils/helpers/ticket-helper';
import { useAccountModalContext } from '../useAccountModalContext';
import { useUserModalContext } from '../useUserModalContext';
import { getAccountId, getUserData } from '../../services/local-storage-service';
import { useHostModalContext } from '../useHostModalContext';
import { useNetDeviceContext } from '../useNetDeviceContext';
import { useRegisterContext } from '../useRegisterContext';
import { useWhiteListContext } from '../useWhiteListContext';
import { useGroupContext } from '../useGroupContext';
import type { TicketDeletionResponse } from '../../types/__generated/on-premise-solution/api/ticketDeletionResponse.v1';
import { ticketPriorityValue2, ticketStatusValue } from '../../utils/helpers/constants';
import type { TicketResponse } from '../../types/__generated/on-premise-solution/api/ticketResponse.v1';
import { useTicketSystemContext } from '../useTicketSystemContext';
import localeTicket from '../../utils/i18n/security/vulnerabilities.json';
import { useCommonContext } from '../useCommonContext';
import { useAssetCreatePropContext } from '../useAssetCreatePropContext';

export function useTicketMethods(): TUseTicketMethods {
  const currentLocale =
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN';

  const queryClient = useQueryClient();

  const history = useHistory();

  const currentUser = getUserData();

  const currentAccount = getAccountId();

  const { assetId } = useParams<{ assetId: string }>();

  const { accountId, setAccountId } = useAccountModalContext();

  const { setSelectedVulns } = useAssetCreatePropContext();

  const { uid, setUid, setUsername, username, login, setLogin, email, setEmail } =
    useUserModalContext();

  const { hostForm, setHostForm, setHostFormTemp } = useHostModalContext();

  const { deviceForm, setDeviceForm, setDeviceFormTemp } = useNetDeviceContext();

  const { imageForm, setImageForm } = useRegisterContext();

  const { bulletinForm, setBulletinForm, setBulletinFormTemp } = useWhiteListContext();

  const { dynamicGroupForm, setDynamicGroupForm } = useGroupContext();

  const {
    setErrorMutation,
    editTicketData,
    ticketSystem,
    type,
    summary,
    description,
    deadlineDate,
    priority,
    ticketStatus,
    isTicketAutoCloseEnabled,
    setEditTicketData,
    setAddTicket,
    setSummary,
    setDescription,
    setTicketSystem,
    setDeadlineDate,
    setPriority,
    setTicketStatus,
    setTicketAutoCloseEnabled,
    setTicketStatusLoading,
    errorMutationTicketStatus,
    setErrorMutationTicketStatus,
    ticketIdVale,
    setType,
    setTicketCreatedSuccess,
  } = useTicketContext();

  const { setDescriptionTs, source } = useTicketSystemContext();

  const { setAssetId } = useCommonContext();

  const handleClose = (): void => {
    setSummary('');
    setDescription('');
    setDescriptionTs('');
    setAddTicket(false);
    setAccountId({
      id: '000',
      name: localeTicket[currentLocale].tickets.form.placeholderAccountName,
    });
    setUid('');
    setUsername('');
    setHostForm(null);
    setHostFormTemp(null);
    setDynamicGroupForm(null);
    setDeviceForm(null);
    setDeviceFormTemp(null);
    setImageForm(null);
    setBulletinForm(null);
    setBulletinFormTemp(null);
    setTicketSystem(null);
    setDeadlineDate(null);
    setPriority(ticketPriorityValue2[0]);
    setTicketStatus(ticketStatusValue[0]);
    setEditTicketData(null);
    setTicketAutoCloseEnabled(false);
    setLogin('');
    setEmail('');
    setType('fix_vulnerabilities');
  };

  const mutationNewTicket = useMutation<TicketCreationResponse, ApiError, TicketCreationRequest>(
    (payload) => createTicket(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('tickets');
        setTicketCreatedSuccess(true);
        handleClose();
        setSelectedVulns(null);
      },
      onError: (resp) => {
        setErrorMutation(resp.message);
      },
    },
  );

  const mutationEditTicket = useMutation<
    TicketUpdateResponse,
    ApiError,
    { ticketId: string; payload: TicketUpdateRequest }
  >(({ ticketId, payload }) => editTicket(ticketId, payload), {
    onSuccess: (resp) => {
      queryClient.invalidateQueries('tickets');

      setSelectedVulns(null);

      if (document.location.pathname.includes('tickets/')) {
        queryClient.invalidateQueries('ticketDescription');
      }
      setTicketStatusLoading(false);

      if (errorMutationTicketStatus?.filter((val) => val.id === resp.ticketId)) {
        const mutationErrorTicketStatus = errorMutationTicketStatus?.filter(
          (val) => val.id !== resp.ticketId,
        );
        if (mutationErrorTicketStatus) setErrorMutationTicketStatus([...mutationErrorTicketStatus]);
      }

      handleClose();
    },
    onError: (resp) => {
      setErrorMutation(resp.message);
      if (errorMutationTicketStatus) {
        setErrorMutationTicketStatus([
          ...errorMutationTicketStatus,
          { message: resp.message, id: ticketIdVale.current || '' },
        ]);
      } else {
        setErrorMutationTicketStatus([{ message: resp.message, id: ticketIdVale.current || '' }]);
      }
      setTicketStatusLoading(false);
    },
  });

  const handleSubmit = (): void => {
    if (editTicketData) {
      const payload = {
        ticketSystemType: editTicketData.ticketSystemType,
        type,
        summary: editTicketData.summary !== summary ? summary : undefined,
        description:
          editTicketData.description &&
          description &&
          description?.length > 0 &&
          editTicketData.description !== description
            ? description
            : !editTicketData.description && description && description.length > 0
            ? description
            : editTicketData.description && !description
            ? null
            : undefined,
        assignedTo:
          (username &&
            editTicketData.assignedTo &&
            editTicketData.assignedTo.name &&
            username !== editTicketData.assignedTo.name) ||
          (username && !editTicketData.assignedTo)
            ? {
                source,
                id: uid || undefined,
                login: source !== 'user' ? login || undefined : undefined,
                email: source !== 'user' ? email || undefined : undefined,
                name: source !== 'user' ? username || undefined : undefined,
              }
            : !username && editTicketData.assignedTo
            ? null
            : undefined,
        deadlineDate:
          deadlineDate &&
          new Date(editTicketData.deadlineDate).getTime() !== deadlineDate?.getTime()
            ? `${deadlineDate.getFullYear()}-${
                deadlineDate.getMonth() + 1 < 10
                  ? `0${deadlineDate.getMonth() + 1}`
                  : deadlineDate.getMonth() + 1
              }-${
                deadlineDate.getDate() < 10 ? `0${deadlineDate.getDate()}` : deadlineDate.getDate()
              }T00:00:00.000Z`
            : undefined,
        priority:
          editTicketData.priority && priority.id && editTicketData.priority !== priority.id
            ? priority.id
            : undefined,
        status: editTicketData.status !== ticketStatus.id ? ticketStatus.id : undefined,
        isTicketAutoCloseEnabled:
          type === 'fix_vulnerabilities' &&
          editTicketData.isTicketAutoCloseEnabled !== isTicketAutoCloseEnabled
            ? isTicketAutoCloseEnabled
            : undefined,
      } as TicketUpdateRequest;
      mutationEditTicket.mutate({ ticketId: editTicketData.ticketId, payload });
    } else {
      const payload = {
        ticketSystemId: ticketSystem?.id,
        ticketSystemType: ticketSystem?.type,
        type,
        accountId: currentUser?.role === 'super_admin' ? accountId.id : currentAccount || '',
        summary,
        description: description && description.length > 0 ? description : undefined,
        assignedTo:
          uid || username
            ? {
                source,
                id: uid || undefined,
                login: source !== 'user' ? login || undefined : undefined,
                email: source !== 'user' ? email || undefined : undefined,
                name: source !== 'user' ? username || undefined : undefined,
              }
            : undefined,
        deadlineDate: deadlineDate
          ? `${deadlineDate.getFullYear()}-${
              deadlineDate.getMonth() + 1 < 10
                ? `0${deadlineDate.getMonth() + 1}`
                : deadlineDate.getMonth() + 1
            }-${
              deadlineDate.getDate() < 10 ? `0${deadlineDate.getDate()}` : deadlineDate.getDate()
            }T00:00:00.000Z`
          : undefined,
        priority: priority.id ? priority.id : undefined,
        status: 'open',
        scope:
          type === 'fix_vulnerabilities'
            ? {
                assetIds: returnIdFromAssetId(
                  hostForm?.filter((a) => !a.assetGroupId) || [],
                  deviceForm,
                  imageForm,
                ),
                assetGroupIds: returnIdFromAssetGroupId(hostForm?.filter((g) => !g.assetId) || []),
                assetDynamicGroupIds: returnIdFromAssetDynamicGroupId(dynamicGroupForm),
              }
            : type !== 'fix_vulnerabilities' &&
              ((hostForm && hostForm.length > 0) ||
                (deviceForm && deviceForm.length > 0) ||
                (imageForm && imageForm.length > 0) ||
                (dynamicGroupForm && dynamicGroupForm.length > 0))
            ? {
                assetIds: returnIdFromAssetId(
                  hostForm?.filter((a) => !a.assetGroupId) || [],
                  deviceForm,
                  imageForm,
                ),
                assetGroupIds: returnIdFromAssetGroupId(hostForm?.filter((g) => !g.assetId) || []),
                assetDynamicGroupIds: returnIdFromAssetDynamicGroupId(dynamicGroupForm),
              }
            : undefined,
        bulletinIds:
          type === 'fix_vulnerabilities' && returnIdFromBulletinId(bulletinForm)
            ? returnIdFromBulletinId(bulletinForm)
            : undefined,
        isTicketAutoCloseEnabled:
          type === 'fix_vulnerabilities' ? isTicketAutoCloseEnabled : undefined,
      } as TicketCreationRequest;

      mutationNewTicket.mutate(payload);
    }
  };

  const mutationDelTicket = useMutation<
    TicketDeletionResponse | null,
    ApiError,
    TicketDeletionResponse['ticketId']
  >((payload) => deleteTicket(payload), {
    onSuccess: () => {
      queryClient.invalidateQueries('tickets');
      history.push('/lk/security/tickets');
    },
    onError: (resp) => {
      setErrorMutation(resp.message);
    },
  });

  const mutationRelocateTicket = useMutation<
    TicketResponse | null,
    ApiError,
    TicketDeletionResponse['ticketId']
  >((payload) => relocateTicket(payload), {
    onSuccess: () => {
      queryClient.invalidateQueries('ticketDescription');
    },
    onError: (resp) => {
      setErrorMutation(resp.message);
    },
  });

  const hadleDeleteTicket = (
    ticketId: string,
    setConfirmDel: React.Dispatch<React.SetStateAction<boolean>>,
  ): void => {
    mutationDelTicket.mutate(ticketId);
    setConfirmDel(false);
  };

  const hadleRelocateTicket = (
    ticketId: string,
    setConfirmRelocate: React.Dispatch<React.SetStateAction<boolean>>,
  ): void => {
    mutationRelocateTicket.mutate(ticketId);
    setConfirmRelocate(false);
  };

  const handleEdit = (dataItem: { [key: string]: any }): void => {
    let hosts: {
      assetId: string;
      hostname: string;
    }[] = [];

    if (dataItem.scope.assets && Array.isArray(dataItem.scope.assets)) {
      const assets = dataItem.scope.assets as {
        assetId: string;
        type: string;
        hostname: string;
      }[];

      hosts = assets
        .filter((d) => d.type === 'host')
        .map((d) => ({ assetId: d.assetId, hostname: d.hostname }));

      if (!dataItem.scope.assetGroups && hosts) setHostForm(hosts);

      const networks = assets
        .filter(
          (d) =>
            d.type === 'hypervisor' ||
            d.type === 'switch' ||
            d.type === 'router' ||
            d.type === 'firewall',
        )
        .map((d) => ({ assetId: d.assetId, hostname: d.hostname }));

      if (networks) setDeviceForm(networks);
    }

    if (dataItem.scope.assets && Array.isArray(dataItem.scope.assets)) {
      const assets = dataItem.scope.assets as {
        assetId: string;
        type: string;
        imageNames: string;
      }[];

      const images = assets
        .filter((d) => d.type === 'image')
        .map((d) => ({ assetId: d.assetId, imageNames: d.imageNames[0] }));

      if (images) setImageForm(images);
    }

    if (dataItem.scope.assetGroups && Array.isArray(dataItem.scope.assetGroups)) {
      const assetGroups = dataItem.scope.assetGroups as {
        assetGroupId: string;
        type: string;
        name: string;
      }[];

      const groups = assetGroups
        .filter((g) => g.type === 'host')
        .map((g) => ({ assetGroupId: g.assetGroupId, name: g.name }));

      if (hosts && groups) setHostForm([...hosts, ...groups]);

      if (!hosts && groups) setHostForm(groups);
    }

    if (dataItem.scope.assetDynamicGroups && Array.isArray(dataItem.scope.assetDynamicGroups)) {
      const assetDynamicGroups = dataItem.scope.assetDynamicGroups as {
        assetDynamicGroupId: string;
        type: string;
        name: string;
      }[];
      const dynamicGroups = assetDynamicGroups
        .filter((g) => g.type === 'host')
        .map((g) => ({ assetDynamicGroupId: g.assetDynamicGroupId, name: g.name }));

      if (dynamicGroups) setDynamicGroupForm(dynamicGroups);
    }

    if (dataItem.bulletinIds) {
      const bulletinIds = dataItem.bulletinIds.map((b: string) => ({ bulletinId: b }));
      setBulletinForm(bulletinIds);
    }

    if (dataItem.assignedTo) {
      setUid(dataItem.assignedTo.id);
      setUsername(dataItem.assignedTo.name);
    }

    const data: TEditTicket = createDataTicketEdit(dataItem);
    setEditTicketData(data);
    setAddTicket(true);
  };

  const changePriorityTicket = (event: DropDownListChangeEvent): void => {
    setPriority({
      id: event.target.value.id,
      name: event.target.value.name,
    });
  };

  const changeStatusTicketInDropDownList = (event: DropDownListChangeEvent): void => {
    setTicketStatus({
      id: event.target.value.id,
      name: event.target.value.name,
    });
  };

  const changeStatusTicket = (editStatus: {
    ticketId: string;
    status: string;
    ticketSystemType: string;
    type: string;
  }): void => {
    const payload = {
      status: editStatus.status,
      ticketSystemType: editStatus.ticketSystemType,
      type: editStatus.type,
    } as TicketUpdateRequest;

    setTicketStatusLoading(true);

    ticketIdVale.current = editStatus.ticketId;

    mutationEditTicket.mutate({ ticketId: editStatus.ticketId, payload });
  };

  const handleTicket = (
    currentAssetAccount: { accountId: string; name: string } | undefined | null,
  ): void => {
    if (assetId) {
      setAssetId(assetId);
    }
    if (currentAssetAccount)
      setAccountId({ id: currentAssetAccount.accountId, name: currentAssetAccount.name });
    setAddTicket(true);
  };

  const updateSize = (
    tabStripRef: React.RefObject<HTMLDivElement>,
    setHeightValue?: (value: React.SetStateAction<number>) => void,
  ): void => {
    if (tabStripRef.current?.getBoundingClientRect && setHeightValue) {
      const { height } = tabStripRef.current.getBoundingClientRect();

      setHeightValue(height);
    }
  };

  function handleDatePicker(data: Date | null): void {
    if (data) {
      setDeadlineDate(data);
    }
  }

  const changeTicketSystem = (event: DropDownListChangeEvent): void => {
    setTicketSystem({
      id: event.target.value.id,
      name: event.target.value.name,
      type: event.target.value.type,
    });
  };

  const handleChangeType = (e: RadioButtonChangeEvent): void => {
    setType(e.value);
  };

  return {
    handleSubmit,
    hadleDeleteTicket,
    isErrorDelTicket: mutationDelTicket.isError,
    errorMessageDelTicket: mutationDelTicket.error?.message,
    handleEdit,
    handleClose,
    changePriorityTicket,
    changeStatusTicketInDropDownList,
    changeStatusTicket,
    hadleRelocateTicket,
    handleTicket,
    updateSize,
    handleDatePicker,
    changeTicketSystem,
    handleChangeType,
  };
}
