import type { InventorizationResponse } from '../../../../../types/__generated/on-premise-solution/api/inventorizationsResponse.v1';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import { getPastTime } from '../../../../../utils/helpers/settings-helpers';
import { InventoryNetDevices } from './InventoryNetDevices';
import { WrapperMenu } from './WrapperMenu';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function SystemInfo({ data }: { data: InventorizationResponse }): React.ReactElement {
  return (
    <WrapperMenu title={locale[currentLocale].systemInfo.title}>
      {(data.type === 'linux' ||
        data.type === 'windows' ||
        data.type === 'router' ||
        data.type === 'firewall' ||
        data.type === 'switch' ||
        data.type === 'hypervisor') && (
        <>
          <table className="column">
            <tbody>
              <tr>
                <td>
                  <span>{locale[currentLocale].systemInfo.hostname}</span>
                </td>
                <td>
                  <span>{data.result.system_info?.hostname}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>{locale[currentLocale].systemInfo.uuid}</span>
                </td>
                <td>
                  {data.result.system_info && typeof data.result.system_info?.uuid === 'string' ? (
                    <span>{data.result.system_info?.uuid}</span>
                  ) : (
                    <span>-</span>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <span>{locale[currentLocale].upTime.title}</span>
                </td>
                <td>
                  {data.result.uptime?.total_seconds ? (
                    <span>
                      {getPastTime(Number(data.result.uptime?.total_seconds), currentLocale, 1000)}
                    </span>
                  ) : (
                    <span>-</span>
                  )}
                </td>
              </tr>
              {data.class === 'eltex' && (
                <tr>
                  <td>
                    <span>{locale[currentLocale].systemInfo.mac}</span>
                  </td>
                  <td>
                    <span>
                      {data.type !== 'switch' &&
                      data.result.hardware_info &&
                      typeof data.result.hardware_info === 'object' &&
                      'mac' in data.result.hardware_info
                        ? data.result.hardware_info.mac
                        : '-'}
                    </span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <table className="column">
            <tbody>
              <tr>
                <td>
                  <span>{locale[currentLocale].time.dateTime}</span>
                </td>
                <td>
                  {data.result.time?.datetime ? (
                    <span>
                      {new Date(String(data.result.time?.datetime)).toLocaleString(currentLocale, {
                        timeZone: 'UTC',
                      })}
                    </span>
                  ) : (
                    <span>-</span>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <span>{locale[currentLocale].time.localTimezone}</span>
                </td>
                <td>
                  {data.result.time?.local_timezone ? (
                    <span>{data.result.time?.local_timezone}</span>
                  ) : (
                    <span>-</span>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <span>{locale[currentLocale].time.unixTime}</span>
                </td>
                <td>
                  {data.result.time?.unix_time ? (
                    <span>{data.result.time?.unix_time}</span>
                  ) : (
                    <span>-</span>
                  )}
                </td>
              </tr>
              {data.class === 'eltex' && (
                <tr>
                  <td>
                    <span>{locale[currentLocale].systemInfo.serialNumber}</span>
                  </td>
                  <td>
                    {data.type !== 'switch' &&
                    data.result.hardware_info &&
                    'serialNumber' in data.result.hardware_info &&
                    typeof data.result.hardware_info.serialNumber === 'string' ? (
                      <span>{data.result.hardware_info.serialNumber}</span>
                    ) : (
                      <span>-</span>
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="break" />
          {(data.type === 'router' || data.type === 'firewall') &&
            data.class === 'cisco' &&
            data.result.inventory && <InventoryNetDevices data={data} />}
        </>
      )}
    </WrapperMenu>
  );
}
