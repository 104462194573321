import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useState } from 'react';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
import localeSettings from '../../../utils/i18n/settingsLocales/settings.json';
import { getWhiteLIstId } from '../../../services/settings-service';
import type { BulletinWhitelistDashboardResponse } from '../../../types/__generated/on-premise-solution/api/bulletinWhitelistDashboardResponse.v1';
import { WhiteListDescriptionMetaBlock } from './description/WhiteListDescriptionMetaBlock';
import { WhiteListDescriptionContent } from './description/WhiteListDescriptionContent';
import { useAuditModalContext } from '../../../hooks/useAuditModalContext';
import { VulnModal } from '../../common/modal/vulnModal/VulnModal';
import { Button } from '../../common/baseElements/Button';
import { getUserData } from '../../../services/local-storage-service';
import { useWhiteListContext } from '../../../hooks/useWhiteListContext';
import type { TExtendedBulletinWhitelist } from '../../../utils/helpers/types';
import { AddWhiteListForm } from './form/AddWhiteListForm';
import { DeleteModal } from '../../common/modal/DeleteModal';
import { useAssetCreatePropContext } from '../../../hooks/useAssetCreatePropContext';
import { AddVulnToWLForm } from './form/AddVulnToWLForm';
import { EntityDescription } from '../../templates/EntityDescription';
import { useWhiteListMethods } from '../../../hooks/components/useWhiteListMethods';

export function WhiteListDescription(): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeSettings;

  const history = useHistory();

  const currentUser = getUserData();

  const [isConfirm, setConfirm] = useState(false);

  const { bulletinWhitelistId } = useParams<{ bulletinWhitelistId: string }>();

  const { whitelistUpdate, isWhiteListForm } = useWhiteListContext();

  const { editWhiteList, handleStatusWhiteList, mutationDeleteWhiteList } = useWhiteListMethods();

  const { cveName } = useAuditModalContext();

  const { isAddVulnToWLForm } = useAssetCreatePropContext();

  const query = useQuery<BulletinWhitelistDashboardResponse, ApiError>(
    ['bulletinWhitelistId', bulletinWhitelistId],
    () => getWhiteLIstId(bulletinWhitelistId),
  );

  const createdByID = query.data?.createdBy.source === 'user' && query.data.createdBy.id;

  const handleEdit = (): void => {
    const data = query.data as TExtendedBulletinWhitelist;
    editWhiteList(data);
  };

  const handleConfirm = (): void => {
    setConfirm(true);
  };

  const handleDelete = (): void => {
    mutationDeleteWhiteList.mutate(query.data!.auditWhitelistId);
    history.goBack();
    setConfirm(false);
  };

  return (
    <EntityDescription
      isLoading={query.isLoading}
      isNotFound={query.error?.code === '404'}
      entity={{
        id: bulletinWhitelistId,
        type: 'wl',
        name: query.data?.name,
      }}
      topactions={
        currentUser?.role !== 'reader_user' ? (
          <>
            <Button
              fill="action"
              onClick={handleEdit}
              disabled={currentUser?.role === 'user' && createdByID !== currentUser?.id}
            >
              {localeSettings[currentLocale].whiteList.grid.edit}
            </Button>
            <Button
              onClick={(): void =>
                handleStatusWhiteList(query.data!.isDisabled, query.data!.auditWhitelistId)
              }
              disabled={currentUser?.role === 'user' && createdByID !== currentUser?.id}
            >
              {query.data?.isDisabled
                ? localeSettings[currentLocale].whiteList.grid.setActiveMenu
                : localeSettings[currentLocale].whiteList.grid.setNotActive}
            </Button>
            <Button
              fill="delete"
              onClick={handleConfirm}
              disabled={currentUser?.role === 'user' && createdByID !== currentUser?.id}
            >
              {localeSettings[currentLocale].whiteList.grid.delete}
            </Button>
          </>
        ) : null
      }
      className="whitelist-description"
    >
      <DeleteModal
        isConfirm={isConfirm}
        setConfirm={setConfirm}
        handleDelete={handleDelete}
        name={query.data?.name || ''}
        countInside={query.data?.totalBulletins}
      />
      <WhiteListDescriptionMetaBlock data={query.data} />
      <WhiteListDescriptionContent
        scope={query.data?.scope}
        bulletins={query.data?.bulletins}
        softwareList={query.data?.softwareList}
      />
      {cveName && <VulnModal />}
      {((whitelistUpdate && !isWhiteListForm) || (!whitelistUpdate && isWhiteListForm)) && (
        <AddWhiteListForm />
      )}
      {isAddVulnToWLForm && <AddVulnToWLForm />}
    </EntityDescription>
  );
}
