import { Grid, GridColumn as Column, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { cvssScoreWidth, PAGEABLE_DATA } from '../../utils/helpers/constants';
import type { ICommonNetworkDeviceList } from '../../utils/helpers/types';
import { handleSort } from '../../utils/sorting-in-table';
import localeDevice from '../../utils/i18n/devices/devices.json';
import { CellDeviceName } from '../features/Devices/cellComponents/CellDeviceName';
import { CellOs } from '../features/Devices/cellComponents/CellOs';
import { CellType } from '../features/Devices/cellComponents/CellType';
import { CellClass } from '../features/Devices/cellComponents/CellClass';
import { CellMenu } from '../features/Devices/cellComponents/CellMenu';
import { CellSelectDevice } from '../features/Devices/cellComponents/CellSelectDevice';
import { CellDeviceVulnerabilities } from '../features/Devices/cellComponents/CellDeviceVulnerabilities';
import { CellDeviceModel } from '../features/Devices/cellComponents/CellDeviceModel';
import { ScoreCvssCell } from './cells/ScoreCvssCell';
import { VHeaderCell } from './VHeaderCell';
import { ScoreVCell } from './cells/ScoreVCell';
import { CellDeviceAssetId } from '../features/Devices/cellComponents/CellDeviceAssetId';
import { getUserData } from '../../services/local-storage-service';
import { AccountCell } from '../features/Host/hostList/cellHostList/AccountCell';

export function CommonNetworkDeviceList(props: ICommonNetworkDeviceList): React.ReactElement {
  const { sort, data, page, total, pageChange, setSort, dataForHooks, conditionSort } = props;

  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeDevice;

  const currentUser = getUserData();

  console.log(dataForHooks);

  return (
    <LocalizationProvider language={currentLocale}>
      <IntlProvider locale={currentLocale.substring(0, 2)}>
        <Grid
          pageable={total && total > conditionSort ? PAGEABLE_DATA : false}
          sortable
          sort={sort}
          data={data}
          skip={page.skip}
          take={page.take}
          total={total || 0}
          onPageChange={pageChange}
          onSortChange={(e: GridSortChangeEvent): void => handleSort(e, setSort)}
          scrollable="none"
          className={
            total && total < conditionSort && dataForHooks.componentName !== 'VulnModalNetDev'
              ? 'no-pageable-k-grid'
              : ''
          }
        >
          {dataForHooks.componentName === 'AddDeviceList' && (
            <Column
              field="addAsset"
              // title={localeDevice[currentLocale].form.selectDevice}
              title=" "
              cell={CellSelectDevice}
              width="40px"
            />
          )}
          <Column
            field="latestAudit.maxCvssScore"
            title={localeDevice[currentLocale].grid.cvssScore}
            filter="text"
            cell={ScoreCvssCell}
            width={cvssScoreWidth.sort}
          />
          <Column
            field="latestAudit.maxVFstec"
            title="v"
            filter="text"
            cell={ScoreVCell}
            width={cvssScoreWidth.sort}
            headerCell={VHeaderCell}
          />
          {dataForHooks.componentName !== 'DeviceList' &&
          dataForHooks.componentName !== 'SensorDeviceListTab' ? (
            <Column
              field="latestInventorization.hostname"
              title={localeDevice[currentLocale].grid.hostName}
              filter="text"
              width="180px"
              cell={CellDeviceAssetId}
            />
          ) : (
            <Column
              field="latestInventorization.hostname"
              title={localeDevice[currentLocale].grid.hostName}
              filter="text"
              cell={CellDeviceName}
              width="200px"
            />
          )}
          <Column field="latestInventorization.ip" title="IP" filter="text" width="132px" />
          <Column
            field="latestAudit.vulnerabilities.count"
            title={localeDevice[currentLocale].grid.vulnerabilities}
            filter="text"
            cell={CellDeviceVulnerabilities}
            // width="90px"
            width={dataForHooks.componentName === 'AddDeviceList' ? '80px' : '132px'}
          />
          <Column
            field="type"
            title={localeDevice[currentLocale].grid.type}
            cell={CellType}
            width="76px"
            // width={dataForHooks.componentName === 'AddDeviceList' ? '80px' : '100px'}
          />
          <Column
            field="class"
            title={localeDevice[currentLocale].grid.class}
            cell={CellClass}
            // width="auto"
            width="156px"
          />
          <Column
            field="latestInventorization.os.name"
            title="OS"
            filter="text"
            cell={CellOs}
            // width="480px"
            // width={dataForHooks.componentName === 'AddDeviceList' ? '420px' : '500px'}
          />
          <Column
            field="latestInventorization.model.name"
            title={localeDevice[currentLocale].grid.model}
            filter="text"
            cell={CellDeviceModel}
            width={dataForHooks.componentName === 'AddDeviceList' ? '200px' : '200px'}
            // width="240px"
          />
          {currentUser?.role === 'super_admin' &&
            dataForHooks.componentName !== 'VulnModalHosts' && (
              <Column
                field="account.name"
                title={localeDevice[currentLocale].form.account}
                filter="text"
                width="124px"
                cell={dataForHooks.componentName === 'DeviceList' ? AccountCell : undefined}
              />
            )}
          {dataForHooks.componentName !== 'AddDeviceList' && (
            <Column cell={CellMenu} width="40px" />
          )}
        </Grid>
      </IntlProvider>
    </LocalizationProvider>
  );
}
