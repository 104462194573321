import type { GridCellProps } from '@progress/kendo-react-grid';
import { useState } from 'react';
import { Button } from '../../../../common/baseElements/Button';
import { getUserData } from '../../../../../services/local-storage-service';
import { DeleteConfirmModal } from '../../../../common/modal/DeleteConfirmModal';
import localeTicket from '../../../../../utils/i18n/security/vulnerabilities.json';
import { useTicketMethods } from '../../../../../hooks/components/useTicketMethods';
import type { TicketDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/ticketsDashboardResponse.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeTicket;

export function CellMenuTicketList(props: GridCellProps): React.ReactElement {
  const currentUser = getUserData();

  const { dataItem } = props;

  const { summary, ticketId }: TicketDashboardResponse = dataItem;

  const [isConfirmDel, setConfirmDel] = useState(false);

  const { hadleDeleteTicket, isErrorDelTicket, errorMessageDelTicket, handleEdit } =
    useTicketMethods();

  return (
    <>
      {isConfirmDel && (
        <DeleteConfirmModal
          name={summary}
          type="ticket"
          onClose={(): void => setConfirmDel(false)}
          handleDelete={(): void => hadleDeleteTicket(ticketId, setConfirmDel)}
          errorMessage={isErrorDelTicket ? errorMessageDelTicket : undefined}
        />
      )}
      <td className="cell_menu">
        <div className="groups__menu">
          <div className="dropdown__container">
            <span className="k-icon k-i-more-vertical" />
            <div className="dropdown__content">
              <Button fill="menu" onClick={(): void => handleEdit(dataItem)}>
                {localeTicket[currentLocale].tickets.grid.menu.edit}
              </Button>
              {(currentUser?.role === 'super_admin' || currentUser?.role === 'account_admin') && (
                <Button fill="menu" onClick={(): void => setConfirmDel(true)}>
                  {localeTicket[currentLocale].tickets.grid.menu.delete}
                </Button>
              )}
            </div>
          </div>
        </div>
      </td>
    </>
  );
}
