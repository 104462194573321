import '../../TaskDescription.module.scss';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import type { State, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { useEffect, useRef, useState } from 'react';
import { Filter } from '@progress/kendo-react-data-tools';
import type { ExcelExport } from '@progress/kendo-react-excel-export';
import { NavLink, useParams } from 'react-router-dom';
import { FILTER_INITIAL } from '../../../../../utils/helpers/constants';
import localeAuditTask from '../../../../../utils/i18n/taskLocale/auditTask.json';
import locale from '../../../../../utils/i18n/security/vulnerabilities.json';
import { useAuditModalContext } from '../../../../../hooks/useAuditModalContext';
import { collectVulnsData } from '../../../../../utils/helpers/audit-helpers';
import {
  handlerCustomSearch,
  onFilterChange,
} from '../../../../../utils/helpers/local-filter-helpers';
import { useLocalExcelExport } from '../../../../../hooks/components/useLocalExcelExport';
import { LOCAL_FILTER_VULNS_LIST } from '../../../../../utils/helpers/constant-local-filter-task';
import { VulnModal } from '../../../../common/modal/vulnModal/VulnModal';
import { Input } from '../../../../common/baseElements/Input';
import { Button } from '../../../../common/baseElements/Button';
import styles from './packageNameModal.module.scss';
import type {
  IPkgVulns,
  TVulnerabilitiesList,
  TVulnOnPage,
} from '../../../../../utils/helpers/types';
import { useAssetCreatePropContext } from '../../../../../hooks/useAssetCreatePropContext';
import { AddVulnToWLForm } from '../../../WhiteList/form/AddVulnToWLForm';
import { useWhiteListContext } from '../../../../../hooks/useWhiteListContext';
import { AddWhiteListForm } from '../../../WhiteList/form/AddWhiteListForm';
import { VulnerabilitiesListTable } from './VulnerabilitiesListTable';
import { useAssetActions } from '../../../../../hooks/components/useAssetActions';
import { useTicketMethods } from '../../../../../hooks/components/useTicketMethods';
import { useCommonContext } from '../../../../../hooks/useCommonContext';

export function VulnerabilitiesList(props: TVulnerabilitiesList): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeAuditTask;

  const { dataResult, hostName, vulnsWhiteList, id } = props;

  const pageLimitGrid: { [key: string]: string } =
    localStorage.getItem('pageLimitGrid') &&
    JSON.parse(localStorage.getItem('pageLimitGrid') || '');

  const initialDataState: State = {
    sort: [{ field: 'maxScore', dir: 'desc' }],
    take:
      pageLimitGrid && pageLimitGrid.VulnerabilitiesList
        ? Number(pageLimitGrid.VulnerabilitiesList)
        : 10,
    skip: 0,
    filter: FILTER_INITIAL,
  };

  const fieldFindInTable = 'id';

  const [vulnsData, setVulnsData] = useState<IPkgVulns[] | undefined>(undefined);

  const [vulnsDataWl, setVulnsDataWl] = useState<IPkgVulns[] | undefined>(undefined);

  const [dataState, setDataState] = useState<State>(initialDataState);

  const { assetId } = useParams<{ assetId: string }>();

  const [filter, setFilter] = useState(FILTER_INITIAL);

  const [customSearch, setCustomSearch] = useState('');

  const [isWhiteListTable, setWhiteListTable] = useState(false);

  const [isEnabledWl, setEnabledWl] = useState(false);

  const [allVulnOnPageData, setAllVulnOnPageData] = useState<TVulnOnPage[]>([]);

  const filterValue = useRef<CompositeFilterDescriptor | null>(null);

  const refWrapper = useRef<HTMLDivElement>(null);

  const { selectedVulns, setSelectedVulns, isAddVulnToWLForm, setAddVulnToWLForm } =
    useAssetCreatePropContext();

  const { isWhiteListForm } = useWhiteListContext();

  const { handleTicket } = useTicketMethods();

  useEffect(() => {
    setVulnsData(
      collectVulnsData(dataResult?.cumulativeData.vulns, dataResult?.cumulativeData.metrics),
    );
    setVulnsDataWl(
      collectVulnsData(
        dataResult?.whitelisted?.cumulativeData.vulns,
        dataResult?.whitelisted?.cumulativeData.metrics,
      ),
    );
  }, [
    dataResult?.cumulativeData.metrics,
    dataResult?.cumulativeData.vulns,
    dataResult?.vulnerableObjects,
    dataResult?.whitelisted?.cumulativeData.metrics,
    dataResult?.whitelisted?.cumulativeData.vulns,
    dataResult?.whitelisted?.vulnerableObjects,
  ]);

  useEffect(() => {
    if (!refWrapper.current?.className.includes('vulns-soft')) {
      setEnabledWl(true);
    }

    const wlBlock = document.getElementById('idVulnerabilities');

    if (wlBlock) {
      wlBlock.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, [isWhiteListTable]);

  const { cveName } = useAuditModalContext();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _export = useRef<ExcelExport | null>(null);

  const excelExport = useLocalExcelExport(dataState, _export, vulnsData, setDataState);

  const { selectAllVulnOnPage } = useAssetActions();

  const { accountOfAsset } = useCommonContext();

  useEffect(() => {
    if (dataState && vulnsData) {
      const slicedvulnOnPageData = vulnsData
        .sort((a, b) => Number(b.maxScore) - Number(a.maxScore))
        .slice(dataState.skip, (dataState.skip || 0) + (dataState.take || 0))
        .map((vuln) => ({ bulletinId: vuln.id }));

      setAllVulnOnPageData(slicedvulnOnPageData);
    }
  }, [dataState.skip, dataState.take]);

  return (
    <>
      <div
        className={
          vulnsWhiteList
            ? `${styles.vulnsSoftWhiteList} ${styles.vulnsSoft}`
            : `vulns-soft ${styles.vulnsSoft}`
        }
        ref={refWrapper}
        id={id}
      >
        {vulnsWhiteList || vulnsData ? (
          <>
            <LocalizationProvider language={currentLocale}>
              <IntlProvider locale={currentLocale.substring(0, 2)}>
                <div className="filter-line">
                  <Filter
                    value={filter}
                    onChange={(event): void =>
                      onFilterChange(
                        event,
                        setCustomSearch,
                        setDataState,
                        dataState,
                        setFilter,
                        filterValue,
                        customSearch,
                        fieldFindInTable,
                      )
                    }
                    fields={LOCAL_FILTER_VULNS_LIST[currentLocale]}
                  />
                  {filter.filters.length === 0 && (
                    <Input
                      name="customSearch"
                      placeholder={localeAuditTask[currentLocale].customFilterId}
                      value={customSearch}
                      onChange={(e): void =>
                        handlerCustomSearch(
                          e,
                          setCustomSearch,
                          setDataState,
                          filterValue,
                          dataState,
                          fieldFindInTable,
                        )
                      }
                    />
                  )}
                  {vulnsDataWl && vulnsDataWl.length > 0 && !isEnabledWl && (
                    <div className={styles.whiteListGridTitle}>
                      <img
                        src={`${process.env.PUBLIC_URL}/images/attention_yellow.svg`}
                        alt="attention"
                      />
                      <Button
                        fill="dotted"
                        type="button"
                        onClick={(): void => setWhiteListTable(!isWhiteListTable)}
                      >
                        {!isWhiteListTable
                          ? localeAuditTask[currentLocale].vulnsSoft.table.commontitleWl
                          : localeAuditTask[currentLocale].vulnsSoft.table.commontitleWlHide}
                        ({vulnsDataWl.length})
                      </Button>
                    </div>
                  )}
                  <Button excel fill="flat" onClick={excelExport}>
                    {localeAuditTask[currentLocale].vulnsSoft.table.exportToExcel}
                  </Button>
                </div>
              </IntlProvider>
            </LocalizationProvider>
            {vulnsData && vulnsData.length > 0 && !id && (
              <div className={styles.pkgUpdate}>
                <Button
                  onClick={(): void => selectAllVulnOnPage(allVulnOnPageData)}
                  className="selected-pkgs button-mr20"
                >
                  {localeAuditTask[currentLocale].vulnsSoft.selectAllVulnerabilities}
                </Button>
                {selectedVulns && selectedVulns.length > 0 && (
                  <>
                    <Button
                      onClick={(): void => setAddVulnToWLForm(true)}
                      className="selected-pkgs action button-mr20"
                    >
                      {localeAuditTask[currentLocale].vulnsSoft.excludeSelectedVulnerabilities} (
                      {selectedVulns.length})
                    </Button>
                    <Button
                      onClick={(): void => handleTicket(accountOfAsset)}
                      className="button-mr20"
                    >
                      {localeAuditTask[currentLocale].createTicket}
                    </Button>
                  </>
                )}
                {selectedVulns && selectedVulns.length > 0 && (
                  <Button
                    onClick={(): void => setSelectedVulns(null)}
                    className="cancel-selected-pkgs"
                  >
                    {localeAuditTask[currentLocale].cancelSelectedPackages}
                  </Button>
                )}
              </div>
            )}
            <VulnerabilitiesListTable
              hostName={hostName}
              vulnsData={vulnsData || []}
              id={id}
              exportRef={_export}
              dataState={dataState}
              vulnsWhiteList={vulnsWhiteList}
              setDataState={setDataState}
              pageLimitGrid={pageLimitGrid}
            />
            {cveName && <VulnModal />}
          </>
        ) : (
          <p>{localeAuditTask[currentLocale].vulnsSoft.table.noVulnsFound}</p>
        )}
      </div>
      {dataResult?.whitelisted && vulnsDataWl && vulnsDataWl.length > 0 && !isEnabledWl && (
        <div className={styles.actionsWLWrap}>
          <div className={styles.whiteListGridTitle}>
            <span className="whitelist-grid-title__text">
              {locale[currentLocale].vulnsTable.titleWl}
            </span>
            <NavLink
              className="dashboard__link"
              to={`/lk/audit/exceptions${
                assetId
                  ? `?skip=0&limit=15&sortField=createdAt&sortOrder=desc&assetIds[$eq]=${assetId}`
                  : ''
              }`}
            >
              {locale[currentLocale].vulnsTable.titleWlLink}
            </NavLink>{' '}
            <Button
              fill="dotted"
              type="button"
              onClick={(): void => setWhiteListTable(!isWhiteListTable)}
            >
              {isWhiteListTable
                ? localeAuditTask[currentLocale].vulnsSoft.table.linkWlHide
                : localeAuditTask[currentLocale].vulnsSoft.table.linkWlShow}
              {localeAuditTask[currentLocale].vulnsSoft.table.linkWlVulns} ({vulnsDataWl.length})
            </Button>
          </div>
        </div>
      )}
      {isWhiteListTable && (
        <VulnerabilitiesList
          dataResult={dataResult}
          hostName={hostName}
          vulnsWhiteList={vulnsDataWl}
          id="idVulnerabilities"
        />
      )}
      {isAddVulnToWLForm && <AddVulnToWLForm />}
      {isWhiteListForm && !isAddVulnToWLForm && <AddWhiteListForm />}
    </>
  );
}
