import type { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import type { IUrlParamsList } from './getUrl-list-helper';

export function fillFiltersTicketList(
  urlParams: IUrlParamsList,
  filterValue: React.MutableRefObject<CompositeFilterDescriptor | null>,
  setFilter: (value: React.SetStateAction<CompositeFilterDescriptor>) => void,
  setFilterStatus: React.Dispatch<React.SetStateAction<boolean>>,
): void {
  if (urlParams.filterUrlData.openTicketCount && urlParams.filterUrlData.ticketSystemId) {
    filterValue.current = {
      filters: [
        {
          field: 'status',
          operator: 'ne',
          value: 'closed',
        },
        {
          field: 'ticketSystemId',
          operator: 'eq',
          value: urlParams.filterUrlData.ticketSystemId,
        },
      ],
      logic: 'and',
    } as CompositeFilterDescriptor;
    setFilter(filterValue.current);
  } else if (!urlParams.filterUrlData.openTicketCount && urlParams.filterUrlData.ticketSystemId) {
    setFilterStatus(true);
    filterValue.current = {
      filters: [
        {
          field: 'ticketSystemId',
          operator: 'eq',
          value: urlParams.filterUrlData.ticketSystemId,
        },
      ],
      logic: 'and',
    } as CompositeFilterDescriptor;
    setFilter(filterValue.current);
  }
}
