/* eslint-disable no-nested-ternary */
import type {
  AssetDeviceDashboardResponse,
  AssetHypervisorDashboardResponse,
  AssetRouterDashboardResponse,
  AssetSwitchDashboardResponse,
} from '../../types/__generated/on-premise-solution/api/assetsDevicesDashboardResponse.v1';
import type { AuditWindowsResult } from '../../types/__generated/on-premise-solution/api/audit.v1';
import type { AuditLinuxDifference } from '../../types/__generated/on-premise-solution/api/auditDifferenceResponse.v1';
import type {
  AuditHypervisorResult,
  AuditNetworkDeviceResult,
  AuditResponse,
  AuditResult,
  AuditWindowsResponse,
} from '../../types/__generated/on-premise-solution/api/auditsResponse.v1';
import type { IPkgDataModal, IPkgVulns } from './types';

export function collectpkgDataModal(
  auditData: AuditResponse | null,
  pkgName: {
    name: string;
    version: string;
    wl?: boolean;
  } | null,
): IPkgDataModal {
  let pkgDataModal = {} as IPkgDataModal;

  if (!pkgName?.wl) {
    auditData?.result.vulnerableObjects.forEach((vObj) => {
      if (vObj.name === pkgName?.name && vObj.version === pkgName.version) {
        const vulns: number = vObj.vulns.length;
        const updates: number | undefined = vObj.updates?.length;
        const pkgVulns = [] as IPkgVulns[];

        vObj.vulns.forEach((v) => {
          const keyValue = v.id;
          let maxScore: number | null = null;
          let sourceMaxScore: string | undefined;
          let severityMaxScore: string | undefined;
          const scores = [] as number[];
          let cwe: string | null = null;
          let vector: string | null = null;
          let cweName: string | null = null;

          if (
            auditData.result.cumulativeData.vulns &&
            auditData.result.cumulativeData.vulns[keyValue]
          ) {
            auditData.result.cumulativeData.vulns[keyValue].metrics?.forEach((m) => {
              if (m.cvss?.score) scores.push(Number(m?.cvss?.score));
            });
            const tempScore = Math.max(...scores);
            if ((maxScore || 0) < tempScore) maxScore = tempScore;

            auditData.result.cumulativeData.vulns[keyValue].metrics?.forEach((m) => {
              if (m.cvss && Number(m.cvss.score) === (maxScore || 0)) {
                vector = m.cvss.vector;
                if ('source' in m && 'severity' in m) {
                  sourceMaxScore = m.source;
                  severityMaxScore = m.severity;
                }
                if (m.cwe) [cwe] = m.cwe;
              }
            });

            auditData.result?.cumulativeData.metrics?.forEach((m) => {
              if (m.cwe && cwe && m.cwe[cwe]) cweName = m.cwe[cwe].name;
            });

            const related = [] as string[];
            v.related?.forEach((r) => related.push(r));
            const vulnsElement = {
              ...auditData.result.cumulativeData.vulns[keyValue],
              maxScore,
              vector,
              sourceMaxScore,
              severityMaxScore,
              cwe,
              cweName,
              related,
            } as IPkgVulns;
            pkgVulns.push(vulnsElement);
          }
        });
        pkgDataModal = {
          vulns,
          updates,
          pkgVulns,
        };
      }
    });
  } else {
    auditData?.result.whitelisted?.vulnerableObjects.forEach((vObj) => {
      if (vObj.name === pkgName?.name && vObj.version === pkgName.version) {
        const vulns: number = vObj.vulns.length;
        const updates: number | undefined = vObj.updates?.length;
        const pkgVulns = [] as IPkgVulns[];

        vObj.vulns.forEach((v) => {
          const keyValue = v.id;
          let maxScore: number | null = null;
          let sourceMaxScore: string | undefined;
          let severityMaxScore: string | undefined;
          const scores = [] as number[];
          let cwe: string | null = null;
          let vector: string | null = null;
          let cweName: string | null = null;

          if (
            auditData.result.whitelisted?.cumulativeData.vulns &&
            auditData.result.whitelisted?.cumulativeData.vulns[keyValue]
          ) {
            auditData.result.whitelisted?.cumulativeData.vulns[keyValue].metrics?.forEach((m) => {
              if (m.cvss?.score) scores.push(Number(m?.cvss?.score));
            });
            const tempScore = Math.max(...scores);
            if ((maxScore || 0) < tempScore) maxScore = tempScore;

            auditData.result.whitelisted?.cumulativeData.vulns[keyValue].metrics?.forEach((m) => {
              if (m.cvss && Number(m.cvss.score) === (maxScore || 0)) {
                vector = m.cvss.vector;
                if ('source' in m && 'severity' in m) {
                  sourceMaxScore = m.source;
                  severityMaxScore = m.severity;
                }
                if (m.cwe) [cwe] = m.cwe;
              }
            });

            auditData.result?.cumulativeData.metrics?.forEach((m) => {
              if (m.cwe && cwe && m.cwe[cwe]) cweName = m.cwe[cwe].name;
            });

            const related = [] as string[];
            v.related?.forEach((r) => related.push(r));
            const vulnsElement = {
              ...auditData.result.whitelisted?.cumulativeData.vulns[keyValue],
              maxScore,
              vector,
              sourceMaxScore,
              severityMaxScore,
              cwe,
              cweName,
              related,
            } as IPkgVulns;
            pkgVulns.push(vulnsElement);
          }
        });
        pkgDataModal = {
          vulns,
          updates,
          pkgVulns,
        };
      }
    });
  }

  return pkgDataModal;
}

export interface IVulns {
  id: string;
  type: 'cve' | 'advisory';
  related: string[] | null;
  reason: string;
  aliases?: string[] | undefined;
}

export type TAvailableUpdate = {
  softwareCodename: string;
  softwareName: string;
  availableUpdatesCount?: number;
  approvedUpdatesCount?: number;
};
export interface ICollectVulnSoftData {
  maxScore: number;
  name: string;
  version: string;
  updateVersion: string | null;
  vulns: IVulns[];
  totalVulns: number;
  currentWinName?: string | undefined;
  currentVersion: string;
  diffType?: 'closed' | 'noChanges' | 'updated';
  bulletinWhitelistIds?: string[] | undefined;
  type?: string;
  availableUpdate?: TAvailableUpdate;
}

// Creating data for linux packages and windows soft

function changeVersionForHypervisor(
  vulnerableObjects:
    | AuditResult['vulnerableObjects']
    | AuditWindowsResult['vulnerableObjects']
    | AuditNetworkDeviceResult['vulnerableObjects']
    | AuditLinuxDifference['vulnerableObjects']['closed']
    | AuditHypervisorResult['vulnerableObjects']
    | undefined,
): string | undefined {
  if (vulnerableObjects && vulnerableObjects.length > 0) {
    const detectVersion =
      'type' in vulnerableObjects[0] &&
      vulnerableObjects[0].type === 'os' &&
      'detected' in vulnerableObjects[0] &&
      'version' in vulnerableObjects[0].detected
        ? vulnerableObjects[0].detected.version
        : undefined;

    if (detectVersion) {
      const versionData = detectVersion.split('.');

      const versionStr = `${versionData[0]}.${versionData[1]}.Upd${versionData[2]}-${versionData[3]}`;

      return versionStr;
    }
  }

  return undefined;
}

function changeVersionForCheckpoint(
  vulnerableObjects:
    | AuditResult['vulnerableObjects']
    | AuditWindowsResult['vulnerableObjects']
    | AuditNetworkDeviceResult['vulnerableObjects']
    | AuditLinuxDifference['vulnerableObjects']['closed']
    | AuditHypervisorResult['vulnerableObjects']
    | undefined,
): string | undefined {
  if (vulnerableObjects && vulnerableObjects.length > 0) {
    const version =
      'type' in vulnerableObjects[0] &&
      vulnerableObjects[0].type === 'os' &&
      'detected' in vulnerableObjects[0] &&
      'version' in vulnerableObjects[0].detected
        ? vulnerableObjects[0].detected.version
        : undefined;

    if (version) {
      const takeVersionStr = version;

      return takeVersionStr;
    }
  }

  return undefined;
}
export function collectVulnSoft(
  vulnerableObjects:
    | AuditResult['vulnerableObjects']
    | AuditWindowsResult['vulnerableObjects']
    | AuditNetworkDeviceResult['vulnerableObjects']
    | AuditLinuxDifference['vulnerableObjects']['closed']
    | AuditHypervisorResult['vulnerableObjects']
    | undefined,
  vulns:
    | AuditResult['cumulativeData']['vulns']
    | AuditWindowsResult['cumulativeData']['vulns']
    | AuditNetworkDeviceResult['cumulativeData']['vulns']
    | Exclude<AuditResult['whitelisted'], undefined>['cumulativeData']['vulns']
    | Exclude<AuditWindowsResult['whitelisted'], undefined>['cumulativeData']['vulns']
    | Exclude<AuditNetworkDeviceResult['whitelisted'], undefined>['cumulativeData']['vulns']
    | undefined,
  diffType?: 'closed' | 'noChanges' | 'updated',
  assetType?: 'hypervisor' | 'linux' | 'windows' | 'network_device' | undefined,
  classOfDvice?: string | undefined,
): ICollectVulnSoftData[] | undefined {
  const data = vulnerableObjects?.map((o) => {
    let maxScore: number | null = null;
    const whitelists: string[] = [];

    o.vulns.forEach((v) => {
      const keyValue = v.id;

      if (
        (v.type === 'cve' || v.type === 'advisory') &&
        'auditWhitelistIds' in o &&
        o.auditWhitelistIds &&
        o.auditWhitelistIds.length > 0
      ) {
        whitelists.push(
          ...(o.auditWhitelistIds as string[]).filter((wlid) => whitelists.indexOf(wlid) === -1),
        );
      }

      const scores = [] as number[];

      if (vulns && vulns[keyValue]) {
        vulns[keyValue].metrics?.forEach((m) => {
          // const vulnsAditWhitelistIds = vulns[keyValue].auditWhitelistIds || [];
          if (m.cvss?.score) scores.push(Number(m.cvss?.score));
          // if (vulnsAditWhitelistIds && vulnsAditWhitelistIds.length > 0) {
          //   whitelists.push(
          //     ...(vulns[keyValue].auditWhitelistIds as string[]).filter(
          //       (wlid) => whitelists.indexOf(wlid) === -1,
          //     ),
          //   );
          // }
        });
        const vulnsAditWhitelistIds = vulns[keyValue].auditWhitelistIds || [];

        if (vulnsAditWhitelistIds && vulnsAditWhitelistIds.length > 0) {
          whitelists.push(
            ...(vulns[keyValue].auditWhitelistIds as string[]).filter(
              (wlid) => whitelists.indexOf(wlid) === -1,
            ),
          );
        }
      }
      const tempScore = Math.max(...scores);

      if ((maxScore || 0) < tempScore) maxScore = tempScore;
    });

    let win11Name = '';

    if (
      'type' in vulnerableObjects[0] &&
      vulnerableObjects[0].type === 'os' &&
      vulnerableObjects[0].name.toLowerCase().includes('windows 10') &&
      vulnerableObjects[0].detected.name.toLowerCase().includes('windows 11')
    ) {
      win11Name = vulnerableObjects[0].detected.name;
    }

    // if (vulns) {
    //   Object.values(vulns).forEach((vuln: IPkgVulns) => {
    //     if (
    //       'auditWhitelistIds' in vuln &&
    //       vuln.auditWhitelistIds &&
    //       Array.isArray(vuln.auditWhitelistIds) &&
    //       vuln.auditWhitelistIds.length > 0
    //     ) {
    //       whitelists.push(
    //         ...(vuln.auditWhitelistIds as string[]).filter(
    //           (wlid) => whitelists.indexOf(wlid) === -1,
    //         ),
    //       );
    //     }
    //   });
    // }

    return {
      maxScore,
      // name: win11Name || o.name,
      name:
        'type' in o &&
        o.type === 'os' &&
        o.name.toLowerCase().includes('windows 10') &&
        o.detected.name.toLowerCase().includes('windows 11')
          ? o.detected.name
          : o.name,
      version:
        assetType === 'hypervisor'
          ? changeVersionForHypervisor(vulnerableObjects)
          : classOfDvice === 'checkpoint'
          ? changeVersionForCheckpoint(vulnerableObjects)
          : o.version,
      currentVersion: o.version,
      updateVersion: o.updates && o.updates[o.updates.length - 1].to_version,
      vulns: o.vulns,
      totalVulns: o.vulns.length,
      currentWinName: win11Name ? o.name : undefined,
      diffType,
      bulletinWhitelistIds: whitelists,
      type: assetType,
      availableUpdate: 'availableUpdate' in o ? o.availableUpdate : undefined,
    };
  }) as ICollectVulnSoftData[] | undefined;

  return data;
}

// Creating data for vulnerabilities
export function collectVulnsData(
  vulns:
    | AuditResult['cumulativeData']['vulns']
    | AuditWindowsResult['cumulativeData']['vulns']
    | AuditNetworkDeviceResult['cumulativeData']['vulns']
    | Exclude<AuditResult['whitelisted'], undefined>['cumulativeData']['vulns']
    | Exclude<AuditWindowsResult['whitelisted'], undefined>['cumulativeData']['vulns']
    | Exclude<AuditNetworkDeviceResult['whitelisted'], undefined>['cumulativeData']['vulns']
    | undefined,
  metrics:
    | AuditResult['cumulativeData']['metrics']
    | AuditWindowsResult['cumulativeData']['metrics']
    | AuditNetworkDeviceResult['cumulativeData']['metrics']
    | undefined,
  diffType?: 'closed' | 'updated' | 'noChanges',
): IPkgVulns[] {
  const dataObj = vulns as object;

  const data = vulns
    ? Object.values(dataObj).map((o: IPkgVulns) => {
        const whitelists: string[] = [];

        let maxScore: number | null = null;

        let sourceMaxScore: string | undefined;

        let severityMaxScore: string | undefined;

        const scores = [] as number[];

        let cwe: string | null = null;

        let vector: string | null = null;

        let cweName: string | null = null;

        o.metrics?.forEach((m) => {
          if (m.cvss?.score) scores.push(Number(m?.cvss?.score));
        });

        const tempScore = Math.max(...scores);

        if ((maxScore || 0) < tempScore) maxScore = tempScore;
        o.metrics?.forEach((m) => {
          if (m.cvss && Number(m.cvss.score) === (maxScore || 0)) {
            vector = m.cvss.vector;
            if ('source' in m && 'severity' in m) {
              sourceMaxScore = m.source;
              severityMaxScore = m.severity;
            }
            if (m.cwe) [cwe] = m.cwe;
          }
        });

        if (
          'auditWhitelistIds' in o &&
          o.auditWhitelistIds &&
          Array.isArray(o.auditWhitelistIds) &&
          o.auditWhitelistIds.length > 0
        ) {
          whitelists.push(
            ...(o.auditWhitelistIds as string[]).filter((wlid) => whitelists.indexOf(wlid) === -1),
          );
        }

        metrics?.forEach((m) => {
          if (m.cwe && cwe && m.cwe[cwe]) cweName = m.cwe[cwe].name;
        });

        return {
          ...o,
          aliases: o.aliases ? o.aliases[0] : undefined,
          bulletinWhitelistIds: whitelists,
          maxScore,
          sourceMaxScore,
          severityMaxScore,
          cwe,
          cweName,
          vector,
          diffType,
        };
      })
    : ([] as IPkgVulns[]);

  return data;
}

export function handleVulnModal(
  value: string,
  setOpen: (v: boolean) => void,
  setCveName: (v: string | null) => void,
): void {
  setCveName(value);
  setOpen(true);
}

export function handleVectorModal(
  value: string,
  setOpen: (v: boolean) => void,
  setCvssVector: (v: string | null) => void,
  setCveName?: (v: string | null) => void,
): void {
  setCvssVector(value);
  setOpen(true);
  if (setCveName) setCveName(null);
}

export function createOsNameForHypervisor(
  hypervisorResp: AssetHypervisorDashboardResponse | undefined,
): string {
  let resultValue = '';

  if (hypervisorResp?.latestInventorization.os) {
    const osVersionData = hypervisorResp?.latestInventorization.os.version.split('.');

    const build = hypervisorResp?.latestInventorization.os.build;

    const update = hypervisorResp?.latestInventorization.os.update;

    resultValue = `${hypervisorResp?.latestInventorization.os?.name} ${osVersionData[0]}.${osVersionData[1]}.Upd${update}-${build}`;
  }

  return resultValue;
}

export function createOsNameForCheckpoint(
  checkpointResp:
    | AssetRouterDashboardResponse
    | AssetDeviceDashboardResponse
    | AssetSwitchDashboardResponse
    | undefined,
): string {
  let resultValue = '';

  if (checkpointResp?.latestInventorization.os) {
    const version = checkpointResp?.latestInventorization.os.version;

    const take =
      checkpointResp.class === 'checkpoint' && checkpointResp?.latestInventorization.os.take;

    if (take) {
      resultValue = `${version}.Take${take}`;
    } else {
      resultValue = version;
    }
  }

  return resultValue;
}
