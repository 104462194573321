/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect, useRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Filter, FilterChangeEvent } from '@progress/kendo-react-data-tools';
import type { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { useLocation } from 'react-router-dom';
import { Typography } from '@progress/kendo-react-common';
import {
  Grid,
  GridColumn as Column,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid';
import { useQuery, useQueryClient } from 'react-query';
import { Button } from '../../common/baseElements/Button';
import { FILTER_INITIAL, PAGEABLE_DATA } from '../../../utils/helpers/constants';
import type { IDataHooks, IPageState } from '../../../utils/helpers/types';
import localeTask from '../../../utils/i18n/taskLocale/task.json';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
import { getTasks } from '../../../services/task-service';
import { СommonTableLoader } from '../../common/tableLoader/CommonTableLoader';
import { handleError } from '../../../utils/errors';
import { useNotificationContext } from '../../../hooks/useNotificationContext';
import { handleSort } from '../../../utils/sorting-in-table';
import { Status } from './cellsTaskList/Status';
import { AssetScope } from './cellsTaskList/AssetScope';
import { Actions } from './cellsTaskList/Actions';
import { MenuTaskList } from './cellsTaskList/MenuTaskList';
import { TaskName } from './cellsTaskList/TaskName';
import { useTaskContext } from '../../../hooks/useTaskContext';
import { Schedule } from './cellsTaskList/Schedule';
import { LastStart } from './cellsTaskList/LastStart';
import { getUrlTaskList } from '../../../utils/helpers/task-list-helper';
import { fillFiltersTaskList } from '../../../utils/helpers/task-list-fill-filter-helper';
import { handleFilteringTest, IFilterVal } from '../../../utils/filtering-in-table-test';
import { useCustomSearch } from '../../../hooks/components/useCustomSearch';
import { usePageChange } from '../../../hooks/components/usePageChange';
import { useFilter } from '../../../hooks/components/useFilter';
import { FILTER_OPERATORS } from '../../../utils/helpers/constant-filter-operators';
import { FILTER_LIST_TASK } from '../../../utils/helpers/constant-serv-filter-task';
import type { TasksDashboardResponse } from '../../../types/__generated/on-premise-solution/api/tasksDashboardResponse.v1';
import { BoundaryErrorComponent } from '../../common/BoundaryErrorComponent';
import { Input } from '../../common/baseElements/Input';
import { ActionHeaderCell } from './cellsTaskList/ActionHeaderCell';
import { DeleteConfirmModal } from '../../common/modal/DeleteConfirmModal';
import { CellScheduleHeader } from './taskVmDetails/vmDetailsComponents/cells/CellScheduleHeader';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeTask;

export function TaskList(): React.ReactElement {
  const urlParams = getUrlTaskList('TaskList');

  const location = useLocation();

  const queryClient = useQueryClient();

  const [customSearch, setCustomSearch] = useState('');

  const [sort, setSort] = useState<SortDescriptor[]>(urlParams.sort);

  const [page, setPage] = useState<IPageState>(urlParams.page);

  const [filter, setFilter] = useState<CompositeFilterDescriptor>(FILTER_INITIAL);

  const [filterStatus, setFilterStatus] = useState(false);

  const [filterVal, setFilterVal] = useState<IFilterVal[]>([]);

  const filterValue = useRef<CompositeFilterDescriptor | null>(null);

  const filterRef = useRef<CompositeFilterDescriptor>(filter);

  const dataForHooks: IDataHooks = {
    name: 'name',
    componentName: 'TaskList',
  };

  const { createNotification } = useNotificationContext();

  const { setOpenDialog, taskDeleteValues, setTaskDeleteValues, handleDelete } = useTaskContext();

  const debouncedCustomSearch = useCustomSearch(
    setFilterStatus,
    setCustomSearch,
    filter,
    setFilterVal,
    setPage,
    page,
    dataForHooks,
  );

  const filterSearch = useFilter(
    filterValue,
    setFilter,
    customSearch,
    setCustomSearch,
    setFilterVal,
    filterRef,
    filter,
    setPage,
    page,
    dataForHooks,
  );

  const getPage = usePageChange(setPage, urlParams, dataForHooks);

  const query = useQuery<TasksDashboardResponse, ApiError>(
    ['queueTasks', page, filterVal, sort],
    () => getTasks(page, filterVal, sort),
    {
      // staleTime: staleTimeVal,
      // cacheTime: DATA_CACHE_CACHETIME,
      keepPreviousData: true,
    },
  );

  useEffect(() => {
    fillFiltersTaskList(urlParams, filterValue, setFilter, customSearch);
  }, []);

  useEffect(() => {
    queryClient.invalidateQueries(['queueTasks']);
  }, [queryClient]);

  useEffect(() => {
    let delaySearch: NodeJS.Timeout;

    if (filter?.filters?.length > 0) {
      filterRef.current = filter;
      delaySearch = setTimeout(() => {
        handleFilteringTest(filterRef.current, setFilterVal);
        setFilterStatus(false);
      }, 300);
    }

    return () => clearTimeout(delaySearch);
  }, [filter.filters, page.skip, location]);

  useEffect(() => {
    if (query.error) {
      handleError(query.error, createNotification);
    }
  }, [query.isError, query.error, createNotification]);

  const pageChange = (event: GridPageChangeEvent): void => {
    getPage(event);
  };

  const onFilterChange = (event: FilterChangeEvent): void => {
    filterSearch(event);
  };

  const handleCustomSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    debouncedCustomSearch(e);
  };

  const handleRefresh = (): void => {
    setFilterStatus(true);
    queryClient.invalidateQueries('queueTasks').then(() => setFilterStatus(false));
  };

  return (
    <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
      <div className="task">
        {taskDeleteValues && (
          <DeleteConfirmModal
            name={taskDeleteValues.taskName}
            type="task"
            onClose={(): void => setTaskDeleteValues(null)}
            handleDelete={(): void => handleDelete(Number(taskDeleteValues.taskId))}
          />
        )}
        <div className="common-header-page">
          <Typography.h3>{localeTask[currentLocale].taskListTitle}</Typography.h3>
          <div className="topactions">
            <Button
              // // onClick={(): void => history.push('/lk/audit/taskaddedit')}
              fill="action"
              onClick={(): void => {
                setOpenDialog(true);
              }}
            >
              {localeTask[currentLocale].btnCreateTask}
            </Button>
            <Button icon="refresh" onClick={handleRefresh}>
              {localeTask[currentLocale].btnRefresh}
            </Button>
          </div>
        </div>
        <LocalizationProvider language={currentLocale}>
          <IntlProvider locale={currentLocale.substring(0, 2)}>
            <div className="filter-line">
              <Filter
                value={filter}
                onChange={onFilterChange}
                fields={FILTER_LIST_TASK[currentLocale]}
              />
              {filter.filters.length === 0 && (
                <Input
                  name="customSearch"
                  placeholder={localeTask[currentLocale].customSearchName}
                  value={customSearch}
                  onChange={(e): void => handleCustomSearch(e)}
                />
              )}
            </div>
          </IntlProvider>
        </LocalizationProvider>
        <div className="task-list">
          <LocalizationProvider language={currentLocale}>
            <IntlProvider locale={currentLocale.substring(0, 2)}>
              <Grid
                pageable={Number(query.data?.total) > 15 ? PAGEABLE_DATA : false}
                sortable
                sort={sort}
                // className="table task-table"
                data={query?.data?.data}
                skip={page.skip}
                take={page.take}
                total={Number(query.data?.total)}
                filterOperators={FILTER_OPERATORS}
                onPageChange={pageChange}
                onSortChange={(e: GridSortChangeEvent): void => handleSort(e, setSort)}
                scrollable="none"
                className={
                  Number(query?.data?.total) && Number(query?.data?.total) < 15
                    ? 'no-pageable-k-grid'
                    : ''
                }
              >
                <Column
                  cell={Status}
                  title={localeTask[currentLocale].form.status}
                  filter="text"
                  field="status"
                  width="144px"
                />
                <Column
                  headerCell={CellScheduleHeader}
                  cell={Schedule}
                  title={localeTask[currentLocale].form.timeStart}
                  filter="text"
                  field="schedule.repeat"
                  width="280px"
                />
                <Column
                  cell={LastStart}
                  title={localeTask[currentLocale].form.lastStart}
                  filter="text"
                  field="startedAt"
                  width="180px"
                />
                <Column
                  field="actions"
                  cell={Actions}
                  title={localeTask[currentLocale].form.actions}
                  width="180px"
                  headerCell={ActionHeaderCell}
                />
                <Column
                  field="name"
                  title={localeTask[currentLocale].form.taskName}
                  filter="text"
                  cell={TaskName}
                  width="320px"
                />
                <Column
                  cell={AssetScope}
                  title={localeTask[currentLocale].form.scope}
                  filter="text"
                  field="scope.assets"
                />
                <Column
                  cell={MenuTaskList}
                  // title={localeTask[currentLocale].form.menu}
                  width="40px"
                />
              </Grid>

              <СommonTableLoader queryData={query.isLoading} filteringData={filterStatus} />
              {query.isError && (
                <div className="info alert alert-danger">Error: {query.error.message}</div>
              )}
            </IntlProvider>
          </LocalizationProvider>
        </div>
      </div>
    </ErrorBoundary>
  );
}
