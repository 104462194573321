import type { GridCellProps } from '@progress/kendo-react-grid';
import type { TreeListCellProps } from '@progress/kendo-react-treelist';
import type { InstalledSoftwareListDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/installedSoftwareListDashboardResponse.v1';
import type { VulnerableSoftwareListDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/vulnerableSoftwareListDashboardResponse.v1';
import { useSoftwaresContext } from '../../../../hooks/useSoftwaresContext';
import { Button } from '../../../common/baseElements/Button';

export function CellTotalAssetsByPackageModal(
  props: GridCellProps | TreeListCellProps,
): React.ReactElement {
  const { dataItem, field } = props;

  const { setSoftwaresModalData } = useSoftwaresContext();

  const {
    softwareId,
    name,
    isVulnerableFlag,
  }:
    | (InstalledSoftwareListDashboardResponse['data'][number] & { isVulnerableFlag?: boolean })
    | (VulnerableSoftwareListDashboardResponse['data'][number] & { isVulnerableFlag?: boolean }) =
    dataItem;

  const handleModal = (): void => {
    setSoftwaresModalData({
      softwareId,
      softwareName: name,
      defaultTabId: field === 'totalImageAssets' ? 1 : 0,
      fixes: dataItem.mergedUpdates,
      isVulnerableFlag,
    });
  };

  return (
    <td className="number">
      {field && field in dataItem && Number.isInteger(dataItem[field]) ? (
        <Button fill="modal" onClick={handleModal}>
          <span>{dataItem[field]}</span>
        </Button>
      ) : (
        '-'
      )}
    </td>
  );
}
