import type { ReactElement } from 'react';
import { Filter } from '@progress/kendo-react-data-tools';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { useParams } from 'react-router';
import type { SensorDashboardResponse } from '../../../types/__generated/on-premise-solution/api/sensorDashboardResponse.v1';
import localeFilters from '../../../utils/i18n/commonLocales/filters.json';
import localeSensor from '../../../utils/i18n/sensorLocales/sensor.json';
import { CommonSensorList } from '../../common/CommonSensorList';
import type { IDataHooks } from '../../../utils/helpers/types';
import { getUrlList } from '../../../utils/helpers/getUrl-list-helper';
import { INITIAL_SORT } from '../../../utils/helpers/constants';
import { useSensorList } from '../../../hooks/components/useSensorList';
import { FILTER_SENSORLIST } from '../../../utils/helpers/constant-serv-filter-host';
import { Input } from '../../common/baseElements/Input';
import { Button } from '../../common/baseElements/Button';
import { getUserData } from '../../../services/local-storage-service';
import { useSensorActions } from '../../../hooks/components/useSensorActions';
import type { IOpenedFromComponentInfo } from '../../../hooks/useSensorContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeFilters;

export function SensorListTab({
  sensorIds,
}: {
  sensorIds?: SensorDashboardResponse['childSensors'];
}): ReactElement {
  const dataForHooks: IDataHooks = {
    name: 'name',
    componentName: 'SensorListTab',
  };
  const urlParams = getUrlList('SensorListTab', INITIAL_SORT);
  const currentUser = getUserData();

  const { sensorId } = useParams<{ sensorId: string }>();

  const { createSensor } = useSensorActions();

  const {
    query,
    total,
    sort,
    page,
    filter,
    customSearch,
    setSort,
    pageChange,
    onFilterChange,
    handleCustomSearch,
  } = useSensorList(urlParams, dataForHooks, sensorIds?.map((s) => s.sensorId) || []);

  const openedFromInfo: IOpenedFromComponentInfo = {
    rootComponentName: 'SensorDescription',
    subcomponentName: 'SensorListTab',
  };

  return (
    <div>
      <div
        className="topactions"
        style={{
          marginBottom: '24px',
        }}
      >
        {currentUser?.role !== 'reader_user' && (
          <Button
            fill="action"
            onClick={(): void => {
              createSensor(sensorId, openedFromInfo);
            }}
          >
            {localeSensor[currentLocale].mainList.addBtn}
          </Button>
        )}
      </div>
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <div className="filter-line">
            <Filter
              value={filter}
              onChange={onFilterChange}
              fields={FILTER_SENSORLIST[currentLocale]}
            />
            {filter.filters.length === 0 && (
              <Input
                name="customSearch"
                placeholder={`${localeFilters[currentLocale].customSerachBy} ${localeFilters[currentLocale].customSearchFields.entityTitle}`}
                value={customSearch}
                onChange={(e): void => handleCustomSearch(e)}
              />
            )}
          </div>
        </IntlProvider>
      </LocalizationProvider>
      <CommonSensorList
        sort={sort}
        data={query.data}
        page={page}
        total={total}
        pageChange={pageChange}
        setSort={setSort}
        dataForHooks={dataForHooks}
      />
    </div>
  );
}
