import { useState } from 'react';
import type { TicketDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/ticketsDashboardResponse.v1';
import type localeTicket from '../../../../utils/i18n/security/vulnerabilities.json';
import { TableFTicketMetaBlock } from './tables/TableFTicketMetaBlock';
import { TableSTicketMetaBlock } from './tables/TableSTicketMetaBlock';
import { TableThTicketMetaBlock } from './tables/TableThTicketMetaBlock';
import styles from './ticket.module.scss';
import { TicketScopeColumn } from './tables/TicketScopeColumn';

export function TicketMetaBlock({
  data,
}: {
  data: TicketDashboardResponse | undefined;
}): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeTicket;

  const [isShowScope, setShow] = useState(false);

  const [isShowBulletin, setShowBulletin] = useState(false);

  return (
    <div className={`${styles.ticketMetablockWrap} common-summary-page`}>
      <div className={styles.ticketMetablock}>
        <TableFTicketMetaBlock data={data} currentLocale={currentLocale} />
        <TableSTicketMetaBlock data={data} currentLocale={currentLocale} />
        <TicketScopeColumn
          currentLocale={currentLocale}
          scoreData={data?.scope}
          bulletinData={data?.type === 'fix_vulnerabilities' ? data?.bulletinIds : null}
          isShowScope={isShowScope}
          setShow={setShow}
          isShowBulletin={isShowBulletin}
          setShowBulletin={setShowBulletin}
          type={data?.type}
        />
        <TableThTicketMetaBlock data={data} currentLocale={currentLocale} />
      </div>
    </div>
  );
}
