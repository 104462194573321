import { NavLink } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import localeSettings from '../../utils/i18n/settingsLocales/settings.json';
import styles from '../features/Settings/Settings.module.scss';
import { useWhiteListContext } from '../../hooks/useWhiteListContext';
import { useTicketContext } from '../../hooks/useTicketContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;
export function CommonNotification({
  type,
  timerValue,
}: {
  type: string;
  timerValue: number;
}): React.ReactElement {
  const { setOnSubmitVulnSuccess, selectedWhitelist, setSelectedWhitelist } = useWhiteListContext();

  const { setTicketCreatedSuccess } = useTicketContext();

  const timer = useRef<NodeJS.Timeout | null>(null);

  const setTimer = (): void => {
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      if (type === 'exceptions') {
        setOnSubmitVulnSuccess(false);
        setSelectedWhitelist(null);
      }
      if (type === 'ticket') {
        setTicketCreatedSuccess(false);
      }
    }, timerValue);
  };

  useEffect(() => {
    setTimer();

    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
  }, []);

  return (
    <div className={styles.wlNotificationWrap}>
      {type === 'exceptions' && (
        <>
          <span>{localeSettings[currentLocale].changeTheExclusionList}</span>
          {selectedWhitelist && (
            <div className={styles.wlNotificationLinkWrap}>
              <NavLink to={`/lk/audit/exceptions/${selectedWhitelist[0].bulletinWhitelistId}`}>
                {selectedWhitelist[0].name}
              </NavLink>
            </div>
          )}{' '}
          <span>{localeSettings[currentLocale].changeTheExclusionListSaved}</span>{' '}
        </>
      )}
      {type === 'ticket' && (
        <>
          <span style={{ marginRight: '4px' }}>
            {localeSettings[currentLocale].createTicketListTitle}
          </span>
          {!document.location.pathname.includes('/tickets?') && (
            <NavLink to="/lk/security/tickets?skip=0&limit=15&sortField=createdAt&sortOrder=desc">
              {localeSettings[currentLocale].createTicketList}
            </NavLink>
          )}
        </>
      )}
    </div>
  );
}
