/* eslint-disable react/jsx-pascal-case */
import { Grid, GridColumn as Column, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { PAGEABLE_DATA } from '../../utils/helpers/constants';
import type { ICommonSensorList } from '../../utils/helpers/types';
import { handleSort } from '../../utils/sorting-in-table';
import localeSensor from '../../utils/i18n/sensorLocales/sensor.json';
import { getUserData } from '../../services/local-storage-service';
import { CellSensorMenu } from '../features/Sensor/cells/CellSensorMenu';
import { CellCommonDate } from './baseElements/CellCommonDate';
import { CellSensorStatus } from '../features/Sensor/cells/CellSensorStatus';
import { CellSensorDomain } from '../features/Sensor/cells/CellSensorDomain';
import { CellSensorParent } from '../features/Sensor/cells/CellSensorParent';
import { StandartCell } from './baseElements/StandartCell';
import { CellSensorName } from '../features/Sensor/cells/CellSensorName';
import { SensorStatusHeaderCell } from '../features/Sensor/tables/SensorStatusHeaderCell';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSensor;

export function CommonSensorList(props: ICommonSensorList): React.ReactElement {
  const { sort, data, page, total, pageChange, setSort, dataForHooks } = props;
  const currentUser = getUserData();

  return (
    <div className="sensor-list">
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <Grid
            data={data}
            total={total}
            pageable={total && total > page.take ? PAGEABLE_DATA : false}
            scrollable="none"
            sortable
            sort={sort}
            skip={page.skip}
            take={page.take}
            onPageChange={pageChange}
            onSortChange={(e: GridSortChangeEvent): void => handleSort(e, setSort)}
            className={total && total <= page.take ? 'no-pageable-k-grid table' : 'table'}
          >
            <Column
              field="name"
              title={localeSensor[currentLocale].mainList.name}
              cell={CellSensorName}
            />
            <Column
              field="hostname"
              width={280}
              title={localeSensor[currentLocale].mainList.domainName}
              cell={CellSensorDomain}
            />
            <Column
              field="ip"
              width={124}
              cell={StandartCell}
              title={localeSensor[currentLocale].mainList.ip}
            />
            <Column
              field="assetCount"
              title={localeSensor[currentLocale].mainList.assets}
              cell={StandartCell}
              width={100}
            />
            <Column
              field="status"
              width={150}
              title={localeSensor[currentLocale].mainList.status}
              cell={CellSensorStatus}
              sortable={false}
              headerCell={SensorStatusHeaderCell}
            />
            {/* <Column field="version" title="Версия" /> */}
            <Column
              field="createdAt"
              title={localeSensor[currentLocale].mainList.createdAt}
              cell={CellCommonDate}
              width={160}
            />
            <Column
              field="parentId"
              title={localeSensor[currentLocale].mainList.connectedTo}
              cell={CellSensorParent}
              width={140}
              className="title"
            />
            {(currentUser?.role === 'super_admin' || currentUser?.role === 'account_admin') && (
              <Column
                field="account.name"
                title={localeSensor[currentLocale].mainList.account}
                cell={StandartCell}
                className="title"
                width={140}
              />
            )}
            {dataForHooks.componentName === 'SensorList' && (
              <Column width={40} title=" " cell={CellSensorMenu} />
            )}
          </Grid>
          {/* <Grid
            pageable={total && total > conditionSort ? PAGEABLE_DATA : false}
            sortable
            sort={sort}
            data={dataDashboard}
            skip={page.skip}
            take={page.take}
            total={total || 0}
            onPageChange={pageChange}
            onSortChange={(e: GridSortChangeEvent): void => handleSort(e, setSort)}
            scrollable="none"
            className={
              total && total < conditionSort && dataForHooks.componentName !== 'VulnModalHosts'
                ? 'no-pageable-k-grid'
                : ''
            }
          >
            {(dataForHooks.componentName === 'AddHostList' ||
              dataForHooks.componentName === 'GroupDescription') && (
              <Column field="addAsset" title=" " cell={CellSelectHost} width="40px" />
            )}
            <Column
              field="latestAudit.maxCvssScore"
              title={localeHost[currentLocale].form.cvssScore}
              filter="text"
              cell={ScoreCvssCell}
              width={cvssScoreWidth.sort}
            />
            <Column
              field="latestAudit.maxVFstec"
              title="v"
              filter="text"
              cell={ScoreVCell}
              width={cvssScoreWidth.sort}
              headerCell={VHeaderCell}
            />
            {dataForHooks.componentName === 'AddHostList' ? (
              <Column
                field="latestInventorization.hostname"
                title={localeHost[currentLocale].form.domainName}
                filter="text"
                width="200px"
              />
            ) : (
              <Column
                field="latestInventorization.hostname"
                title={localeHost[currentLocale].form.domainName}
                filter="text"
                cell={DomainName}
                width="354px"
              />
            )}
            <Column
              field="latestInventorization.ip"
              title={localeHost[currentLocale].form.ip}
              filter="text"
              cell={Ip}
              width="124px"
            />
            <Column
              field="latestAudit.vulnerabilities.count"
              title={localeHost[currentLocale].form.vulnerabilities}
              filter="numeric"
              cell={Vulnerabilities}
              width={dataForHooks.componentName === 'VulnModalHosts' ? '118px' : '132px'}
            />
            <Column
              field="latestAudit.updates.count"
              title={localeHost[currentLocale].form.updates}
              filter="text"
              cell={Updates}
              width={dataForHooks.componentName === 'VulnModalHosts' ? '116px' : '132px'}
            />
            <Column
              title={localeHost[currentLocale].scanType}
              filterable={false}
              cell={AgentStatus}
              width="200px"
              headerCell={AgentStatusHeaderCell}
              field="agentSoftwareVersion"
            />
            <Column
              field="latestInventorization.os.name"
              title={localeHost[currentLocale].form.system}
              filter="text"
              cell={System}
              width={currentUser?.role === 'super_admin' ? '300px' : 'auto'}
            />
            {currentUser?.role === 'super_admin' &&
              dataForHooks.componentName !== 'VulnModalHosts' && (
                <Column
                  field="account.name"
                  title={localeHost[currentLocale].form.accountName}
                  filter="text"
                />
              )}
            {dataForHooks.componentName === 'HostList' && (
              <Column cell={MenuHostList} width="60px" />
            )}
          </Grid> */}
        </IntlProvider>
      </LocalizationProvider>
    </div>
  );
}
