import { useHistory, useParams } from 'react-router';
import { useEffect, useState, type ReactElement } from 'react';
import { Menu, MenuItem, MenuSelectEvent } from '@progress/kendo-react-layout';
import { useQuery, useQueryClient } from 'react-query';
import { EntityDescription } from '../../templates/EntityDescription';
import { Button } from '../../common/baseElements/Button';
import { useNetDeviceContext } from '../../../hooks/useNetDeviceContext';
import { useUserModalContext } from '../../../hooks/useUserModalContext';
import { AddUserForm } from '../User/modals/AddUserForm';
import localeNames from '../../../utils/i18n/commonLocales/names.json';
import localeButtons from '../../../utils/i18n/commonLocales/buttons.json';
import localeAccount from '../../../utils/i18n/accountLocales/accountDescription.json';
import localeDrawer from '../../../utils/i18n/drawer/drawerUserMenu.json';
import { DeleteConfirmModal } from '../../common/modal/DeleteConfirmModal';
import { useAccountModalContext } from '../../../hooks/useAccountModalContext';
import { useAccountDescription } from '../../../hooks/components/useAccountDescription';
import { AccountMetaBlock } from './AccountMetaBlock';
import { TabAccountDescription } from './TabAccountDescription';
import { useHeaderContext } from '../../../hooks/useHeaderContext';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
import type { ConfigResponse } from '../../../types/__generated/on-premise-solution/api/configResponse.v1';
import { getConfig } from '../../../services/license-service';
import { getUserData } from '../../../services/local-storage-service';
import { DATA_CACHE_CACHETIME, DATA_CACHE_STALETIME } from '../../../utils/helpers/constants';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeButtons;

export function AccountDescription(): ReactElement {
  const { accountId } = useParams<{ accountId: string }>();
  const currentUser = getUserData();

  const history = useHistory();
  const queryClient = useQueryClient();

  const { mutationDeleteAccount, editAccount } = useAccountModalContext();
  const { query } = useAccountDescription();
  const { openHostDialog } = useHeaderContext();
  const { setAddNetDevice, setNetDeviceModalData } = useNetDeviceContext();
  const { isOpenDialog, editUserData, setOpenDialog } = useUserModalContext();
  const [isDeleteConfirm, setDeleteConfirm] = useState(false);

  const configQuery = useQuery<ConfigResponse, ApiError>(['accountConfig'], () => getConfig(), {
    staleTime: DATA_CACHE_STALETIME,
    cacheTime: DATA_CACHE_CACHETIME,
    keepPreviousData: true,
    enabled: currentUser?.role === 'super_admin' || currentUser?.role === 'account_admin',
  });

  const handleAddAsset = (e: MenuSelectEvent): void => {
    if (e.item.data === 'linux' || e.item.data === 'windows')
      openHostDialog(e.item.data, currentUser?.role === 'super_admin' ? accountId : undefined);
    else if (e.item.data === 'device')
      // setAddNetDevice(true);
      setNetDeviceModalData({ accountId });
  };

  const handleDelete = (): void => {
    mutationDeleteAccount.mutate(accountId);
    setDeleteConfirm(false);
    history.goBack();
  };

  useEffect(() => {
    return () => {
      queryClient.resetQueries(['total-vulnerabilities-by-account']);
      queryClient.resetQueries(['allTotalAssetsByAccount']);
      queryClient.resetQueries(['agent-statuses-by-account']);
      queryClient.resetQueries(['sensor-statuses-by-account']);
      queryClient.resetQueries(['users-info-by-account']);
      queryClient.resetQueries(['audit-asset-age-by-account']);
      queryClient.resetQueries(['invAssetsAgeByAccount']);
      queryClient.resetQueries(['bulletinsByAccount']);
      queryClient.resetQueries(['vulnerable-assets-by-account']);
    };
  });

  return (
    <EntityDescription
      entity={{ id: accountId, name: query.data?.name, type: 'account' }}
      isNotFound={query.error?.code === '404'}
      isLoading={query.isLoading}
      className="account-description"
      topactions={
        <>
          <Menu hoverOpenDelay={0} onSelect={handleAddAsset} className="action">
            <MenuItem text={localeAccount[currentLocale].createAsset}>
              <MenuItem
                cssStyle={{ width: '172px' }}
                text={localeDrawer[currentLocale].quickMenu.asset.linux}
                data="linux"
              />
              <MenuItem
                cssStyle={{ width: '172px' }}
                text={localeDrawer[currentLocale].quickMenu.asset.windows}
                data="windows"
              />
              <MenuItem
                cssStyle={{ width: '172px' }}
                text={localeDrawer[currentLocale].quickMenu.asset.device}
                data="device"
              />
            </MenuItem>
          </Menu>
          <Button onClick={(): void => setOpenDialog(true)}>
            {localeAccount[currentLocale].createUserBtn}
          </Button>
          {/* <Button>Change description</Button> */}
          <Button fill="delete" onClick={(): void => setDeleteConfirm(true)}>
            {localeButtons[currentLocale].delete}
          </Button>
        </>
      }
    >
      {isDeleteConfirm && (
        <DeleteConfirmModal
          type="account"
          name="testName"
          onClose={(): void => setDeleteConfirm(false)}
          handleDelete={handleDelete}
          errorMessage={mutationDeleteAccount.error?.message}
        />
      )}
      {(isOpenDialog || editUserData) && <AddUserForm />}
      {query.data && <AccountMetaBlock data={query.data} />}
      <TabAccountDescription data={query.data} config={configQuery.data} />
    </EntityDescription>
  );
}
