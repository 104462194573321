import type { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { memo, useCallback } from 'react';
import { useHostModalContext } from '../../../../../../hooks/useHostModalContext';
import type { AuditWindowsResult } from '../../../../../../types/__generated/on-premise-solution/api/audit.v1';

function CellSelectPackage(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { name, version }: AuditWindowsResult['vulnerableObjects'][0] = dataItem;

  const { selectedPkgs, setSelectedPkgs, modifySoftwareInWl, softwareInWl, setSoftwareInWl } =
    useHostModalContext();

  const addPackage = useCallback(
    (itemName: string, itemVersion: string, itemType: string | undefined): void => {
      if (selectedPkgs && selectedPkgs.length > 0) {
        const index = selectedPkgs?.findIndex(
          (p) => p.name === itemName && p.version === itemVersion,
        );
        if (index !== -1) {
          const cutSelectedPkgs = selectedPkgs.filter(
            (pkgName) => pkgName.name !== itemName || pkgName.version !== itemVersion,
          );
          setSelectedPkgs([...cutSelectedPkgs]);
        } else {
          setSelectedPkgs([...selectedPkgs, { name: itemName, version: itemVersion }]);
        }
      } else {
        setSelectedPkgs([{ name: itemName, version: itemVersion }]);
      }

      modifySoftwareInWl(itemName, itemVersion, itemType, softwareInWl, setSoftwareInWl);
    },
    [modifySoftwareInWl, selectedPkgs, setSelectedPkgs, setSoftwareInWl, softwareInWl],
  );

  return (
    <td>
      {selectedPkgs?.find((p) => p.name === name) ? (
        <Checkbox
          checked={!!selectedPkgs?.find((p) => p.name === name && p.version === version)}
          onClick={(): void => addPackage(name, version, dataItem.type)}
        />
      ) : (
        <Checkbox
          checked={!!selectedPkgs?.find((p) => p.name === name && p.version === version)}
          onClick={(): void => addPackage(name, version, dataItem.type)}
        />
      )}
    </td>
  );
}

export default memo(CellSelectPackage);
