import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { ReactElement, useState } from 'react';
import { SoftwaresOsList } from './SoftwaresOsList';
import localeSoftware from '../../../utils/i18n/software/software.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSoftware;

export function SoftwareOsTabs(): ReactElement {
  const [selected, setSelected] = useState(0);

  return (
    <TabStrip
      style={{
        marginTop: '4px',
      }}
      selected={selected}
      onSelect={(e): void => setSelected(e.selected)}
    >
      <TabStripTab title={localeSoftware[currentLocale].linuxTab}>
        <SoftwaresOsList type="linux" />
      </TabStripTab>
      <TabStripTab title={localeSoftware[currentLocale].windowsTab}>
        <SoftwaresOsList type="windows" />
      </TabStripTab>
      <TabStripTab title={localeSoftware[currentLocale].deviceTab}>
        <SoftwaresOsList type="other" />
      </TabStripTab>
    </TabStrip>
  );
}
