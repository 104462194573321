import type { GridCellProps } from '@progress/kendo-react-grid';
import { nanoid } from 'nanoid';
import { Button } from '../../../../../common/baseElements/Button';
import { useAuditModalContext } from '../../../../../../hooks/useAuditModalContext';
import {
  handleVulnModal,
  ICollectVulnSoftData,
} from '../../../../../../utils/helpers/audit-helpers';
import localeAuditTask from '../../../../../../utils/i18n/taskLocale/auditTask.json';
import styles from '../packageNameModal.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAuditTask;

export function CellVulns(props: GridCellProps): React.ReactElement {
  const { dataItem, className } = props;

  const { vulns }: ICollectVulnSoftData = dataItem;

  const { setCveName, setOpen } = useAuditModalContext();

  return (
    <td className={`audit__vulns ${className || ''}`}>
      {vulns.length <= 3 ? (
        vulns.map((v, i: number) => (
          <Button
            fill="modal"
            onClick={(): void => handleVulnModal(vulns[i].id, setOpen, setCveName)}
            key={nanoid(8)}
            style={{ padding: '0', margin: '0 4px 0 0' }}
          >
            {v.id}
            {vulns.length - 1 !== i && vulns.length > 1 ? ',' : ''}
          </Button>
        ))
      ) : (
        <div className={styles.vuln_soft_cell}>
          <div className={styles.vuln_soft}>
            <Button
              fill="modal"
              onClick={(): void => handleVulnModal(vulns[0].id, setOpen, setCveName)}
              style={{ padding: '0', margin: '0 4px 0 0' }}
            >
              {vulns[0].id},{' '}
            </Button>
            <Button
              fill="modal"
              onClick={(): void => handleVulnModal(vulns[1].id, setOpen, setCveName)}
              style={{ padding: '0', margin: '0 4px 0 0' }}
            >
              {vulns[1].id},{' '}
            </Button>
            <Button
              fill="modal"
              onClick={(): void => handleVulnModal(vulns[2].id, setOpen, setCveName)}
              style={{ padding: '0', margin: '0 4px 0 0' }}
            >
              {vulns[2].id}{' '}
            </Button>
          </div>
          <span className="auditvm__addition">
            {' '}
            {localeAuditTask[currentLocale].vulnsSoft.table.andMore} {vulns.length - 3}
          </span>
        </div>
      )}
    </td>
  );
}
