import type { GridCellProps } from '@progress/kendo-react-grid';
import styles from '../ticket.module.scss';
import type {
  TicketJiraFixVulnerabilities,
  TicketLocalFixVulnerabilities,
} from '../../../../../types/__generated/on-premise-solution/api/ticketsDashboardResponse.v1';

export function CellTicketProgress(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { actionsProgress }: TicketLocalFixVulnerabilities | TicketJiraFixVulnerabilities =
    dataItem;

  let progressValue: string;

  if (typeof actionsProgress === 'number' && Number.isInteger(actionsProgress)) {
    progressValue = `${actionsProgress} %`;
  } else if (
    actionsProgress &&
    typeof actionsProgress === 'number' &&
    !Number.isInteger(actionsProgress)
  ) {
    progressValue = `${actionsProgress.toFixed(1)} %`;
  } else {
    progressValue = '-';
  }

  return (
    <td>
      <span className={styles.spanProgressBar}>{progressValue}</span>
    </td>
  );
}
