/* eslint-disable no-nested-ternary */
import {
  createContext,
  JSXElementConstructor,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import type { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import type {
  ICredentialContext,
  ICredentialData,
  ISelectedCredential,
} from '../utils/helpers/types';
import type { ApiError } from '../types/__generated/on-premise-solution/api/apiError.v1';
import { getCredentialId } from '../services/credential-service';
import type { CredentialCheckResponse } from '../types/__generated/on-premise-solution/api/credentialCheckResponse.v1';
import locale from '../utils/i18n/credentials/credentials.json';
import CredentialCreationResponseJson from '../types/__generated/on-premise-solution/api/credentialCreationResponse.v1.schema.json';
import { useCommonContext } from './useCommonContext';
import type { CredentialDashboardResponse } from '../types/__generated/on-premise-solution/api/credentialDashboardResponse.v1';
import { useHostCreateContext } from './useHostCreateContext';
import { authTypeJiraValue, authTypeYandexValue } from '../utils/helpers/constants';

export interface ICredInitialState {
  vaultId: string;
  vault: { vaultId: string; name: string };
  nameCred: string;
  typeCred: string;
  username?: string | null;
  password?: string;
  passwordConfirm?: string;
  description?: string | null;
  privateKey?: string;
  passphrase?: string;
  token?: string;
  accountId?: string | null;
  ipKdc?: string;
  realm?: string;
  authType?: false | 'basic' | 'ntlm' | 'kerberos' | 'certificate' | string | undefined;
  community?: string;
  version?: string;
  authKey?: string | null;
  authProtocol?: string | null;
  authLevel?: string | null;
  privacyProtocol?: string | null;
  privacyKey?: string | null;
  dn?: string;
  uid?: string;
  certificate?: string | null | undefined;
}

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

const CredentialContext = createContext<ICredentialContext | null>(null);

export function CredentialContextProvider({
  children,
}: {
  children: ReactElement<string | JSXElementConstructor<string>>;
}): React.ReactElement {
  const typeValuesForSelect = useMemo(() => ['hashicorp_vault'], []);

  // const typeOfCredential = CredentialCreationResponseJson.properties.type.enum;

  const typeOfCredentialCommon = CredentialCreationResponseJson.oneOf[0].properties.type.enum;

  const typeOfCredentialWinRM = CredentialCreationResponseJson.oneOf[1].properties.type.enum;

  const typeOfCredentialSnmp = CredentialCreationResponseJson.oneOf[2].properties.type.enum;

  const typeOfCredentialLdap = CredentialCreationResponseJson.oneOf[3].properties.type.enum;

  const typeOfCredentialAd = CredentialCreationResponseJson.oneOf[4].properties.type.enum;

  const typeOfCredentialJira = CredentialCreationResponseJson.oneOf[5].properties.type.enum;

  const typeOfCredentialYandex = CredentialCreationResponseJson.oneOf[6].properties.type.enum;

  const VersionEnum = CredentialCreationResponseJson.oneOf[2].properties.version?.enum;

  const versionList = useMemo(() => (VersionEnum && [...VersionEnum]) || [], [VersionEnum]);

  const authProtocolList = useMemo(
    () => ['md5', 'sha', 'sha224', 'sha256', 'sha384', 'sha512'],
    [],
  );

  const authLevelList = useMemo(() => ['noAuthNoPriv', 'authNoPriv', 'authPriv'], []);

  const privacyProtocolList = useMemo(() => ['des', 'aes', 'aes256r'], []);

  const credTypeValForSelect = useMemo(
    () => [
      ...typeOfCredentialCommon,
      ...typeOfCredentialWinRM,
      ...typeOfCredentialSnmp,
      ...typeOfCredentialLdap,
      ...typeOfCredentialAd,
      ...typeOfCredentialJira,
      ...typeOfCredentialYandex,
    ],
    [
      typeOfCredentialAd,
      typeOfCredentialCommon,
      typeOfCredentialJira,
      typeOfCredentialLdap,
      typeOfCredentialSnmp,
      typeOfCredentialWinRM,
      typeOfCredentialYandex,
    ],
  );

  // states for credetials form:
  const [nameCredStorage, setNameCredStorage] = useState({
    id: '000',
    name: locale[currentLocale].form.selectVaultName,
  });

  const [typeCred, setTypeCred] = useState<string>('');

  const [nameCred, setNameCred] = useState<string>('');

  const [username, setUsername] = useState<string>('');

  const [password, setPassword] = useState<string>('');

  const [passwordConfirm, setPasswordConfirm] = useState<string>('');

  const [description, setDescription] = useState<string | null>(null);

  const [privateKey, setPrivateKey] = useState<string>('');

  const [passphrase, setPassphrase] = useState<string>('');

  const [token, setToken] = useState<string>('');

  const [initialState, setInitialState] = useState<ICredInitialState | null>(null);

  const [authType, setAuthType] = useState<string>('');

  const [ipKdc, setIpKdc] = useState('');

  const [realm, setRealm] = useState<string>('');

  const authTypeData = useMemo(() => ['basic', 'ntlm', 'kerberos'], []);

  const [uid, setUid] = useState<string | null>('');

  const [dn, setDn] = useState<string | null>('');

  const [authTypeJira, setAuthTypeJira] = useState(authTypeJiraValue[0]);

  const [authTypeYandex, setAuthTypeYandex] = useState(authTypeYandexValue[0]);

  const [certificate, setCertificate] = useState('');
  //

  const [isAddCredential, setAddCredential] = useState<boolean>(false);

  const [isAddCredentialStorage, setAddCredentialStorage] = useState<boolean>(false);

  const [nameVault, setNameVault] = useState<string>('');

  const [typeVault, setTypeVault] = useState<string>(typeValuesForSelect[0]);

  const [endpoint, setEndpoint] = useState<string>('');

  const [enginePath, setEnginePath] = useState<string>('');

  const [checkingCredential, setCheckingCredential] = useState(false);

  const [descriptionVault, setDescriptionVault] = useState<string>('');

  const [errorMutation, setErrorMutation] = useState('');

  const [checkErrorMutation, setCheckErrorMutation] = useState('');

  const [selectedCredential, setSelectedCredential] = useState<
    ISelectedCredential | null | undefined
  >(null);

  const [checkCredentilsResp, setCheckCredentilsResp] = useState<
    CredentialCheckResponse | undefined
  >(undefined);

  const [credentialVault, setCredentialVault] = useState<
    | {
        credentialId: string | undefined;
        credentialName: string | undefined;
        credentialType: string | undefined;
        credentialSshPort: number | undefined;
      }
    | undefined
  >(undefined);

  const [credentialHost, setCredentialHost] = useState<
    | {
        credentialId: string | undefined;
        credentialName: string | undefined;
        credentialType: string | undefined;
      }
    | undefined
  >(undefined);

  const [credentialDev, setCredentialDev] = useState<
    | {
        credentialId: string | undefined | null;
        credentialName: string | undefined;
        credentialType: string | undefined;
        credentialSshPort: number | undefined;
      }
    | undefined
  >(undefined);

  const [credentialData, setCredentialData] = useState<ICredentialData | null>(null);

  const [idValue, setIdValue] = useState<string | null | undefined>(null);

  const [isEdit, setEdit] = useState(false);

  const [version, setVersion] = useState<string>('');

  const [authProtocol, setAuthProtocol] = useState<string | null>(null);

  const [authLevel, setAuthLevel] = useState<string | null>(null);

  const [privacyProtocol, setPrivacyProtocol] = useState<string | null>(null);

  const [privacyKey, setPrivacyKey] = useState<string | null>(null);

  const [authKey, setAuthKey] = useState<string | null>(null);

  const [community, setCommunity] = useState('');

  const { setResultCheckIp, resultCheckIp } = useCommonContext();

  const { hostCreateData } = useHostCreateContext();

  useEffect(() => {
    if (!hostCreateData?.assetType) {
      setNameCred(credentialData ? credentialData.name : '');
      setTypeCred(credentialData ? credentialData.type : '');
      setUsername(
        credentialData && credentialData.credentials.username
          ? credentialData.credentials.username
          : '',
      );
      setPassword(credentialData ? '1***5' : '');
      setPasswordConfirm(credentialData ? '1***5' : '');
      setDescription(
        credentialData && credentialData.description ? credentialData.description : null,
      );
      setPrivateKey(credentialData ? '1***5' : '');
      setPassphrase(credentialData ? '*****' : '');
      setToken(credentialData ? '*****' : '');
      setNameCredStorage(
        credentialData
          ? { id: credentialData.vault.vaultId || '', name: credentialData.vault.name || '' }
          : {
              id: '000',
              name: locale[currentLocale].form.selectVaultName,
            },
      );
      setAuthType(credentialData && credentialData.authType ? credentialData.authType : '');
      setIpKdc(credentialData && credentialData.ipKdc ? credentialData.ipKdc : '');
      setRealm(credentialData && credentialData.realm ? credentialData.realm : '');
      setVersion(credentialData && credentialData.version ? credentialData.version : '');
      setAuthProtocol(
        credentialData && credentialData.authProtocol ? credentialData.authProtocol : null,
      );
      setAuthLevel(credentialData && credentialData.authLevel ? credentialData.authLevel : null);
      setPrivacyProtocol(
        credentialData && credentialData.privacyProtocol ? credentialData.privacyProtocol : null,
      );
      setPrivacyKey(credentialData && credentialData.privacyKey ? credentialData.privacyKey : null);
      setDn(credentialData && credentialData.dn ? credentialData.dn : null);
      setUid(credentialData && credentialData.uid ? credentialData.uid : null);
      setAuthTypeJira(
        credentialData && credentialData.authTypeJira
          ? credentialData.authTypeJira
          : authTypeJiraValue[0],
      );
      setCertificate(
        credentialData && credentialData.certificate ? credentialData.certificate : '',
      );
      if (credentialData) {
        setInitialState({
          vaultId: credentialData.vaultId,
          vault: { vaultId: credentialData.vault.vaultId, name: credentialData.vault.name },
          nameCred: credentialData.name,
          typeCred: credentialData.type,
          username: credentialData.credentials.username,
          authType: credentialData.authType || credentialData.authTypeJira,
          ipKdc: credentialData.ipKdc,
          realm: credentialData.realm,
          password: '1***5',
          passwordConfirm: '1***5',
          description: credentialData.description,
          privateKey: '1***5',
          passphrase: '*****',
          token: '*****',
          accountId: credentialData.accountId,
          community: credentialData.community,
          version: credentialData.version,
          authKey: credentialData.authKey,
          authProtocol: credentialData.authProtocol,
          authLevel: credentialData.authLevel,
          privacyProtocol: credentialData.privacyProtocol,
          privacyKey: credentialData.privacyKey,
          dn: credentialData.dn,
          uid: credentialData.uid,
          certificate: credentialData.certificate,
        });
      }
    }
  }, [credentialData, hostCreateData?.assetType]);

  useQuery<CredentialDashboardResponse, ApiError>([idValue], () => getCredentialId(idValue), {
    keepPreviousData: true,
    onSuccess: (response) => {
      setCredentialData({
        type: response.type,
        accountId: response.accountId,
        name: response.name,
        description: response.description,
        vaultId: response.vaultId,
        vault: { vaultId: response.vault.vaultId, name: response.vault.name },
        authType: response.type === 'winrm' && response.authType,
        ipKdc: response.ipKdc,
        realm: response.realm,
        credentials: {
          username: response.username,
          password: '',
          privateKey: '',
          passphrase: '',
        },
        credentialId: response.credentialId,
        sshPort: 22,
        version: (response.type === 'snmp' && response.version) || undefined,
        authKey: response.authKey || undefined,
        authProtocol: response.authProtocol || undefined,
        authLevel: response.authLevel || undefined,
        privacyProtocol: response.privacyProtocol || undefined,
        privacyKey: response.privacyKey || undefined,
        dn: (response.type === 'ldap' && response.dn) || undefined,
        uid: (response.type === 'ldap' && response.uid) || undefined,
        authTypeJira: response.type === 'jira' && response.authType,
        certificate: response.certificate,
      });
      setIdValue(null);
    },
    enabled: idValue !== null,
    cacheTime: 1000,
    staleTime: 1000,
  });

  const handleEdit = (credentialId: string | undefined): void => {
    setIdValue(credentialId);
  };

  const changeAuthMeth = useCallback((event: DropDownListChangeEvent): void => {
    setAuthType(event.target.value);
  }, []);

  const changeIpKdc = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      setResultCheckIp(null);
      setIpKdc(e.target.value);
    },
    [setResultCheckIp],
  );

  const changeAuthProtocol = useCallback(
    (event: DropDownListChangeEvent): void => {
      setAuthProtocol(event.target.value);
    },
    [setAuthProtocol],
  );

  const changeAuthLevel = useCallback(
    (event: DropDownListChangeEvent): void => {
      setAuthLevel(event.target.value);
    },
    [setAuthLevel],
  );

  const changePrivacyProtocol = useCallback(
    (event: DropDownListChangeEvent): void => {
      setPrivacyProtocol(event.target.value);
    },
    [setPrivacyProtocol],
  );

  const setCredential = useCallback(
    (credential: ISelectedCredential | undefined): void => {
      setSelectedCredential(credential);
    },
    [setSelectedCredential],
  );

  const value = useMemo(
    () => ({
      isAddCredential,
      setAddCredential,
      isAddCredentialStorage,
      setAddCredentialStorage,
      selectedCredential,
      setSelectedCredential,
      checkCredentilsResp,
      setCheckCredentilsResp,
      checkingCredential,
      setCheckingCredential,
      typeVault,
      nameVault,
      setNameVault,
      endpoint,
      setEndpoint,
      isEdit,
      setEdit,
      typeValuesForSelect,
      enginePath,
      setEnginePath,
      descriptionVault,
      setDescriptionVault,
      credentialVault,
      setCredentialVault,
      credentialHost,
      setCredentialHost,
      typeCred,
      credTypeValForSelect,
      nameCred,
      setNameCred,
      username,
      setUsername,
      password,
      passwordConfirm,
      setPasswordConfirm,
      setPassword,
      description,
      setDescription,
      privateKey,
      setPrivateKey,
      passphrase,
      setPassphrase,
      credentialData,
      setCredentialData,
      handleEdit,
      nameCredStorage,
      setIdValue,
      setTypeVault,
      setNameCredStorage,
      setTypeCred,
      token,
      setToken,
      credentialDev,
      setCredentialDev,
      initialState,
      errorMutation,
      setErrorMutation,
      checkErrorMutation,
      setCheckErrorMutation,
      authTypeData,
      authType,
      setAuthType,
      changeAuthMeth,
      ipKdc,
      setIpKdc,
      changeIpKdc,
      realm,
      setRealm,
      version,
      setVersion,
      versionList,
      authProtocolList,
      changeAuthProtocol,
      privacyKey,
      setPrivacyKey,
      authKey,
      setAuthKey,
      authLevel,
      setAuthLevel,
      authLevelList,
      changeAuthLevel,
      privacyProtocolList,
      privacyProtocol,
      setPrivacyProtocol,
      changePrivacyProtocol,
      community,
      setCommunity,
      authProtocol,
      uid,
      setUid,
      dn,
      setDn,
      setCredential,
      authTypeJira,
      setAuthTypeJira,
      certificate,
      setCertificate,
      authTypeYandex,
      setAuthTypeYandex,
    }),
    [
      isAddCredential,
      isAddCredentialStorage,
      selectedCredential,
      checkCredentilsResp,
      checkingCredential,
      typeVault,
      nameVault,
      endpoint,
      isEdit,
      typeValuesForSelect,
      enginePath,
      descriptionVault,
      credentialVault,
      credentialHost,
      typeCred,
      credTypeValForSelect,
      nameCred,
      username,
      password,
      passwordConfirm,
      description,
      privateKey,
      passphrase,
      credentialData,
      nameCredStorage,
      token,
      credentialDev,
      initialState,
      errorMutation,
      checkErrorMutation,
      authTypeData,
      authType,
      changeAuthMeth,
      ipKdc,
      changeIpKdc,
      realm,
      version,
      versionList,
      authProtocolList,
      changeAuthProtocol,
      privacyKey,
      authKey,
      authLevel,
      authLevelList,
      changeAuthLevel,
      privacyProtocolList,
      privacyProtocol,
      changePrivacyProtocol,
      community,
      authProtocol,
      uid,
      dn,
      setCredential,
      authTypeJira,
      certificate,
      authTypeYandex,
    ],
  );

  return <CredentialContext.Provider value={value}>{children}</CredentialContext.Provider>;
}
export const useCredentialContext = (): ICredentialContext => {
  const context = useContext(CredentialContext);
  if (context === null) {
    throw new Error('useCredentialContext must be used inside the CommonContextProvider.Provider.');
  }

  return context;
};
