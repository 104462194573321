import {
  createContext,
  JSXElementConstructor,
  ReactElement,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { useMutation } from 'react-query';
import type {
  TPriorityMapping,
  TStatusMapping,
  TTicketSystemCreationRequest,
} from '../utils/helpers/types';
import { subTypeValue } from '../utils/helpers/constants';
import { useAccountModalContext } from './useAccountModalContext';
import type {
  JiraProject,
  JiraTicketPriority,
  JiraTicketStatus,
} from '../types/__generated/on-premise-solution/api/jiraMetaResponse.v1';
import { getAccountId, getUserData } from '../services/local-storage-service';
import type { ApiError } from '../types/__generated/on-premise-solution/api/apiError.v1';
import { ticketSystemRequestFnMeta } from '../services/tickets-service';
import type {
  Account,
  TicketSystemJira,
  TicketSystemYandexTracker,
} from '../types/__generated/on-premise-solution/api/ticketSystemsDashboardResponse.v1';
import { useTaskAddContext } from './useTaskAddContext';
import type { TicketSystemMetaRequest } from '../types/__generated/on-premise-solution/api/ticketSystemMetaRequest.v1';
import type {
  TicketSystemMetaResponse,
  YandexTrackerQueue,
  YandexTrackerTicketPriority,
  YandexTrackerTicketStatus,
} from '../types/__generated/on-premise-solution/api/ticketSystemMetaResponse.v1';
import { useTicketContext } from './useTicketContext';

const TicketSystemContext = createContext<TTicketSystemCreationRequest | null>(null);

export function TicketSystemProvider({
  children,
}: {
  children: ReactElement<string | JSXElementConstructor<string>>;
}): React.ReactElement {
  const currentUser = getUserData();

  const { setTicketSystemType, ticketSystemType } = useTicketContext();

  const currentAccountId = getAccountId() || '';

  const [isAddTicketSystem, setAddTicketSystem] = useState(false);

  const [subType, setSubType] = useState<'on-premise' | 'cloud'>(subTypeValue[0]);

  const [name, setName] = useState('');

  const [descriptionTs, setDescriptionTs] = useState<string | undefined>(undefined);

  const [isDefault, setDefault] = useState(false);

  const [jiraProjectId, setJiraProjectId] = useState('');

  const [apiBaseUrl, setApiBaseUrl] = useState('');

  const [jiraPriorityMapping, setJiraPriorityMapping] = useState<TPriorityMapping>({});

  const [jiraStatusMapping, setJiraStatusMapping] = useState<TStatusMapping>({});

  const [yandexStatusMapping, setYandexStatusMapping] = useState<
    TicketSystemYandexTracker['statusMapping']
  >({});

  const [yandexPriorityMapping, setYandexPriorityMapping] = useState<
    TicketSystemYandexTracker['priorityMapping']
  >({});

  const [jiraStatusMapping2, setJiraStatusMapping2] = useState<
    { name: string; id: string }[] | null
  >(null);

  const [jiraPriorityMapping2, setJiraPriorityMapping2] = useState<
    | {
        name: string;
        id: string;
      }[]
    | null
  >(null);

  const [credentialTicket, setCredentialTicket] = useState<
    | {
        credentialId: string | undefined | null;
        credentialName: string | undefined;
        credentialType: string | undefined;
      }
    | undefined
  >(undefined);

  const [jiraProjectIds, setJiraProjectIds] = useState<JiraProject[] | null>(null);

  const [statuses, setStatuses] = useState<JiraTicketStatus[] | YandexTrackerTicketStatus[] | null>(
    null,
  );

  const [priorities, setPriorities] = useState<
    JiraTicketPriority[] | YandexTrackerTicketPriority[] | null
  >(null);

  const [isSuccessJiraMetaRequest, setSuccessJiraMetaRequest] = useState(false);

  const [errorJiraMetaRequest, setErrorJiraMetaRequest] = useState('');

  const [isJiraMetaLoading, setJiraMetaLoading] = useState(false);

  const [isUseCron, setUseCron] = useState(false);

  const [editTicketSystemData, setEditTicketSystemData] = useState<
    | ((TicketSystemJira | TicketSystemYandexTracker) & { account: Account } & {
        credential: {
          credentialId: string;
          name: string;
        } | null;
      } & { project: JiraProject })
    | null
  >(null);

  const [isMutationTicketSystem, setMutationTicketSystem] = useState(false);

  const [source, setSource] = useState<'user' | 'jira_on-premise' | 'jira_cloud'>('user');

  const [organizationId, setOrganizationId] = useState('');

  const [isOnlyYandexCloudOrganizationUsed, setOnlyYandexCloudOrganizationUsed] = useState(true);

  const [yandexTrackerQueueId, setYandexTrackerQueueId] = useState('');

  const [yandexTrackerQueueIds, setYandexTrackerQueueIds] = useState<YandexTrackerQueue[] | null>(
    null,
  );

  const { setAccountId, accountId } = useAccountModalContext();

  const { setCorrectCron, setCronInputValue } = useTaskAddContext();

  const mutationTicketSystemMeta = useMutation<
    TicketSystemMetaResponse,
    ApiError,
    TicketSystemMetaRequest
  >((payload) => ticketSystemRequestFnMeta(payload), {
    onSuccess: (resp) => {
      if (resp.ticketSystemType === 'jira' && resp.projects) {
        setJiraProjectIds(resp.projects);
      }
      if (resp.ticketSystemType === 'yandex_tracker' && resp.queues) {
        setYandexTrackerQueueIds(resp.queues);
      }
      setStatuses(resp.statuses);
      setPriorities(resp.priorities);
      setJiraMetaLoading(false);
      setErrorJiraMetaRequest('');
      setSuccessJiraMetaRequest(true);
    },
    onError: (err) => {
      setJiraMetaLoading(false);
      const errMessage = `${err.code} ${err.message}`;
      setErrorJiraMetaRequest(errMessage);
    },
  });

  useEffect(() => {
    if (editTicketSystemData) {
      if (editTicketSystemData.type) {
        setTicketSystemType(editTicketSystemData.type);
      }
      if (editTicketSystemData.accountId) {
        setAccountId({
          id: editTicketSystemData.account.accountId,
          name: editTicketSystemData.account.name,
        });
      }
      if (editTicketSystemData.apiBaseUrl) {
        setApiBaseUrl(editTicketSystemData.apiBaseUrl);
      }
      if (editTicketSystemData.credential) {
        setCredentialTicket({
          credentialId: editTicketSystemData.credential.credentialId,
          credentialName: editTicketSystemData.credential.name,
          credentialType: editTicketSystemData.type,
        });
      }
      if (editTicketSystemData.description) {
        setDescriptionTs(editTicketSystemData.description);
      }
      if (editTicketSystemData.isDefault) {
        setDefault(editTicketSystemData.isDefault);
      }
      if (editTicketSystemData.type === 'jira' && editTicketSystemData.jiraProjectId) {
        setJiraProjectId(editTicketSystemData.jiraProjectId);
      }
      if (editTicketSystemData.name) {
        setName(editTicketSystemData.name);
      }
      if (editTicketSystemData.type === 'jira' && editTicketSystemData.statusMapping) {
        setJiraStatusMapping(editTicketSystemData.statusMapping);
      }
      if (editTicketSystemData.type === 'jira' && editTicketSystemData.priorityMapping) {
        setJiraPriorityMapping(editTicketSystemData.priorityMapping);
      }
      if (editTicketSystemData.type === 'jira' && editTicketSystemData.subType) {
        setSubType(editTicketSystemData.subType);
      }
      if (editTicketSystemData.refreshTicketsSystemTask) {
        setUseCron(true);
        setCorrectCron(editTicketSystemData.refreshTicketsSystemTask.cron);
        setCronInputValue(editTicketSystemData.refreshTicketsSystemTask.cron);
      }
      if (editTicketSystemData.type === 'yandex_tracker' && editTicketSystemData.organizationId) {
        setOrganizationId(editTicketSystemData.organizationId);
      }
      if (
        editTicketSystemData.type === 'yandex_tracker' &&
        editTicketSystemData.yandexTrackerQueueId
      ) {
        setYandexTrackerQueueId(editTicketSystemData.yandexTrackerQueueId);
      }
      if (editTicketSystemData.type === 'yandex_tracker' && editTicketSystemData.statusMapping) {
        setYandexStatusMapping(editTicketSystemData.statusMapping);
      }
      if (editTicketSystemData.type === 'yandex_tracker' && editTicketSystemData.priorityMapping) {
        setYandexPriorityMapping(editTicketSystemData.priorityMapping);
      }
    }
  }, [editTicketSystemData]);

  useEffect(() => {
    if (ticketSystemType === 'jira') {
      if (
        apiBaseUrl &&
        currentUser?.role === 'super_admin' &&
        accountId.id !== '000' &&
        credentialTicket?.credentialId
      ) {
        const payload = {
          ticketSystemType,
          ticketSystemSubType: subType,
          apiBaseUrl,
          accountId: accountId.id,
          credentialId: credentialTicket?.credentialId,
        } as TicketSystemMetaRequest;

        mutationTicketSystemMeta.mutate(payload);
        setJiraMetaLoading(true);
      } else if (
        apiBaseUrl &&
        currentUser?.role !== 'super_admin' &&
        credentialTicket?.credentialId
      ) {
        const payload = {
          ticketSystemType,
          ticketSystemSubType: subType,
          apiBaseUrl,
          accountId: currentAccountId,
          credentialId: credentialTicket?.credentialId,
        } as TicketSystemMetaRequest;

        mutationTicketSystemMeta.mutate(payload);
        setJiraMetaLoading(true);
      }
    }
    if (ticketSystemType === 'yandex_tracker') {
      if (
        apiBaseUrl &&
        currentUser?.role === 'super_admin' &&
        accountId.id !== '000' &&
        credentialTicket?.credentialId
      ) {
        const payload = {
          ticketSystemType,
          apiBaseUrl,
          accountId: accountId.id,
          credentialId: credentialTicket?.credentialId,
          isOnlyYandexCloudOrganizationUsed,
          organizationId,
        } as TicketSystemMetaRequest;

        mutationTicketSystemMeta.mutate(payload);
        setJiraMetaLoading(true);
      } else if (
        apiBaseUrl &&
        currentUser?.role !== 'super_admin' &&
        credentialTicket?.credentialId
      ) {
        const payload = {
          ticketSystemType,
          apiBaseUrl,
          accountId: currentAccountId,
          credentialId: credentialTicket?.credentialId,
          isOnlyYandexCloudOrganizationUsed,
          organizationId,
        } as TicketSystemMetaRequest;

        mutationTicketSystemMeta.mutate(payload);
        setJiraMetaLoading(true);
      }
    }
  }, [apiBaseUrl, accountId, credentialTicket, organizationId, ticketSystemType]);

  const value = useMemo(
    () => ({
      name,
      setName,
      descriptionTs,
      setDescriptionTs,
      isDefault,
      setDefault,
      jiraProjectId,
      setJiraProjectId,
      jiraPriorityMapping,
      setJiraPriorityMapping,
      jiraStatusMapping,
      setJiraStatusMapping,
      subType,
      setSubType,
      credentialTicket,
      setCredentialTicket,
      apiBaseUrl,
      setApiBaseUrl,
      jiraProjectIds,
      setJiraProjectIds,
      statuses,
      setStatuses,
      priorities,
      setPriorities,
      jiraStatusMapping2,
      setJiraStatusMapping2,
      jiraPriorityMapping2,
      setJiraPriorityMapping2,
      errorJiraMetaRequest,
      setErrorJiraMetaRequest,
      isJiraMetaLoading,
      setJiraMetaLoading,
      isUseCron,
      setUseCron,
      editTicketSystemData,
      setEditTicketSystemData,
      isSuccessJiraMetaRequest,
      setSuccessJiraMetaRequest,
      isMutationTicketSystem,
      setMutationTicketSystem,
      source,
      setSource,
      organizationId,
      setOrganizationId,
      isOnlyYandexCloudOrganizationUsed,
      setOnlyYandexCloudOrganizationUsed,
      yandexTrackerQueueId,
      setYandexTrackerQueueId,
      yandexTrackerQueueIds,
      setYandexTrackerQueueIds,
      yandexStatusMapping,
      setYandexStatusMapping,
      yandexPriorityMapping,
      setYandexPriorityMapping,
      isAddTicketSystem,
      setAddTicketSystem,
    }),
    [
      name,
      descriptionTs,
      isDefault,
      jiraProjectId,
      jiraPriorityMapping,
      jiraStatusMapping,
      subType,
      credentialTicket,
      apiBaseUrl,
      jiraProjectIds,
      statuses,
      priorities,
      jiraStatusMapping2,
      jiraPriorityMapping2,
      errorJiraMetaRequest,
      isJiraMetaLoading,
      isUseCron,
      editTicketSystemData,
      isSuccessJiraMetaRequest,
      isMutationTicketSystem,
      source,
      organizationId,
      isOnlyYandexCloudOrganizationUsed,
      yandexTrackerQueueId,
      yandexTrackerQueueIds,
      yandexStatusMapping,
      yandexPriorityMapping,
      isAddTicketSystem,
    ],
  );

  return <TicketSystemContext.Provider value={value}>{children}</TicketSystemContext.Provider>;
}
export const useTicketSystemContext = (): TTicketSystemCreationRequest => {
  const context = useContext(TicketSystemContext);
  if (context === null) {
    throw new Error('useTicketContext must be used inside the WebSocketContext.Provider.');
  }

  return context;
};
