/* eslint-disable react-hooks/exhaustive-deps */
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import type { TextFilterProps } from '@progress/kendo-react-data-tools/dist/npm/filter/filters/TextFilter';
import type localeSoftwareManagement from '../../../utils/i18n/assetLocales/softwareManagement.json';
import localeFilters from '../../../utils/i18n/commonLocales/filters.json';
import type { IDaraFilterSelect } from '../../../utils/helpers/types';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSoftwareManagement;

let dataStatus = [] as IDaraFilterSelect[];
export function FilterTicketStatus(props: TextFilterProps): React.ReactElement {
  const { filter, onFilterChange } = props;

  const urlSearch = document.location.search.includes('closed');

  dataStatus = [
    {
      text: localeFilters[currentLocale].notChanged,
      val: '',
    },
    {
      text: currentLocale === 'ru-RU' || window.navigator.language === 'ru' ? 'Открыт' : 'Open',
      val: 'open',
    },
    {
      text:
        currentLocale === 'ru-RU' || window.navigator.language === 'ru'
          ? 'В работе'
          : 'In-progress',
      val: 'in-progress',
    },
    {
      text:
        currentLocale === 'ru-RU' || window.navigator.language === 'ru'
          ? 'На проверке'
          : 'In-review',
      val: 'in-review',
    },
    {
      text: currentLocale === 'ru-RU' || window.navigator.language === 'ru' ? 'Закрыт' : 'Closed',
      val: 'closed',
    },
  ];

  const handleChange = (event: DropDownListChangeEvent): void => {
    onFilterChange({
      nextFilter: { ...filter, ...{ value: event.target.value.val, text: '' } },
    });
  };

  return (
    <DropDownList
      defaultValue={!urlSearch ? dataStatus[0] : dataStatus[4]}
      data={dataStatus}
      textField="text"
      dataItemKey="val"
      onChange={handleChange}
    />
  );
}
