import type { SortDescriptor } from '@progress/kendo-data-query';
import { INITIAL_PAGING, INITIAL_SORT_WL } from './constants';
import type { IPageState, TSoftwareState } from './types';
import { getUserData } from '../../services/local-storage-service';

interface IFilterUrlWls {
  type?: string;
  bulletinId?: string;
  whitelistedBulletinId?: string;
  name?: string;
  createdByName?: string;
  assetIds?: string;
}

type TSoftwareData = {
  name: string;
  addedBy: {
    source: string;
    id: string;
  };
  addedAt: string;
};

export interface IUrlParamsWls {
  sort: SortDescriptor[];
  page: IPageState;
  pageLimitGrid: { [key: string]: string } | null;
  filterUrlData: IFilterUrlWls;
}
export function getUrlParamsWl(pageLimitGridKey: string): IUrlParamsWls {
  const pageLimitGrid: IUrlParamsWls['pageLimitGrid'] =
    localStorage.getItem('pageLimitGrid') &&
    JSON.parse(localStorage.getItem('pageLimitGrid') || '');

  const locationUrlData: { [name: string]: string } | null = {};
  if (document.location.search) {
    const locationSearch = document.location.search.slice(1).split('&');
    locationSearch.forEach((val) => {
      const value = val.split('=');
      if (value[0] === 'name[$eq]') {
        locationUrlData.name = decodeURI(value[1]) || '';
      }
      if (value[0] === 'createdBy.name[$eq]') {
        locationUrlData.createdByName = decodeURI(value[1]) || '';
      }
      if (value[0] === 'assetIds[$eq]') {
        locationUrlData.assetIds = decodeURI(value[1]) || '';
      }
      locationUrlData[value[0]] = value[1] || '';
    });
  }

  const { sortField, sortOrder, skip } = locationUrlData;
  const sort: SortDescriptor[] =
    sortField && sortOrder && (sortOrder === 'asc' || sortOrder === 'desc')
      ? [{ field: sortField, dir: sortOrder }]
      : INITIAL_SORT_WL;

  const page: IPageState = {
    skip: Number(skip) || INITIAL_PAGING.skip,
    take:
      pageLimitGrid && Number(pageLimitGrid[pageLimitGridKey])
        ? Number(pageLimitGrid[pageLimitGridKey])
        : INITIAL_PAGING.take,
  };

  const filterUrlData = {} as IFilterUrlWls;

  if (locationUrlData.name) {
    filterUrlData.name = locationUrlData.name;
  }
  if (locationUrlData.createdByName) {
    filterUrlData.createdByName = locationUrlData.createdByName;
  }
  if (locationUrlData.assetIds) {
    filterUrlData.assetIds = locationUrlData.assetIds;
  }

  return {
    sort,
    page,
    pageLimitGrid,
    filterUrlData,
  };
}

export function prepareSoftwareList(
  state: TSoftwareState | null,
  stateWl: TSoftwareState | null,
  isException: boolean,
): TSoftwareData[] | undefined {
  const user = getUserData();

  let swData: TSoftwareData[] = [];

  if (!isException && state && Object.keys(state).length > 0) {
    swData = Object.values(state).map((v) => {
      return {
        name: v.name,
        addedBy: v.addedBy
          ? v.addedBy
          : {
              source: 'user',
              id: user?.id || '',
            },
        osType: v.osType,
        addedAt: new Date().toISOString(),
        versionRanges: v.versionRanges ? v.versionRanges : [`=${v.version}`],
      };
    });
  }

  if (isException && stateWl && Object.keys(stateWl).length > 0) {
    swData = Object.values(stateWl).map((v) => {
      return {
        name: v.name,
        addedBy: {
          source: 'user',
          id: user?.id || '',
        },
        osType: v.osType,
        addedAt: new Date().toISOString(),
        versionRanges: [`=${v.version}`],
      };
    });
  }

  return swData && swData.length > 0 ? swData : undefined;
}
