import { Filter, TextFilter, type FilterChangeEvent } from '@progress/kendo-react-data-tools';
import { useEffect, useRef, useState, type ReactElement } from 'react';
import { useQuery } from 'react-query';
import type { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import {
  Grid,
  GridColumn,
  GridSortChangeEvent,
  type GridPageChangeEvent,
} from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import type { OperationSystemsResponse } from '../../../types/__generated/on-premise-solution/api/operationSystemsResponse.v1';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
import { getSoftwareOsList } from '../../../services/softwares-service';
import { useNotificationContext } from '../../../hooks/useNotificationContext';
import { getUrlVulnRelated } from '../../../utils/helpers/vuln-related-list-helper';
import type { IPageState } from '../../../utils/helpers/types';
import { FILTER_INITIAL, PAGEABLE_DATA } from '../../../utils/helpers/constants';
import { handleFilteringTest, IFilterVal } from '../../../utils/filtering-in-table-test';
import { usePageChange } from '../../../hooks/components/usePageChange';
import { useFilter } from '../../../hooks/components/useFilter';
import { useCustomSearch } from '../../../hooks/components/useCustomSearch';
import { handleError } from '../../../utils/errors';
import { Input } from '../../common/baseElements/Input';
import { handleSort } from '../../../utils/sorting-in-table';
import localeFilters from '../../../utils/i18n/commonLocales/filters.json';
import localeSoftware from '../../../utils/i18n/software/software.json';
import { StandartCell } from '../../common/baseElements/StandartCell';
import { FILTER_TASK_OPERATORS } from '../../../utils/helpers/constant-filter-operators';
import { CellTotalAssetsByOs } from './cells/CellTotalAssetsByOs';
import { CellOs } from './cells/CellOs';
import { useSoftwaresContext } from '../../../hooks/useSoftwaresContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeFilters;

export function SoftwaresOsList({ type }: { type: 'linux' | 'windows' | 'other' }): ReactElement {
  const dataForHooks = {
    name: 'osName',
    componentName: 'SoftwaresOsList',
  };
  const { createNotification } = useNotificationContext();
  const { changedAccountId } = useSoftwaresContext();

  const urlParams = getUrlVulnRelated(dataForHooks.componentName);

  const FILTERS = [
    {
      name: 'osName',
      label: localeFilters[currentLocale].filters.name,
      filter: TextFilter,
      operators: FILTER_TASK_OPERATORS.text,
    },
  ];
  const [page, setPage] = useState<IPageState>({ skip: 0, take: 15 });
  const [sort, setSort] = useState<Array<SortDescriptor>>([{ field: 'osName', dir: 'asc' }]);
  const [filter, setFilter] = useState<CompositeFilterDescriptor>(FILTER_INITIAL);
  const [filterVal, setFilterVal] = useState<IFilterVal[]>([]);
  const getPage = usePageChange(setPage, urlParams, dataForHooks);
  const [filterStatus, setFilterStatus] = useState(false);
  const [customSearch, setCustomSearch] = useState('');
  const filterValue = useRef<CompositeFilterDescriptor | null>(null);
  const filterRef = useRef<CompositeFilterDescriptor>(filter);

  const query = useQuery<OperationSystemsResponse | undefined, ApiError>(
    ['SoftwareOsList', page, filterVal, sort, type, changedAccountId],
    () => getSoftwareOsList(page, filterVal, sort, type, changedAccountId),
  );

  const pageChange = (event: GridPageChangeEvent): void => {
    getPage(event);
  };

  useEffect(() => {
    let delaySearch: NodeJS.Timeout;

    if (filter?.filters?.length > 0) {
      filterRef.current = filter;
      delaySearch = setTimeout(() => {
        handleFilteringTest(filterRef.current, setFilterVal);
        setFilterStatus(false);
      }, 300);
    }

    return () => clearTimeout(delaySearch);
  }, [filter.filters, page.skip, location]);

  useEffect(() => {
    if (query.error) {
      handleError(query.error, createNotification);
    }
  }, [query.isError, query.error]);

  const debouncedCustomSearch = useCustomSearch(
    setFilterStatus,
    setCustomSearch,
    filter,
    setFilterVal,
    setPage,
    page,
    dataForHooks,
  );

  const filterSearch = useFilter(
    filterValue,
    setFilter,
    customSearch,
    setCustomSearch,
    setFilterVal,
    filterRef,
    filter,
    setPage,
    page,
    dataForHooks,
  );

  const onFilterChange = (event: FilterChangeEvent): void => {
    filterSearch(event);
  };

  const handleCustomSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    debouncedCustomSearch(e);
  };

  return (
    <LocalizationProvider language={currentLocale}>
      <IntlProvider locale={currentLocale.substring(0, 2)}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <div className="filter-line" style={{ flex: 'fit-content' }}>
            <Filter value={filter} onChange={onFilterChange} fields={FILTERS} />
            {filter.filters.length === 0 && (
              <Input
                name="customSearch"
                placeholder={`${localeFilters[currentLocale].customSerachBy} ${localeFilters[currentLocale].customSearchFields.os}`}
                value={customSearch}
                onChange={(e): void => handleCustomSearch(e)}
              />
            )}
          </div>
        </div>
        <Grid
          data={query.data?.data}
          pageable={PAGEABLE_DATA}
          sortable
          sort={sort}
          skip={page.skip}
          take={page.take}
          total={query?.data?.total}
          onPageChange={pageChange}
          onSortChange={(e: GridSortChangeEvent): void =>
            handleSort(e, setSort, dataForHooks.name, 'asc')
          }
          scrollable="none"
        >
          <GridColumn
            field="osName"
            // width={360}
            title={localeSoftware[currentLocale].tables.osName}
            cell={CellOs}
          />
          <GridColumn
            field="osVersion"
            title={localeSoftware[currentLocale].tables.version}
            cell={StandartCell}
            // width={140}
            className="title"
          />
          {type !== 'other' && (
            <GridColumn
              field="totalHostAssets"
              title={localeSoftware[currentLocale].tables.totalHosts}
              cell={CellTotalAssetsByOs}
              width={96}
            />
          )}
          {type !== 'other' && (
            <GridColumn
              field="totalImageAssets"
              title={localeSoftware[currentLocale].tables.totalImages}
              cell={CellTotalAssetsByOs}
              width={96}
            />
          )}
          {type === 'other' && (
            <GridColumn
              field="totalOtherAssets"
              title={localeSoftware[currentLocale].tables.totalDevices}
              cell={CellTotalAssetsByOs}
              width={120}
            />
          )}
        </Grid>
      </IntlProvider>
    </LocalizationProvider>
  );
}
