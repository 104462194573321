/* eslint-disable jsx-a11y/label-has-associated-control */
import { RadioButton } from '@progress/kendo-react-inputs';
import localeTicket from '../../../../../utils/i18n/security/vulnerabilities.json';
import styles from '../ticket.module.scss';
import { useTicketContext } from '../../../../../hooks/useTicketContext';
import { useTicketMethods } from '../../../../../hooks/components/useTicketMethods';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeTicket;

export function TicketActionBlock(): React.ReactElement {
  const { type, editTicketData } = useTicketContext();

  const { handleChangeType } = useTicketMethods();

  return (
    <div className="newtask__time">
      <label className="k-label">{localeTicket[currentLocale].tickets.form.titleTicketType}</label>
      <div className={styles.radioWrap}>
        <RadioButton
          name="group1"
          value="fix_vulnerabilities"
          checked={type === 'fix_vulnerabilities'}
          label={localeTicket[currentLocale].tickets.form.fixTask}
          onChange={handleChangeType}
          disabled={!!(editTicketData && type !== 'fix_vulnerabilities')}
        />
      </div>
      <div className={styles.radioWrap}>
        <RadioButton
          name="group1"
          value="custom"
          checked={type === 'custom'}
          label={localeTicket[currentLocale].tickets.form.custom}
          onChange={handleChangeType}
          disabled={!!(editTicketData && type !== 'custom')}
        />
      </div>
      <div className={styles.radioWrap}>
        <RadioButton
          name="group1"
          value="agent_update_task"
          checked={type === 'agent_update_task'}
          label={localeTicket[currentLocale].tickets.form.updateAgent}
          onChange={handleChangeType}
          disabled
        />
      </div>
    </div>
  );
}
