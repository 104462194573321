import type { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import type { TotalAssetVulnerabillitiesResponse } from '../../../../types/__generated/on-premise-solution/api/totalAssetVulnerabillitiesResponse.v1';
import localeDashboards from '../../../../utils/i18n/dashboardLocale/dashboardLayout.json';
import styles from '../Dashboard.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeDashboards;

export function VulnerabilitiesCount({
  data,
  accountId,
}: {
  data: TotalAssetVulnerabillitiesResponse | undefined;
  accountId?: string;
}): ReactElement {
  return (
    <div
      className={styles.number_of_assets}
      //   style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      {/* <NavLink className="dashboard__link" to="/lk/security/vulnerabilities">
        <h2 style={{ fontSize: '36px' }}>{data?.total || 0}</h2>
      </NavLink> */}
      <table className="column">
        <tbody>
          <tr>
            <td className={styles.asset_type} style={{ fontSize: '24px' }}>
              {localeDashboards[currentLocale].assetsCount.total}
            </td>
            <td className={styles.asset_count} style={{ fontSize: '28px' }}>
              <NavLink
                style={{ fontWeight: 'bold' }}
                className="dashboard__link"
                to={`/lk/security/vulnerabilities${accountId ? `?accountId=${accountId}` : ''}`}
              >
                {data?.total !== undefined ? data.total : '-'}
              </NavLink>
            </td>
          </tr>
          {data &&
            data.data &&
            data.data.map((d) => (
              <tr>
                <td className={styles.asset_type}>
                  {
                    localeDashboards[currentLocale].vulnsCount[
                      d.label === 'host' || d.label === 'image' ? d.label : 'device'
                    ]
                  }
                </td>
                <td className={styles.asset_count} style={{ color: '#333' }}>
                  {d.value !== undefined ? d.value : '-'}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}
