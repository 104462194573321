/* eslint-disable radix */
/* eslint-disable no-bitwise */
import React, { createContext, useMemo, useContext, useState, useCallback } from 'react';
import type { IChangeColorTag, IChildrenNodes } from '../utils/helpers/types';
// import type localeDashboardForm from '../utils/i18n/dashboardLocale/addDashboardForm.json';
import { useHostModalContext } from './useHostModalContext';

// const currentLocale = (
//   window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
// ) as keyof typeof localeDashboardForm;

interface ITag {
  isShowAddTag: boolean;
  setShowAddTag: React.Dispatch<React.SetStateAction<boolean>>;
  editTagData: { tagId: string; tagName: string; indexEl: number } | null;
  setEditTagData: React.Dispatch<
    React.SetStateAction<{ tagId: string; tagName: string; indexEl: number } | null>
  >;
  editTagColor: IChangeColorTag | null;
  setEditTagColor: React.Dispatch<React.SetStateAction<IChangeColorTag | null>>;
  hovered: string;
  setHovered: React.Dispatch<React.SetStateAction<string>>;
  tagName: string;
  setTagName: React.Dispatch<React.SetStateAction<string>>;
  isTagMenu: boolean;
  setTagMenu: React.Dispatch<React.SetStateAction<boolean>>;
  tagDataForDel: { tagId: string; tagName: string } | null;
  setTagDataForDel: React.Dispatch<React.SetStateAction<{ tagId: string; tagName: string } | null>>;
  keyValue: number | null;
  setKeyValue: React.Dispatch<React.SetStateAction<number | null>>;
  closeTagWindow: (e: any) => void;
}

const TagContext = createContext<ITag | null>(null);

export function TagProvider({ children }: IChildrenNodes): React.ReactElement {
  const [isShowAddTag, setShowAddTag] = useState(false);
  const [editTagData, setEditTagData] = useState<{
    tagId: string;
    tagName: string;
    indexEl: number;
  } | null>(null);
  const [editTagColor, setEditTagColor] = useState<IChangeColorTag | null>(null);
  const [hovered, setHovered] = useState<string>('');

  const [tagName, setTagName] = useState<string>('');
  const [isTagMenu, setTagMenu] = useState(false);
  const [tagDataForDel, setTagDataForDel] = useState<null | { tagId: string; tagName: string }>(
    null,
  );
  const [keyValue, setKeyValue] = useState<number | null>(null);

  const { setTagListInHostList } = useHostModalContext();

  const closeTagWindow = useCallback(
    (e: any): void => {
      if (
        e.target &&
        e.target.nodeName !== 'IMG' &&
        e.target.nodeName !== 'SPAN' &&
        e.target.nodeName !== 'BUTTON' &&
        !isShowAddTag &&
        e.target.id !== 'tag'
      ) {
        setShowAddTag(false);
        setTagMenu(false);
        // setEditTagData(null);
        setTagListInHostList('');
      }
    },
    [isShowAddTag],
  );

  const value = useMemo(
    () => ({
      isShowAddTag,
      setShowAddTag,
      editTagData,
      setEditTagData,
      editTagColor,
      setEditTagColor,
      hovered,
      setHovered,
      tagName,
      setTagName,
      isTagMenu,
      setTagMenu,
      tagDataForDel,
      setTagDataForDel,
      keyValue,
      setKeyValue,
      closeTagWindow,
    }),
    [
      closeTagWindow,
      editTagColor,
      editTagData,
      hovered,
      isShowAddTag,
      isTagMenu,
      keyValue,
      tagDataForDel,
      tagName,
    ],
  );

  return <TagContext.Provider value={value}>{children}</TagContext.Provider>;
}
export const useTagContext = (): ITag => {
  const context = useContext(TagContext);
  if (context === null) {
    throw new Error('TagContext must be used inside the ModalContext.Provider.');
  }

  return context;
};
