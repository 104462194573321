import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Dialog } from '@progress/kendo-react-dialogs';
import { useEffect } from 'react';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { checkLength } from '../../../../../utils/helpers/validation';
import localeUserList from '../../../../../utils/i18n/userLocales/userList.json';
import localeTicket from '../../../../../utils/i18n/security/vulnerabilities.json';
import { FormInput } from '../../../../common/form/FormInput';
import { DropDownSelect } from '../../../../common/form/DropDownSelect';
import { useAccounts } from '../../../../../hooks/components/useAccounts';
import { Button } from '../../../../common/baseElements/Button';
import { FormTextArea } from '../../../../common/form/FormTextArea';
import { СommonTableLoader } from '../../../../common/tableLoader/CommonTableLoader';
import { useAccountModalContext } from '../../../../../hooks/useAccountModalContext';
import {
  getAccountId,
  getAccountName,
  getUserData,
} from '../../../../../services/local-storage-service';
import styles from '../../../User/UserList.module.scss';
import { useTicketContext } from '../../../../../hooks/useTicketContext';
import {
  ticketPriorityValue2,
  ticketStatusValueForm,
} from '../../../../../utils/helpers/constants';
import { useUserModalContext } from '../../../../../hooks/useUserModalContext';
import { UserListForm } from '../../../Task/form/UserListForm';
import { AssetsMutableBlock } from '../../../../common/form/AssetsMutableBlock';
import { TaskScopeArea } from '../../../../common/form/TaskScopeArea';
import { useHostModalContext } from '../../../../../hooks/useHostModalContext';
import { UserEmailBlockTask } from '../../../Task/form/UserEmailBlockTask';
import { BulletinMutableBlockWhiteList } from '../../../../common/form/BulletinMutableBlockWhiteList';
import { BulletinsListForForm } from '../../../WhiteList/form/BulletinsListForForm';
import { useWhiteListContext } from '../../../../../hooks/useWhiteListContext';
import { useTicketSystemList } from '../../../../../hooks/components/useTicketSystemList';
import { getUrlList } from '../../../../../utils/helpers/getUrl-list-helper';
import type { IDataHooks } from '../../../../../utils/helpers/types';
import { useNetDeviceContext } from '../../../../../hooks/useNetDeviceContext';
import { useRegisterContext } from '../../../../../hooks/useRegisterContext';
import { CheckBox } from '../../../../common/form/CheckBox';
import { useGroupContext } from '../../../../../hooks/useGroupContext';
import { TicketActionBlock } from './TicketActionBlock';
import { useTicketMethods } from '../../../../../hooks/components/useTicketMethods';
import { useAssetCreatePropContext } from '../../../../../hooks/useAssetCreatePropContext';
import { useAssetActions } from '../../../../../hooks/components/useAssetActions';

export function AddTicketForm(): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeTicket;

  const currentUser = getUserData();

  const urlParams = getUrlList('AddTicketForm');

  const dataForHooks: IDataHooks = {
    name: 'url',
    componentName: 'TicketSystemList',
  };

  const min = new Date();

  const { isAddUserEmail } = useUserModalContext();

  const { accountId, setAccountId } = useAccountModalContext();

  const {
    type,
    summary,
    setSummary,
    description,
    setDescription,
    priority,
    ticketStatus,
    ticketSystem,
    errorMutation,
    setErrorMutation,
    deadlineDate,
    isTicketAutoCloseEnabled,
    setTicketAutoCloseEnabled,
    editTicketData,
  } = useTicketContext();

  const {
    handleSubmit,
    handleClose,
    changePriorityTicket,
    changeStatusTicketInDropDownList,
    handleDatePicker,
    changeTicketSystem,
  } = useTicketMethods();

  const { selectedVulns } = useAssetCreatePropContext();

  useEffect(() => {
    if (errorMutation) {
      setErrorMutation('');
    }

    return () => setErrorMutation('');
  }, []);

  const { isOpen, setOpen } = useHostModalContext();

  const { destructuringAccounts, isLoading } = useAccounts();

  const { isAddBulletin, bulletinForm, setBulletinForm } = useWhiteListContext();

  const destructuredAccounts = destructuringAccounts();

  const { ticketSystemForForm } = useTicketSystemList(urlParams, dataForHooks);

  const { hostForm } = useHostModalContext();

  const { deviceForm } = useNetDeviceContext();

  const { imageForm } = useRegisterContext();

  const { dynamicGroupForm } = useGroupContext();

  const { handlerDropDownAccount } = useAssetActions();

  useEffect(() => {
    if (
      (document.location.pathname.includes('/assets/') ||
        document.location.pathname.includes('vulnerabilities')) &&
      selectedVulns &&
      selectedVulns.length > 0
    ) {
      const bulletins = selectedVulns?.map((b) => ({
        bulletinId: b.vulnId,
        addedBy: {
          source: 'user' as const,
          id: currentUser?.id || '',
        },
        addedAt: new Date().toISOString(),
      }));

      setBulletinForm(bulletins);
    }

    if (accountId.id === '000') {
      const currentAccouintId = getAccountId();
      const currentAccountName = getAccountName();
      setAccountId({ id: currentAccouintId || '', name: currentAccountName || '' });
    }
  }, []);

  return (
    <Dialog
      title={
        !editTicketData
          ? localeTicket[currentLocale].tickets.form.title
          : `${localeTicket[currentLocale].tickets.form.titleEdit} ${summary}`
      }
      onClose={handleClose}
      className="modal small"
    >
      <Form
        render={(): JSX.Element => (
          <FormElement>
            {ticketSystemForForm && (
              <div className="form-content">
                {!editTicketData && currentUser?.role === 'super_admin' && (
                  <Field
                    name="accountId"
                    component={DropDownSelect}
                    label={localeTicket[currentLocale].tickets.form.accountName}
                    onChange={handlerDropDownAccount}
                    data={destructuredAccounts?.filter((a) => a.id !== accountId.id)}
                    textField="name"
                    dataItemKey="id"
                    defaultValue={{
                      id: '000',
                      name: localeUserList[currentLocale].modal.placeholderAccountName,
                    }}
                    cls="form-field-wrapper"
                    currentValue={accountId}
                    disabled={!destructuredAccounts}
                    classForLabel={!editTicketData ? 'required-label' : ''}
                  />
                )}
                <Field
                  name="summary"
                  component={FormInput}
                  id="summary"
                  label={localeTicket[currentLocale].tickets.form.summary}
                  onChange={(e): void => {
                    setSummary(e.target.value.trimStart());
                  }}
                  inputValue={summary}
                  className="form-field-wrapper"
                  customValidator={checkLength}
                  type="text"
                  classForLabel={!editTicketData ? 'required-label' : ''}
                />
                {!editTicketData && (
                  <Field
                    name="ticketSystem"
                    component={DropDownSelect}
                    label={localeTicket[currentLocale].tickets.form.ticketSystem}
                    onChange={changeTicketSystem}
                    data={
                      ticketSystem
                        ? ticketSystemForForm.filter((t) => t.id !== ticketSystem?.id)
                        : ticketSystemForForm
                    }
                    defaultValue={
                      ticketSystem || {
                        id: '000',
                        name: localeTicket[currentLocale].tickets.form.selectTicketSystem,
                      }
                    }
                    cls="form-field-wrapper"
                    currentValue={ticketSystem}
                    classForLabel={!editTicketData ? 'required-label' : ''}
                    textField="name"
                    dataItemKey="id"
                  />
                )}
                <TicketActionBlock />
                <div className="form-field-wrapper">
                  <span className="k-label">
                    {localeTicket[currentLocale].tickets.form.deadline}
                  </span>
                  <LocalizationProvider language={currentLocale}>
                    <IntlProvider locale={currentLocale.substring(0, 2)}>
                      <DatePicker
                        format="dd-MMM-yyyy"
                        name="startAt"
                        onChange={(e): void => handleDatePicker(e.target.value)}
                        placeholder={
                          !deadlineDate
                            ? localeTicket[currentLocale].tickets.form.specifyTheDate
                            : `${
                                new Date(deadlineDate).getDate() < 10
                                  ? `0${new Date(deadlineDate).getDate()}`
                                  : new Date(deadlineDate).getDate()
                              }.${
                                deadlineDate.getMonth() + 1 < 10
                                  ? `0${deadlineDate.getMonth() + 1}`
                                  : deadlineDate.getMonth() + 1
                              }.${new Date(deadlineDate).getFullYear()}`
                        }
                        min={min}
                        value={deadlineDate}
                      />
                    </IntlProvider>
                  </LocalizationProvider>
                </div>
                <Field
                  name="priority"
                  component={DropDownSelect}
                  label={localeTicket[currentLocale].tickets.form.ticketPriority}
                  onChange={(e): void => changePriorityTicket(e)}
                  data={ticketPriorityValue2.filter((p) => p.id !== priority.id)}
                  textField="name"
                  dataItemKey="id"
                  defaultValue={priority}
                  cls="form-field-wrapper"
                  currentValue={priority}
                />
                {editTicketData && (
                  <Field
                    name="ticketStatus"
                    component={DropDownSelect}
                    label={localeTicket[currentLocale].tickets.form.ticketStatus}
                    onChange={(e): void => changeStatusTicketInDropDownList(e)}
                    data={ticketStatusValueForm.filter((p) => p.id !== ticketStatus.id)}
                    textField="name"
                    dataItemKey="id"
                    defaultValue={ticketStatus}
                    cls="form-field-wrapper"
                    currentValue={ticketStatus}
                    classForLabel={!editTicketData ? 'required-label' : ''}
                  />
                )}
                <div className={styles.scopeWrap}>
                  <div className={styles.titleWrap}>
                    <span
                      className={
                        !editTicketData && type === 'fix_vulnerabilities' ? 'required-label' : ''
                      }
                    >
                      {localeTicket[currentLocale].tickets.form.scope}
                    </span>
                    <Button
                      onClick={(): void => setOpen(true)}
                      type="button"
                      disabled={!!editTicketData}
                    >
                      {localeTicket[currentLocale].tickets.form.addAssetScope}
                    </Button>
                  </div>

                  <div className={styles.mutableBlockWrap}>
                    <AssetsMutableBlock dataForHooks={dataForHooks} />
                  </div>
                </div>
                {type === 'fix_vulnerabilities' && (
                  <BulletinMutableBlockWhiteList dataForHooks={dataForHooks} />
                )}
                <UserEmailBlockTask type="user" />
                <Field
                  name="description"
                  component={FormTextArea}
                  id="description"
                  label={localeTicket[currentLocale].tickets.form.description}
                  onChange={(e): void => {
                    setDescription(e.value);
                  }}
                  placeholder={localeTicket[currentLocale].tickets.form.desPlaceholder}
                  inputValue={description}
                  maxLength={2000}
                />
                {type === 'fix_vulnerabilities' && (
                  <Field
                    name="isTicketAutoCloseEnabled"
                    component={CheckBox}
                    checked={isTicketAutoCloseEnabled}
                    value={isTicketAutoCloseEnabled}
                    label={localeTicket[currentLocale].tickets.form.closeTheTicketAutomatically}
                    onChange={(e): void => {
                      setTicketAutoCloseEnabled(e.target.value);
                    }}
                    className={styles.childCheckbox}
                    disabled={
                      ((!hostForm || hostForm.length === 0) &&
                        (!deviceForm || deviceForm.length === 0) &&
                        (!imageForm || imageForm.length === 0) &&
                        (!dynamicGroupForm || dynamicGroupForm.length === 0)) ||
                      !bulletinForm ||
                      (bulletinForm && bulletinForm.length === 0)
                    }
                  />
                )}
              </div>
            )}
            <div className="error-common-field">
              <span>{errorMutation}</span>
            </div>
            <div className="k-form-buttons">
              <Button
                type="submit"
                disabled={
                  !editTicketData &&
                  ((currentUser?.role === 'super_admin' && accountId.id === '000') ||
                    !ticketSystem ||
                    !summary ||
                    (type === 'fix_vulnerabilities' &&
                      (!hostForm || hostForm.length === 0) &&
                      (!deviceForm || deviceForm.length === 0) &&
                      (!imageForm || imageForm.length === 0) &&
                      (!dynamicGroupForm || dynamicGroupForm.length === 0)) ||
                    (type === 'fix_vulnerabilities' &&
                      (!bulletinForm || bulletinForm.length === 0)))
                }
                onClick={handleSubmit}
              >
                {!editTicketData
                  ? localeUserList[currentLocale].modal.btnCreate
                  : localeUserList[currentLocale].modal.btnSave}
              </Button>
              <Button type="button" onClick={handleClose}>
                {localeUserList[currentLocale].modal.btnCancel}
              </Button>
            </div>
          </FormElement>
        )}
      />
      {isOpen && <TaskScopeArea parentComponent="addTicket" />}
      {isAddUserEmail && <UserListForm parentComponent="addTicket" />}
      {isAddBulletin && <BulletinsListForForm />}
      <СommonTableLoader queryData={isLoading} filteringData={isLoading} />
    </Dialog>
  );
}
