import { getTicketListForDelTs } from '../../services/tickets-service';
import type { WhitelistedBulletin } from '../../types/__generated/on-premise-solution/api/bulletinWhitelistDashboardResponse.v1';
import type { TicketSystemsResponse } from '../../types/__generated/on-premise-solution/api/ticketSystemsResponse.v1';
import type { IDeviceForm, IHostForm, IImageForm, TDynamicGroupForm, TEditTicket } from './types';

export function createTicketSystemForForm(
  data: TicketSystemsResponse | undefined,
  id: string,
): { id: string; name: string; type: string; isDefault: boolean }[] {
  const ticketSystemDataForList: { id: string; name: string; type: string; isDefault: boolean }[] =
    [];

  data?.data.forEach((t) => {
    if (t.accountId === id) {
      const ticketSystemData = {
        id: t.ticketSystemId,
        name: t.name,
        type: t.type,
        isDefault: t.isDefault,
      };
      ticketSystemDataForList.push(ticketSystemData);
    }
  });

  return ticketSystemDataForList.sort((a, b) => (a.isDefault < b.isDefault ? 1 : -1));
}

export function returnIdFromAssetId(
  hostForm: IHostForm[] | null,
  deviceForm: IDeviceForm[] | null,
  imageForm: IImageForm[] | null,
): string[] | undefined {
  let dataOfAssetIds: string[] = [];

  if (hostForm && hostForm.length > 0) {
    const assetIds = hostForm.map((a) => a.assetId) as string[];

    if (assetIds && assetIds.length > 0) {
      dataOfAssetIds = [...assetIds];
    }
  }

  if (deviceForm && deviceForm.filter((a) => a.assetId)) {
    const assetIds = deviceForm.map((a) => a.assetId) as string[];

    if (assetIds && assetIds.length > 0 && dataOfAssetIds) {
      dataOfAssetIds = [...dataOfAssetIds, ...assetIds];
    }
  }

  if (imageForm && imageForm.filter((a) => a.assetId)) {
    const assetIds = imageForm.map((a) => a.assetId) as string[];

    if (assetIds && assetIds.length > 0 && dataOfAssetIds) {
      dataOfAssetIds = [...dataOfAssetIds, ...assetIds];
    }
  }

  return dataOfAssetIds && dataOfAssetIds.length > 0 ? dataOfAssetIds : undefined;
}

export function returnIdFromAssetGroupId(hostForm: IHostForm[] | null): string[] | undefined {
  let dataOfAssetGroupId: string[] = [];

  if (hostForm && hostForm.length > 0) {
    const assetGroupId = hostForm.map((a) => a.assetGroupId) as string[];

    if (assetGroupId && assetGroupId.length > 0) {
      dataOfAssetGroupId = [...assetGroupId];
    }
  }

  return dataOfAssetGroupId && dataOfAssetGroupId.length > 0 ? dataOfAssetGroupId : undefined;
}

export function returnIdFromBulletinId(
  bulletinForm: WhitelistedBulletin[] | null,
): string[] | undefined {
  let dataOfBulletinId: string[] | undefined = [];

  if (bulletinForm && bulletinForm.filter((b) => b.bulletinId)) {
    const bulletinId = bulletinForm.map((b) => b.bulletinId) as string[];

    if (bulletinId && bulletinId.length > 0 && !dataOfBulletinId) {
      dataOfBulletinId = [...bulletinId];
    } else {
      dataOfBulletinId = [...dataOfBulletinId, ...bulletinId];
    }
  }

  return dataOfBulletinId && dataOfBulletinId.length > 0 ? dataOfBulletinId : undefined;
}

export function returnIdFromAssetDynamicGroupId(
  dynamicGroupForm: TDynamicGroupForm[] | null,
): string[] | undefined {
  let assetDynamicGroupId: string[] | undefined = [];

  if (dynamicGroupForm && dynamicGroupForm.filter((g) => g.assetDynamicGroupId)) {
    const assetDynamicGroupIdData = dynamicGroupForm.map((b) => b.assetDynamicGroupId) as string[];

    if (assetDynamicGroupIdData && assetDynamicGroupIdData.length > 0 && !assetDynamicGroupId) {
      assetDynamicGroupId = [...assetDynamicGroupIdData];
    } else {
      assetDynamicGroupId = [...assetDynamicGroupId, ...assetDynamicGroupIdData];
    }
  }

  return assetDynamicGroupId && assetDynamicGroupId.length > 0 ? assetDynamicGroupId : undefined;
}

export function createDataTicketEdit(dataItem: { [key: string]: any }): TEditTicket {
  const data: TEditTicket = {
    ticketSystemId: dataItem.ticketSystemId,
    ticketSystem: dataItem.ticketSystem,
    ticketSystemType: dataItem.ticketSystemType,
    type: dataItem.type,
    account: dataItem.account,
    summary: dataItem.summary,
    assignedTo: dataItem.assignedTo,
    deadlineDate: dataItem.deadlineDate,
    priority: dataItem.priority,
    status: dataItem.status,
    isTicketAutoCloseEnabled: dataItem.isTicketAutoCloseEnabled,
    description: dataItem.description,
    ticketId: dataItem.ticketId,
    assetIds: dataItem.scope.assetIds,
    assetGroupIds: dataItem.scope.assetGroupIds,
    assetDynamicGroupIds: dataItem.scope.assetDynamicGroupIds,
    bulletinIds: dataItem.bulletinIds,
  };

  return data;
}

export function createInfoWhenDelTs(
  ticketSystemId: string,
  setTickets: React.Dispatch<
    React.SetStateAction<{
      notClosedTickets: number;
      allTickets: number;
    } | null>
  >,
): void {
  getTicketListForDelTs(ticketSystemId).then((r) => {
    if (r.data) {
      const notClosedTickets = r.data.filter((t) => t.status !== 'closed');
      const allTickeys = r.data;
      if (notClosedTickets.length === 0 && allTickeys.length === 0) {
        setTickets(null);
      } else {
        setTickets({
          notClosedTickets: notClosedTickets ? notClosedTickets.length : 0,
          allTickets: allTickeys ? allTickeys.length : 0,
        });
      }
    }
  });
}
