/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import type { GridCellProps } from '@progress/kendo-react-grid';
import { NavLink } from 'react-router-dom';
import type { AssetsHostItemDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/assetsHostsDashboardResponse.v1';

export function AccountCell(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { account }: AssetsHostItemDashboardResponse = dataItem;

  return (
    <td>
      {account.accountId ? (
        <NavLink to={`/lk/accounts/${account.accountId}`}>{account.name}</NavLink>
      ) : (
        '-'
      )}
    </td>
  );
}
