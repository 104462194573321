import type { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { memo } from 'react';
import { useHostModalContext } from '../../../../../../hooks/useHostModalContext';
import type { AuditWindowsResult } from '../../../../../../types/__generated/on-premise-solution/api/audit.v1';

function CellSelectWinSoft(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { availableUpdate, name }: AuditWindowsResult['vulnerableObjects'][0] = dataItem;

  const { winSoftwareUpdateState, softwareInWl, handleSoftwareAction } = useHostModalContext();

  return (
    <td>
      <Checkbox
        checked={
          (winSoftwareUpdateState &&
            availableUpdate &&
            availableUpdate.softwareCodename in winSoftwareUpdateState) ||
          (softwareInWl && name in softwareInWl)
        }
        onClick={(): void => handleSoftwareAction(dataItem, dataItem.type, 'default')}
      />
    </td>
  );
}

export default memo(CellSelectWinSoft);
