import type { GridCellProps } from '@progress/kendo-react-grid';
import { useState } from 'react';
import { Button } from '../../../../common/baseElements/Button';
import { getUserData } from '../../../../../services/local-storage-service';
import { DeleteConfirmModal } from '../../../../common/modal/DeleteConfirmModal';
import localeTicket from '../../../../../utils/i18n/security/vulnerabilities.json';
import { useTicketSystemMethods } from '../../../../../hooks/components/useTicketSystemMethods';
import type { TicketSystemDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/ticketSystemsDashboardResponse.v1';

export function CellMenuTicketSystemList(props: GridCellProps): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeTicket;
  const currentUser = getUserData();

  const { dataItem } = props;

  const { name }: TicketSystemDashboardResponse = dataItem;

  const [isConfirmDel, setConfirmDel] = useState(false);

  const {
    hadleDeleteTickeSystem,
    isErrorDelTicketSystem,
    errorMessageDelTicketSystem,
    handleEdit,
    setDefaultTicketSystem,
    handleShowTicketHook,
  } = useTicketSystemMethods();

  return (
    <>
      {isConfirmDel && (
        <DeleteConfirmModal
          name={name}
          type="ticketSystem"
          onClose={(): void => setConfirmDel(false)}
          handleDelete={(): void => hadleDeleteTickeSystem(dataItem.ticketSystemId, setConfirmDel)}
          errorMessage={isErrorDelTicketSystem ? errorMessageDelTicketSystem : undefined}
          dataItemEl={dataItem.ticketSystemId}
        />
      )}
      <td className="cell_menu">
        <div className="groups__menu">
          <div className="dropdown__container">
            <span className="k-icon k-i-more-vertical" />
            <div className="dropdown__content">
              <Button
                fill="menu"
                onClick={(): void => handleShowTicketHook(dataItem.ticketSystemId, dataItem.name)}
              >
                {localeTicket[currentLocale].ticketSystem.grid.menu.webhook}
              </Button>
              <Button
                fill="menu"
                onClick={(): void => handleEdit(dataItem)}
                disabled={dataItem.type === 'local'}
              >
                {localeTicket[currentLocale].ticketSystem.grid.menu.edit}
              </Button>
              <Button
                fill="menu"
                onClick={(): void =>
                  setDefaultTicketSystem(dataItem.ticketSystemId, dataItem.subType, dataItem.type)
                }
              >
                {localeTicket[currentLocale].ticketSystem.grid.menu.isDefault}
              </Button>
              {(currentUser?.role === 'super_admin' || currentUser?.role === 'account_admin') && (
                <Button
                  fill="menu"
                  onClick={(): void => setConfirmDel(true)}
                  disabled={dataItem.type === 'local'}
                >
                  {localeTicket[currentLocale].ticketSystem.grid.menu.delete}
                </Button>
              )}
            </div>
          </div>
        </div>
      </td>
    </>
  );
}
