import { Filter } from '@progress/kendo-react-data-tools';
import type { ReactElement } from 'react';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import type { InstalledSoftwareListDashboardResponse } from '../../../types/__generated/on-premise-solution/api/installedSoftwareListDashboardResponse.v1';
import { getUrlList } from '../../../utils/helpers/getUrl-list-helper';
import type { IDataHooks } from '../../../utils/helpers/types';
import { Input } from '../../common/baseElements/Input';
import localeHost from '../../../utils/i18n/assetLocales/hostList.json';
import { getUserData } from '../../../services/local-storage-service';
import {
  FILTER_IMAGELIST,
  FILTER_IMAGELIST_NOT_SUPER_ADMIN,
} from '../../../utils/helpers/constant-serv-filter-host';
import { useImagesList } from '../../../hooks/components/useImageList';
import { CommonImageList } from '../../common/CommonImageList';
import type { VulnerableSoftwareListDashboardResponse } from '../../../types/__generated/on-premise-solution/api/vulnerableSoftwareListDashboardResponse.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHost;

export function SoftwareImagesTab({
  assets,
}: {
  assets:
    | InstalledSoftwareListDashboardResponse['data'][number]['assets']
    | VulnerableSoftwareListDashboardResponse['data'][number]['assets']
    | undefined;
}): ReactElement {
  const urlParams = getUrlList('HostList');
  const currentUser = getUserData();

  const dataForHooks: IDataHooks = {
    name: 'latestInventorization',
    componentName: 'SoftwareHostsTab',
  };
  const {
    handleRefresh,
    filter,
    onFilterChange,
    customSearch,
    sort,
    dataImage,
    page,
    handleCustomSearch,
    total,
    pageChange,
    setSort,
    filterStatus,
    isError,
    errorMessage,
  } = useImagesList(
    urlParams,
    dataForHooks,
    assets?.map((a) => a.assetId),
  );

  return (
    <>
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <div className="filter-line">
            <Filter
              value={filter}
              onChange={onFilterChange}
              fields={
                currentUser?.role === 'super_admin'
                  ? FILTER_IMAGELIST[currentLocale]
                  : FILTER_IMAGELIST_NOT_SUPER_ADMIN[currentLocale]
              }
            />
            {filter.filters.length === 0 && (
              <Input
                name="customSearch"
                placeholder={localeHost[currentLocale].customSearchImage}
                value={customSearch}
                onChange={(e): void => handleCustomSearch(e)}
              />
            )}
          </div>
        </IntlProvider>
      </LocalizationProvider>
      <CommonImageList
        sort={sort}
        dataImage={dataImage}
        page={page}
        total={total}
        pageChange={pageChange}
        setSort={setSort}
        dataForHooks={dataForHooks}
        conditionSort={15}
      />
    </>
  );
}
