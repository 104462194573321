import type { AssetMaintenanceWindowsResponse2 } from '../../types/__generated/on-premise-solution/api/assetMaintenanceWindowsResponse.v1';
import type { MaintenanceWindowsField } from '../../types/__generated/on-premise-solution/api/assetUpdateRequest.v1';

interface IMaintenanceWindowPeriod {
  hourRanges?: string[];
  dayOfWeekRanges?: string[];
  dayOfMonthRanges?: string[];
  monthRanges?: string[];
  utcOffset?: string;
  isDisabled?: boolean;
}

export type TaskActionTypes = keyof Exclude<
  Exclude<MaintenanceWindowsField, null>['taskActions'],
  null
>;

export interface IWindowData {
  period: IMaintenanceWindowPeriod;
  taskActions: {
    [k in TaskActionTypes]?: boolean;
  };
  parentId?: string;
  parentType?: 'group' | 'account';
  parentName?: string;
}

export function getLocaleUTC(): string {
  const offset = new Date().getTimezoneOffset();
  const o = Math.abs(offset);

  return `${(offset < 0 ? '+' : '-') + `00${Math.floor(o / 60)}`.slice(-2)}:${`00${o % 60}`.slice(
    -2,
  )}`;
}

export function isPeriodsEq(
  period1: IMaintenanceWindowPeriod,
  period2: IMaintenanceWindowPeriod,
): boolean {
  const hoursEq = !!(
    (!period1.hourRanges && !period2.hourRanges) ||
    (period1.hourRanges &&
      period2.hourRanges &&
      period1.hourRanges.sort().join() === period2.hourRanges.sort().join())
  );
  const dayOfWeeksEq = !!(
    (!period1.dayOfWeekRanges && !period2.dayOfWeekRanges) ||
    (period1.dayOfWeekRanges &&
      period2.dayOfWeekRanges &&
      period1.dayOfWeekRanges.sort().join() === period2.dayOfWeekRanges.sort().join())
  );
  const dayOfMonthsEq = !!(
    (!period1.dayOfMonthRanges && !period2.dayOfMonthRanges) ||
    (period1.dayOfMonthRanges &&
      period2.dayOfMonthRanges &&
      period1.dayOfMonthRanges.sort().join() === period2.dayOfMonthRanges.sort().join())
  );
  const monthsEq = !!(
    (!period1.monthRanges && !period2.monthRanges) ||
    (period1.monthRanges &&
      period2.monthRanges &&
      period1.monthRanges.sort().join() === period2.monthRanges.sort().join())
  );

  const utcOffsetEq = period1.utcOffset === period2.utcOffset;

  return hoursEq && dayOfWeeksEq && dayOfMonthsEq && monthsEq && utcOffsetEq;
}

export function createWindowsListInfo(data: MaintenanceWindowsField | undefined): {
  [k in TaskActionTypes]?: number;
} {
  const wListInfo: {
    [k in TaskActionTypes]?: number;
  } = {};

  Object.entries(data?.taskActions || {}).forEach(([action, actionData]) => {
    if (actionData.length) {
      wListInfo[action as TaskActionTypes] = wListInfo[action as TaskActionTypes]
        ? (wListInfo[action as TaskActionTypes] as number) + actionData.length
        : actionData.length;
    }
  });

  return wListInfo;
}

export function convertFromActionsToPeriods(
  data: MaintenanceWindowsField | undefined,
  additionsProps?: { [key: string]: string | undefined },
): IWindowData[] {
  const maintenanceWindows: IWindowData[] = [];

  Object.entries(data?.taskActions || {}).forEach(([action, aData]) => {
    aData.forEach((element) => {
      const wIndex = maintenanceWindows.findIndex((el) => isPeriodsEq(el.period, element));
      if (wIndex > -1) maintenanceWindows[wIndex].taskActions[action as TaskActionTypes] = true;
      else
        maintenanceWindows.push({
          period: element,
          taskActions: { [action]: true },
          ...additionsProps,
        });
    });
  });

  return maintenanceWindows;
}

export function convertFromPeriodsToActions(data: IWindowData[]): MaintenanceWindowsField {
  const taskActions: Exclude<MaintenanceWindowsField, null>['taskActions'] = {};
  data.forEach((periodData: IWindowData) => {
    Object.entries(periodData.taskActions)
      .filter(([_, actionData]) => actionData)
      .forEach(([action, _]) => {
        if ((action as TaskActionTypes) in taskActions) {
          (taskActions[action as TaskActionTypes] as IWindowData['period'][]).push(
            periodData.period,
          );
        } else taskActions[action as TaskActionTypes] = [periodData.period];
      });
  });

  return { taskActions: Object.keys(taskActions).length ? taskActions : null };
}

export function convertGroupsWindowsToMaintenanceList(
  staticGroups: Exclude<AssetMaintenanceWindowsResponse2, null>['assetGroups'] | undefined,
  dynamicGroups: Exclude<AssetMaintenanceWindowsResponse2, null>['assetDynamicGroups'] | undefined,
): IWindowData[] {
  const maintenanceWindows: IWindowData[] = [];
  staticGroups?.forEach((group) => {
    maintenanceWindows.push(
      ...convertFromActionsToPeriods(group.maintenanceWindows, {
        groupId: group.assetGroupId,
        name: group.name,
        groupType: 'static',
      }),
    );
  });
  dynamicGroups?.forEach((group) => {
    maintenanceWindows.push(
      ...convertFromActionsToPeriods(group.maintenanceWindows, {
        groupId: group.assetDynamicGroupId,
        name: group.name,
        groupType: 'dynamic',
      }),
    );
  });

  return maintenanceWindows;
}
