/* eslint-disable no-nested-ternary */
import {
  createContext,
  JSXElementConstructor,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import { useAccountModalContext } from './useAccountModalContext';
import localeUserList from '../utils/i18n/userLocales/userList.json';
import type { WhitelistedBulletin } from '../types/__generated/on-premise-solution/api/bulletinWhitelistCreationRequest.v1';
import { getWhiteLIstId } from '../services/settings-service';
import type { ApiError } from '../types/__generated/on-premise-solution/api/apiError.v1';
import type {
  IAssetsAlreadyInWl,
  IWhiteListContext,
  TExtendedBulletinWhitelist,
} from '../utils/helpers/types';
import type { BulletinWhitelistDashboardResponse } from '../types/__generated/on-premise-solution/api/bulletinWhitelistDashboardResponse.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeUserList;

const WhiteListContext = createContext<IWhiteListContext | null>(null);

export function WhiteListProvider({
  children,
}: {
  children: ReactElement<string | JSXElementConstructor<string>>;
}): React.ReactElement {
  const [isWhiteListForm, setWhiteListForm] = useState<boolean>(false);

  const [isRelatedWhiteListModal, setRelatedWhiteListModal] = useState<boolean>(false);
  const relatedWlIds = useRef<string[]>([]);

  const [name, setName] = useState<string>('');

  const [description, setDescription] = useState<string | null>('');

  const [bulletinForm, setBulletinForm] = useState<WhitelistedBulletin[] | null>(null);

  const [bulletinFormTemp, setBulletinFormTemp] = useState<WhitelistedBulletin[] | null>(null);

  const [whiteListStatus, setWhiteListStatus] = useState<{ text: string; val: boolean }>({
    text: localeUserList[currentLocale].active,
    val: false,
  });

  const [isAddBulletin, setAddBulletin] = useState(false);

  const [whitelistUpdate, setWhitelistUpdate] = useState<TExtendedBulletinWhitelist | null>(null);

  const [initialState, setInitialState] = useState<TExtendedBulletinWhitelist | null>(null);

  const [errorMutation, setErrorMutation] = useState('');

  const [assetType, setAssetType] = useState<IWhiteListContext['assetType']>({
    host: false,
    image: false,
    router: false,
    switch: false,
    firewall: false,
    hypervisor: false,
    all: false,
  });

  const [onSubmitVulnSuccess, setOnSubmitVulnSuccess] = useState(false);

  const [selectedWhitelist, setSelectedWhitelist] = useState<
    | {
        name: string;
        bulletinWhitelistId: string;
      }[]
    | null
  >(null);

  const [isAddSoftwareList, setAddSoftwareList] = useState(false);

  const whiteListStatusValues = useMemo(
    () => [
      { text: currentLocale === 'ru-RU' ? 'Активный' : 'Active', val: false },
      { text: currentLocale === 'ru-RU' ? 'Не активный' : 'Not active', val: true },
    ],
    [],
  );

  const [assetsAlreadyInWl, setAssetsAlreadyInWl] = useState<IAssetsAlreadyInWl[] | null>(null);

  const { setAccountId } = useAccountModalContext();

  useEffect(() => {
    if (whitelistUpdate) {
      setName(whitelistUpdate.name);
      setWhiteListStatus(
        whitelistUpdate.isDisabled
          ? { text: localeUserList[currentLocale].notActive, val: true }
          : { text: localeUserList[currentLocale].active, val: false },
      );
      setDescription(whitelistUpdate.description);
      setInitialState(whitelistUpdate);
    }
  }, [setAccountId, whitelistUpdate]);

  useQuery<BulletinWhitelistDashboardResponse, ApiError>(
    ['bulletinWhitelistId', (selectedWhitelist && selectedWhitelist[0].bulletinWhitelistId) || ''],
    () => getWhiteLIstId((selectedWhitelist && selectedWhitelist[0].bulletinWhitelistId) || ''),
    {
      onSuccess: (response) => {
        const assetsInScope = response.scope.assets?.map((a) => ({
          assetId: a.assetId,
          addedBy: {
            source: a.addedBy.source,
            id: a.addedBy.source === 'user' ? a.addedBy.id : '',
          },
          addedAt: a.addedAt,
        }));
        if (assetsInScope) setAssetsAlreadyInWl(assetsInScope);
      },
      enabled: !!(selectedWhitelist && selectedWhitelist[0].bulletinWhitelistId),
    },
  );

  const value = useMemo(
    () => ({
      isWhiteListForm,
      setWhiteListForm,
      relatedWlIds,
      isRelatedWhiteListModal,
      setRelatedWhiteListModal,
      name,
      setName,
      description,
      setDescription,
      bulletinForm,
      setBulletinForm,
      isAddBulletin,
      setAddBulletin,
      bulletinFormTemp,
      setBulletinFormTemp,
      whiteListStatus,
      whiteListStatusValues,
      whitelistUpdate,
      errorMutation,
      setErrorMutation,
      assetType,
      setAssetType,
      selectedWhitelist,
      setSelectedWhitelist,
      onSubmitVulnSuccess,
      setOnSubmitVulnSuccess,
      isAddSoftwareList,
      setAddSoftwareList,
      setWhitelistUpdate,
      initialState,
      setWhiteListStatus,
      assetsAlreadyInWl,
    }),
    [
      isWhiteListForm,
      isRelatedWhiteListModal,
      name,
      description,
      bulletinForm,
      isAddBulletin,
      bulletinFormTemp,
      whiteListStatus,
      whiteListStatusValues,
      whitelistUpdate,
      errorMutation,
      assetType,
      selectedWhitelist,
      onSubmitVulnSuccess,
      isAddSoftwareList,
      initialState,
      assetsAlreadyInWl,
    ],
  );

  return <WhiteListContext.Provider value={value}>{children}</WhiteListContext.Provider>;
}

export const useWhiteListContext = (): IWhiteListContext => {
  const context = useContext(WhiteListContext);
  if (context === null) {
    throw new Error('useWhiteListContext must be used inside the WebSocketContext.Provider.');
  }

  return context;
};
