import type { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import type { IUrlParamsHosts } from './host-list-helper';

export function fillFiltersHostList(
  urlParams: IUrlParamsHosts,
  filterValue: React.MutableRefObject<CompositeFilterDescriptor | null>,
  setFilter: (value: React.SetStateAction<CompositeFilterDescriptor>) => void,
  setFilterStatus: React.Dispatch<React.SetStateAction<boolean>>,
): void {
  if (urlParams.filterUrlData.osVersionRegex && urlParams.filterUrlData.osNameRegex) {
    filterValue.current = {
      filters: [
        {
          field: 'latestInventorization.os.name',
          operator: 'eq',
          value: urlParams.filterUrlData.osNameRegex,
        },
        {
          field: 'latestInventorization.os.version',
          operator: 'eq',
          value: urlParams.filterUrlData.osVersionRegex,
        },
      ],
      logic: 'and',
    } as CompositeFilterDescriptor;
    setFilter(filterValue.current);
  } else if (urlParams.filterUrlData.osNameRegex) {
    setFilterStatus(true);
    filterValue.current = {
      filters: [
        {
          field: 'latestInventorization.os.name',
          operator: 'contains',
          value: urlParams.filterUrlData.osNameRegex,
        },
      ],
      logic: 'and',
    } as CompositeFilterDescriptor;
    setFilter(filterValue.current);
  } else if (urlParams.filterUrlData.hostNameEq) {
    setFilterStatus(true);
    filterValue.current = {
      filters: [
        {
          field: 'latestInventorization.hostname',
          operator: 'eq',
          value: urlParams.filterUrlData.hostNameEq,
        },
      ],
      logic: 'and',
    } as CompositeFilterDescriptor;
    setFilter(filterValue.current);
  } else if (urlParams.filterUrlData.hostNameNe) {
    setFilterStatus(true);
    filterValue.current = {
      filters: [
        {
          field: 'latestInventorization.hostname',
          operator: 'ne',
          value: urlParams.filterUrlData.hostNameNe,
        },
      ],
      logic: 'and',
    } as CompositeFilterDescriptor;
    setFilter(filterValue.current);
  } else if (urlParams.filterUrlData.hostNameRegex) {
    setFilterStatus(true);
    filterValue.current = {
      filters: [
        {
          field: 'latestInventorization.hostname',
          operator: 'contains',
          value: urlParams.filterUrlData.hostNameRegex,
        },
      ],
      logic: 'and',
    } as CompositeFilterDescriptor;
    setFilter(filterValue.current);
  } else if (urlParams.filterUrlData.bulletinId) {
    setFilterStatus(true);
    filterValue.current = {
      filters: [
        {
          field: 'bulletinId',
          operator: 'eq',
          value: urlParams.filterUrlData.bulletinId,
        },
      ],
      logic: 'and',
    } as CompositeFilterDescriptor;
    setFilter(filterValue.current);
  } else if (urlParams.filterUrlData.whitelistedBulletinId) {
    setFilterStatus(true);
    filterValue.current = {
      filters: [
        {
          field: 'whitelistedBulletinId',
          operator: 'eq',
          value: urlParams.filterUrlData.whitelistedBulletinId,
        },
      ],
      logic: 'and',
    } as CompositeFilterDescriptor;
    setFilter(filterValue.current);
  } else if (urlParams.filterUrlData.tagsName) {
    setFilterStatus(true);
    filterValue.current = {
      filters: [
        {
          field: 'tags.name',
          operator: 'eq',
          value: urlParams.filterUrlData.tagsName,
        },
      ],
      logic: 'and',
    } as CompositeFilterDescriptor;
    setFilter(filterValue.current);
  } else if (urlParams.filterUrlData.accountId) {
    setFilterStatus(true);
    filterValue.current = {
      filters: [
        {
          field: 'accountId',
          operator: 'eq',
          value: urlParams.filterUrlData.accountId,
        },
      ],
      logic: 'and',
    } as CompositeFilterDescriptor;
    setFilter(filterValue.current);
  } else if (urlParams.filterUrlData.softwareName && urlParams.filterUrlData.softwareVersion) {
    setFilterStatus(true);
    filterValue.current = {
      filters: [
        {
          field: 'softwareName',
          operator: 'contains',
          value: urlParams.filterUrlData.softwareName,
        },
        {
          field: 'softwareVersion',
          operator: 'eq',
          value: urlParams.filterUrlData.softwareVersion,
        },
      ],
      logic: 'and',
    } as CompositeFilterDescriptor;
    setFilter(filterValue.current);
  } else if (
    urlParams.filterUrlData.vulnerableSoftwareName &&
    urlParams.filterUrlData.softwareVersion
  ) {
    setFilterStatus(true);
    filterValue.current = {
      filters: [
        {
          field: 'vulnerableSoftwareName',
          operator: 'contains',
          value: urlParams.filterUrlData.vulnerableSoftwareName,
        },
        {
          field: 'softwareVersion',
          operator: 'eq',
          value: urlParams.filterUrlData.softwareVersion,
        },
      ],
      logic: 'and',
    } as CompositeFilterDescriptor;
    setFilter(filterValue.current);
  }
  // else if (document.location.pathname.includes('credentials')) {
  //   filterValue.current = {
  //     filters: [
  //       {
  //         field: 'latestInventorization.os.name',
  //         operator: 'contains',
  //         value: 'Linux',
  //       },
  //     ],
  //     logic: 'and',
  //   } as CompositeFilterDescriptor;
  //   setFilter(filterValue.current);
  //   // setPage({ skip: 0, take: 15 });
  //   // setCustomSearch('');
  // }
}
