import { useCallback, useEffect, useMemo, useRef, useState, type ReactElement } from 'react';
import { nanoid } from 'nanoid';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import styles from './Tag.module.scss';
import { Input } from '../baseElements/Input';
import { useTagContext } from '../../../hooks/useTagContext';
import { Button } from '../baseElements/Button';
import type { TagsResponse } from '../../../types/__generated/on-premise-solution/api/tagsResponse.v1';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
import { editTag, getAllTags } from '../../../services/asset-service';
import type { Tag } from '../../../types/__generated/on-premise-solution/api/assetsImagesDashboardResponse.v1';
import { useTagActions } from '../../../hooks/components/useTagActions';
import type { TagUpdateResponse } from '../../../types/__generated/on-premise-solution/api/tagUpdateResponse.v1';
import type { TagUpdateRequest } from '../../../types/__generated/on-premise-solution/api/tagUpdateRequest.v1';
import type { AssetUpdateRequest } from '../../../types/__generated/on-premise-solution/api/assetUpdateRequest.v1';
import { useHostActions } from '../../../hooks/components/useHostActions';
import { useSensorActions } from '../../../hooks/components/useSensorActions';
import localeTag from '../../../utils/i18n/commonLocales/tags.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeTag;

interface IEditTagBlock {
  entityId: string;
  tags: Tag[] | null | undefined;
  setHovered: React.Dispatch<React.SetStateAction<string>>;
  keyValue: number | null;
  currentTagId: string;
  beforeEditVal: string;
  entityType: 'host' | 'sensor';
  updateEntityTagList?: () => void;
}

export function EditTagBlock(propEditTagBlock: IEditTagBlock): ReactElement {
  const queryClient = useQueryClient();
  const {
    entityId,
    tags,
    setHovered,
    keyValue,
    currentTagId,
    beforeEditVal,
    entityType,
    updateEntityTagList,
  } = propEditTagBlock;

  const [editTagError, setEditTagError] = useState<string | null>(null);

  const isExistingTag = useRef(true);
  const currentTagName = useRef<string | null>(null);

  const { tagName, setTagName, editTagData, setEditTagData, setTagMenu } = useTagContext();
  const { addExistingTag, handleEditTag } = useTagActions();

  const { handleEditHost } = useHostActions();
  const { editSensor } = useSensorActions();

  const closeEditing = useCallback((e: any): void => {
    isExistingTag.current = true;
  }, []);

  const query = useQuery<TagsResponse, ApiError>(['tagsList', tagName], () => getAllTags(tagName), {
    keepPreviousData: true,
  });

  const mutation = useMutation<TagUpdateResponse, ApiError, TagUpdateRequest>(
    (payload) => editTag(editTagData?.tagId || '', payload),
    {
      onSuccess: (resp) => {
        queryClient.invalidateQueries('tagsList').then(() => {
          setEditTagData(null);
          if (entityType === 'sensor') editSensor(entityId, { tagIdsToAdd: [resp.tagId] });
          else if (entityType === 'host')
            handleEditHost(entityId, {
              tagIdsToAdd: [resp.tagId],
              type: 'host',
            } as AssetUpdateRequest);
          isExistingTag.current = true;
        });
      },
      onError: (resp) => {
        if (resp.code && resp.code === '409') {
          setEditTagError(`${localeTag[currentLocale].confirmDelTag}`);
        } else {
          setEditTagError(`${resp.code} ${resp.type}`);
        }
      },
    },
  );

  const dataEdit = useMemo(
    () => ({
      editTagError,
      tags,
      isExistingTag,
      setEditTagError,
      mutation,
      setHovered,
      keyValue,
    }),
    [editTagError, keyValue, mutation, setHovered, tags],
  );

  const dataAddExistTag = {
    query,
    currentTagName,
    currentTagId,
    tags,
    entityId,
    entityType,
    setHovered,
    setEditTagError,
    beforeEditVal,
  };

  const changeTagName = useCallback(
    (value: string): void => {
      if (editTagError) {
        setEditTagError(null);
      }
      isExistingTag.current = false;
      setTagName(() => value);
    },
    [editTagError, setTagName],
  );

  const tagN = useRef<HTMLInputElement>(null);
  const handleKeydown = useCallback((e: KeyboardEvent): void => {
    if (e.key === 'Escape') {
      setEditTagData(null);
      setHovered('');
      setTagMenu(false);
    }
    if (e.key === 'Enter') {
      // handleEditTag({ ...dataEdit, name: tagName });
      handleEditTag({
        ...dataEdit,
        name: tagN.current?.value || '',
        prevName: editTagData?.tagName || '',
        updateEntityTagList,
      });
      // setEditTagData(null);
      setHovered('');
      setTagMenu(false);
    }
  }, []);

  useEffect(() => {
    document.removeEventListener('keydown', handleKeydown);
    document.addEventListener('keydown', handleKeydown);
  }, [handleKeydown]);

  useEffect(() => {
    return () => {
      setEditTagData(null);
      document.removeEventListener('keydown', handleKeydown);
    };
  }, []);

  return (
    <div className={styles.editTagInputWrap}>
      {/* <Input
        ref={tagN}
        className="edit-tag-input"
        name="edit"
        // value={tagName}
        // onChange={(e): void => changeTagName(e.target.value)}
        onChange={() => console.log(tagN.current)}
        onBlur={(e): void => handleEditTag({ ...dataEdit, name: e.target.value.trim() })}
        autoFocus
      /> */}
      <input
        type="text"
        ref={tagN}
        // onChange={() => console.log(tagN.current?.value, editTagData?.tagName)}
        onBlur={(e): void =>
          handleEditTag({
            ...dataEdit,
            name: e.target.value.trim(),
            prevName: editTagData?.tagName || '',
            updateEntityTagList,
          })
        }
        defaultValue={editTagData?.tagName}
        autoFocus
      />
      <div
        className={
          editTagError ? `error-common-field ${styles.errorFieldUnderTag}` : 'error-common-field'
        }
      >
        <span>{editTagError}</span>
      </div>
      {/* {editTagError === null && (
        <div className={styles.editAllTags} onMouseEnter={closeEditing}>
          {query.data?.data.map((t) => (
            <Button
              key={nanoid()}
              fill="menu"
              onClick={(): void =>
                addExistingTag({ ...dataAddExistTag, existedTagName: t.name, tagId: t.tagId })
              }
              className={styles.editTagBtn}
            >
              <div key={nanoid()} className={styles.tagContentWrap}>
                <span className="tagTitle" style={{ color: t.color }}>
                  {t.name}
                </span>
              </div>
            </Button>
          ))}
        </div>
      )} */}
    </div>
  );
}
