import { type ReactElement, useState, MouseEvent } from 'react';
import cronstrue from 'cronstrue/i18n';
import { useTaskAddContext } from '../../../hooks/useTaskAddContext';
import { InputButton } from '../baseElements/InputButton';
import styles from './CronCalculator.module.scss';
import newTaskStyles from '../../features/Task/form/NewTask.module.scss';
import { CronTimeConfig } from './CronTimeConfig';
import cronLocale from '../../../utils/i18n/commonLocales/cronCalculator.json';
import localeNewTask from '../../../utils/i18n/taskLocale/newTask.json';
import { CheckBox } from '../form/CheckBox';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof cronLocale;

export type ICronTimes = 'minute' | 'hour' | 'day' | 'month' | 'weekday';
export type ICronConfigTimes = 'every-time' | 'in-time' | 'every-starting' | 'specific';
export type ICronConfig = {
  minute: string;
  hour: string;
  day: string;
  month: string;
  weekday: string;
};

export function CronCalculator({
  maxWidth,
  label,
  chooseAssetTimeZone,
}: {
  maxWidth?: string;
  label: string;
  chooseAssetTimeZone?: boolean;
}): ReactElement {
  const {
    cronInputValue,
    setCronInputValue,
    changeCronValue,
    errorCron,
    correctCron,
    isAssetTimezone,
    setAssetTimezone,
  } = useTaskAddContext();
  const [isOpen, setOpen] = useState(false);

  const [pageTab, setPageTab] = useState<ICronTimes>('minute');

  const [config, setConfig] = useState<ICronConfig>({
    minute: '*',
    hour: '*',
    day: '*',
    month: '*',
    weekday: '*',
  });

  const handleChangeTab = (event: MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setPageTab((event.target as Element).id as ICronTimes);
  };

  return (
    <div style={{ position: 'relative' }}>
      {/* <div> */}
      {/* <div>{cronLocale[currentLocale].utcTimeCalculator}</div> */}
      <div className="cron-form-label">{label}</div>
      <div className={newTaskStyles.inputWithUseAssetTimezone}>
        <InputButton
          id="cron"
          onChange={(e): void => {
            setCronInputValue(e.target.value);
          }}
          onBlur={changeCronValue}
          value={cronInputValue}
          imageType="calculator"
          // className="k-input k-input-md k-rounded-md k-input-solid k-state-invalid"
          type="text"
          onImageClick={(): void => setOpen((prev) => !prev)}
          disabled={isOpen}
        />
        {chooseAssetTimeZone && (
          <CheckBox
            name="useAssetLocalTime"
            checked={isAssetTimezone}
            value
            onChange={(): void => setAssetTimezone(!isAssetTimezone)}
            label={localeNewTask[currentLocale].form.todoActions.useAssetLocalTime}
          />
        )}
      </div>
      {isOpen && (
        <div className={styles.cron_block} style={{ maxWidth }}>
          <div style={{ marginBottom: '20px' }}>
            {cronLocale[currentLocale].startTime}:{' '}
            <span style={{ textDecoration: 'underline' }}>
              {cronstrue.toString(correctCron, { locale: currentLocale === 'ru-RU' ? 'ru' : 'en' })}
              {/* {cronstrue.toString(correctCron)} */}
            </span>
          </div>
          {/* <div style={{ textDecoration: 'underline' }}>
              {cronstrue.toString(correctCron, { locale: 'ru' })}
            </div> */}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', gap: '8px' }}>
              <button
                type="button"
                className={pageTab === 'minute' ? 'custom-type-search-active' : undefined}
                // fill={pageTab === 'minute' ? 'action' : undefined}
                onClick={handleChangeTab}
                id="minute"
              >
                {cronLocale[currentLocale].tabNames.minute}
              </button>
              <button
                type="button"
                className={pageTab === 'hour' ? 'custom-type-search-active' : undefined}
                // fill={pageTab === 'hour' ? 'action' : undefined}
                id="hour"
                onClick={handleChangeTab}
              >
                {cronLocale[currentLocale].tabNames.hour}
              </button>
              <button
                type="button"
                className={pageTab === 'day' ? 'custom-type-search-active' : undefined}
                // fill={pageTab === 'day' ? 'action' : undefined}
                onClick={handleChangeTab}
                id="day"
              >
                {cronLocale[currentLocale].tabNames.day}
              </button>
              <button
                type="button"
                className={pageTab === 'month' ? 'custom-type-search-active' : undefined}
                // fill={pageTab === 'month' ? 'action' : undefined}
                onClick={handleChangeTab}
                id="month"
              >
                {cronLocale[currentLocale].tabNames.month}
              </button>
              <button
                type="button"
                className={pageTab === 'weekday' ? 'custom-type-search-active' : undefined}
                // fill={pageTab === 'month' ? 'action' : undefined}
                onClick={handleChangeTab}
                id="weekday"
              >
                {cronLocale[currentLocale].tabNames.weekday}
              </button>
            </div>
            {/* <div>
              <button type='button' onClick={handleReset}>{cronLocale[currentLocale].resetBtn}</button>
            </div> */}
          </div>
          <CronTimeConfig timeType={pageTab} config={config} setConfig={setConfig} />
        </div>
      )}
      {errorCron.length < 1 && (
        <p className="newtask__description">{cronstrue.toString(correctCron, { locale: 'ru' })}</p>
      )}
      {errorCron.length > 0 && <div className="newtask__description">{errorCron}</div>}
    </div>
  );
}
