import qs from 'qs';
import type { SortDescriptor } from '@progress/kendo-data-query';
import { vmInstance } from '../lib/axios/hooks/useVmAxios';
import type { ConfigUpdateRequest } from '../types/__generated/on-premise-solution/api/configUpdateRequest.v1';
import { dashboardInstance } from '../lib/axios/hooks/useDashboardAxios';
import type { IPageState } from '../utils/helpers/types';
import type { IFilterVal } from '../utils/filtering-in-table-test';
import type { ApiKeysResponse } from '../types/__generated/on-premise-solution/api/apiKeysResponse.v1';
import { prepareFilterVal } from './filterVal-for-service';
import { history } from '../utils/history';
import type { ApiKeyCreationRequest } from '../types/__generated/on-premise-solution/api/apiKeyCreationRequest.v1';
import type { ApiKeyCreationResponse } from '../types/__generated/on-premise-solution/api/apiKeyCreationResponse.v1';
import type { ApiKeyDeletionResponse } from '../types/__generated/on-premise-solution/api/apiKeyDeletionResponse.v1';
import type { ApiKeyDashboardResponse } from '../types/__generated/on-premise-solution/api/apiKeyDashboardResponse.v1';
import type { BulletinWhitelistCreationRequest } from '../types/__generated/on-premise-solution/api/bulletinWhitelistCreationRequest.v1';
import type { BulletinWhitelistCreationResponse } from '../types/__generated/on-premise-solution/api/bulletinWhitelistCreationResponse.v1';
import type { BulletinWhitelistsResponse } from '../types/__generated/on-premise-solution/api/bulletinWhitelistsResponse.v1';
import type { BulletinWhitelistUpdateRequest } from '../types/__generated/on-premise-solution/api/bulletinWhitelistUpdateRequest.v1';
import type { BulletinWhitelistUpdateResponse } from '../types/__generated/on-premise-solution/api/bulletinWhitelistUpdateResponse.v1';
import type { BulletinWhitelistDeletionResponse } from '../types/__generated/on-premise-solution/api/bulletinWhitelistDeletionResponse.v1';
import type { BulletinWhitelistDashboardResponse } from '../types/__generated/on-premise-solution/api/bulletinWhitelistDashboardResponse.v1';

export async function patchConfig(configData: ConfigUpdateRequest): Promise<ConfigUpdateRequest> {
  const result = await vmInstance.patch<ConfigUpdateRequest>('/system/config', configData);

  return result.data;
}

export async function getApiKeyList(
  page: IPageState,
  sort: Array<SortDescriptor>,
  filterVal: IFilterVal[],
): Promise<ApiKeysResponse> {
  const result = await vmInstance.get<ApiKeysResponse>('/api-keys', {
    params: {
      skip: page.skip,
      limit: page.take,
      sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
      sortOrder: sort[0].dir,
      ...prepareFilterVal(filterVal),
    },

    paramsSerializer: (params) => {
      const stringifiedParams = qs.stringify(params, { encode: false });

      history.replace({
        pathname: `/lk/settings/tokens?${stringifiedParams}`,
      });

      return stringifiedParams;
    },
  });

  return result.data;
}

export async function getApiKeyId(apiKeyId: string): Promise<ApiKeyDashboardResponse> {
  const result = await dashboardInstance.get<ApiKeyDashboardResponse>(`/api-keys/${apiKeyId}`);

  return result.data;
}
export async function createApiKey({
  name,
  accountId,
  description,
  scope,
}: ApiKeyCreationRequest): Promise<ApiKeyCreationResponse> {
  const result = await vmInstance.post<ApiKeyCreationResponse>('/api-keys', {
    name,
    accountId,
    description,
    scope,
  });

  return result.data;
}
export async function deleteToken(apiKeyId: string): Promise<ApiKeyDeletionResponse | null> {
  const result = await vmInstance.delete<ApiKeyDeletionResponse>(`/api-keys/${apiKeyId}`);

  return result.data;
}

export async function getWhiteList(
  page: IPageState,
  sort: Array<SortDescriptor>,
  filterVal: IFilterVal[],
  filterLogicType?: 'and' | 'or',
): Promise<BulletinWhitelistsResponse> {
  const result = await dashboardInstance.get<BulletinWhitelistsResponse>('/audit-whitelists', {
    params: {
      skip: page.skip,
      limit: page.take,
      sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
      sortOrder: sort[0].dir,
      ...prepareFilterVal(filterVal, filterLogicType),
    },

    paramsSerializer: (params) => {
      const stringifiedParams = qs.stringify(params, { encode: false });

      if (document.location.pathname.includes('lk/audit/exceptions')) {
        history.replace({
          pathname: `/lk/audit/exceptions?${stringifiedParams}`,
        });
      }

      return stringifiedParams;
    },
  });

  return result.data;
}

export async function createWhiteList({
  name,
  accountId,
  description,
  scope,
  bulletins,
  softwareList,
  isDisabled,
}: BulletinWhitelistCreationRequest): Promise<BulletinWhitelistCreationResponse> {
  const result = await vmInstance.post<BulletinWhitelistCreationResponse>('/bulletin-whitelists', {
    name,
    accountId,
    description,
    scope,
    bulletins,
    softwareList,
    isDisabled,
  });

  return result.data;
}

export async function updateWhiteList(
  id: string,
  payload: BulletinWhitelistUpdateRequest,
): Promise<BulletinWhitelistUpdateResponse> {
  const result = await vmInstance.patch<BulletinWhitelistUpdateResponse>(
    `/bulletin-whitelists/${id}`,
    payload,
  );

  return result.data;
}

export async function deleteWhiteList(
  id: string,
): Promise<BulletinWhitelistDeletionResponse | null> {
  const result = await vmInstance.delete<BulletinWhitelistDeletionResponse>(
    `/bulletin-whitelists/${id}`,
  );

  return result.data;
}

export async function getWhiteLIstId(
  bulletinWhitelistId: string,
): Promise<BulletinWhitelistDashboardResponse> {
  const result = await dashboardInstance.get<BulletinWhitelistDashboardResponse>(
    `/audit-whitelists/${bulletinWhitelistId}`,
  );

  return result.data;
}
