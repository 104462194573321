import type { GridCellProps } from '@progress/kendo-react-grid';
import type React from 'react';

export function CellAuth({ dataItem }: GridCellProps): React.ReactElement {
  let auth = dataItem.auth && dataItem.auth.login ? dataItem.auth.login : '-';

  if (dataItem.auth && dataItem.auth.type === 'local') auth = dataItem.auth.login;
  if (dataItem.auth && dataItem.auth.type === 'ldap') auth = dataItem.auth.uid;
  if (dataItem.auth && dataItem.auth.type === 'activedirectory') auth = dataItem.auth.username;

  return <td>{auth}</td>;
}
