import { useParams } from 'react-router';
import { useQuery } from 'react-query';
import { Filter, FilterChangeEvent } from '@progress/kendo-react-data-tools';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import {
  Grid,
  GridColumn as Column,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid';
import { useEffect, useRef, useState } from 'react';
import type { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { ErrorBoundary } from 'react-error-boundary';
import { СommonTableLoader } from '../../../common/tableLoader/CommonTableLoader';
import localeTicket from '../../../../utils/i18n/security/vulnerabilities.json';
import type { TicketActionsDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/ticketActionsDashboardResponse.v1';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { getTicketActionsByTicketId } from '../../../../services/tickets-service';
import { getUrlList } from '../../../../utils/helpers/getUrl-list-helper';
import type { IDataHooks, IPageState } from '../../../../utils/helpers/types';
import {
  FILTER_INITIAL,
  INITIAL_STATUS_SORT,
  PAGEABLE_DATA_LOCAL,
} from '../../../../utils/helpers/constants';
import { usePageChange } from '../../../../hooks/components/usePageChange';
import { handleSort } from '../../../../utils/sorting-in-table';
import { CellTicketStatus } from './cells/CellTicketStatus';
import type { IFilterVal } from '../../../../utils/filtering-in-table-test';
import { CellTicketAsset } from './cells/CellTicketAsset';
import { useFilter } from '../../../../hooks/components/useFilter';
import { FILTER_TICKET_ACTION } from '../../../../utils/helpers/constant-serv-filter-task';
import { BoundaryErrorComponent } from '../../../common/BoundaryErrorComponent';
import { CellId } from '../../Task/taskVmDetails/vmDetailsComponents/cells/CellVulnerabilitiesListId';
import { useAuditModalContext } from '../../../../hooks/useAuditModalContext';
import { VulnModal } from '../../../common/modal/vulnModal/VulnModal';
import { CellTicketAction } from './cells/CellTicketAction';
import styles from './ticket.module.scss';
import { Input } from '../../../common/baseElements/Input';
import { useCustomSearch } from '../../../../hooks/components/useCustomSearch';
import { useTicketMethods } from '../../../../hooks/components/useTicketMethods';

export function TicketDescriptionActions({
  setHeightValue,
}: {
  setHeightValue: React.Dispatch<React.SetStateAction<number>>;
}): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeTicket;

  const { ticketId } = useParams<{ ticketId: string }>();

  const urlParams = getUrlList('TicketDescriptionActions', INITIAL_STATUS_SORT);

  const dataForHooks: IDataHooks = {
    name: 'asset.latestInventorization.hostname',
    componentName: 'TicketDescriptionActions',
    urlPath: `/lk/security/tickets/${ticketId}`,
  };

  const [sort, setSort] = useState<SortDescriptor[]>(urlParams.sort);

  const [page, setPage] = useState<IPageState>(urlParams.page);

  const [filterVal, setFilterVal] = useState<IFilterVal[]>([]);

  const [filter, setFilter] = useState<CompositeFilterDescriptor>(FILTER_INITIAL);

  const [filterStatus, setFilterStatus] = useState(false);

  const [customSearch, setCustomSearch] = useState('');

  const filterValue = useRef<CompositeFilterDescriptor | null>(null);

  const filterRef = useRef<CompositeFilterDescriptor>(filter);

  const tabStripRef = useRef<HTMLDivElement>(null);

  const timer = useRef<NodeJS.Timeout | null>(null);

  const filterSearch = useFilter(
    filterValue,
    setFilter,
    customSearch,
    setCustomSearch,
    setFilterVal,
    filterRef,
    filter,
    setPage,
    page,
    dataForHooks,
  );

  const getPage = usePageChange(setPage, urlParams, dataForHooks);

  const { updateSize } = useTicketMethods();

  const pageChange = (event: GridPageChangeEvent): void => {
    getPage(event);
  };

  const onFilterChange = (event: FilterChangeEvent): void => {
    filterSearch(event);
  };

  const query = useQuery<TicketActionsDashboardResponse, ApiError>(
    ['ticketActionsByTicketId', page, filterVal, sort, ticketId],
    () => getTicketActionsByTicketId(page, filterVal, sort, ticketId),
    {
      keepPreviousData: true,
      onSuccess: () => {
        if (timer.current) clearTimeout(timer.current);

        timer.current = setTimeout(() => {
          updateSize(tabStripRef, setHeightValue);
        }, 0);
      },
    },
  );

  const { cveName } = useAuditModalContext();

  const debouncedCustomSearch = useCustomSearch(
    setFilterStatus,
    setCustomSearch,
    filter,
    setFilterVal,
    setPage,
    page,
    dataForHooks,
  );

  function handleCustomSearch(e: React.ChangeEvent<HTMLInputElement>): void {
    debouncedCustomSearch(e);
  }

  useEffect(() => {
    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
  }, []);

  return (
    <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
      <div ref={tabStripRef}>
        <LocalizationProvider language={currentLocale}>
          <IntlProvider locale={currentLocale.substring(0, 2)}>
            <div className="filter-line">
              <Filter
                value={filter}
                onChange={onFilterChange}
                fields={FILTER_TICKET_ACTION[currentLocale]}
              />
              {filter.filters.length === 0 && (
                <Input
                  name="customSearch"
                  placeholder={localeTicket[currentLocale].tickets.description.grid.customSearch}
                  value={customSearch}
                  onChange={(e): void => handleCustomSearch(e)}
                />
              )}
            </div>
          </IntlProvider>
        </LocalizationProvider>
        {query.data?.data && (
          <div className={styles.gridTicketDescriptionActions}>
            <LocalizationProvider language={currentLocale}>
              <IntlProvider locale={currentLocale.substring(0, 2)}>
                <Grid
                  pageable={query.data?.total > 10 ? PAGEABLE_DATA_LOCAL : false}
                  skip={page.skip}
                  take={page.take}
                  sortable
                  sort={sort}
                  data={query.data}
                  total={query.data?.total}
                  onPageChange={pageChange}
                  onSortChange={(e: GridSortChangeEvent): void => handleSort(e, setSort)}
                  scrollable="none"
                  className={
                    Number(query?.data?.total) && Number(query?.data?.total) < 10
                      ? 'no-pageable-k-grid'
                      : ''
                  }
                >
                  <Column
                    field="status"
                    title={localeTicket[currentLocale].tickets.description.grid.status}
                    cell={CellTicketStatus}
                    width="180px"
                  />
                  <Column
                    field="type"
                    filter="text"
                    title={localeTicket[currentLocale].tickets.description.grid.actionType}
                    cell={CellTicketAction}
                    width="240px"
                  />
                  <Column
                    field="bulletinId"
                    title={localeTicket[currentLocale].tickets.description.grid.vulnerability}
                    cell={CellId}
                    width="200px"
                  />
                  <Column
                    field="asset.latestInventorization.hostname"
                    title={localeTicket[currentLocale].tickets.description.grid.asset}
                    cell={CellTicketAsset}
                  />
                </Grid>
                <СommonTableLoader queryData={query.isLoading} filteringData={query.isLoading} />
              </IntlProvider>
            </LocalizationProvider>
          </div>
        )}
        {cveName && <VulnModal />}
      </div>
    </ErrorBoundary>
  );
}
