/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import type { GridCellProps } from '@progress/kendo-react-grid';
import type { TreeListCellProps } from '@progress/kendo-react-treelist';
import { CvssBlock } from '../smallBlocks/CvssBlock';

export function ScoreCvssCell(props: GridCellProps | TreeListCellProps): React.ReactElement {
  const { dataItem, field, className, level } = props;

  // For software List group view
  if (Array.isArray(level) && level?.length <= 1) return <td />;

  const fieldArray = field ? field.split('.') : [];
  let value = dataItem;

  if (!fieldArray) value = null;
  else if (fieldArray.length === 1) value = dataItem[fieldArray[0]];
  else if (fieldArray.length > 1) {
    fieldArray.forEach((f) => {
      value = value ? value[f] : value;
    });
  }
  if (typeof value === 'number') value = Number(value) >= 0 ? Number(value) : null;

  return (
    <td className={className || ''} style={{ paddingTop: '0', paddingBottom: '0' }}>
      <CvssBlock score={value} />
    </td>
  );
}
