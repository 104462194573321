import type { SortDescriptor } from '@progress/kendo-data-query';
import type { IFilterVal } from '../utils/filtering-in-table-test';
import type { IPageState } from '../utils/helpers/types';
import type { InstalledSoftwareListDashboardResponse } from '../types/__generated/on-premise-solution/api/installedSoftwareListDashboardResponse.v1';
import { dashboardInstance } from '../lib/axios/hooks/useDashboardAxios';
import { prepareFilterVal } from './filterVal-for-service';
import type { VulnerableSoftwareListDashboardResponse } from '../types/__generated/on-premise-solution/api/vulnerableSoftwareListDashboardResponse.v1';
import type { OperationSystemsResponse } from '../types/__generated/on-premise-solution/api/operationSystemsResponse.v1';
import { vmInstance } from '../lib/axios/hooks/useVmAxios';
import { getAccountId } from './local-storage-service';
import type { InstalledSoftwareListGroupedByNameDashboardResponse } from '../types/__generated/on-premise-solution/api/installedSoftwareListGroupedByNameDashboardResponse.v1';
import type { VulnerableSoftwareListGroupedByNameDashboardResponse } from '../types/__generated/on-premise-solution/api/vulnerableSoftwareListGroupedByNameDashboardResponse.v1';

export async function getInstalledSoftwareList(
  page: IPageState,
  filterVal: IFilterVal[],
  sort: Array<SortDescriptor>,
  osType?: 'linux' | 'windows',
  accountId?: string,
): Promise<InstalledSoftwareListDashboardResponse> {
  const paramsData = {
    skip: page.skip,
    limit: page.take,
    sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
    sortOrder: sort[0].dir,
    osType,
    accountId: accountId || undefined,
    ...prepareFilterVal(filterVal),
  };
  const result = await dashboardInstance.get<InstalledSoftwareListDashboardResponse>(
    '/software/installed',
    {
      params: paramsData,

      // paramsSerializer: (params) => {
      //   const stringifiedParams = qs.stringify(params, { encode: false });
      //   history.replace({
      //     pathname: `/lk/assets/images?${stringifiedParams}`,
      //   });

      //   return stringifiedParams;
      // },
    },
  );

  return result.data;
}

export async function getInstalledSoftwareListGroups(
  page: IPageState,
  filterVal: IFilterVal[],
  sort: Array<SortDescriptor>,
  osType?: 'linux' | 'windows',
  accountId?: string,
): Promise<InstalledSoftwareListGroupedByNameDashboardResponse> {
  const paramsData = {
    skip: page.skip,
    limit: page.take,
    sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
    sortOrder: sort[0].dir,
    osType,
    accountId: accountId || undefined,
    ...prepareFilterVal(filterVal),
  };
  const result = await dashboardInstance.get<InstalledSoftwareListGroupedByNameDashboardResponse>(
    '/software/installed/grouped',
    {
      params: paramsData,

      // paramsSerializer: (params) => {
      //   const stringifiedParams = qs.stringify(params, { encode: false });
      //   history.replace({
      //     pathname: `/lk/assets/images?${stringifiedParams}`,
      //   });

      //   return stringifiedParams;
      // },
    },
  );

  return result.data;
}

export async function getVulnerableSoftwareList(
  page: IPageState,
  filterVal: IFilterVal[],
  sort: Array<SortDescriptor>,
  osType?: 'linux' | 'windows',
  accountId?: string,
): Promise<VulnerableSoftwareListDashboardResponse> {
  const paramsData = {
    skip: page.skip,
    limit: page.take,
    sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
    sortOrder: sort[0].dir,
    osType,
    accountId: accountId || undefined,
    ...prepareFilterVal(filterVal),
  };
  const result = await dashboardInstance.get<VulnerableSoftwareListDashboardResponse>(
    '/software/vulnerable',
    {
      params: paramsData,

      // paramsSerializer: (params) => {
      //   const stringifiedParams = qs.stringify(params, { encode: false });
      //   history.replace({
      //     pathname: `/lk/assets/images?${stringifiedParams}`,
      //   });

      //   return stringifiedParams;
      // },
    },
  );

  return result.data;
}

export async function getVulnerableSoftwareListGroups(
  page: IPageState,
  filterVal: IFilterVal[],
  sort: Array<SortDescriptor>,
  osType?: 'linux' | 'windows',
  accountId?: string,
): Promise<VulnerableSoftwareListGroupedByNameDashboardResponse> {
  const paramsData = {
    skip: page.skip,
    limit: page.take,
    sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
    sortOrder: sort[0].dir,
    osType,
    accountId: accountId || undefined,
    ...prepareFilterVal(filterVal),
  };
  const result = await dashboardInstance.get<VulnerableSoftwareListGroupedByNameDashboardResponse>(
    '/software/vulnerable/grouped',
    {
      params: paramsData,

      // paramsSerializer: (params) => {
      //   const stringifiedParams = qs.stringify(params, { encode: false });
      //   history.replace({
      //     pathname: `/lk/assets/images?${stringifiedParams}`,
      //   });

      //   return stringifiedParams;
      // },
    },
  );

  return result.data;
}

export async function getSoftwareOsList(
  page: IPageState,
  filterVal: IFilterVal[],
  sort: Array<SortDescriptor>,
  osType?: 'linux' | 'windows' | 'other',
  accountId?: string,
): Promise<OperationSystemsResponse> {
  const paramsData = {
    skip: page.skip,
    limit: page.take,
    sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
    sortOrder: sort[0].dir,
    osType,
    ...prepareFilterVal(filterVal),
    accountId: accountId || undefined,
  };
  const result = await vmInstance.get<OperationSystemsResponse>('/operation-systems', {
    params: paramsData,
    // paramsSerializer: (params) => {
    //   const stringifiedParams = qs.stringify(params, { encode: false });
    //   history.replace({
    //     pathname: `/lk/assets/images?${stringifiedParams}`,
    //   });
    //   return stringifiedParams;
    // },
  });

  return result.data;
}

export async function getInstalledSoftwaresInfo(
  softwareId: string,
): Promise<InstalledSoftwareListDashboardResponse['data'][number] | null> {
  const result = await dashboardInstance.get<
    InstalledSoftwareListDashboardResponse['data'][number] | null
  >(`/software/installed/${softwareId}`);

  return result.data;
}

export async function getVulnerableSoftwaresInfo(
  softwareId: string,
): Promise<VulnerableSoftwareListDashboardResponse['data'][number] | null> {
  const result = await dashboardInstance.get<
    VulnerableSoftwareListDashboardResponse['data'][number] | null
  >(`/software/vulnerable/${softwareId}`);

  return result.data;
}
