import { useEffect, useState, type ReactElement } from 'react';
import { useParams } from 'react-router';
import {
  TabStrip,
  TabStripTab,
  type TabStripSelectEventArguments,
} from '@progress/kendo-react-layout';
import { useQueryClient } from 'react-query';
import { useSensorDescription } from '../../../hooks/components/useSensorDescription';
import { Button } from '../../common/baseElements/Button';
import { TableFSensorMetaBlock } from './tables/TableFSensorMetaBlock';
import localeSensor from '../../../utils/i18n/sensorLocales/sensor.json';
import localeButtons from '../../../utils/i18n/commonLocales/buttons.json';
import localeNames from '../../../utils/i18n/commonLocales/names.json';
import { TableTSensorMetaBlock } from './tables/TableTSensorMetaBlock';
import { TableSSensorMetaBlock } from './tables/TableSSensorMetaBlock';
import { IOpenedFromComponentInfo, useSensorContext } from '../../../hooks/useSensorContext';
import { DeleteConfirmModal } from '../../common/modal/DeleteConfirmModal';
import { AssetListTab } from './AssetListTab';
import { SensorTaskHistory } from './SensorTaskHistory';
import { SensorListTab } from './SensorListTab';
import { ReinstallSensorModal } from './ReinstallSensorModal';
import { EntityDescription } from '../../templates/EntityDescription';
import { LogsTab } from './LogsTab';
import { useSensorActions } from '../../../hooks/components/useSensorActions';
import { SensorEditModal } from './SensorEditModal';
import { SensorNotEmptyModal } from './SensorNotEmptyModal';
import { useTagContext } from '../../../hooks/useTagContext';
import { TagHeader } from '../../common/tag/TagHeader';
import { ConfirmTagDelete } from '../../common/tag/ConfirmTagDelete';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSensor;

export function SensorDescription(): ReactElement {
  const { sensorId } = useParams<{ sensorId: string }>();
  const queryClient = useQueryClient();

  const openedFromInfo: IOpenedFromComponentInfo = {
    rootComponentName: 'SensorDescription',
    subcomponentName: 'SensorListTab',
  };

  const { querySensorId, commandLogsMutation } = useSensorDescription();
  const {
    sensorIdContext,
    sensorName,
    sensorDescription,
    reinstallOpen,
    setReinstallOpen,
    deleteOpen,
    setDeleteOpen,
    isSensorNotEmptyModal,
    editOpen,
    setEditOpen,
    logs,
  } = useSensorContext();
  const { deleteSensor } = useSensorActions();
  const { setShowAddTag, closeTagWindow, setEditTagColor, tagDataForDel } = useTagContext();

  const [selected, setSelected] = useState<number>(0);

  const handleSelect = (e: TabStripSelectEventArguments): void => {
    setSelected(e.selected);
  };

  // const timer = setTimeout(() => {

  // }, 10000)

  // useEffect(() => {
  //   if (querySensorId.)
  // }, [querySensorId])

  useEffect(() => {
    document.addEventListener('click', closeTagWindow);
    // commandLogsMutation.mutateAsync();

    return () => {
      // clearTimeout(timer);
      document.removeEventListener('click', closeTagWindow);
      setEditTagColor(null);
      setShowAddTag(false);
      logs.current = [];
      sensorName.current = undefined;
      sensorIdContext.current = undefined;
    };
  }, []);

  return (
    <EntityDescription
      isLoading={querySensorId.isLoading}
      isNotFound={querySensorId.error?.code === '404'}
      entity={{
        id: sensorId,
        type: 'sensor',
        name: sensorName.current,
      }}
      tagBlock={
        <TagHeader
          tags={querySensorId.data?.tags}
          tagIds={querySensorId.data?.tagIds}
          entityId={sensorId}
          entityType="sensor"
          updateEntityTagList={(): void => {
            // querySensorId.refetch();
            queryClient.invalidateQueries('sensorDescription');
          }}
        />
      }
      topactions={
        <>
          <div className="button-group">
            <Button
              onClick={(): void => {
                sensorIdContext.current = querySensorId.data?.sensorId;
                sensorName.current = querySensorId.data?.name || '';
                sensorDescription.current = querySensorId.data?.description || undefined;
                setEditOpen(openedFromInfo);
              }}
            >
              {localeSensor[currentLocale].descriptionPage.editBtn}
            </Button>
            <Button onClick={(): void => setReinstallOpen(openedFromInfo)}>
              {localeSensor[currentLocale].descriptionPage.reinstallBtn}
            </Button>
          </div>
          <Button onClick={(): void => setDeleteOpen(openedFromInfo)} fill="delete">
            {localeButtons[currentLocale].delete}
          </Button>
        </>
      }
      className="sensor-description"
    >
      {isSensorNotEmptyModal && <SensorNotEmptyModal />}
      {editOpen && <SensorEditModal />}
      {reinstallOpen && <ReinstallSensorModal sensorId={sensorId} />}
      {deleteOpen && (
        <DeleteConfirmModal
          type="sensor"
          onClose={(): void => setDeleteOpen(undefined)}
          handleDelete={(): void => deleteSensor(sensorId)}
          name={sensorName.current}
        />
      )}
      {!deleteOpen && tagDataForDel && <ConfirmTagDelete />}
      <div className="common-summary-page">
        <TableFSensorMetaBlock currentLocale={currentLocale} data={querySensorId.data} />
        <TableSSensorMetaBlock currentLocale={currentLocale} data={querySensorId.data} />
        <TableTSensorMetaBlock currentLocale={currentLocale} data={querySensorId.data} />
      </div>
      <div className="top-tabstrip">
        <TabStrip selected={selected} onSelect={handleSelect}>
          <TabStripTab title={localeSensor[currentLocale].descriptionPage.assetsTab}>
            <AssetListTab assetIds={querySensorId.data?.assets} />
          </TabStripTab>
          <TabStripTab title={localeSensor[currentLocale].descriptionPage.sensorsTab}>
            <SensorListTab sensorIds={querySensorId.data?.childSensors} />
          </TabStripTab>
          <TabStripTab title={localeNames[currentLocale].taskHistory}>
            <SensorTaskHistory />
          </TabStripTab>
          <TabStripTab title={localeNames[currentLocale].logs}>
            <LogsTab />
          </TabStripTab>
        </TabStrip>
      </div>
    </EntityDescription>
  );
}
