import { Dialog } from '@progress/kendo-react-dialogs';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { useEffect, useState, type ReactElement } from 'react';
import { useQuery } from 'react-query';
import { TSoftwareModalData, useSoftwaresContext } from '../../../hooks/useSoftwaresContext';
import { Button } from '../../common/baseElements/Button';
import localeButtons from '../../../utils/i18n/commonLocales/buttons.json';
import localeSoftwareList from '../../../utils/i18n/software/software.json';
import {
  getInstalledSoftwaresInfo,
  getVulnerableSoftwaresInfo,
} from '../../../services/softwares-service';
import type { InstalledSoftwareListDashboardResponse } from '../../../types/__generated/on-premise-solution/api/installedSoftwareListDashboardResponse.v1';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
import { SoftwareHostsTab } from './SoftwareHostsTab';
import { SoftwareImagesTab } from './SoftwareImagesTab';
import { SoftwareBulletinsTab } from './SoftwareBulletinsTab';
import type { VulnerableSoftwareListDashboardResponse } from '../../../types/__generated/on-premise-solution/api/vulnerableSoftwareListDashboardResponse.v1';
import { FixesTab } from './FixesTab';
import { SoftwareAvailableUpdatesTable } from '../../common/modal/SoftwareAvailableUpdatesTable';
import { getAccountId } from '../../../services/local-storage-service';
import { LoadingPanel } from '../../common/tableLoader/LoadingPanel';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeButtons;

function getBulletinIds(
  softwareAssets:
    | InstalledSoftwareListDashboardResponse['data'][number]['assets']
    | VulnerableSoftwareListDashboardResponse['data'][number]['assets']
    | null
    | undefined,
): string[] {
  if (!softwareAssets || !softwareAssets.length) return [];

  const bulletinIds = new Set<string>();
  for (let i = 0; i < softwareAssets.length; i++) {
    const asset = softwareAssets[i];
    if ('bulletins' in asset && asset.bulletins?.length) {
      asset.bulletins.forEach((bulletin) => {
        bulletinIds.add(bulletin.id);
      });
    }
  }

  return Array.from(bulletinIds);
}

function getAssetsByType(
  softwareAssets:
    | InstalledSoftwareListDashboardResponse['data'][number]['assets']
    | VulnerableSoftwareListDashboardResponse['data'][number]['assets']
    | undefined,
  type: 'host' | 'image',
  osType?: 'linux' | 'windows',
):
  | InstalledSoftwareListDashboardResponse['data'][number]['assets']
  | VulnerableSoftwareListDashboardResponse['data'][number]['assets']
  | undefined {
  if (!softwareAssets || !softwareAssets.length) return [];
  const assets = new Array<
    | InstalledSoftwareListDashboardResponse['data'][number]['assets'][number]
    | VulnerableSoftwareListDashboardResponse['data'][number]['assets'][number]
  >();

  for (let i = 0; i < softwareAssets.length; i++) {
    const asset = softwareAssets[i];
    if (asset.assetType === type && (!osType || (osType && osType === asset.os?.type)))
      assets.push(asset);
  }

  return assets;
}

export function SoftwaresInfoModal(): ReactElement {
  const currentAccountId = getAccountId();
  const { softwaresModalData, setSoftwaresModalData } = useSoftwaresContext();
  const [selected, setSelected] = useState(softwaresModalData?.defaultTabId || 0);
  const { changedAccountId } = useSoftwaresContext();

  const query = useQuery<
    | InstalledSoftwareListDashboardResponse['data'][number]
    | VulnerableSoftwareListDashboardResponse['data'][number]
    | null,
    ApiError
  >(
    ['SoftwareInfo'],
    () =>
      softwaresModalData?.isVulnerableFlag
        ? getVulnerableSoftwaresInfo(softwaresModalData?.softwareId || '')
        : getInstalledSoftwaresInfo(softwaresModalData?.softwareId || ''),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      onSuccess: (resp) => {
        if (resp) {
          const r: TSoftwareModalData = {
            softwareId: resp.softwareId,
            softwareName: resp.name,
            version: resp.version,
            osType: resp.osType,
            arch: resp.arch,
            availableUpdate: 'availableUpdate' in resp ? resp.availableUpdate : undefined,
            isVulnerableFlag: softwaresModalData?.isVulnerableFlag,
          };

          setSoftwaresModalData(r);
        }
      },
    },
  );

  useEffect(() => {
    if (softwaresModalData?.defaultTabId) setSelected(softwaresModalData.defaultTabId);
  }, [softwaresModalData?.defaultTabId]);

  return (
    <Dialog
      title={
        <div
          style={{
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
          }}
        >
          {softwaresModalData?.osType && (
            <img
              src={`${process.env.PUBLIC_URL}/images/${softwaresModalData.osType}.svg`}
              alt={softwaresModalData.osType}
              height={24}
            />
          )}{' '}
          {softwaresModalData?.osType === 'windows'
            ? localeSoftwareList[currentLocale].modal.software
            : localeSoftwareList[currentLocale].modal.package}{' '}
          {softwaresModalData?.softwareName} {softwaresModalData?.version || ''}
        </div>
      }
      onClose={(): void => setSoftwaresModalData(undefined)}
    >
      <div className="common-summary-page">
        <table className="column">
          <tbody>
            <tr>
              <td>{localeSoftwareList[currentLocale].modal.meta.name}</td>
              <td>{query.data?.name || '-'}</td>
            </tr>
            <tr>
              <td>{localeSoftwareList[currentLocale].modal.meta.version}</td>
              <td>{query.data?.version || '-'}</td>
            </tr>
          </tbody>
        </table>
        <table className="column">
          <tbody>
            {query.data?.arch && (
              <tr>
                <td>{localeSoftwareList[currentLocale].modal.meta.arch}</td>
                <td>{query.data?.arch || '-'}</td>
              </tr>
            )}
            <tr>
              <td>{localeSoftwareList[currentLocale].modal.meta.osType}</td>
              <td>{query.data?.osType || '-'}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <TabStrip selected={selected} onSelect={(e): void => setSelected(e.selected)}>
        <TabStripTab
          title={`${localeSoftwareList[currentLocale].modal.tabs.hosts} (${
            getAssetsByType(query.data?.assets, 'host', softwaresModalData?.osType)?.length || 0
          })`}
        >
          <div style={{ position: 'relative' }}>
            {query.isFetching || query.isLoading ? (
              <LoadingPanel />
            ) : (
              <SoftwareHostsTab
                assets={getAssetsByType(query.data?.assets, 'host', softwaresModalData?.osType)}
              />
            )}
          </div>
        </TabStripTab>
        <TabStripTab
          title={`${localeSoftwareList[currentLocale].modal.tabs.images} (${
            getAssetsByType(query.data?.assets, 'image', softwaresModalData?.osType)?.length || 0
          })`}
        >
          <SoftwareImagesTab
            assets={getAssetsByType(query.data?.assets, 'image', softwaresModalData?.osType)}
          />
        </TabStripTab>
        {softwaresModalData?.isVulnerableFlag && (
          <TabStripTab
            title={`${localeSoftwareList[currentLocale].modal.tabs.vulnerabilities} (${
              getBulletinIds(query.data?.assets).length || 0
            })`}
          >
            {query.isFetching || query.isLoading ? (
              <LoadingPanel />
            ) : (
              <SoftwareBulletinsTab bulletinIds={getBulletinIds(query.data?.assets)} />
            )}
          </TabStripTab>
        )}
        {/* {query.data && 'mergedUpdates' in query.data && (
          <TabStripTab
            title={`${localeSoftwareList[currentLocale].modal.tabs.fixes} (${
              query.data.mergedUpdates?.length || 0
            })`}
          >
            <FixesTab data={query.data.mergedUpdates} />
          </TabStripTab>
        )} */}
        {softwaresModalData?.osType === 'windows' && (
          <TabStripTab
            title={`${localeSoftwareList[currentLocale].modal.tabs.updates} (${
              query.data && 'availableUpdate' in query.data
                ? query.data.availableUpdate?.softwareCodename.length
                : 0
            })`}
          >
            <SoftwareAvailableUpdatesTable
              softwareCodename={
                query.data && 'availableUpdate' in query.data && query.data.availableUpdate
                  ? query.data.availableUpdate.softwareCodename
                  : null
              }
              accountId={changedAccountId || currentAccountId}
            />
          </TabStripTab>
        )}
      </TabStrip>
      <div className="k-form-buttons">
        <Button
          onClick={
            // handleCancelSensor
            (e): void => {
              e.preventDefault();
              setSoftwaresModalData(undefined);
            }
          }
        >
          {localeButtons[currentLocale].cancel}
        </Button>
      </div>
    </Dialog>
  );
}
