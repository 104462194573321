import React, { useState, useEffect, useRef } from 'react';
import { Filter, FilterChangeEvent } from '@progress/kendo-react-data-tools';
import type { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { IntlProvider, loadMessages, LocalizationProvider } from '@progress/kendo-react-intl';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import {
  Grid,
  GridColumn as Column,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid';
import { useQuery, useQueryClient } from 'react-query';
import {
  cvssScoreWidth,
  FILTER_INITIAL,
  PAGEABLE_DATA,
  publishedWidth,
} from '../../../utils/helpers/constants';
import type { IDataHooks, IPageState, ISecurityVulns } from '../../../utils/helpers/types';
import './securityVulnsList.scss';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
import { СommonTableLoader } from '../../common/tableLoader/CommonTableLoader';
import { handleError } from '../../../utils/errors';
import { useNotificationContext } from '../../../hooks/useNotificationContext';
import { handleSort } from '../../../utils/sorting-in-table';
import locale from '../../../utils/i18n/security/vulnerabilities.json';
import localeAuditTask from '../../../utils/i18n/taskLocale/auditTask.json';
import { handleFilteringTest, IFilterVal } from '../../../utils/filtering-in-table-test';
import { useCustomSearch } from '../../../hooks/components/useCustomSearch';
import { usePageChange } from '../../../hooks/components/usePageChange';
import { useFilter } from '../../../hooks/components/useFilter';
import { FILTER_OPERATORS } from '../../../utils/helpers/constant-filter-operators';
import { FILTER_SECURITY_VULNS } from '../../../utils/helpers/constant-serv-filter-task';
import { getBulletins, getBulletinsWl } from '../../../services/bulletins-service';
import type { DashboardBulletinsResponse } from '../../../types/__generated/on-premise-solution/api/dashboardBulletinsResponse.v1';
import { CellSecurityCwe } from './cells/CellSecurityCwe';
import { CellPublished } from '../Task/taskVmDetails/vmDetailsComponents/cells/CellVulnerabilitiesListPublished';
import { useAuditModalContext } from '../../../hooks/useAuditModalContext';
import { CellSecurityVulnsId } from './cells/CellSecurityVulnsId';
import { CellSecurityTitle } from './cells/CellSecurityTitle';
import { CellSecurityAsset } from './cells/CellSecurityAsset';
import {
  collectSecurityVulns,
  getUrlSecVulnsList,
} from '../../../utils/helpers/security-vulns-helper';
import { VulnModal } from '../../common/modal/vulnModal/VulnModal';
import { CellSecurityNetDev } from './cells/CellSecurityNetDev';
import { CellVulnListAlias } from '../Task/taskVmDetails/vmDetailsComponents/cells/CellVulnListAlias';
import { Input } from '../../common/baseElements/Input';
import { CellSecurityImage } from './cells/CellSecurityImage';
import { ScoreCvssCell } from '../../common/cells/ScoreCvssCell';
import { Button } from '../../common/baseElements/Button';
import { SecurityVulnsListWL } from './SecurityVulnsListWL';
import CellSelectVuln from '../Task/taskVmDetails/vmDetailsComponents/cells/CellSelectVuln';
import { useAssetCreatePropContext } from '../../../hooks/useAssetCreatePropContext';
import styles from '../Task/taskVmDetails/vmDetailsComponents/packageNameModal.module.scss';
import { AddVulnToWLForm } from '../WhiteList/form/AddVulnToWLForm';
import { useWhiteListContext } from '../../../hooks/useWhiteListContext';
import { AddWhiteListForm } from '../WhiteList/form/AddWhiteListForm';
import { useAssetActions } from '../../../hooks/components/useAssetActions';
import { fillFiltersHostList } from '../../../utils/helpers/host-list-fill-filter-helper';
import { getAccountId, getAccountName, getUserData } from '../../../services/local-storage-service';
import { useTicketMethods } from '../../../hooks/components/useTicketMethods';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

loadMessages(locale[currentLocale], currentLocale);

export function SecurityVulnsList(): React.ReactElement {
  const urlParams = getUrlSecVulnsList('SecurityVulnsList');

  const currentUser = getUserData();

  const accountId = getAccountId() || '';

  const accontName = getAccountName() || '';

  const currentAssetAccount = { accountId, name: accontName };

  const { assetId } = useParams<{ assetId: string }>();

  const location = useLocation();

  const queryClient = useQueryClient();

  const [customSearch, setCustomSearch] = useState('');

  const [sort, setSort] = useState<SortDescriptor[]>(urlParams.sort);

  const [page, setPage] = useState<IPageState>(urlParams.page);

  const [filter, setFilter] = useState<CompositeFilterDescriptor>(FILTER_INITIAL);

  const [filterStatus, setFilterStatus] = useState(false);

  const [filterVal, setFilterVal] = useState<IFilterVal[]>([]);

  const [isWhiteListTable, setWhiteListTable] = useState(false);

  const filterValue = useRef<CompositeFilterDescriptor | null>(null);

  const filterRef = useRef<CompositeFilterDescriptor>(filter);

  const dataForHooks: IDataHooks = {
    name: 'bulletinId',
    componentName: 'SecurityVulnsList',
  };

  const { handleTicket } = useTicketMethods();

  const { createNotification } = useNotificationContext();

  const { cveName } = useAuditModalContext();

  const debouncedCustomSearch = useCustomSearch(
    setFilterStatus,
    setCustomSearch,
    filter,
    setFilterVal,
    setPage,
    page,
    dataForHooks,
  );

  const filterSearch = useFilter(
    filterValue,
    setFilter,
    customSearch,
    setCustomSearch,
    setFilterVal,
    filterRef,
    filter,
    setPage,
    page,
    dataForHooks,
  );

  const getPage = usePageChange(setPage, urlParams, dataForHooks);

  const query = useQuery<DashboardBulletinsResponse, ApiError>(
    ['SecurityVulnsList', page, filterVal, sort],
    () => getBulletins(page, filterVal, sort),
    {
      keepPreviousData: true,
    },
  );

  const queryWl = useQuery<DashboardBulletinsResponse, ApiError>(
    ['SecurityVulnsListWL', page, filterVal, sort],
    () => getBulletinsWl(page, filterVal, sort),
    {
      keepPreviousData: true,
    },
  );

  useEffect(() => {
    const wlBlock = document.getElementById('idSecurityVulns');

    if (wlBlock) {
      wlBlock.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, [isWhiteListTable]);

  useEffect(() => {
    queryClient.invalidateQueries(['SecurityVulnsList']);
  }, [queryClient]);

  useEffect(() => {
    let delaySearch: NodeJS.Timeout;

    if (filter?.filters?.length > 0) {
      filterRef.current = filter;
      delaySearch = setTimeout(() => {
        handleFilteringTest(filterRef.current, setFilterVal);
        setFilterStatus(false);
      }, 300);
    }

    return () => clearTimeout(delaySearch);
  }, [filter.filters, page.skip, location]);

  useEffect(() => {
    if (query.error) {
      handleError(query.error, createNotification);
    }
  }, [query.isError, query.error, createNotification]);

  const pageChange = (event: GridPageChangeEvent): void => {
    getPage(event);
  };

  const onFilterChange = (event: FilterChangeEvent): void => {
    filterSearch(event);
  };

  const handleCustomSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    debouncedCustomSearch(e);
  };

  const { data } = query;

  const securityVulns = collectSecurityVulns(data);

  const { selectedVulns, setSelectedVulns, setAddVulnToWLForm, isAddVulnToWLForm } =
    useAssetCreatePropContext();

  useEffect(() => {
    fillFiltersHostList(urlParams, filterValue, setFilter, setFilterStatus);
    setSelectedVulns(null);
  }, []);

  const { isWhiteListForm, whitelistUpdate } = useWhiteListContext();

  const { selectAllVulnOnPage } = useAssetActions();

  return (
    <>
      <div className={`security-vulns ${styles.securityVulns}`}>
        <LocalizationProvider language={currentLocale}>
          <IntlProvider locale={currentLocale.substring(0, 2)}>
            <div className="filter-line">
              <Filter
                value={filter}
                onChange={onFilterChange}
                fields={
                  currentUser?.role === 'super_admin'
                    ? FILTER_SECURITY_VULNS[currentLocale]
                    : FILTER_SECURITY_VULNS[currentLocale].filter((f) => f.name !== 'accountId')
                }
              />
              {filter.filters.length === 0 && (
                <Input
                  name="customSearch"
                  placeholder={locale[currentLocale].customSearchName}
                  value={customSearch}
                  onChange={(e): void => handleCustomSearch(e)}
                  style={{ width: '480px' }}
                />
              )}
              {queryWl.data?.total && Number(queryWl.data.total) > 0 ? (
                <div className="whitelist-grid-title">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/attention_yellow.svg`}
                    alt="attention"
                  />
                  <Button
                    fill="dotted"
                    type="button"
                    onClick={(): void => setWhiteListTable(!isWhiteListTable)}
                  >
                    {!isWhiteListTable
                      ? locale[currentLocale].vulnsTable.titleWlTop
                      : locale[currentLocale].vulnsTable.titleWlTopHide}
                  </Button>
                </div>
              ) : null}
            </div>
          </IntlProvider>
        </LocalizationProvider>
        <div className={styles.pkgUpdate}>
          <Button
            onClick={(): void => selectAllVulnOnPage(securityVulns)}
            className="selected-pkgs button-mr20"
          >
            {localeAuditTask[currentLocale].vulnsSoft.selectAllVulnerabilities}
          </Button>
          {selectedVulns && selectedVulns.length > 0 && (
            <>
              <Button
                onClick={(): void => setAddVulnToWLForm(true)}
                className="selected-pkgs action button-mr20"
              >
                {localeAuditTask[currentLocale].vulnsSoft.excludeSelectedVulnerabilities} (
                {selectedVulns.length})
              </Button>
              <Button
                onClick={(): void => handleTicket(currentAssetAccount)}
                className="button-mr20"
              >
                {localeAuditTask[currentLocale].createTicket}
              </Button>
            </>
          )}
          {selectedVulns && selectedVulns.length > 0 && (
            <Button onClick={(): void => setSelectedVulns(null)} className="cancel-selected-pkgs">
              {localeAuditTask[currentLocale].cancelSelectedPackages}
            </Button>
          )}
        </div>
        <LocalizationProvider language={currentLocale}>
          <IntlProvider locale={currentLocale.substring(0, 2)}>
            <Grid
              pageable={PAGEABLE_DATA}
              sortable
              sort={sort}
              className="table task-table"
              data={securityVulns}
              skip={page.skip}
              take={page.take}
              total={Number(query.data?.total)}
              filterOperators={FILTER_OPERATORS}
              onPageChange={pageChange}
              onSortChange={(e: GridSortChangeEvent): void => handleSort(e, setSort)}
              scrollable="none"
            >
              <Column
                field="addVuln"
                title={localeAuditTask[currentLocale].vulnsSoft.table.selectPackage}
                cell={CellSelectVuln}
                width="80px"
                sortable={false}
              />
              <Column
                title={locale[currentLocale].vulnsTable.cvssScore}
                filter="text"
                field="maxScore"
                width={cvssScoreWidth.noSort}
                cell={ScoreCvssCell}
                sortable={false}
                headerClassName="no-cursor"
              />
              <Column
                title={locale[currentLocale].vulnsTable.id}
                filter="text"
                field="bulletinId"
                width="140px"
                cell={CellSecurityVulnsId}
              />
              <Column
                field="aliases"
                title={localeAuditTask[currentLocale].vulnsList.table.aliases}
                cell={CellVulnListAlias}
                width={query.data?.data.find((a) => a.aliases) ? '180px' : '160px'}
                sortable={false}
                headerClassName="no-cursor"
              />
              <Column
                title={locale[currentLocale].vulnsTable.title}
                filter="text"
                field="title"
                width="140px"
                cell={CellSecurityTitle}
                sortable={false}
                headerClassName="no-cursor"
              />
              <Column
                field="cwe"
                title={locale[currentLocale].vulnsTable.cwe}
                cell={CellSecurityCwe}
                sortable={false}
              />
              <Column
                title={locale[currentLocale].vulnsTable.published}
                width={publishedWidth.sort}
                filter="text"
                field="date.published"
                cell={CellPublished}
                sortable={false}
                headerClassName="no-cursor"
              />
              <Column
                title={locale[currentLocale].vulnsTable.hosts}
                width="98px"
                filter="text"
                field="totalHostAssets"
                cell={CellSecurityAsset}
              />
              <Column
                title={locale[currentLocale].vulnsTable.totalImageAssets}
                width="96px"
                filter="text"
                field="totalImageAssets"
                cell={CellSecurityImage}
              />
              <Column
                title={locale[currentLocale].vulnsTable.netDevices}
                filter="text"
                field="totalNetworkDeviceAssets"
                cell={CellSecurityNetDev}
                width="128px"
              />
            </Grid>
            <СommonTableLoader queryData={query.isLoading} filteringData={filterStatus} />
            {query.isError && (
              <div className="info alert alert-danger">Error: {query.error.message}</div>
            )}
          </IntlProvider>
        </LocalizationProvider>
        {cveName && <VulnModal />}
        {queryWl.data?.total && Number(queryWl.data.total) > 0 ? (
          <div className="whitelist-grid-title">
            <span className="whitelist-grid-title__text">
              {locale[currentLocale].vulnsTable.titleWl}
            </span>
            <NavLink
              className="dashboard__link"
              to={`/lk/audit/exceptions${
                assetId
                  ? `?skip=0&limit=15&sortField=createdAt&sortOrder=desc&assetIds[$eq]=${assetId}`
                  : ''
              }`}
            >
              {locale[currentLocale].vulnsTable.titleWlLink}
            </NavLink>
            <Button
              fill="dotted"
              type="button"
              onClick={(): void => setWhiteListTable(!isWhiteListTable)}
            >
              {isWhiteListTable
                ? locale[currentLocale].vulnsTable.titleWlHide
                : locale[currentLocale].vulnsTable.titleWlShow}
            </Button>
          </div>
        ) : null}
      </div>
      {isWhiteListTable && <SecurityVulnsListWL />}
      {isAddVulnToWLForm && <AddVulnToWLForm />}
      {(isWhiteListForm || whitelistUpdate) && <AddWhiteListForm />}
    </>
  );
}
