/* eslint-disable jsx-a11y/label-has-associated-control */
import { RadioButton } from '@progress/kendo-react-inputs';
import localeTicket from '../../../../../utils/i18n/security/vulnerabilities.json';
import styles from '../../../Security/Tickets/ticket.module.scss';
import { CommonTooltip } from '../../../../common/CommonTooltip';
import { useTicketSystemMethods } from '../../../../../hooks/components/useTicketSystemMethods';
import { useTicketSystemContext } from '../../../../../hooks/useTicketSystemContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeTicket;

export function DeploymentMethodBlock(): React.ReactElement {
  const { subType } = useTicketSystemContext();

  const { handleChangeSubType } = useTicketSystemMethods();

  return (
    <div className="newtask__time">
      <div className={styles.deploymentMethodLabelWrap}>
        <label className="k-label">
          {localeTicket[currentLocale].tickets.form.ticketSystemSubtype}
        </label>
        <CommonTooltip visibleStyle={{ marginLeft: '4px' }} hiddenStyle={{ marginLeft: '32px' }}>
          <div>
            <span className={styles.titleRb}>Jira Software Data Center:</span>
            <span>{localeTicket[currentLocale].tickets.form.jiraSoftwareDataCenter}</span>
          </div>
          <div>
            <span className={styles.titleRb}>Jira Software Cloud:</span>
            <span>{localeTicket[currentLocale].tickets.form.jiraSoftwareCloud}</span>
          </div>
        </CommonTooltip>
      </div>
      <div className={styles.radioWrap}>
        <RadioButton
          name="group1"
          value="on-premise"
          checked={subType === 'on-premise'}
          label="Jira Software Data Center"
          onChange={handleChangeSubType}
        />
      </div>
      <div className={styles.radioWrap}>
        <RadioButton
          name="group1"
          value="cloud"
          checked={subType === 'cloud'}
          label="Jira Software Cloud"
          onChange={handleChangeSubType}
        />
      </div>
    </div>
  );
}
