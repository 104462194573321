import type { GridCellProps } from '@progress/kendo-react-grid';
import { NavLink } from 'react-router-dom';
import type { TicketDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/ticketsDashboardResponse.v1';

export function CellTicketName(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { summary, ticketId }: TicketDashboardResponse = dataItem;

  return (
    <td title={summary || ticketId}>
      <NavLink to={`/lk/security/tickets/${dataItem.ticketId}`}>{summary || ticketId}</NavLink>
    </td>
  );
}
