import type { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import type { TotalAssetCount } from '../../../../types/__generated/on-premise-solution/api/totalAssetCount.v1';
import localeDashboards from '../../../../utils/i18n/dashboardLocale/dashboardLayout.json';
import styles from '../Dashboard.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeDashboards;

export function AssetsCount({
  data,
  accountId,
}: {
  data: TotalAssetCount;
  accountId?: string;
}): ReactElement {
  let devicesCount = 0;

  return (
    <div className={styles.total_assets_info}>
      <table className="column">
        <tbody>
          {data?.map((t) => {
            if (t.type === 'host' || t.type === 'image')
              return (
                <tr>
                  <td className={styles.asset_type}>
                    {localeDashboards[currentLocale].assetsCount[t.type]}
                  </td>
                  <td className={styles.asset_count}>
                    <NavLink
                      className="dashboard__link"
                      to={`/lk/assets/${t.type}s${accountId ? `?accountId=${accountId}` : ''}`}
                    >
                      {t.count}
                    </NavLink>
                  </td>
                </tr>
              );
            devicesCount += t.count;

            return undefined;
          })}
          <tr>
            <td className={styles.asset_type}>
              {localeDashboards[currentLocale].assetsCount.device}
            </td>
            <td className={styles.asset_count}>
              <NavLink
                className="dashboard__link"
                to={`/lk/assets/devices${accountId ? `?accountId=${accountId}` : ''}`}
              >
                {devicesCount}
              </NavLink>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
