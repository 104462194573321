/* eslint-disable no-nested-ternary */
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { ReactElement, useState } from 'react';
import localeSoftwares from '../../../utils/i18n/software/software.json';
import { useSoftwaresContext } from '../../../hooks/useSoftwaresContext';
import { SoftwareListTree } from './SoftwareListTree';
import { VulnerableSoftwareList } from './VulnerableSoftwareList';
import { InstalledSoftwareList } from './InstalledSoftwareList';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSoftwares;

export function SoftwareTabs({ isVulnerable }: { isVulnerable?: boolean }): ReactElement {
  const { isGroupped } = useSoftwaresContext();
  const [selected, setSelected] = useState(0);

  return (
    <TabStrip
      style={{
        marginTop: '4px',
      }}
      selected={selected}
      onSelect={(e): void => setSelected(e.selected)}
    >
      <TabStripTab title={localeSoftwares[currentLocale].linuxTab}>
        {isGroupped ? (
          <SoftwareListTree isVulnerable={isVulnerable} osType="linux" />
        ) : // <SoftwareList isVulnerable={isVulnerable} osType="linux" />
        isVulnerable ? (
          <VulnerableSoftwareList osType="linux" />
        ) : (
          <InstalledSoftwareList osType="linux" />
        )}
      </TabStripTab>
      <TabStripTab title={localeSoftwares[currentLocale].windowsTab}>
        {isGroupped ? (
          <SoftwareListTree isVulnerable={isVulnerable} osType="windows" />
        ) : isVulnerable ? (
          <VulnerableSoftwareList osType="windows" />
        ) : (
          <InstalledSoftwareList osType="windows" />
        )}
      </TabStripTab>
    </TabStrip>
  );
}
