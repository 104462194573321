import { nanoid } from 'nanoid';
import { Button } from '../baseElements/Button';
import locale from '../../../utils/i18n/security/vulnerabilities.json';
import { useWhiteListContext } from '../../../hooks/useWhiteListContext';
import { useHostModalContext } from '../../../hooks/useHostModalContext';
import { useTicketContext } from '../../../hooks/useTicketContext';
import type { IDataHooks } from '../../../utils/helpers/types';

export function SoftwareMutableBlockWhiteList({
  dataForHooks,
}: {
  dataForHooks?: IDataHooks;
}): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof locale;

  const { setAddSoftwareList } = useWhiteListContext();

  const { softwareInWl, setSoftwareInWl, modifySoftwareInWl, isExceptionAssetVulnTable } =
    useHostModalContext();

  const { editTicketData } = useTicketContext();

  return (
    <div className="apply-assets">
      <div className="title-apply-assets">
        <span>{locale[currentLocale].excludeSoftware.title}</span>
        <Button onClick={(): void => setAddSoftwareList(true)} type="button">
          {locale[currentLocale].vulnsTable.AddSoftware}
        </Button>
      </div>
      <div className="list-assets">
        {softwareInWl &&
          !isExceptionAssetVulnTable &&
          Object.values(softwareInWl)?.map((s) => {
            return (
              <Button
                type="button"
                fill={
                  dataForHooks?.componentName === 'TicketSystemList' && !!editTicketData
                    ? 'flat'
                    : 'task_asset'
                }
                onClick={(): void =>
                  modifySoftwareInWl(s.name, s.version, s.osType, softwareInWl, setSoftwareInWl)
                }
                key={nanoid()}
                disabled={dataForHooks?.componentName === 'TicketSystemList' && !!editTicketData}
              >
                {s.name} {s.version}
              </Button>
            );
          })}

        {((softwareInWl && Object.keys(softwareInWl).length === 0) || softwareInWl === null) && (
          <span className="placeholder">
            {locale[currentLocale].applyForVulns.selectSoftwarePlaceholder}
          </span>
        )}
      </div>
    </div>
  );
}
