import { Checkbox } from '@progress/kendo-react-inputs';
import localeSettings from '../../../../utils/i18n/settingsLocales/settings.json';
import styles from '../WhiteListTabEl.module.scss';
import { useWhiteListContext } from '../../../../hooks/useWhiteListContext';
import { useHostModalContext } from '../../../../hooks/useHostModalContext';
import type { TValuesForCheckbox } from '../../../../utils/helpers/types';

export function SoftwareCheckboxBlock({
  valuesForCheckbox,
}: {
  valuesForCheckbox: TValuesForCheckbox;
}): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeSettings;

  const { isBulletins, setBulletins, isSoftware, setSoftware } = valuesForCheckbox;

  const { bulletinForm } = useWhiteListContext();

  const { softwareInWl, isExceptionAssetVulnTable } = useHostModalContext();

  // const types = assetType ? usedAssetType(assetType) : null;

  const changeCheckbox = (text: string, val: boolean): void => {
    if (text === 'bulletin') {
      setBulletins(val);
    }
    if (text === 'software') {
      setSoftware(val);
    }
  };

  return (
    <div className={styles.whitelistCheckboxWrapper}>
      <div className={styles.titleTabMain}>
        {/* <span className={!whitelistUpdate ? 'required-label' : ''}> */}
        <span>{localeSettings[currentLocale].whiteList.titleCheckbox}</span>
      </div>
      <div className={styles.whitelistCheckboxContent}>
        <div className={styles.checkboxWrapperCount}>
          <Checkbox
            label={localeSettings[currentLocale].apiKey.form.checkbox.bulletin}
            checked={isBulletins}
            onChange={(): void => changeCheckbox('bulletin', !isBulletins)}
            className={styles.checkboxMb}
          />
          {bulletinForm && bulletinForm.length > 0 && isBulletins && (
            <div className={styles.checkboxCount}>
              <span>
                ({localeSettings[currentLocale].whiteList.form.selected} {bulletinForm?.length})
              </span>
            </div>
          )}
          {isBulletins && (!bulletinForm || bulletinForm.length === 0) && (
            <div className={styles.checkboxCount}>
              <span>({localeSettings[currentLocale].whiteList.form.notSelected})</span>
            </div>
          )}
        </div>
        <div className={styles.checkboxWrapperCount}>
          <Checkbox
            label={localeSettings[currentLocale].apiKey.form.checkbox.software}
            checked={isSoftware}
            onChange={(): void => changeCheckbox('software', !isSoftware)}
          />
          {softwareInWl && Object.keys(softwareInWl).length > 0 && isSoftware && (
            <div className={styles.checkboxCount}>
              <span>
                ({localeSettings[currentLocale].whiteList.form.selected}{' '}
                {!isExceptionAssetVulnTable && softwareInWl && Object.keys(softwareInWl).length})
              </span>
            </div>
          )}
          {isSoftware && (!softwareInWl || Object.keys(softwareInWl).length === 0) && (
            <div className={styles.checkboxCount}>
              <span>({localeSettings[currentLocale].whiteList.form.notSelected})</span>
            </div>
          )}
        </div>{' '}
        {/* {types && isAssetType && <UsedAssetType types={types} />} */}
      </div>
    </div>
  );
}
