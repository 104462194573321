import type { SortDescriptor } from '@progress/kendo-data-query';
import type { GridSortChangeEvent } from '@progress/kendo-react-grid';
import type { TreeListSortChangeEvent } from '@progress/kendo-react-treelist';

export function handleSort(
  e: GridSortChangeEvent | TreeListSortChangeEvent,
  setSort: (value: React.SetStateAction<SortDescriptor[]>) => void,
  defaultField?: string,
  defaultDir?: 'asc' | 'desc',
): void {
  if (e.sort.length > 0) {
    setSort([...e.sort]);
  } else {
    setSort([{ field: defaultField || '', dir: defaultDir || 'desc' }]);
  }
}
