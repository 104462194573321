import './HostDescription.module.scss';
import { useEffect } from 'react';
import type localeHostDescription from '../../../../utils/i18n/assetLocales/hostDescription.json';
import { AddCredentialForm } from '../../Credentials/form/AddCredentialForm';
import { TableFHostMetaBlock } from './tables/TableFHostMetaBlock';
import { TableSHostMetaBlock } from './tables/TableSHostMetaBlock';
import type { AssetHostDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/assetHostDashboardResponse.v1';
import { useCredentialContext } from '../../../../hooks/useCredentialContext';
import { TableTHostMetaBlock } from './tables/TableTHostMetaBlock';
import { useAssetCreatePropContext } from '../../../../hooks/useAssetCreatePropContext';
import { TableFourthHostMetaBlock } from './tables/TableFourthHostMetaBlock';

export function HostMetaBlock({
  data,
}: {
  data: AssetHostDashboardResponse | undefined;
}): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeHostDescription;

  const { isAddCredential, credentialData, isEdit } = useCredentialContext();

  const { setShowImportance, setShowHostType, setShowPerimeterImpact, setShowConnectedBy } =
    useAssetCreatePropContext();

  const rootDiv = document.getElementsByClassName('common-summary-page');

  const hideDropDownList = (): void => {
    setShowImportance(false);
    setShowHostType(false);
    setShowPerimeterImpact(false);
    setShowConnectedBy(false);
  };

  const keyDownHandler = (event: KeyboardEvent): void => {
    if (event.key === 'Escape') {
      event.preventDefault();

      setShowImportance(false);
      setShowHostType(false);
      setShowPerimeterImpact(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', keyDownHandler);

    if (rootDiv[0]) {
      rootDiv[0].addEventListener('click', hideDropDownList);
      rootDiv[0].addEventListener('keydown', hideDropDownList);
    }

    return () => {
      if (rootDiv[0]) {
        rootDiv[0].removeEventListener('click', hideDropDownList);
      }
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  return (
    <div className="common-summary-page">
      <TableFHostMetaBlock data={data} currentLocale={currentLocale} />
      <TableSHostMetaBlock data={data} currentLocale={currentLocale} />
      <TableTHostMetaBlock data={data} currentLocale={currentLocale} />
      <TableFourthHostMetaBlock data={data} currentLocale={currentLocale} />
      {(isAddCredential || (credentialData && isEdit)) && <AddCredentialForm />}
    </div>
  );
}
