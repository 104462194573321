import {
  createContext,
  JSXElementConstructor,
  ReactElement,
  useContext,
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react';
import type { TEditTicket, TTicketCreationRequest } from '../utils/helpers/types';
import {
  ticketPriorityValue2,
  ticketStatusValue,
  ticketSystemTypeValue,
} from '../utils/helpers/constants';
import { useAccountModalContext } from './useAccountModalContext';

const TicketContext = createContext<TTicketCreationRequest | null>(null);

export function TicketProvider({
  children,
}: {
  children: ReactElement<string | JSXElementConstructor<string>>;
}): React.ReactElement {
  const [summary, setSummary] = useState('');

  const [ticketSystem, setTicketSystem] = useState<{
    id: string;
    name: string;
    type: string;
  } | null>(null);

  const [ticketSystemType, setTicketSystemType] = useState<'jira' | 'yandex_tracker'>(
    ticketSystemTypeValue[0],
  );

  const [type, setType] = useState('fix_vulnerabilities');

  const [description, setDescription] = useState<string | undefined>(undefined);

  const [deadlineDate, setDeadlineDate] = useState<Date | null>(null);

  const [priority, setPriority] = useState(ticketPriorityValue2[0]);

  const [ticketStatus, setTicketStatus] = useState(ticketStatusValue[0]);

  const [isAddTicket, setAddTicket] = useState(false);

  const [errorMutation, setErrorMutation] = useState('');

  const [errorMutationTicketStatus, setErrorMutationTicketStatus] = useState<
    { message: string; id: string }[] | null
  >(null);

  const [isTicketAutoCloseEnabled, setTicketAutoCloseEnabled] = useState(false);

  const [editTicketData, setEditTicketData] = useState<TEditTicket | null>(null);

  const [isTicketStatusLoading, setTicketStatusLoading] = useState(false);

  const ticketIdVale = useRef<string | null>(null);

  const [isTicketCreatedSuccess, setTicketCreatedSuccess] = useState(false);

  const { setAccountId, accountId } = useAccountModalContext();

  useEffect(() => {
    if (editTicketData) {
      if (editTicketData.account) {
        setAccountId({ id: editTicketData.account.accountId, name: editTicketData.account.name });
      }
      if (editTicketData.summary) {
        setSummary(editTicketData.summary);
      }
      if (editTicketData.type) {
        setType(editTicketData.type);
      }
      if (editTicketData.ticketSystem) {
        setTicketSystem({
          id: editTicketData.ticketSystem.ticketSystemId,
          name: editTicketData.ticketSystem.name,
          type: editTicketData.ticketSystem.type,
        });
      }
      if (editTicketData.ticketSystemType) {
        setTicketSystemType(editTicketData.ticketSystemType);
      }
      if (editTicketData.deadlineDate) {
        setDeadlineDate(new Date(editTicketData.deadlineDate));
      }
      if (
        editTicketData.priority &&
        ticketPriorityValue2.filter((p) => p.id === editTicketData.priority).length > 0
      ) {
        const prioritet = ticketPriorityValue2.filter((p) => p.id === editTicketData.priority);
        setPriority({ id: prioritet[0].id, name: prioritet[0].name });
      }
      if (editTicketData.status) {
        const status = ticketStatusValue.filter((s) => s.id === editTicketData.status);
        setTicketStatus({ id: status[0].id || '', name: status[0].name || '' });
      }
      if (editTicketData.description) {
        setDescription(editTicketData.description);
      }
      if (editTicketData.isTicketAutoCloseEnabled) {
        setTicketAutoCloseEnabled(editTicketData.isTicketAutoCloseEnabled);
      }
    }
  }, [editTicketData]);

  const value = useMemo(
    () => ({
      summary,
      setSummary,
      isAddTicket,
      setAddTicket,
      ticketSystem,
      setTicketSystem,
      ticketSystemType,
      setTicketSystemType,
      type,
      setType,
      description,
      setDescription,
      deadlineDate,
      setDeadlineDate,
      priority,
      setPriority,
      ticketStatus,
      setTicketStatus,
      errorMutation,
      setErrorMutation,
      isTicketAutoCloseEnabled,
      setTicketAutoCloseEnabled,
      editTicketData,
      setEditTicketData,
      isTicketStatusLoading,
      setTicketStatusLoading,
      errorMutationTicketStatus,
      setErrorMutationTicketStatus,
      ticketIdVale,
      isTicketCreatedSuccess,
      setTicketCreatedSuccess,
    }),
    [
      summary,
      isAddTicket,
      ticketSystem,
      ticketSystemType,
      type,
      description,
      deadlineDate,
      priority,
      ticketStatus,
      errorMutation,
      isTicketAutoCloseEnabled,
      editTicketData,
      isTicketStatusLoading,
      errorMutationTicketStatus,
      isTicketCreatedSuccess,
    ],
  );

  return <TicketContext.Provider value={value}>{children}</TicketContext.Provider>;
}
export const useTicketContext = (): TTicketCreationRequest => {
  const context = useContext(TicketContext);
  if (context === null) {
    throw new Error('useTicketContext must be used inside the WebSocketContext.Provider.');
  }

  return context;
};
