/* eslint-disable react-hooks/exhaustive-deps */
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import type { TextFilterProps } from '@progress/kendo-react-data-tools/dist/npm/filter/filters/TextFilter';
import type localeTask from '../../../utils/i18n/taskLocale/task.json';
import type { IDaraFilterSelect } from '../../../utils/helpers/types';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeTask;

let dataStatus = [] as IDaraFilterSelect[];
export function FilterTicketStatusSelect(props: TextFilterProps): React.ReactElement {
  const { filter, onFilterChange } = props;

  dataStatus = [
    {
      text:
        currentLocale === 'ru-RU' || window.navigator.language === 'ru'
          ? 'Выбрать статус...'
          : 'Select status...',
      val: '',
    },
    {
      text:
        currentLocale === 'ru-RU' || window.navigator.language === 'ru' ? 'Выполнено' : 'Completed',
      val: 'completed',
    },
    {
      text:
        currentLocale === 'ru-RU' || window.navigator.language === 'ru'
          ? 'Ожидает выполнения'
          : 'Uncompleted',
      val: 'uncompleted',
    },
    {
      text:
        currentLocale === 'ru-RU' || window.navigator.language === 'ru'
          ? 'Невозможно'
          : 'Impossible',
      val: 'impossible',
    },
  ];

  const handleChange = (event: DropDownListChangeEvent): void => {
    onFilterChange({
      nextFilter: { ...filter, ...{ value: event.target.value.val, text: '' } },
    });
  };

  return (
    <DropDownList
      defaultValue={dataStatus[0]}
      data={dataStatus}
      textField="text"
      dataItemKey="val"
      onChange={handleChange}
    />
  );
}
