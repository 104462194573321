import { useRef } from 'react';
import { useAuditModalContext } from '../../../../../hooks/useAuditModalContext';
import localeAuditTask from '../../../../../utils/i18n/taskLocale/auditTask.json';
import { collectpkgDataModal } from '../../../../../utils/helpers/audit-helpers';

export function PackageNameModalMetaBlock(): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeAuditTask;

  const currentLength = useRef<number | null>(null);

  const { auditData, pkgName } = useAuditModalContext();

  const data = collectpkgDataModal(auditData, pkgName);

  if (data.pkgVulns && data.pkgVulns.length) currentLength.current = data.pkgVulns.length;

  return (
    <div className="common-summary-page">
      <table className="column">
        <tbody>
          <tr>
            <td>{localeAuditTask[currentLocale].vulnsSoft.packageNameModal.vulnerabilities}</td>
            <td>{data.vulns}</td>
          </tr>
          <tr>
            <td>{localeAuditTask[currentLocale].vulnsSoft.packageNameModal.updates}</td>
            <td>{data.updates ? <span>{data.updates}</span> : <span>-</span>}</td>
          </tr>
          <tr>
            <td>{localeAuditTask[currentLocale].vulnsSoft.packageNameModal.upgradeTo}</td>
            <td>
              {pkgName?.updateVersion ? <span>{pkgName?.updateVersion}</span> : <span>-</span>}
            </td>
          </tr>
        </tbody>
      </table>
      <table className="column">
        <tbody>
          <tr>
            <td>{localeAuditTask[currentLocale].vulnsSoft.packageNameModal.os}</td>
            <td style={{ textTransform: 'capitalize' }}>
              {auditData?.payload.os.name
                ? `${auditData?.payload.os.name} ${auditData?.payload.os.version || ''}`
                : '-'}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
