import { TileLayout } from '@progress/kendo-react-layout';
import { useMemo, type ReactElement } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import {
  getAgentStatusesDashboard,
  getAllTotalInfo,
  getAuditAssetAge,
  getBulletins,
  getInventarizationAssetsAge,
  getSensorStatusesDashboard,
  getTotalVulnerabilities,
  getUsersDashboard,
  getVulnerableAssets,
} from '../../../../services/dashboard-service';
import type { TotalAssetCount } from '../../../../types/__generated/on-premise-solution/api/totalAssetCount.v1';
import { AssetsCount } from '../../Dashboard/audit/AssetsCount';
import { VulnerabilitiesCount } from '../../Dashboard/audit/VulnerabilitiesCount';
import type { TotalAssetVulnerabillitiesResponse } from '../../../../types/__generated/on-premise-solution/api/totalAssetVulnerabillitiesResponse.v1';
import locale from '../../../../utils/i18n/dashboardLocale/dashboardLayout.json';
import { AgentStatus } from '../../Dashboard/inventorization/AgentStatus';
import type { AgentStatuses } from '../../../../types/__generated/on-premise-solution/api/agentStatuses.v1';
import type { SensorStatuses } from '../../../../types/__generated/on-premise-solution/api/sensorStatuses.v1';
import { SensorStatus } from '../../Dashboard/inventorization/SensorStatus';
import { UsersInfo } from '../../Dashboard/inventorization/UsersInfo';
import { AudAssetsAge } from '../../Dashboard/audit/AuditAssetsAge';
import type { AuditAssetsAgeResponse } from '../../../../types/__generated/on-premise-solution/api/auditAssetsAgeResponse.v1';
import { InvAssetsAge } from '../../Dashboard/inventorization/InvAssetsAge';
import type { InventorizationAssetsAge } from '../../../../types/__generated/on-premise-solution/api/inventorizationAssetsAge.v1';
import { Bulletins } from '../../Dashboard/audit/Bulletins';
import { TopVulnsAsset } from '../../Dashboard/audit/TopVulnsAsset';
import type { VulnerableAssetsResponse } from '../../../../types/__generated/on-premise-solution/api/vulnerableAssetsResponse.v1';
import type { BulletinsWidgetResponse } from '../../../../types/__generated/on-premise-solution/api/bulletinsWidgetResponse.v1';
import styles from '../../Dashboard/Dashboard.module.scss';
import { useAuditModalContext } from '../../../../hooks/useAuditModalContext';
import { VulnModal } from '../../../common/modal/vulnModal/VulnModal';
import { AddVulnToWLForm } from '../../WhiteList/form/AddVulnToWLForm';
import { AddWhiteListForm } from '../../WhiteList/form/AddWhiteListForm';
import { useWhiteListContext } from '../../../../hooks/useWhiteListContext';
import { useAssetCreatePropContext } from '../../../../hooks/useAssetCreatePropContext';
import { getUserData } from '../../../../services/local-storage-service';
import type { UserCountGroupedByTypeResponse } from '../../../../types/__generated/on-premise-solution/api/userCountGroupedByTypeResponse.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function DashboardTab(): ReactElement {
  const currentUser = getUserData();
  const { accountId } = useParams<{ accountId: string }>();
  const { cveName } = useAuditModalContext();
  const { isWhiteListForm } = useWhiteListContext();
  const { isAddVulnToWLForm } = useAssetCreatePropContext();

  const queryTotalVulnerabilities = useQuery<TotalAssetVulnerabillitiesResponse, ApiError>(
    ['total-vulnerabilities-by-account'],
    () => getTotalVulnerabilities(accountId),
  );

  const queryAllTotalAssets = useQuery<TotalAssetCount, ApiError>(['allTotalAssetsByAccount'], () =>
    getAllTotalInfo(accountId),
  );

  const queryAgentStatuses = useQuery<AgentStatuses, ApiError>(['agent-statuses-by-account'], () =>
    getAgentStatusesDashboard(accountId),
  );

  const querySensorStatuses = useQuery<SensorStatuses, ApiError>(
    ['sensor-statuses-by-account'],
    () => getSensorStatusesDashboard(accountId),
  );

  const queryUsersInfo = useQuery<UserCountGroupedByTypeResponse, ApiError>(
    ['users-info-by-account'],
    () => getUsersDashboard(accountId),
  );

  const queryAuditAssetAge = useQuery<AuditAssetsAgeResponse, ApiError>(
    ['audit-asset-age-by-account'],
    () => getAuditAssetAge(accountId),
  );

  const queryInvAssetsAge = useQuery<InventorizationAssetsAge, ApiError>(
    ['invAssetsAgeByAccount'],
    () => getInventarizationAssetsAge(accountId),
  );

  const queryBulletins = useQuery<BulletinsWidgetResponse, ApiError>(['bulletinsByAccount'], () =>
    getBulletins(accountId),
  );

  const queryVulnerableAssets = useQuery<VulnerableAssetsResponse, ApiError>(
    ['vulnerable-assets-by-account'],
    () => getVulnerableAssets(undefined, accountId),
  );

  const defaultDashboardSet = [
    { col: 1, rowSpan: 1, colSpan: 2, order: 0 }, // Дашборд "Уязвимостей"
    { col: 1, rowSpan: 1, colSpan: 2, order: 1 }, // Дашборд "Активов"
    { col: 3, rowSpan: 2, colSpan: 2, order: 2 }, // Дашборд "Пользователей"
    { col: 5, rowSpan: 2, colSpan: 2, order: 3 }, // Дашборд "Статус агентов"
    { col: 7, rowSpan: 2, colSpan: 2, order: 4 }, // Дашборд "Статус сенсоров"
    { col: 5, rowSpan: 2, colSpan: 2, order: 5 }, // Дашборд "Давность аудита"
    { col: 7, rowSpan: 2, colSpan: 2, order: 6 }, // Дашборд "Давность инвентаризации"
    { col: 1, rowSpan: 2, colSpan: 2, order: 7 }, // Дашборд "Топ 10 уязвимостей (по кол-ву хостов)"
    { col: 3, rowSpan: 2, colSpan: 2, order: 8 }, // Дашборд "Топ 10 уязвимых хостов"
  ];

  const tiles = [
    // Дашборд "Уязвимостей"
    {
      header: locale[currentLocale].totalVulnerabilities,
      body: (
        <VulnerabilitiesCount
          data={queryTotalVulnerabilities.data}
          accountId={currentUser?.role === 'super_admin' ? accountId : undefined}
        />
      ),
    },
    // Дашборд "Активов"
    {
      header: locale[currentLocale].numberOfAssets,
      body: (
        <div className={styles.number_of_assets}>
          {queryAllTotalAssets.data && (
            <AssetsCount
              data={queryAllTotalAssets.data}
              accountId={currentUser?.role === 'super_admin' ? accountId : undefined}
            />
          )}
        </div>
      ),
    },
    // Дашборд "Пользователей"
    {
      header: locale[currentLocale].users,
      body: useMemo(() => {
        return (
          <div>
            <UsersInfo data={queryUsersInfo.data} />
          </div>
        );
      }, [queryUsersInfo.data]),
    },
    // Дашборд "Статус агентов"
    {
      header: locale[currentLocale].agentsStatus,
      body: useMemo(() => {
        return (
          <div>
            <AgentStatus data={queryAgentStatuses.data} />
          </div>
        );
      }, [queryAgentStatuses.data]),
    },
    // Дашборд "Статус сенсоров"
    {
      header: locale[currentLocale].sensorsStatus,
      body: useMemo(() => {
        return (
          <div>
            <SensorStatus data={querySensorStatuses.data} />
          </div>
        );
      }, [querySensorStatuses.data]),
    },
    // Дашборд "Давность аудита"
    {
      header: locale[currentLocale].auditAge,
      body: useMemo(() => {
        return (
          <div>
            <AudAssetsAge data={queryAuditAssetAge.data} />
          </div>
        );
      }, [queryAuditAssetAge.data]),
    },
    // Дашборд "Давность инвентаризации"
    {
      header: locale[currentLocale].invAssetsAge,
      body: useMemo(() => {
        return (
          <div>
            <InvAssetsAge data={queryInvAssetsAge.data} />
          </div>
        );
      }, [queryInvAssetsAge.data]),
    },
    // Дашборд "Топ 10 уязвимостей (по кол-ву хостов)"
    {
      header: locale[currentLocale].topVulnerabilities,
      body: useMemo(() => {
        return (
          <div>
            <Bulletins data={queryBulletins.data} />
          </div>
        );
      }, [queryBulletins.data]),
    },
    // Дашборд "Топ 10 уязвимых хостов"
    {
      header: locale[currentLocale].vulnerableHosts,
      body: useMemo(() => {
        return (
          <div>
            <TopVulnsAsset data={queryVulnerableAssets.data} />
          </div>
        );
      }, [queryVulnerableAssets.data]),
    },
  ];

  return (
    <div>
      <TileLayout
        autoFlow="column"
        columns={8}
        rowHeight={255}
        positions={defaultDashboardSet}
        gap={{
          rows: 10,
          columns: 10,
        }}
        items={tiles}
        //   onReposition={handleReposition}
      />
      {cveName && <VulnModal />}
      {isAddVulnToWLForm && <AddVulnToWLForm />}
      {isWhiteListForm && !isAddVulnToWLForm && <AddWhiteListForm />}
    </div>
  );
}
