import styles from '../TicketSystem.module.scss';
import { useTicketContext } from '../../../../../hooks/useTicketContext';
import { Button } from '../../../../common/baseElements/Button';
import { useCredentialContext } from '../../../../../hooks/useCredentialContext';
import { useAccountModalContext } from '../../../../../hooks/useAccountModalContext';
import localeUser from '../../../../../utils/i18n/userLocales/user.json';
import { SelectedCredForAsset } from '../../../../common/form/SelectedCredForAsset';
import { useTicketSystemContext } from '../../../../../hooks/useTicketSystemContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeUser;

export function CredentialsTicketForm(): React.ReactElement {
  const { ticketSystemType } = useTicketContext();

  const { credentialTicket, subType } = useTicketSystemContext();

  const { accountId } = useAccountModalContext();

  const { setCredential } = useCredentialContext();

  return (
    <div className={`${styles.credentialWrap} form-field-wrapper`}>
      <Button
        fill="flat"
        icon="plus"
        type="button"
        textColor="green"
        className="credential-bnt required-label button-pl0"
        onClick={(): void =>
          setCredential({
            accountId: accountId.id || undefined,
            assetType: undefined,
            type: ticketSystemType === 'jira' ? subType : ticketSystemType,
          })
        }
      >
        {credentialTicket?.credentialId
          ? localeUser[currentLocale].connectServers.form.changedCredential
          : localeUser[currentLocale].connectServers.form.addCredential}
      </Button>
      <SelectedCredForAsset
        dataCred={credentialTicket}
        type={ticketSystemType === 'jira' ? 'jira' : 'yandex_tracker'}
      />
    </div>
  );
}
