/* eslint-disable react/jsx-pascal-case */
import { Grid, GridColumn as Column, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { cvssScoreWidth, PAGEABLE_DATA } from '../../utils/helpers/constants';
import type { ICommonSoftwaresList } from '../../utils/helpers/types';
import { handleSort } from '../../utils/sorting-in-table';
import localeSoftwareList from '../../utils/i18n/software/software.json';
import { ScoreCvssCell } from './cells/ScoreCvssCell';
import { CellSoftwareName } from '../features/Software/cells/CellSoftwareName';
import { StandartCell } from './baseElements/StandartCell';
import { CellTotalVulnerabilities } from '../features/Software/cells/CellTotalVulnerabilities';
import CellSelectSoft from '../features/Software/cells/CellSelectSoft';
import { CellTotalAssetsByPackageModal } from '../features/Software/cells/CellTotalAssetsByPackageModal';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSoftwareList;

export function CommonSoftwaresList(props: ICommonSoftwaresList): React.ReactElement {
  const {
    sort,
    data,
    page,
    total,
    pageChange,
    setSort,
    dataForHooks,
    conditionSort,
    isVulnerableList,
  } = props;

  return (
    <div className="softwares-list">
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <Grid
            pageable={conditionSort && total && total > conditionSort ? PAGEABLE_DATA : false}
            sortable
            sort={sort}
            data={
              isVulnerableList
                ? data?.data.map((d) => ({ isVulnerableFlag: isVulnerableList, ...d }))
                : data?.data
            }
            skip={page?.skip}
            take={page?.take}
            total={total || 0}
            onPageChange={pageChange}
            onSortChange={
              setSort ? (e: GridSortChangeEvent): void => handleSort(e, setSort) : undefined
            }
            scrollable="none"
            className={`
              ${
                conditionSort &&
                total &&
                total < conditionSort &&
                dataForHooks?.componentName !== 'VulnModalHosts'
                  ? 'no-pageable-k-grid'
                  : ''
              }
            `}
          >
            {/* {(dataForHooks.componentName === 'AddHostList' ||
              dataForHooks.componentName === 'GroupDescription') && (
            {(dataForHooks?.componentName === 'AddHostList' ||
              dataForHooks?.componentName === 'GroupDescription') && (
              <Column field="addAsset" title=" " cell={CellSelectHost} width="40px" />
            )} */}
            {dataForHooks?.componentName === 'SoftwareListForForm' && (
              <Column
                field="addSoftware"
                title=" "
                cell={CellSelectSoft}
                width="40px"
                sortable={false}
              />
            )}
            {(dataForHooks?.componentName === 'SoftwareListForForm' ||
              dataForHooks?.componentName === 'SoftwareVulnerableList' ||
              (dataForHooks?.componentName === 'SoftwareListRow' && isVulnerableList)) && (
              <Column
                field="maxMetric.cvss.score"
                title="CVSS"
                filter="text"
                cell={ScoreCvssCell}
                width={cvssScoreWidth.sort}
              />
            )}
            <Column
              title={
                dataForHooks?.moreData === 'windows'
                  ? localeSoftwareList[currentLocale].tables.softwareName
                  : localeSoftwareList[currentLocale].tables.packageName
              }
              cell={CellSoftwareName}
              field="name"
            />
            <Column
              title={localeSoftwareList[currentLocale].tables.version}
              className="title"
              cell={StandartCell}
              // width={
              //   dataForHooks?.componentName === 'SoftwareVulnerableList' ||
              //   (dataForHooks?.componentName === 'SoftwareListRow' && isVulnerableList)
              //     ? 180
              //     : undefined
              // }
              field="version"
            />
            {dataForHooks?.moreData === 'linux' && (
              <Column
                title={localeSoftwareList[currentLocale].tables.arch}
                cell={StandartCell}
                field="arch"
                width={128}
              />
            )}
            {/* {(dataForHooks?.componentName === 'SoftwareVulnerableList' ||
              (dataForHooks?.componentName === 'SoftwareListRow' && isVulnerableList)) && (
              <Column
                width={80}
                title={localeSoftwareList[currentLocale].tables.fixes}
                field="fix"
                cell={CellFixes}
              />
            )} */}
            {/* {(dataForHooks?.componentName === 'SoftwareVulnerableList' ||
              (dataForHooks?.componentName === 'SoftwareListRow' && isVulnerableList)) && (
              <Column
                title={localeSoftwareList[currentLocale].tables.vulnerabilities}
                cell={CellVulnerabilities}
              />
            )} */}
            {(dataForHooks?.componentName === 'SoftwareVulnerableList' ||
              (dataForHooks?.componentName === 'SoftwareListRow' && isVulnerableList)) && (
              <Column
                width={148}
                title={localeSoftwareList[currentLocale].tables.totalVulnerabilities}
                cell={CellTotalVulnerabilities}
                // cell={CellTotalBulletins}
                field="totalBulletins"
              />
            )}
            <Column
              width={100}
              title={localeSoftwareList[currentLocale].tables.totalHosts}
              field="totalHostAssets"
              cell={CellTotalAssetsByPackageModal}
            />
            <Column
              width={100}
              title={localeSoftwareList[currentLocale].tables.totalImages}
              field="totalImageAssets"
              cell={CellTotalAssetsByPackageModal}
            />
          </Grid>
        </IntlProvider>
      </LocalizationProvider>
    </div>
  );
}
