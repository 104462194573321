/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import type { GridCellProps } from '@progress/kendo-react-grid';
import type { TreeListCellProps } from '@progress/kendo-react-treelist';

export function StandartCell(props: GridCellProps | TreeListCellProps): React.ReactElement {
  const { dataItem, field, className, style, level } = props;

  if (Array.isArray(level) && level.length <= 1) return <td />;

  const fieldArray = field ? field.split('.') : [];
  let value = dataItem;

  if (!fieldArray) value = null;
  else if (fieldArray.length === 1) value = dataItem[fieldArray[0]];
  else if (fieldArray.length > 1) {
    fieldArray.forEach((f) => {
      value = value ? value[f] : value;
    });
  }

  // return (value || value === 0) && value !== '-' && value !== 0 ? (
  return (value || value === 0) && value !== '-' ? (
    <td
      title={className && className.includes('title') ? value : ''}
      className={className || ''}
      style={style}
    >
      {value}
    </td>
  ) : (
    <td className={className || ''}>-</td>
  );
}
