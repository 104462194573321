/* eslint-disable react/jsx-no-useless-fragment */
import { Field } from '@progress/kendo-react-form';
import { DropDownSelect } from '../../../../common/form/DropDownSelect';
import styles from '../TicketSystem.module.scss';
import { useTicketContext } from '../../../../../hooks/useTicketContext';
import { CommonTooltip } from '../../../../common/CommonTooltip';
import localeTicket from '../../../../../utils/i18n/security/vulnerabilities.json';
import { useTicketSystemContext } from '../../../../../hooks/useTicketSystemContext';

export function TicketJiraProjectNameForm(): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeTicket;

  const { ticketSystemType } = useTicketContext();

  const { editTicketSystemData, setJiraProjectId, jiraProjectId, jiraProjectIds } =
    useTicketSystemContext();

  return (
    <>
      {ticketSystemType === 'jira' && (
        <>
          <div className={styles.jiraProjectIdsLabelWrap}>
            <span className={!editTicketSystemData ? 'required-label' : ''}>
              {localeTicket[currentLocale].ticketSystem.form.jiraProjectName}
            </span>
            <CommonTooltip
              visibleStyle={{ marginLeft: '8px' }}
              hiddenStyle={{ marginLeft: '32px', marginBottom: '20px' }}
            >
              {localeTicket[currentLocale].ticketSystem.form.jiraProjectNameInfo}
            </CommonTooltip>
          </div>
          <Field
            name="jiraProjectIds"
            component={DropDownSelect}
            onChange={(e): void => setJiraProjectId(e.target.value.id)}
            data={jiraProjectIds?.filter((d) => d.id !== jiraProjectId)}
            textField="name"
            dataItemKey="id"
            defaultValue={
              editTicketSystemData && jiraProjectIds && Object.keys(jiraProjectIds).length > 0
                ? {
                    name: jiraProjectIds.filter((j) => j.id === jiraProjectId)[0].name,
                    id: jiraProjectIds.filter((j) => j.id === jiraProjectId)[0].id,
                  }
                : { name: '-', id: '-' }
            }
            className="form-field-wrapper"
            currentValue={
              jiraProjectId && jiraProjectIds && Object.keys(jiraProjectIds).length > 0
                ? {
                    name: jiraProjectIds.filter((j) => j.id === jiraProjectId)[0].name,
                    id: jiraProjectIds.filter((j) => j.id === jiraProjectId)[0].id,
                  }
                : { name: '-', id: '-' }
            }
            disabled={!jiraProjectIds}
          />
        </>
      )}
    </>
  );
}
