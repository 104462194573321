import type { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import type { GridPageChangeEvent } from '@progress/kendo-react-grid';
import { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import type { FilterChangeEvent } from '@progress/kendo-react-data-tools';
import { getImageList } from '../../services/dashboard-service';
import type { ApiError } from '../../types/__generated/on-premise-solution/api/apiError.v1';
import type { AssetsImagesDashboardResponse } from '../../types/__generated/on-premise-solution/api/assetsImagesDashboardResponse.v1';
import { handleError } from '../../utils/errors';
import { handleFilteringTest } from '../../utils/filtering-in-table-test';
import type { IPageState } from '../../utils/helpers/types';
import { useNotificationContext } from '../useNotificationContext';
import { useFilterHostChange } from './useFilterHostChange';
import { usePageChange } from './usePageChange';
import { useCustomSearch } from './useCustomSearch';
import type { IUrlParamsList } from '../../utils/helpers/getUrl-list-helper';
import { fillFiltersHostList } from '../../utils/helpers/host-list-fill-filter-helper';
import { useStatesForList } from './useStatesForList';
import { useAccountModalContext } from '../useAccountModalContext';
import { useCommonContext } from '../useCommonContext';

interface IUseImageList {
  handleRefresh: () => void;
  filter: CompositeFilterDescriptor;
  onFilterChange: (event: FilterChangeEvent) => void;
  customSearch: string;
  handleCustomSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  sort: SortDescriptor[];
  page: IPageState;
  total: number | undefined;
  pageChange: (event: GridPageChangeEvent) => void;
  setSort: React.Dispatch<React.SetStateAction<SortDescriptor[]>>;
  dataForHooks: {
    name: string;
    componentName: string;
  };
  isLoading: boolean;
  isFetching: boolean;
  filterStatus: boolean;
  isError: boolean;
  errorMessage: string | undefined;
  dataImage: AssetsImagesDashboardResponse | undefined;
}

export function useImagesList(
  urlParams: IUrlParamsList,
  dataForHooks: {
    name: string;
    componentName: string;
  },
  assetImaegsIds?: string[] | undefined | null,
): IUseImageList {
  const queryClient = useQueryClient();

  const { accountId } = useAccountModalContext();

  const { assetId } = useCommonContext();

  const {
    customSearch,
    setCustomSearch,
    sort,
    setSort,
    page,
    setPage,
    filter,
    setFilter,
    filterStatus,
    setFilterStatus,
    filterVal,
    setFilterVal,
    filterValue,
    filterRef,
  } = useStatesForList(urlParams);

  const { createNotification } = useNotificationContext();

  const debouncedCustomSearch = useCustomSearch(
    setFilterStatus,
    setCustomSearch,
    filter,
    setFilterVal,
    setPage,
    page,
    dataForHooks,
  );

  const filterSearch = useFilterHostChange(
    filterValue,
    setFilter,
    customSearch,
    setCustomSearch,
    setFilterVal,
    filterRef,
    filter,
    setPage,
    urlParams,
  );

  const getPage = usePageChange(setPage, urlParams, dataForHooks);

  const query = useQuery<AssetsImagesDashboardResponse, ApiError>(
    ['ImageList', page, filterVal, sort, assetImaegsIds, accountId.id, assetId],
    () => getImageList(page, filterVal, sort, assetImaegsIds, accountId.id, assetId || ''),
    {
      keepPreviousData: true,
    },
  );

  useEffect(() => {
    fillFiltersHostList(urlParams, filterValue, setFilter, setFilterStatus);
  }, []);

  useEffect(() => {
    if (document.URL.includes('images')) {
      setPage({ skip: urlParams.page.skip, take: urlParams.page.take });
    }
  }, [urlParams.page.skip, urlParams.page.take]);

  useEffect(() => {
    queryClient.invalidateQueries('ImageList');
  }, [queryClient]);

  useEffect(() => {
    let delaySearch: NodeJS.Timeout;

    if (filter?.filters?.length > 0) {
      filterRef.current = filter;
      delaySearch = setTimeout(() => {
        handleFilteringTest(filterRef.current, setFilterVal);
        setFilterStatus(false);
      }, 300);
    }

    return () => clearTimeout(delaySearch);
  }, [filter]);

  const pageChange = (event: GridPageChangeEvent): void => {
    getPage(event);
  };

  useEffect(() => {
    if (query.error) {
      handleError(query.error, createNotification);
    }
  }, [query.isError, createNotification, query.error]);

  const onFilterChange = (event: FilterChangeEvent): void => {
    filterSearch(event);
  };

  function handleCustomSearch(e: React.ChangeEvent<HTMLInputElement>): void {
    debouncedCustomSearch(e);
  }

  const handleRefresh = (): void => {
    setFilterStatus(true);
    query.refetch().then(() => setFilterStatus(false));
  };

  return {
    handleRefresh,
    filter,
    onFilterChange,
    customSearch,
    handleCustomSearch,
    sort,
    page,
    total: query.data?.total,
    pageChange,
    setSort,
    dataForHooks,
    isLoading: query.isLoading,
    isFetching: query.isFetching,
    filterStatus,
    isError: query.isError,
    errorMessage: query?.error?.message,
    dataImage: query.data,
  };
}
