/* eslint-disable jsx-a11y/label-has-associated-control */
import { DropDownList } from '@progress/kendo-react-dropdowns';
import localeTicket from '../../../../../utils/i18n/security/vulnerabilities.json';
import styles from '../../../Security/Tickets/ticket.module.scss';
import { useTicketContext } from '../../../../../hooks/useTicketContext';
import { ticketStatusValueForm } from '../../../../../utils/helpers/constants';
import { CommonTooltip } from '../../../../common/CommonTooltip';
import { useTicketSystemMethods } from '../../../../../hooks/components/useTicketSystemMethods';
import { useTicketSystemContext } from '../../../../../hooks/useTicketSystemContext';

export function StatusMappingBlock(): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeTicket;

  const { ticketSystemType } = useTicketContext();

  const {
    setJiraStatusMapping,
    jiraStatusMapping,
    statuses,
    yandexStatusMapping,
    setYandexStatusMapping,
  } = useTicketSystemContext();

  const { setDefaultStatusName } = useTicketSystemMethods();

  return (
    <div className={styles.statusMappingBlock}>
      <div className={styles.labelWrap}>
        <label className="k-label">
          {localeTicket[currentLocale].ticketSystem.form.statusMapping}
        </label>
        <CommonTooltip
          visibleStyle={{ marginLeft: '2px' }}
          hiddenStyle={{ marginLeft: '32px', marginBottom: '40px' }}
        >
          {localeTicket[currentLocale].ticketSystem.form.mappingStatusInfo}
        </CommonTooltip>
      </div>
      {ticketStatusValueForm.map((s) => {
        const key = s.id as 'open' | 'in-progress' | 'in-review' | 'closed';

        return (
          <div className={styles.ticketStatusWrap} key={s.id}>
            <label className="k-label required-label">{s.name}:</label>
            {ticketSystemType === 'jira' && (
              <DropDownList
                data={statuses || []}
                defaultValue={
                  Object.keys(jiraStatusMapping).length > 0
                    ? {
                        name:
                          (typeof s.id === 'string' &&
                            setDefaultStatusName(jiraStatusMapping[s.id] || '')) ||
                          '',
                      }
                    : { name: '-', id: '-' }
                }
                className={styles.dropDownList}
                textField="name"
                dataItemKey="id"
                onChange={(e): void =>
                  setJiraStatusMapping((prevState) => ({ ...prevState, [s.id]: e.target.value.id }))
                }
                disabled={!statuses}
              />
            )}
            {ticketSystemType === 'yandex_tracker' && yandexStatusMapping && (
              <DropDownList
                data={statuses || []}
                defaultValue={
                  Object.keys(yandexStatusMapping).length > 0
                    ? {
                        name: setDefaultStatusName(String(yandexStatusMapping[key]) || '') || '-',
                      }
                    : { name: '-', id: '-' }
                }
                className={styles.dropDownList}
                textField="name"
                dataItemKey="id"
                onChange={(e): void =>
                  setYandexStatusMapping((prevState) => ({
                    ...prevState,
                    [s.id]: e.target.value.id,
                  }))
                }
                disabled={!statuses}
              />
            )}
          </div>
        );
      })}
    </div>
  );
}
