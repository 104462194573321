/* eslint-disable react/jsx-no-useless-fragment */
import type { GridCellProps } from '@progress/kendo-react-grid';
import { NavLink } from 'react-router-dom';

export function CellLinkWhiteList(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  return (
    <>
      {document.location.pathname.includes('audit/exceptions') ? (
        <td>
          <NavLink to={`/lk/audit/exceptions/${dataItem.auditWhitelistId}`}>
            {dataItem.name}
          </NavLink>
        </td>
      ) : (
        <td>
          <a
            href={`/lk/audit/exceptions/${dataItem.auditWhitelistId}`}
            target="_blank"
            rel="noreferrer"
          >
            {dataItem.name}
          </a>
        </td>
      )}
    </>
  );
}
