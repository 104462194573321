import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
} from '@progress/kendo-react-charts';
import localeDashboard from '../../../../utils/i18n/dashboardLocale/dashboardLayout.json';
import type { UserCountGroupedByTypeResponse } from '../../../../types/__generated/on-premise-solution/api/userCountGroupedByTypeResponse.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeDashboard;

export function UsersInfo({
  data,
}: {
  data: UserCountGroupedByTypeResponse | undefined;
}): React.ReactElement {
  const series = [
    {
      category: `${localeDashboard[currentLocale].usersInfo.local} (${data?.local})`,
      value: data?.local,
      color: '#4caf50',
    },
    {
      category: `${localeDashboard[currentLocale].usersInfo.domain} (${data?.domain})`,
      value: data?.domain,
      color: '#ffc107',
    },
    {
      category: `${localeDashboard[currentLocale].usersInfo.deactivated} (${data?.disabled})`,
      value: data?.disabled,
      color: 'rgba(135,136,141,1)',
    },
  ];

  return (
    <Chart>
      <ChartTooltip
        render={(e: any) => {
          if (e.point?.category) return <span>{e.point.category}</span>;

          return '';
        }}
      />
      <ChartLegend position="bottom" />
      <ChartSeries>
        <ChartSeriesItem
          type="donut"
          data={series}
          field="value"
          tooltip={{ visible: true }}
          categoryField="category"
        />
      </ChartSeries>
    </Chart>
  );
}
