import type { GridCellProps } from '@progress/kendo-react-grid';
import type { TicketSystemDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/ticketSystemsDashboardResponse.v1';
import styles from '../../../Security/Tickets/ticket.module.scss';

export function CellIsDefaultTicketSystem(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { isDefault }: TicketSystemDashboardResponse = dataItem;

  return (
    <td>
      {isDefault ? (
        <span className={`k-button-icon k-icon k-i-check ${styles.weightTitle}`} />
      ) : null}
    </td>
  );
}
