import { Menu, MenuItem, MenuSelectEvent } from '@progress/kendo-react-layout';
import { useCallback } from 'react';
import { useParams } from 'react-router';
import { Button } from '../../../common/baseElements/Button';
import localeHostDescription from '../../../../utils/i18n/assetLocales/hostDescription.json';
import styles from './HostDescription.module.scss';
import { useHostModalContext } from '../../../../hooks/useHostModalContext';
import { useCreateTask } from '../../../../hooks/components/useCreateTask';
import { getUserData } from '../../../../services/local-storage-service';
import { useDifferenceModalContext } from '../../../../hooks/useDifferenceModalContext';
import { useHostActions } from '../../../../hooks/components/useHostActions';
import { useHostDescription } from '../../../../hooks/components/useHostDescription';
import { useTicketMethods } from '../../../../hooks/components/useTicketMethods';
import type { CurrentAssetAccountT } from '../../../../utils/helpers/types';

export function HostDescriptionTopactions({
  setConfirm,
  currentAssetAccount,
}: {
  setConfirm: React.Dispatch<React.SetStateAction<boolean>>;
  currentAssetAccount: CurrentAssetAccountT | undefined;
}): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeHostDescription;

  const { assetId } = useParams<{ assetId: string }>();

  const currentUser = getUserData();

  const { assetIdData, hostname, queryDashboardAssetId, onCredentialChange } = useHostDescription();

  const userRoleCondition =
    assetIdData?.createdBy.source === 'user' &&
    currentUser?.role === 'user' &&
    assetIdData.createdBy.id === currentUser.id;

  const { setOpenHostGroups } = useHostModalContext();

  const { selectCredential } = useHostActions();

  const { setAuditOpen: setDiffAuditOpen, setInvOpen: setDiffInvOpen } =
    useDifferenceModalContext();

  const { createTaskForAsset, handleAsset, createReportHost } = useCreateTask();

  const { handleTicket } = useTicketMethods();

  const handleGroups = useCallback(() => {
    setOpenHostGroups(true);
  }, [setOpenHostGroups]);

  const onSelect = (e: MenuSelectEvent): void => {
    if (e.item.data === 'audit') setDiffAuditOpen(true);
    else if (e.item.data === 'inventorization') setDiffInvOpen(true);
  };

  return (
    <>
      <div className={`button-group ${styles.host_group}`}>
        <Button
          fill="action"
          onClick={(): void => {
            createTaskForAsset({ val: 'default', data: assetIdData, assetId });
          }}
        >
          {localeHostDescription[currentLocale].btnCreateTask}
        </Button>
        <Button onClick={(): void => createReportHost(assetId, hostname)}>
          {localeHostDescription[currentLocale].btnCreateReport}
        </Button>
      </div>
      <div className={`button-group ${styles.host_group}`}>
        <Menu hoverOpenDelay={0} onSelect={onSelect} className="btn-menu">
          <MenuItem text={localeHostDescription[currentLocale].changes.differenceControl}>
            <MenuItem text={localeHostDescription[currentLocale].changes.auditTitle} data="audit" />
            <MenuItem
              text={localeHostDescription[currentLocale].changes.invTitle}
              data="inventorization"
            />
          </MenuItem>
        </Menu>
        {currentUser?.role !== 'reader_user' && (
          <Button
            onClick={handleGroups}
            disabled={
              assetIdData?.createdBy.source === 'user' &&
              currentUser?.role === 'user' &&
              currentUser.id !== assetIdData.createdBy.id
            }
          >
            {localeHostDescription[currentLocale].btnGroups}
          </Button>
        )}
      </div>
      {currentUser?.role !== 'reader_user' && (
        <div className={`button-group ${styles.host_group}`}>
          <Menu
            hoverOpenDelay={0}
            onSelect={(e): Promise<void> => handleAsset(e, queryDashboardAssetId)}
            className="btn-menu"
          >
            <MenuItem
              text={localeHostDescription[currentLocale].agent}
              disabled={
                assetIdData?.createdBy.source === 'user' &&
                currentUser?.role === 'user' &&
                currentUser.id !== assetIdData.createdBy.id
              }
            >
              <MenuItem text={localeHostDescription[currentLocale].agentUpdate} />
              <MenuItem
                disabled={
                  !assetIdData?.agent?.osType && !assetIdData?.latestInventorization?.os.type
                }
                text={
                  !assetIdData?.latestInventorization?.os ||
                  !assetIdData?.latestInventorization?.os.type
                    ? localeHostDescription[currentLocale].agentInstall
                    : localeHostDescription[currentLocale].agentReinstall
                }
              />
            </MenuItem>
          </Menu>
          {(!assetIdData?.credential || !assetIdData?.credentialId) &&
            (currentUser?.role === 'super_admin' ||
              currentUser?.role === 'account_admin' ||
              userRoleCondition) && (
              <Button
                onClick={(): void =>
                  selectCredential({
                    assetId: assetIdData?.assetId,
                    accountId: assetIdData?.accountId,
                    assetGroupIds: assetIdData?.assetGroupIds,
                    credentialId:
                      assetIdData?.type === 'host'
                        ? assetIdData?.credential?.credentialId
                        : undefined,
                    osType:
                      assetIdData?.type === 'host'
                        ? assetIdData?.latestInventorization?.os.type
                        : undefined,
                    assetType: 'host',
                  })
                }
                disabled={
                  !assetIdData?.latestInventorization?.os ||
                  !assetIdData?.latestInventorization?.os.type
                }
              >
                {localeHostDescription[currentLocale].btnAddCredential}
              </Button>
            )}
          {assetIdData &&
            assetIdData.type === 'host' &&
            assetIdData.credential &&
            assetIdData.credentialId &&
            (currentUser?.role === 'super_admin' ||
              currentUser?.role === 'account_admin' ||
              userRoleCondition) && (
              <Menu hoverOpenDelay={0} onSelect={onCredentialChange} className="btn-menu">
                <MenuItem
                  text={localeHostDescription[currentLocale].summary.credential}
                  disabled={
                    assetIdData?.createdBy.source === 'user' &&
                    currentUser?.role === 'user' &&
                    currentUser.id !== assetIdData?.createdBy.id
                  }
                >
                  <MenuItem
                    data="edit"
                    text={localeHostDescription[currentLocale].summary.editCredetial}
                  />
                  <MenuItem
                    data="changeCredential"
                    text={localeHostDescription[currentLocale].summary.changeCredential}
                  />
                  {assetIdData?.credential.type === 'ssh' && (
                    <MenuItem
                      data="changeSsh"
                      text={localeHostDescription[currentLocale].summary.changeSsh}
                    />
                  )}
                  {assetIdData?.credential.type === 'winrm' && (
                    <MenuItem
                      data="changeSmbPort"
                      text={localeHostDescription[currentLocale].summary.changesmbPort}
                    />
                  )}
                  {assetIdData?.credential.type === 'winrm' && (
                    <MenuItem
                      data="changeWinRmPort"
                      text={localeHostDescription[currentLocale].summary.changewinrmPort}
                    />
                  )}
                  <MenuItem
                    data="delete"
                    text={localeHostDescription[currentLocale].summary.deleteCredential}
                  />
                </MenuItem>
              </Menu>
            )}
        </div>
      )}
      <Button onClick={(): void => handleTicket(currentAssetAccount)}>
        {localeHostDescription[currentLocale].createTicket}
      </Button>
      {currentUser?.role !== 'reader_user' && (
        <Button
          onClick={(): void => setConfirm(true)}
          fill="delete"
          disabled={
            assetIdData?.createdBy.source === 'user' &&
            currentUser?.role === 'user' &&
            currentUser.id !== assetIdData.createdBy.id
          }
        >
          {localeHostDescription[currentLocale].btnDelete}
        </Button>
      )}
    </>
  );
}
