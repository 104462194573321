import { getUserData } from '../../../../../services/local-storage-service';
import type { TicketDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/ticketsDashboardResponse.v1';
import localeTicket from '../../../../../utils/i18n/security/vulnerabilities.json';

export function TableThTicketMetaBlock({
  data,
  currentLocale,
}: {
  data: TicketDashboardResponse | undefined;
  currentLocale: 'ru-RU' | 'en-EN';
}): React.ReactElement {
  const currentUser = getUserData();

  return (
    <table className="column column-first">
      <tbody>
        <tr>
          <td>
            <span>{localeTicket[currentLocale].tickets.description.metablock.createdAt}</span>
          </td>
          <td>{data?.createdAt ? new Date(data?.createdAt).toLocaleString() : '-'}</td>
        </tr>
        <tr>
          <td>
            <span>{localeTicket[currentLocale].tickets.description.metablock.createdBy}</span>
          </td>
          <td>{data?.createdBy.source === 'user' ? data?.createdBy.name : '-'}</td>
        </tr>
        {currentUser?.role === 'super_admin' && (
          <tr>
            <td>
              <span>{localeTicket[currentLocale].tickets.description.metablock.account}</span>
            </td>
            <td>{data?.account.name || '-'}</td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
