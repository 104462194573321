import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { useState, type ReactElement } from 'react';
import { useQuery } from 'react-query';
import type { AccountsDashboardResponse } from '../../../types/__generated/on-premise-solution/api/accountsDashboardResponse.v1';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
import { getAccountsForList } from '../../../services/account-service';
import { getAccountId, getUserData } from '../../../services/local-storage-service';
import localeAccountList from '../../../utils/i18n/accountLocales/accountList.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAccountList;

export function AccountChangeDropDown({
  selectedAccountId,
  onChange,
  hasAllAccounts,
}: {
  selectedAccountId?: string;
  onChange?: (event: DropDownListChangeEvent) => void;
  hasAllAccounts?: boolean;
}): ReactElement {
  const currentUser = getUserData();
  const currentAccountId = getAccountId();

  const [accountList, setAccountList] = useState<{ text: string; id: string }[]>([]);

  useQuery<AccountsDashboardResponse, ApiError>(['accountList'], () => getAccountsForList(), {
    keepPreviousData: true,
    enabled: currentUser?.role === 'super_admin',
    onSuccess: (resp) => {
      setAccountList(
        hasAllAccounts
          ? [{ text: localeAccountList[currentLocale].allAccounts, id: '' }].concat(
              resp.data.map((accountInfo) => ({
                text: accountInfo.name,
                id: accountInfo.accountId,
              })),
            )
          : resp.data.map((accountInfo) => ({ text: accountInfo.name, id: accountInfo.accountId })),
      );
    },
  });

  return currentUser?.role === 'super_admin' ? (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        padding: '0 12px',
      }}
    >
      <span>{localeAccountList[currentLocale].titleForColumnGrid.name}:</span>
      <DropDownList
        style={{
          width: '180px',
        }}
        value={
          accountList.find((accountInfo) => accountInfo.id === selectedAccountId) ||
          accountList.find((accountInfo) => accountInfo.id === currentAccountId)
        }
        onChange={
          onChange
          //     (event: DropDownListChangeEvent): void => {
          //     setSelectedAccountId(event.target.value.id);
          //     queryClient.invalidateQueries('softwareInfo');
          // }
        }
        textField="text"
        data={accountList}
      />
    </div>
  ) : (
    <></>
  );
}
