/* eslint-disable react/jsx-no-useless-fragment */
import type { GridCellProps } from '@progress/kendo-react-grid';
import { NavLink } from 'react-router-dom';
import type { TicketActionDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/ticketActionsDashboardResponse.v1';

export function CellTicketAsset(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { assetId, asset }: TicketActionDashboardResponse = dataItem;

  return (
    <td>
      {asset && asset instanceof Object && 'type' in asset ? (
        <>
          {asset.type === 'host' && asset.hostname && (
            <NavLink
              className="link-underline"
              to={`/lk/assets/hosts/${assetId}`}
              title={asset.hostname}
            >
              {asset.hostname}
            </NavLink>
          )}
          {(asset.type === 'router' ||
            asset.type === 'firewall' ||
            asset.type === 'switch' ||
            asset.type === 'hypervisor') &&
            asset.hostname && (
              <NavLink
                className="link-underline"
                to={`/lk/assets/hosts/${assetId}`}
                title={asset.hostname}
              >
                {asset.hostname}
              </NavLink>
            )}
          {(asset.type === 'router' ||
            asset.type === 'firewall' ||
            asset.type === 'switch' ||
            asset.type === 'hypervisor') &&
            !asset.hostname && (
              <NavLink
                className="link-underline"
                to={`/lk/assets/hosts/${assetId}`}
                title={assetId}
              >
                {assetId}
              </NavLink>
            )}
          {asset && asset instanceof Object && asset.type === 'image' && asset.imageNames && (
            <NavLink
              className="link-underline"
              to={`/lk/assets/images/${assetId}`}
              title={asset.imageNames[0]}
            >
              {asset.imageNames[0]}
            </NavLink>
          )}
        </>
      ) : (
        <span>{assetId}</span>
      )}
    </td>
  );
}
