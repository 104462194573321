import { Grid, GridColumn as Column, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { PAGEABLE_DATA } from '../../utils/helpers/constants';
import type { ICommonTicketList } from '../../utils/helpers/types';
import { handleSort } from '../../utils/sorting-in-table';
import localeTicket from '../../utils/i18n/security/vulnerabilities.json';
import { StandartCell } from './baseElements/StandartCell';
import { CellCommonDate } from './baseElements/CellCommonDate';
import { CellMenuTicketList } from '../features/Security/Tickets/cells/CellMenuTicketList';
import { getUserData } from '../../services/local-storage-service';
import { CellTicketName } from '../features/Security/Tickets/cells/CellTicketName';
import { CellTicketDeadlineDate } from '../features/Security/Tickets/cells/CellTicketDeadlineDate';
import { CellTicketStatus } from '../features/Security/Tickets/cells/CellTicketStatus';
import { CellTicketPriority } from '../features/Security/Tickets/cells/CellTicketPriority';
import { CellTicketProgress } from '../features/Security/Tickets/cells/CellTicketProgress';

export function CommonTicketList(props: ICommonTicketList): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeTicket;

  const { sort, data, page, total, pageChange, setSort } = props;

  const currentUser = getUserData();

  return (
    <LocalizationProvider language={currentLocale}>
      <IntlProvider locale={currentLocale.substring(0, 2)}>
        <Grid
          pageable={total && total > 15 ? PAGEABLE_DATA : false}
          sortable
          sort={sort}
          data={data}
          skip={page.skip}
          take={page.take}
          total={total || 0}
          onPageChange={pageChange}
          onSortChange={(e: GridSortChangeEvent): void => handleSort(e, setSort)}
          scrollable="none"
          className="no-pageable-k-grid"
        >
          <Column
            field="status"
            title={localeTicket[currentLocale].tickets.grid.status}
            filter="text"
            width="144px"
            cell={CellTicketStatus}
          />
          <Column
            field="summary"
            title={localeTicket[currentLocale].tickets.grid.title}
            filter="text"
            width="240px"
            cell={CellTicketName}
          />
          <Column
            field="assignedTo.name"
            title={localeTicket[currentLocale].tickets.grid.responsible}
            filter="text"
            cell={StandartCell}
          />
          <Column
            field="deadlineDate"
            title={localeTicket[currentLocale].tickets.grid.deadline}
            filter="text"
            cell={CellTicketDeadlineDate}
            width="100px"
          />
          <Column
            field="priority"
            title={localeTicket[currentLocale].tickets.grid.priority}
            filter="text"
            cell={CellTicketPriority}
            width="100px"
          />
          <Column
            field="actionsProgress"
            filter="text"
            title={localeTicket[currentLocale].tickets.grid.actionsProgress}
            cell={CellTicketProgress}
            width="120px"
          />
          <Column
            field="createdAt"
            title={localeTicket[currentLocale].tickets.grid.createdAt}
            filter="text"
            cell={CellCommonDate}
            width="160px"
          />
          <Column
            field="ticketSystem.name"
            title={localeTicket[currentLocale].tickets.grid.ticketSystem}
            filter="text"
            cell={StandartCell}
            className="title"
          />
          {currentUser?.role === 'super_admin' && (
            <Column
              field="account.name"
              title={localeTicket[currentLocale].tickets.grid.organization}
              filter="text"
            />
          )}
          <Column width="40px" cell={CellMenuTicketList} />
        </Grid>
      </IntlProvider>
    </LocalizationProvider>
  );
}
