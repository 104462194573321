import '../TaskDescription.module.scss';
import { Filter, FilterChangeEvent } from '@progress/kendo-react-data-tools';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import {
  Grid,
  GridColumn as Column,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid';
import type { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useQuery, useQueryClient } from 'react-query';
import {
  DATA_CACHE_STALETIME,
  FILTER_INITIAL,
  PAGEABLE_DATA_LOCAL,
} from '../../../../utils/helpers/constants';
import localeTaskDescription from '../../../../utils/i18n/taskLocale/taskDescription.json';
import type { IDataHooks, IPageState } from '../../../../utils/helpers/types';
import { CellStatus } from './cellResult/CellStatus';
import { CellStartedAt } from './cellResult/CellStartedAt';
import { CellLastActivity } from './cellResult/CellLastActivity';
import { CellActions } from './cellResult/CellActions';
import { handleSort } from '../../../../utils/sorting-in-table';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { getResultTaskActions } from '../../../../services/task-service';
import { handleError } from '../../../../utils/errors';
import { useNotificationContext } from '../../../../hooks/useNotificationContext';
import { СommonTableLoader } from '../../../common/tableLoader/CommonTableLoader';
import type { TaskActionsDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/taskActionsDashboardResponse.v1';
import { getUrlParamsResult } from '../../../../utils/helpers/result-task-helper';
import { handleFilteringTest, IFilterVal } from '../../../../utils/filtering-in-table-test';
import { usePageChange } from '../../../../hooks/components/usePageChange';
import { useFilter } from '../../../../hooks/components/useFilter';
import { FILTER_RESULT_TASK } from '../../../../utils/helpers/constant-serv-filter-task';
import { CellConsole } from './cellResult/CellConsole';
import { ActionHeaderCell } from '../cellsTaskList/ActionHeaderCell';
import { CellLog } from './cellResult/CellLog';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeTaskDescription;

export function Results(): React.ReactElement {
  const urlParams = getUrlParamsResult('Result');

  const queryClient = useQueryClient();

  const [customSearch, setCustomSearch] = useState('');

  const [sort, setSort] = useState<SortDescriptor[]>(urlParams.sort);

  const [page, setPage] = useState<IPageState>(urlParams.page);

  const [filter, setFilter] = useState<CompositeFilterDescriptor>(FILTER_INITIAL);

  const [filterStatus, setFilterStatus] = useState(false);

  const [filterVal, setFilterVal] = useState<IFilterVal[]>([]);

  const [staleTimeVal, setStaleTimeVal] = useState(DATA_CACHE_STALETIME);

  const filterValue = useRef<CompositeFilterDescriptor | null>(null);

  const filterRef = useRef<CompositeFilterDescriptor>(filter);

  const { taskExecutionId } = useParams<{ taskExecutionId: string }>();

  const dataForHooks: IDataHooks = {
    name: '',
    componentName: 'Result',
    urlPath: `/lk/task/executions/${taskExecutionId}`,
  };

  const { createNotification } = useNotificationContext();

  const getPage = usePageChange(setPage, urlParams, dataForHooks);

  const query = useQuery<TaskActionsDashboardResponse, ApiError>(
    ['taskActions', taskExecutionId, page, sort, filterVal],
    () => getResultTaskActions(taskExecutionId, page, sort, filterVal),
    {
      keepPreviousData: true,
    },
  );

  useEffect(() => {
    let delaySearch: NodeJS.Timeout;

    if (!filter || filter.filters.length === 0) {
      setStaleTimeVal(DATA_CACHE_STALETIME);
      handleFilteringTest(filter, setFilterVal);
      filterRef.current = filter;
      setFilterStatus(false);
    }

    if (filter?.filters?.length > 0) {
      filterRef.current = filter;
      delaySearch = setTimeout(() => {
        handleFilteringTest(filterRef.current, setFilterVal);
        setFilterStatus(false);
      }, 300);
    }

    return () => clearTimeout(delaySearch);
  }, [filter, page.skip, taskExecutionId]);

  useEffect(() => {
    queryClient.invalidateQueries('taskActions');
    setPage({ skip: 0, take: page.take });
  }, []);

  useEffect(() => {
    if (query.error) {
      handleError(query.error, createNotification);
    }
  }, [query.isError, query.error, createNotification]);

  const filterSearch = useFilter(
    filterValue,
    setFilter,
    customSearch,
    setCustomSearch,
    setFilterVal,
    filterRef,
    filter,
    setPage,
    page,
    dataForHooks,
  );

  const pageChange = (event: GridPageChangeEvent): void => {
    getPage(event);
  };

  const onFilterChange = (event: FilterChangeEvent): void => {
    filterSearch(event);
  };

  const checkConsole = query.data?.data.find((val) => val.type === 'package_update') || undefined;

  const checkLogKbInstall = query.data?.data.find((val) => val.type === 'kb_install') || undefined;

  const checkLogSuUpdate =
    query.data?.data.find((val) => val.type === 'windows_software_management') || undefined;

  return (
    <>
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <div className="filter-line">
            <Filter
              value={filter}
              onChange={onFilterChange}
              fields={FILTER_RESULT_TASK[currentLocale]}
            />
          </div>
        </IntlProvider>
      </LocalizationProvider>
      {query.data?.data && (
        <div
          className="description-result"
          style={{ paddingBottom: query.data?.data.length <= 9 ? '80px' : '' }}
        >
          <LocalizationProvider language={currentLocale}>
            <IntlProvider locale={currentLocale.substring(0, 2)}>
              <Grid
                pageable={query.data?.total > 10 ? PAGEABLE_DATA_LOCAL : false}
                skip={page.skip}
                take={page.take}
                sortable
                sort={sort}
                data={query.data}
                total={query.data?.total}
                onPageChange={pageChange}
                onSortChange={(e: GridSortChangeEvent): void => handleSort(e, setSort)}
                scrollable="none"
                className={
                  Number(query?.data?.total) && Number(query?.data?.total) < 10
                    ? 'no-pageable-k-grid'
                    : ''
                }
              >
                <Column
                  field="status"
                  title={localeTaskDescription[currentLocale].formResult.status}
                  cell={CellStatus}
                  width={checkConsole ? '168px' : '68px'}
                />
                <Column
                  field="startedAt"
                  title={localeTaskDescription[currentLocale].formResult.start}
                  cell={CellStartedAt}
                  filter="text"
                  width={checkConsole ? '200px' : '172px'}
                />
                <Column
                  field="lastActivity"
                  title={localeTaskDescription[currentLocale].formResult.lastActivity}
                  cell={CellLastActivity}
                  width={checkConsole ? '200px' : '190px'}
                />
                <Column
                  field="type"
                  title={localeTaskDescription[currentLocale].formResult.actions}
                  cell={CellActions}
                  headerCell={ActionHeaderCell}
                />
                {checkConsole && (
                  <Column
                    field="console"
                    title={localeTaskDescription[currentLocale].formResult.console}
                    filter="text"
                    cell={CellConsole}
                    width="88px"
                  />
                )}
                {(checkLogKbInstall || checkLogSuUpdate) && (
                  <Column
                    field="logs"
                    title={localeTaskDescription[currentLocale].formResult.logs}
                    filter="text"
                    cell={CellLog}
                    width="88px"
                    className={checkLogKbInstall ? 'kb' : 'su'}
                  />
                )}
              </Grid>
              <СommonTableLoader queryData={query.isLoading} filteringData={filterStatus} />
            </IntlProvider>
          </LocalizationProvider>
        </div>
      )}
    </>
  );
}
