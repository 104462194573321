import { Grid, GridColumn as Column, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { PAGEABLE_DATA } from '../../utils/helpers/constants';
import type { ICommonTicketSystemList } from '../../utils/helpers/types';
import { handleSort } from '../../utils/sorting-in-table';
import localeTicket from '../../utils/i18n/security/vulnerabilities.json';
import { StandartCell } from './baseElements/StandartCell';
import { CellMenuTicketSystemList } from '../features/Settings/ticketSystem/cells/CellMenuTicketSystemList';
import { CellIsDefaultTicketSystem } from '../features/Settings/ticketSystem/cells/CellIsDefaultTicketSystem';
import { CellOpenAllInTicketSystem } from '../features/Settings/ticketSystem/cells/CellOpenAllInTicketSystem';
import { TicketHeaderCell } from '../features/Settings/ticketSystem/cells/TicketHeaderCell';

export function CommonTicketSystemList(props: ICommonTicketSystemList): React.ReactElement {
  const { sort, data, page, total, pageChange, setSort, dataForHooks, parentComponent } = props;

  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeTicket;

  return (
    <LocalizationProvider language={currentLocale}>
      <IntlProvider locale={currentLocale.substring(0, 2)}>
        <Grid
          pageable={total && total > 15 ? PAGEABLE_DATA : false}
          sortable
          sort={sort}
          data={data}
          skip={page.skip}
          take={page.take}
          total={total || 0}
          onPageChange={pageChange}
          onSortChange={(e: GridSortChangeEvent): void => handleSort(e, setSort)}
          scrollable="none"
          className={total && total < 15 ? 'no-pageable-k-grid' : ''}
        >
          <Column
            field="name"
            title={localeTicket[currentLocale].ticketSystem.grid.name}
            filter="text"
          />
          <Column
            field="type"
            title={localeTicket[currentLocale].ticketSystem.grid.type}
            filter="text"
            width="140px"
          />
          <Column
            field="totalTicketCount"
            title={localeTicket[currentLocale].ticketSystem.grid.tickets}
            filter="text"
            width="260px"
            cell={CellOpenAllInTicketSystem}
            headerCell={TicketHeaderCell}
          />
          <Column
            field="refreshTicketsSystemTask.cron"
            title={localeTicket[currentLocale].ticketSystem.grid.statusUpdatePeriod}
            filter="text"
            cell={StandartCell}
            width="260px"
          />
          <Column
            field="account.name"
            title={localeTicket[currentLocale].ticketSystem.grid.organization}
            filter="text"
            width="300px"
          />
          <Column
            field="isDefault"
            width="164px"
            title="По умолчанию"
            cell={CellIsDefaultTicketSystem}
          />
          <Column width="40px" cell={CellMenuTicketSystemList} />
        </Grid>
      </IntlProvider>
    </LocalizationProvider>
  );
}
