import { useTicketContext } from '../../../../../hooks/useTicketContext';
import { Button } from '../../../../common/baseElements/Button';
import { useAccountModalContext } from '../../../../../hooks/useAccountModalContext';
import localeUserList from '../../../../../utils/i18n/userLocales/userList.json';
import { getUserData } from '../../../../../services/local-storage-service';
import { useTicketSystemMethods } from '../../../../../hooks/components/useTicketSystemMethods';
import { useTicketSystemContext } from '../../../../../hooks/useTicketSystemContext';

export function TicketButtonsForm(): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeUserList;

  const currentUser = getUserData();

  const { ticketSystemType } = useTicketContext();

  const {
    credentialTicket,
    editTicketSystemData,
    name,
    apiBaseUrl,
    jiraProjectId,
    jiraStatusMapping,
    jiraPriorityMapping,
    yandexTrackerQueueId,
    yandexStatusMapping,
    yandexPriorityMapping,
  } = useTicketSystemContext();

  const { handleSubmit, handleClose } = useTicketSystemMethods();

  const { accountId } = useAccountModalContext();

  return (
    <div className="k-form-buttons">
      <Button
        type="submit"
        disabled={
          (!editTicketSystemData &&
            ((currentUser?.role === 'super_admin' && accountId.id === '000') ||
              !name ||
              !apiBaseUrl ||
              !credentialTicket?.credentialId ||
              (ticketSystemType === 'jira' && !jiraProjectId) ||
              (ticketSystemType === 'jira' && Object.keys(jiraStatusMapping).length < 4) ||
              (ticketSystemType === 'jira' && Object.keys(jiraPriorityMapping).length < 4))) ||
          (ticketSystemType === 'yandex_tracker' && !yandexTrackerQueueId) ||
          (ticketSystemType === 'yandex_tracker' &&
            !!yandexStatusMapping &&
            Object.keys(yandexStatusMapping).length < 4) ||
          (ticketSystemType === 'yandex_tracker' &&
            !!yandexPriorityMapping &&
            Object.keys(yandexPriorityMapping).length < 4)
        }
        onClick={handleSubmit}
      >
        {!editTicketSystemData
          ? localeUserList[currentLocale].modal.btnAdd
          : localeUserList[currentLocale].modal.btnSave}
      </Button>
      <Button type="button" onClick={handleClose}>
        {localeUserList[currentLocale].modal.btnCancel}
      </Button>
    </div>
  );
}
