/* eslint-disable jsx-a11y/label-has-associated-control */
import { RadioButton } from '@progress/kendo-react-inputs';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import localeNewTask from '../../../../utils/i18n/taskLocale/newTask.json';
import cronLocale from '../../../../utils/i18n/commonLocales/cronCalculator.json';
import { NEWTASK_VAL_START_TIME } from '../../../../utils/helpers/constants';
import CronTask from './CronTask';
import { useTaskAddContext } from '../../../../hooks/useTaskAddContext';
import styles from './NewTask.module.scss';
import { CheckBox } from '../../../common/form/CheckBox';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeNewTask;

export function SelectTimeTask(): React.ReactElement {
  const min = new Date();

  const { handleDatePicker, handleChange, taskTime, isAssetTimezone, setAssetTimezone } =
    useTaskAddContext();

  return (
    <div className="newtask__time">
      <label className="k-label">{localeNewTask[currentLocale].form.startTime.title}</label>
      <div className={styles.radioWrap}>
        <RadioButton
          name="group1"
          value="now"
          checked={taskTime === 'now'}
          label={NEWTASK_VAL_START_TIME[currentLocale][0].label}
          onChange={handleChange}
        />
      </div>
      <div className="newtask__datepicker">
        <div className={styles.radioWrap}>
          <RadioButton
            name="group1"
            value="startAt"
            checked={taskTime === 'startAt'}
            label={NEWTASK_VAL_START_TIME[currentLocale][1].label}
            onChange={handleChange}
          />
        </div>
        {taskTime === 'startAt' && (
          <div className={[styles.timeValueWrap, styles.inputWithUseAssetTimezone].join(' ')}>
            <LocalizationProvider language={currentLocale}>
              <IntlProvider locale={currentLocale.substring(0, 2)}>
                <DatePicker
                  format="dd-MMM-yyyy HH:mm:ss"
                  focusedDate={new Date()}
                  name="startAt"
                  onChange={(e): void => handleDatePicker(e.target.value)}
                  placeholder={localeNewTask[currentLocale].form.todoActions.specifyTheDate}
                  min={min}
                  width={240}
                />
                <CheckBox
                  name="useAssetLocalTime"
                  checked={isAssetTimezone}
                  value
                  onChange={(): void => setAssetTimezone(!isAssetTimezone)}
                  label={localeNewTask[currentLocale].form.todoActions.useAssetLocalTime}
                />
              </IntlProvider>
            </LocalizationProvider>
          </div>
        )}
      </div>
      <RadioButton
        name="group1"
        value="scheduled"
        checked={taskTime === 'scheduled'}
        label={NEWTASK_VAL_START_TIME[currentLocale][2].label}
        onChange={handleChange}
      />
      {taskTime === 'scheduled' && (
        <div className={styles.timeValueWrap}>
          <CronTask label={cronLocale[currentLocale].utcTimeCalculator} chooseAssetTimeZone />
        </div>
      )}
    </div>
  );
}
