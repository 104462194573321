/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import type { GridCellProps } from '@progress/kendo-react-grid';
import { IMG_SRC_VALUES } from '../../../../utils/helpers/constants';
import { getMainPartOfOsName } from '../../../../utils/helpers/inventorization-helper';
import type { OperationSystemsResponse } from '../../../../types/__generated/on-premise-solution/api/operationSystemsResponse.v1';

export function CellOs(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { osName }: OperationSystemsResponse['data'][number] = dataItem;

  let osLogoName = '';
  if (osName) {
    osLogoName = getMainPartOfOsName(osName);
  }
  if ('class' in dataItem) {
    osLogoName =
      dataItem.class.toLowerCase() === 'pa-vm'
        ? 'paloaltonetworks'
        : dataItem.class.toLowerCase().includes('vmware')
        ? 'vmware'
        : dataItem.class.toLowerCase();
  }

  return (
    <td>
      {osLogoName ? (
        <div className="cell_os">
          <img
            src={`${process.env.PUBLIC_URL}/images/os_logo/${
              IMG_SRC_VALUES[osLogoName.toLowerCase()]
            }`}
            alt={osName}
          />
          <span>{osName}&nbsp;</span>
        </div>
      ) : (
        <span>-</span>
      )}
    </td>
  );
}
