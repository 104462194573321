import styles from '../../../Task/TaskList.module.scss';
import { useAuditModalContext } from '../../../../../hooks/useAuditModalContext';
import { Button } from '../../../../common/baseElements/Button';
import { handleVulnModal } from '../../../../../utils/helpers/audit-helpers';

export function ScopeBulletin({
  bulletin,
  className,
}: {
  bulletin: string;
  className?: string;
}): React.ReactElement {
  const { setCveName, setOpen } = useAuditModalContext();

  return (
    <div className={`${styles.scope} ${className || ''}`} title={bulletin}>
      <span className={`k-icon hosts-menu-icon ${styles.scope_type}`} />
      <Button fill="modal" onClick={(): void => handleVulnModal(bulletin, setOpen, setCveName)}>
        {bulletin}
      </Button>
    </div>
  );
}
