import type { GridCellProps } from '@progress/kendo-react-grid';
import { NavLink } from 'react-router-dom';
import type { OperationSystemsResponse } from '../../../../types/__generated/on-premise-solution/api/operationSystemsResponse.v1';

function getUrlFromField(field: string | undefined): string | undefined {
  const basepath = '/lk/assets/';
  if (field === 'totalHostAssets') return `${basepath}hosts`;
  if (field === 'totalImageAssets') return `${basepath}images`;
  if (field === 'totalOtherAssets') return `${basepath}devices`;

  return undefined;
}

export function CellTotalAssetsByOs(props: GridCellProps): React.ReactElement {
  const { dataItem, field } = props;

  const { totalHostAssets, osName, osVersion }: OperationSystemsResponse['data'][number] = dataItem;
  const pathname = getUrlFromField(field);

  return (
    <td className="number">
      <span>
        {field && field in dataItem && typeof totalHostAssets !== 'undefined' && pathname ? (
          <NavLink
            to={`${pathname}?skip=0&limit=15&sortField=latestAudit.maxCvssScore&sortOrder=desc&latestInventorization.os.name[$regex]=${osName}&latestInventorization.os.version[$regex]=${osVersion}`}
          >
            {dataItem[field]}
          </NavLink>
        ) : (
          <span>-</span>
        )}
      </span>
    </td>
  );
}
