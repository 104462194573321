import type { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import type { GridPageChangeEvent } from '@progress/kendo-react-grid';
import type { MenuSelectEvent } from '@progress/kendo-react-layout';
import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import type { FilterChangeEvent } from '@progress/kendo-react-data-tools';
import { getAgentInstaller } from '../../services/asset-service';
import { getAgentsStatus, getAssetList } from '../../services/dashboard-service';
import type { AgentsStatusResponse } from '../../types/__generated/on-premise-solution/api/agentsStatusResponse.v1';
import type { ApiError } from '../../types/__generated/on-premise-solution/api/apiError.v1';
import { handleError } from '../../utils/errors';
import { handleFilteringTest } from '../../utils/filtering-in-table-test';
import { AGENT_REFETCH_INTERVAL } from '../../utils/helpers/constants';
import { fillFiltersHostList } from '../../utils/helpers/host-list-fill-filter-helper';
import type { IUrlParamsHosts } from '../../utils/helpers/host-list-helper';
import type { IDashboard, IPageState } from '../../utils/helpers/types';
import { useHostCreateContext } from '../useHostCreateContext';
import { useNotificationContext } from '../useNotificationContext';
import { useCustomSearchHost } from './useCustomSearchHost';
import { useFilterHostChange } from './useFilterHostChange';
import { usePageChange } from './usePageChange';
import { handleAddAllAsset } from '../../utils/helpers/add-host-list-helper';
import { useHostModalContext } from '../useHostModalContext';
import { getAccountId } from '../../services/local-storage-service';
import type {
  AssetsHostItemDashboardResponse,
  AssetsHostsDashboardResponse,
} from '../../types/__generated/on-premise-solution/api/assetsHostsDashboardResponse.v1';
import { useStatesForList } from './useStatesForList';
import { useSensorContext } from '../useSensorContext';
import { useAccountModalContext } from '../useAccountModalContext';
import { useCommonContext } from '../useCommonContext';

interface IUseHostList {
  handleAsset: (e: MenuSelectEvent, parentId?: string) => Promise<void>;
  handleRefresh: () => void;
  filter: CompositeFilterDescriptor;
  onFilterChange: (event: FilterChangeEvent) => void;
  customSearch: string;
  handleCustomSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  sort: SortDescriptor[];
  dataDashboard: IDashboard[] | AssetsHostItemDashboardResponse[] | undefined;
  page: IPageState;
  total: number | undefined;
  pageChange: (event: GridPageChangeEvent) => void;
  setSort: React.Dispatch<React.SetStateAction<SortDescriptor[]>>;
  dataForHooks: {
    name: string;
    componentName: string;
  };
  isLoading: boolean;
  isFetching: boolean;
  filterStatus: boolean;
  isError: boolean;
  errorMessage: string | undefined;
  addAllAsset: () => void;
  cancelAllAsset: () => void;
  handleExit: () => void;
  addSelectedAssets: () => void;
  cancelAssetTypeHost: () => void;
}

export function useHostList(
  urlParams: IUrlParamsHosts,
  dataForHooks: {
    name: string;
    componentName: string;
  },
  assetHostIds?: string[] | undefined | null,
  osType?: string,
): IUseHostList {
  const queryClient = useQueryClient();

  const currentAccountId = getAccountId();

  const agentsId = [] as Array<string>;

  const {
    customSearch,
    setCustomSearch,
    sort,
    setSort,
    page,
    setPage,
    filter,
    setFilter,
    filterStatus,
    setFilterStatus,
    filterVal,
    setFilterVal,
    filterValue,
    filterRef,
  } = useStatesForList(urlParams);

  const [dataDashboard, setDataDashboard] = useState<
    // IDashboard[] | AssetDashboardItem[] | undefined
    IDashboard[] | AssetsHostItemDashboardResponse[] | undefined
  >([]);

  const { createNotification } = useNotificationContext();

  const { setHostCreateData } = useHostCreateContext();

  const { setOpen, hostFormTemp, setHostForm, setHostFormTemp } = useHostModalContext();

  const { setDropdownParentId } = useSensorContext();

  const { accountId } = useAccountModalContext();

  const { assetId } = useCommonContext();

  const debouncedCustomSearch = useCustomSearchHost(
    setFilterStatus,
    setCustomSearch,
    filter,
    setFilterVal,
    setPage,
    page,
    urlParams,
  );

  const filterSearch = useFilterHostChange(
    filterValue,
    setFilter,
    customSearch,
    setCustomSearch,
    setFilterVal,
    filterRef,
    filter,
    setPage,
    urlParams,
  );

  const getPage = usePageChange(setPage, urlParams, dataForHooks);

  const query = useQuery<AssetsHostsDashboardResponse, ApiError>(
    [
      dataForHooks.componentName === 'SensorHostListTab' ? 'sensorHostList' : 'hostList',
      page,
      filterVal,
      sort,
      assetHostIds,
      osType,
      accountId.id,
      assetId,
    ],
    () => getAssetList(page, filterVal, sort, assetHostIds, osType, accountId.id, assetId || ''),
    {
      keepPreviousData: true,
      // enabled: !(
      //   dataForHooks.componentName === 'AssetListTab' &&
      //   Array.isArray(assetHostIds) &&
      //   !assetHostIds.length
      // ),
    },
  );

  useEffect(() => {
    fillFiltersHostList(urlParams, filterValue, setFilter, setFilterStatus);
  }, []);

  useEffect(() => {
    if (urlParams.filterUrlData.tagsName && urlParams.filterUrlData.tagsName !== '') {
      setPage({ skip: urlParams.page.skip, take: urlParams.page.take });
      fillFiltersHostList(urlParams, filterValue, setFilter, setFilterStatus);
    }
  }, [urlParams.filterUrlData.tagsName]);

  useEffect(() => {
    queryClient.invalidateQueries('hostList');
  }, [queryClient]);

  useEffect(() => {
    let delaySearch: NodeJS.Timeout;

    if (filter?.filters?.length > 0) {
      filterRef.current = filter;
      delaySearch = setTimeout(() => {
        handleFilteringTest(filterRef.current, setFilterVal);
        setFilterStatus(false);
      }, 300);
    }

    return () => clearTimeout(delaySearch);
  }, [filter]);

  if (query?.data?.data) {
    query.data.data.forEach((q) => {
      if (q.type === 'host' && q.agentId) agentsId.push(q.agentId);
    });
  }
  const agentsQuery = useQuery<AgentsStatusResponse, ApiError>(
    ['agentStatus', agentsId],
    () => getAgentsStatus(agentsId),
    {
      refetchInterval: AGENT_REFETCH_INTERVAL,
    },
  );

  useEffect(() => {
    const dashboardData = query.data?.data.map((q) => {
      if (q.type === 'host' && q.agentId) {
        const agentStatus = agentsQuery.data?.find((f) => f.agentId === q.agentId);

        return {
          ...q,
          agentValue: agentStatus,
        };
      }

      return q;
      // }) as IDashboard[] | AssetDashboardItem[] | undefined;
    }) as IDashboard[] | AssetsHostItemDashboardResponse[] | undefined;

    setDataDashboard(dashboardData);
  }, [query.data?.data, agentsQuery.data]);

  const pageChange = (event: GridPageChangeEvent): void => {
    getPage(event);
  };

  useEffect(() => {
    if (query.data?.total && query.data.total <= page.skip)
      setPage({ take: page.take, skip: Math.trunc(query.data.total / page.take) });
  }, [query.data?.total]);

  useEffect(() => {
    if (query.error) {
      handleError(query.error, createNotification);
    }
  }, [query.isError, createNotification, query.error]);

  const onFilterChange = (event: FilterChangeEvent): void => {
    filterSearch(event);
  };

  function handleCustomSearch(e: React.ChangeEvent<HTMLInputElement>): void {
    debouncedCustomSearch(e);
  }

  const handleAsset = async (e: MenuSelectEvent, parentId?: string): Promise<void> => {
    if (e.item.text === 'linux' || e.item.text === 'windows') {
      const selectAssetType = e.item.text as 'linux' | 'windows';
      const dataAssetDashboard = await getAgentInstaller(selectAssetType, null);
      setHostCreateData({
        ...dataAssetDashboard,
        assetType: selectAssetType,
        queryFn: query,
        accountId: currentAccountId,
      });
      setDropdownParentId(parentId);
    }
  };

  const handleRefresh = (): void => {
    setFilterStatus(true);
    query.refetch().then(() => setFilterStatus(false));
  };

  const addAllAsset = (): void => {
    handleAddAllAsset(dataDashboard, hostFormTemp, setHostFormTemp);
  };

  const addSelectedAssets = (): void => {
    setHostForm(hostFormTemp);
    setOpen(false);
  };

  const cancelAllAsset = (): void => {
    setHostFormTemp(null);
    setHostForm(null);
  };

  const cancelAssetTypeHost = (): void => {
    const mutableAssetTypeHost = hostFormTemp?.filter((h) => h.assetGroupId);
    if (mutableAssetTypeHost) {
      setHostFormTemp(mutableAssetTypeHost);
      setHostForm(mutableAssetTypeHost);
    } else {
      setHostFormTemp(null);
      setHostForm(null);
    }
  };

  const handleExit = (): void => {
    setHostFormTemp(null);
    setHostForm(null);
    setOpen(false);
  };

  return {
    handleAsset,
    handleRefresh,
    filter,
    onFilterChange,
    customSearch,
    handleCustomSearch,
    sort,
    dataDashboard,
    page,
    total: query.data?.total,
    pageChange,
    setSort,
    dataForHooks,
    isLoading: query.isLoading,
    isFetching: query.isFetching,
    filterStatus,
    isError: query.isError,
    errorMessage: query?.error?.message,
    addAllAsset,
    cancelAllAsset,
    handleExit,
    addSelectedAssets,
    cancelAssetTypeHost,
  };
}
