import { ReactElement, useEffect } from 'react';
import { useLocation, useHistory, NavLink } from 'react-router-dom';
import {
  AppBar,
  AppBarSection,
  Drawer,
  DrawerContent,
  DrawerItem,
  DrawerItemProps,
  DrawerSelectEvent,
} from '@progress/kendo-react-layout';
import { Button } from '../../common/baseElements/Button';
import { getAccountName, getUserData } from '../../../services/local-storage-service';
import styles from './DrawerRouterContainer.module.scss';
import { localizeDrawerMenu } from '../../../utils/helpers/localize-drawer-menu';
import { PopUpMenu } from '../../common/dropDownMenu/PopUpMenu';
import localeDrawer from '../../../utils/i18n/drawer/drawerUserMenu.json';
import localeAbout from '../../../utils/i18n/help/about.json';
import { useCommonContext } from '../../../hooks/useCommonContext';
import { useDrawerMenuChecker } from '../../../hooks/components/useHistorySelected';
import { useHeaderContext } from '../../../hooks/useHeaderContext';
import { useUserModalContext } from '../../../hooks/useUserModalContext';
import { getUser } from '../../../services/user-service';
import { CommonErrorLicense } from '../../common/CommonErrorLicense';
import { useWhiteListContext } from '../../../hooks/useWhiteListContext';
import { CommonDBFirstUpdatedInfo } from '../../common/CommonDBFirstUpdatedInfo';
import { CommonNotification } from '../../common/CommonNotification';
import { useNetDeviceContext } from '../../../hooks/useNetDeviceContext';
import { GlobalModals } from '../GlobalModals/GlobalModals';
import { useTicketContext } from '../../../hooks/useTicketContext';
import { AddTicketForm } from '../../features/Security/Tickets/form/AddTicketForm';
import PackageNameModal from '../../features/Task/taskVmDetails/vmDetailsComponents/PackageNameModal';
import { useAuditModalContext } from '../../../hooks/useAuditModalContext';

const currentLocale =
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN';

const ITEMS_MENU = localizeDrawerMenu(currentLocale);

function CustomItem(props: DrawerItemProps): ReactElement {
  const { text, route, iconClass, id, selected, separator } = props;

  return iconClass && route ? (
    <li id={id} className={`k-drawer-item ${styles.link}${selected ? ` ${styles.selected}` : ''}`}>
      <span className={`k-icon ${iconClass}`} />
      <NavLink to={route}>{text}</NavLink>
    </li>
  ) : (
    <li id={id} className={`k-drawer-item ${separator ? styles.separator : styles.group}`}>
      <span className="k-item-text">{text}</span>
    </li>
  );
}

type PropsContent = {
  handleLogout: () => void;
  children: JSX.Element[] | JSX.Element;
};

export function DrawerRouterContainer({
  handleLogout,
  children,
}: PropsContent): React.ReactElement {
  const location = useLocation();

  const history = useHistory();

  const currentUser = getUserData();

  const accountName = getAccountName();

  const { editUserProfile } = useUserModalContext();

  const { onSubmitVulnSuccess } = useWhiteListContext();

  const getUserInfo = async (): Promise<void> => {
    const resultUserId = await getUser(currentUser?.id || '');

    editUserProfile(resultUserId);
  };

  const {
    disabled: sidebarDisabled,
    expanded,
    styles: headerStyles,
    setShowAbout,
    openHostDialog,
    createTaskInHeader,
    handleClick,
  } = useHeaderContext();

  const { setAddNetDevice } = useNetDeviceContext();

  const { pkgName } = useAuditModalContext();

  const {
    auditLocationSelected,
    setSelectedItem,
    groupLocationSelected,
    assetLocationSelected,
    sensorLocationSelected,
    imageLocationSelected,
    deviceLocationSelected,
    settingLocationSelected,
    systemlogLocationSelected,
    usersLocationSelected,
    credentialsLocationSelected,
    whitelistLocationSelected,
    ticketlistLocationSelected,
  } = useDrawerMenuChecker();

  const selected = setSelectedItem(location.pathname);

  const { isOpenForm } = useCommonContext();

  const { isTicketCreatedSuccess, isAddTicket } = useTicketContext();

  const onSelect = (e: DrawerSelectEvent): void => {
    history.push(e.itemTarget.props.route);
  };

  useEffect(() => {
    auditLocationSelected();
    groupLocationSelected();
    assetLocationSelected();
    sensorLocationSelected();
    imageLocationSelected();
    deviceLocationSelected();
    settingLocationSelected();
    systemlogLocationSelected();
    usersLocationSelected();
    credentialsLocationSelected();
    whitelistLocationSelected();
    ticketlistLocationSelected();
  }, [
    auditLocationSelected,
    groupLocationSelected,
    assetLocationSelected,
    sensorLocationSelected,
    imageLocationSelected,
    deviceLocationSelected,
    settingLocationSelected,
    systemlogLocationSelected,
    usersLocationSelected,
    credentialsLocationSelected,
    whitelistLocationSelected,
    ticketlistLocationSelected,
  ]);

  return (
    <div>
      <AppBar className={styles.header}>
        <AppBarSection>
          <Button fill="flat" onClick={handleClick} disabled={sidebarDisabled}>
            <img height="20px" src="/images/bmenu.svg" alt="burger_menu_icon" />
          </Button>
          <Button
            className={styles.logo_button}
            fill="flat"
            onClick={(): void => {
              history.push('/lk/audit/tasks');
            }}
          >
            <img src="/images/logo.svg" alt="logo" />
            <img src="/images/vulns-logo-text.svg" alt="logo_text" />
          </Button>
        </AppBarSection>
        {currentUser && (
          <AppBarSection className={styles.quicks}>
            <div className={`${styles.quick_menu} dropdown__container`}>
              <div className={styles.iconset}>
                <img className={styles.plus} src="/images/plus_btn.svg" alt="plus" />
                <img className="arrow_down" src="/images/down_arrow_btn.svg" alt="arrow_down" />
              </div>
              <div className={`${styles.dropdown_content} dropdown__content`}>
                <PopUpMenu
                  title={localeDrawer[currentLocale].quickMenu.task.title}
                  disabled={isOpenForm}
                >
                  <Button
                    fill="menu"
                    style={{ fontSize: '13px' }}
                    onClick={(): void => {
                      createTaskInHeader('asset');
                    }}
                  >
                    {localeDrawer[currentLocale].quickMenu.task.asset}
                  </Button>
                  <Button
                    fill="menu"
                    style={{ fontSize: '13px' }}
                    onClick={(): void => {
                      createTaskInHeader('report');
                    }}
                  >
                    {localeDrawer[currentLocale].quickMenu.task.report}
                  </Button>
                  <Button
                    fill="menu"
                    style={{ fontSize: '13px' }}
                    onClick={(): void => {
                      createTaskInHeader('asset_synchronize');
                    }}
                  >
                    {localeDrawer[currentLocale].quickMenu.task.sync}
                  </Button>
                </PopUpMenu>
                {currentUser.role !== 'reader_user' && (
                  <PopUpMenu
                    title={localeDrawer[currentLocale].quickMenu.asset.title}
                    disabled={isOpenForm}
                  >
                    <Button
                      fill="menu"
                      style={{ fontSize: '13px' }}
                      onClick={(): void => {
                        openHostDialog('linux');
                      }}
                    >
                      {localeDrawer[currentLocale].quickMenu.asset.linux}
                    </Button>
                    <Button
                      fill="menu"
                      style={{ fontSize: '13px' }}
                      onClick={(): void => {
                        openHostDialog('windows');
                      }}
                    >
                      {localeDrawer[currentLocale].quickMenu.asset.windows}
                    </Button>
                    <Button
                      fill="menu"
                      style={{ fontSize: '13px' }}
                      onClick={(): void => {
                        // openHostDialog('windows');
                        setAddNetDevice(true);
                      }}
                    >
                      {localeDrawer[currentLocale].quickMenu.asset.device}
                    </Button>
                  </PopUpMenu>
                )}
              </div>
            </div>
            <div className={`${styles.quick_menu} dropdown__container`}>
              <div className={styles.iconset}>
                <img src="/images/support_btn.svg" alt="Documentation" />
              </div>
              <div className={`${styles.dropdown_content} dropdown__content`}>
                <Button fill="menu" onClick={(): void => history.push('/lk/doc/')}>
                  {localeDrawer[currentLocale].quickMenu.help.doc}
                </Button>
                <Button
                  fill="menu"
                  onClick={(): void => {
                    setShowAbout(true);
                  }}
                >
                  {localeAbout[currentLocale].title}
                </Button>
              </div>
            </div>
            <NavLink className={`${styles.quick_menu} settings`} to="/lk/settings/license">
              <div className={styles.iconset}>
                <img src="/images/settings_btn.svg" alt="Settings" />
              </div>
            </NavLink>
            <div className={`${styles.quick_menu} dropdown__container ${styles.user_data}`}>
              <div className={styles.iconset}>
                <span className="droptitle">{currentUser.name} &nbsp;</span>
                <span className={styles.droptitleAccount}>({accountName})</span>
                <img className="arrow_down" src="/images/down_arrow_btn.svg" alt="arrow_down" />
              </div>
              <div className={`${styles.dropdown_content_user} dropdown__content`}>
                <Button fill="menu" onClick={getUserInfo}>
                  {localeDrawer[currentLocale].profile}
                </Button>
                <Button
                  fill="menu"
                  icon="settings"
                  onClick={(): void => history.push('/lk/settings/license')}
                >
                  {localeDrawer[currentLocale].settings}
                </Button>
                <Button fill="menu" icon="logout" onClick={handleLogout}>
                  {localeDrawer[currentLocale].exit}
                </Button>
              </div>
            </div>
          </AppBarSection>
        )}
      </AppBar>
      <Drawer
        expanded={expanded}
        position="start"
        mode="push"
        width={240}
        item={CustomItem}
        items={ITEMS_MENU.filter((m) =>
          currentUser?.role === 'reader_user' ? m.id !== 'credentials' && m.id !== 'users-list' : m,
        )
          .filter((m) => (currentUser?.role === 'user' ? m.id !== 'users-list' : m))
          .map((item) => ({
            ...item,
            selected:
              (selected &&
                item.text === selected.text &&
                selected.route &&
                item.route === selected.route) ||
              false,
          }))}
        onSelect={onSelect}
        className={styles.content}
      >
        <DrawerContent>
          <GlobalModals />
          {/* <CommonErrorLicense /> */}
          {/* <CommonDBFirstUpdatedInfo /> */}
          {!history.location.pathname.includes('lk/doc/') ? (
            <>
              <CommonErrorLicense /> <CommonDBFirstUpdatedInfo />
            </>
          ) : null}
          {onSubmitVulnSuccess && <CommonNotification type="exceptions" timerValue={5000} />}
          {isTicketCreatedSuccess && <CommonNotification type="ticket" timerValue={5000} />}
          {isAddTicket && <AddTicketForm />}
          {pkgName && <PackageNameModal />}
          {children}
        </DrawerContent>
      </Drawer>
    </div>
  );
}
