/* eslint-disable jsx-a11y/label-has-associated-control */
import { DropDownList } from '@progress/kendo-react-dropdowns';
import localeTicket from '../../../../../utils/i18n/security/vulnerabilities.json';
import styles from '../../../Security/Tickets/ticket.module.scss';
import { ticketPriorityValueForTicketSystem } from '../../../../../utils/helpers/constants';
import { useTicketContext } from '../../../../../hooks/useTicketContext';
import { CommonTooltip } from '../../../../common/CommonTooltip';
import { useTicketSystemMethods } from '../../../../../hooks/components/useTicketSystemMethods';
import { useTicketSystemContext } from '../../../../../hooks/useTicketSystemContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeTicket;

export function PriorityMappingBlock(): React.ReactElement {
  const { ticketSystemType } = useTicketContext();

  const {
    priorities,
    setJiraPriorityMapping,
    jiraPriorityMapping,
    yandexPriorityMapping,
    setYandexPriorityMapping,
  } = useTicketSystemContext();

  const { setDefaultPriorityName } = useTicketSystemMethods();

  return (
    <div className={styles.statusMappingBlock}>
      <div className={styles.labelWrap}>
        <label className="k-label">
          {localeTicket[currentLocale].ticketSystem.form.priorityMapping}
        </label>
        <CommonTooltip
          visibleStyle={{ marginLeft: '2px' }}
          hiddenStyle={{ marginLeft: '32px', marginBottom: '40px' }}
        >
          {localeTicket[currentLocale].ticketSystem.form.mappingPriorityInfo}
        </CommonTooltip>
      </div>
      {ticketPriorityValueForTicketSystem.map((p) => {
        const key = p.id as 'low' | 'medium' | 'high' | 'critical';

        return (
          <div className={styles.ticketStatusWrap} key={p.id}>
            <label className="k-label required-label">{p.name}:</label>
            {ticketSystemType === 'jira' && (
              <DropDownList
                data={priorities || []}
                defaultValue={
                  Object.keys(jiraPriorityMapping).length > 0
                    ? {
                        name: setDefaultPriorityName(jiraPriorityMapping[p.id] || '') || '-',
                      }
                    : { name: '-', id: '-' }
                }
                textField="name"
                dataItemKey="id"
                className={styles.dropDownList}
                onChange={(e): void =>
                  setJiraPriorityMapping((prevState) => ({
                    ...prevState,
                    [p.id]: e.target.value.id,
                  }))
                }
                disabled={!priorities}
              />
            )}
            {ticketSystemType === 'yandex_tracker' && yandexPriorityMapping && (
              <DropDownList
                data={priorities || []}
                defaultValue={
                  Object.keys(yandexPriorityMapping).length > 0
                    ? {
                        name:
                          setDefaultPriorityName(String(yandexPriorityMapping[key]) || '') || '-',
                      }
                    : { name: '-', id: '-' }
                }
                textField="name"
                dataItemKey="id"
                className={styles.dropDownList}
                onChange={(e): void =>
                  setYandexPriorityMapping((prevState) => ({
                    ...prevState,
                    [p.id]: e.target.value.id,
                  }))
                }
                disabled={!priorities}
              />
            )}
          </div>
        );
      })}
    </div>
  );
}
