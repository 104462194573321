import { ReactElement, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import type { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Filter, FilterChangeEvent } from '@progress/kendo-react-data-tools';
import {
  Grid,
  GridColumn,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid';
import { getRelatedBulletinsPost } from '../../../services/bulletins-service';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
import type { DashboardBulletinsResponse } from '../../../types/__generated/on-premise-solution/api/dashboardBulletinsResponse.v1';
import { handleFilteringTest, type IFilterVal } from '../../../utils/filtering-in-table-test';
import type { IPageState } from '../../../utils/helpers/types';
import {
  FILTER_INITIAL,
  INITIAL_PAGING_10,
  INITIAL_SORT_HOST,
  PAGEABLE_DATA,
} from '../../../utils/helpers/constants';
import { Input } from '../../common/baseElements/Input';
import { ScoreCvssCell } from '../../common/cells/ScoreCvssCell';
import { CellSecurityVulnsId } from '../Security/cells/CellSecurityVulnsId';
import { CellVulnListAlias } from '../Task/taskVmDetails/vmDetailsComponents/cells/CellVulnListAlias';
import { CellSecurityTitle } from '../Security/cells/CellSecurityTitle';
import { CellSecurityCwe } from '../Security/cells/CellSecurityCwe';
import { CellPublished } from '../Task/taskVmDetails/vmDetailsComponents/cells/CellVulnerabilitiesListPublished';
import { useCustomSearch } from '../../../hooks/components/useCustomSearch';
import { useFilter } from '../../../hooks/components/useFilter';
import locale from '../../../utils/i18n/security/vulnerabilities.json';
import localeAuditTask from '../../../utils/i18n/taskLocale/auditTask.json';
import { FILTER_VDB } from '../../../utils/helpers/constant-serv-filter-task';
import { handleError } from '../../../utils/errors';
import { handleSort } from '../../../utils/sorting-in-table';
import { usePageChange } from '../../../hooks/components/usePageChange';
import { getUrlVulnRelated } from '../../../utils/helpers/vuln-related-list-helper';
import { useNotificationContext } from '../../../hooks/useNotificationContext';
import { CellBulletinId } from '../WhiteList/cells/CellBulletinId';
import { collectSecurityVulns } from '../../../utils/helpers/security-vulns-helper';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function SoftwareBulletinsTab({ bulletinIds }: { bulletinIds: string[] }): ReactElement {
  const dataForHooks = {
    name: 'bulletinId',
    componentName: 'SoftwareBulletinsTab',
  };
  const { createNotification } = useNotificationContext();

  const urlParams = getUrlVulnRelated(dataForHooks.componentName);

  const [page, setPage] = useState<IPageState>(INITIAL_PAGING_10);
  const [sort, setSort] = useState(INITIAL_SORT_HOST);
  const [filter, setFilter] = useState<CompositeFilterDescriptor>(FILTER_INITIAL);
  const [filterVal, setFilterVal] = useState<IFilterVal[]>([]);
  const getPage = usePageChange(setPage, urlParams, dataForHooks);
  const [filterStatus, setFilterStatus] = useState(false);
  const [customSearch, setCustomSearch] = useState('');
  const filterValue = useRef<CompositeFilterDescriptor | null>(null);
  const filterRef = useRef<CompositeFilterDescriptor>(filter);

  const query = useQuery<DashboardBulletinsResponse | undefined, ApiError>(
    ['SoftwareRelatedBulletins', bulletinIds, page, filterVal, sort],
    bulletinIds.length
      ? () => getRelatedBulletinsPost(bulletinIds, page, filterVal, sort)
      : () => undefined,
  );

  const securityVulns = collectSecurityVulns(query.data);

  const pageChange = (event: GridPageChangeEvent): void => {
    getPage(event);
  };

  useEffect(() => {
    let delaySearch: NodeJS.Timeout;

    if (filter?.filters?.length > 0) {
      filterRef.current = filter;
      delaySearch = setTimeout(() => {
        handleFilteringTest(filterRef.current, setFilterVal);
        setFilterStatus(false);
      }, 300);
    }

    return () => clearTimeout(delaySearch);
  }, [filter.filters, page.skip, location]);

  useEffect(() => {
    if (query.error) {
      handleError(query.error, createNotification);
    }
  }, [query.isError, query.error]);

  const debouncedCustomSearch = useCustomSearch(
    setFilterStatus,
    setCustomSearch,
    filter,
    setFilterVal,
    setPage,
    page,
    dataForHooks,
  );

  const filterSearch = useFilter(
    filterValue,
    setFilter,
    customSearch,
    setCustomSearch,
    setFilterVal,
    filterRef,
    filter,
    setPage,
    page,
    dataForHooks,
  );

  const onFilterChange = (event: FilterChangeEvent): void => {
    filterSearch(event);
  };

  const handleCustomSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    debouncedCustomSearch(e);
  };

  return (
    <LocalizationProvider language={currentLocale}>
      <IntlProvider locale={currentLocale.substring(0, 2)}>
        <div className="filter-line">
          <Filter value={filter} onChange={onFilterChange} fields={FILTER_VDB[currentLocale]} />
          {filter.filters.length === 0 && (
            <Input
              name="customSearch"
              placeholder={locale[currentLocale].customSearchName}
              value={customSearch}
              onChange={(e): void => handleCustomSearch(e)}
            />
          )}
        </div>
        <Grid
          data={securityVulns}
          pageable={PAGEABLE_DATA}
          sortable
          sort={sort}
          skip={page.skip}
          take={page.take}
          total={query?.data?.total}
          onPageChange={pageChange}
          onSortChange={(e: GridSortChangeEvent): void => handleSort(e, setSort)}
          scrollable="none"
        >
          <GridColumn
            title={locale[currentLocale].vulnsTable.cvssScore}
            filter="text"
            field="maxScore"
            width="96px"
            cell={ScoreCvssCell}
            sortable={false}
            headerClassName="no-cursor"
          />
          <GridColumn
            title={locale[currentLocale].vulnsTable.id}
            filter="text"
            field="bulletinId"
            // width="140px"
            cell={CellBulletinId}
            sortable={false}
            headerClassName="no-cursor"
            width="220px"
          />
          <GridColumn
            field="aliases"
            title={localeAuditTask[currentLocale].vulnsList.table.aliases}
            cell={CellVulnListAlias}
            width="220px"
            // width={query.data?.data.find((a) => a.aliases) ? '180px' : '160px'}
            sortable={false}
            headerClassName="no-cursor"
          />
          <GridColumn
            title={locale[currentLocale].vulnsTable.title}
            filter="text"
            field="title"
            // width="140px"
            cell={CellSecurityTitle}
            sortable={false}
            headerClassName="no-cursor"
          />
          <GridColumn
            field="cwe"
            title={locale[currentLocale].vulnsTable.cwe}
            // width={}
            cell={CellSecurityCwe}
            sortable={false}
            headerClassName="no-cursor"
            width="440px"
          />
          {/* <GridColumn
            field="vector"
            title={locale[currentLocale].vulnsTable.cvssVector}
            width="320px"
            filter="text"
            cell={CellSecurityVector}
            sortable={false}
            headerClassName="no-cursor"
          /> */}
          <GridColumn
            title={locale[currentLocale].vulnsTable.published}
            width="152px"
            filter="text"
            field="date.published"
            cell={CellPublished}
            sortable={false}
            headerClassName="no-cursor"
          />
          {/* <GridColumn
            title={locale[currentLocale].vulnsTable.hosts}
            width="88px"
            filter="text"
            field="totalAssets"
            cell={CellSecurityAsset}
            sortable={false}
            headerClassName="no-cursor"
          />
          <GridColumn
            title={locale[currentLocale].vulnsTable.totalImageAssets}
            width="96px"
            filter="text"
            field="totalImageAssets"
            cell={CellSecurityImage}
            sortable={false}
            headerClassName="no-cursor"
          />
          <GridColumn
            title={locale[currentLocale].vulnsTable.netDevices}
            filter="text"
            field="totalRouterAssets"
            cell={CellSecurityNetDev}
            width="128px"
            sortable={false}
            headerClassName="no-cursor"
          /> */}
        </Grid>
      </IntlProvider>
    </LocalizationProvider>
  );
}
