/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { Menu, MenuItem, MenuSelectEvent } from '@progress/kendo-react-layout';
import { Button } from '../../../common/baseElements/Button';
import localeTicket from '../../../../utils/i18n/security/vulnerabilities.json';
import { getUserData } from '../../../../services/local-storage-service';
import { useNotificationContext } from '../../../../hooks/useNotificationContext';
import { handleError } from '../../../../utils/errors';
import type { TicketDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/ticketsDashboardResponse.v1';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { getTicketId } from '../../../../services/tickets-service';
import { DeleteConfirmModal } from '../../../common/modal/DeleteConfirmModal';
import { TicketMetaBlock } from './TicketMetaBlock';
import { TabsTicketDescription } from './TabsTicketDescription';
import { useTicketMethods } from '../../../../hooks/components/useTicketMethods';
import { ticketStatusLocale, ticketStatusValueForm } from '../../../../utils/helpers/constants';
import styles from './ticket.module.scss';
import { EntityDescription } from '../../../templates/EntityDescription';
import localeRelocated from '../../../../utils/i18n/commonLocales/confirmDeleteForm.json';

export function TicketDescription(): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeTicket;

  const currentUser = getUserData();

  const queryClient = useQueryClient();

  const { ticketId } = useParams<{ ticketId: string }>();

  const [isConfirmDel, setConfirmDel] = useState(false);

  const [isConfirmRelocate, setConfirmRelocate] = useState(false);

  const [menuStyle, setMenuStyle] = useState<{ menu: string; item: string }>({
    menu: '',
    item: '',
  });

  const ticketSystemType = useRef<string | null>(null);

  const type = useRef<string | null>(null);

  const currentTicketStatus = useRef<string>('');

  const description = useRef<string | null>(null);

  const ticketName = useRef<string>('');

  const { createNotification } = useNotificationContext();

  const {
    hadleDeleteTicket,
    isErrorDelTicket,
    errorMessageDelTicket,
    handleEdit,
    changeStatusTicket,
    hadleRelocateTicket,
  } = useTicketMethods();

  const query = useQuery<TicketDashboardResponse, ApiError>(
    ['ticketDescription', ticketId],
    () => getTicketId(ticketId),
    {
      keepPreviousData: true,
      onSuccess: (resp) => {
        ticketSystemType.current = resp.ticketSystemType;
        currentTicketStatus.current = resp.status;
        description.current = resp.description;
        ticketName.current = resp.summary;
        type.current = resp.type;
        setMenuStyle({ menu: `${resp.status}Menu`, item: resp.status });
      },
    },
  );

  const { data } = query;

  useEffect(() => {
    if (query.error) {
      handleError(query.error, createNotification);
    }
  }, [query.isError, query.error, createNotification]);

  const handleRefresh = useCallback((): void => {
    queryClient.invalidateQueries('ticketDescription');
    queryClient.invalidateQueries('ticketActionsByTicketId');
  }, [queryClient]);

  const onSelect = (e: MenuSelectEvent): void => {
    if (e.itemId.includes('_')) {
      const status = e.item.data as string;

      if (ticketSystemType.current) {
        const editData = {
          ticketId,
          status,
          ticketSystemType: ticketSystemType.current,
          type: type.current || '',
        };
        changeStatusTicket(editData);
      }
    }
  };

  return (
    <EntityDescription
      isLoading={query.isLoading}
      isNotFound={query.error?.code === '404'}
      entity={{
        id: ticketId,
        type: 'ticket',
        name: ticketName.current,
      }}
      topactions={
        <>
          <Menu
            hoverOpenDelay={0}
            className={`${styles[menuStyle.menu]} btn-menu`}
            onSelect={onSelect}
          >
            <MenuItem
              text={
                currentTicketStatus.current
                  ? ticketStatusLocale[currentTicketStatus.current]
                  : localeTicket[currentLocale].tickets.description.btns.status
              }
              icon={
                currentTicketStatus.current === 'closed'
                  ? 'k-icon k-i-check'
                  : currentTicketStatus.current === 'open'
                  ? 'k-icon k-i-file k-i-file-vertical k-i-page-portrait k-i-file-v'
                  : currentTicketStatus.current === 'in-progress'
                  ? `k-icon ${[styles.spanIcon, styles.inProgress].join(' ')}`
                  : currentTicketStatus.current === 'in-review'
                  ? 'k-icon k-i-clock'
                  : ''
              }
            >
              {ticketStatusValueForm.map((t) => (
                <MenuItem
                  text={t.name}
                  data={t.id}
                  key={t.id}
                  icon={
                    t.id === 'closed'
                      ? 'k-icon k-i-check'
                      : t.id === 'open'
                      ? 'k-icon k-i-file k-i-file-vertical k-i-page-portrait k-i-file-v'
                      : t.id === 'in-progress'
                      ? `k-icon ${[styles.spanIcon, styles.inProgress].join(' ')}`
                      : t.id === 'in-review'
                      ? 'k-icon k-i-clock'
                      : ''
                  }
                  cssClass={
                    t.id === menuStyle.item
                      ? [styles[t.id], styles.weightTitle].join(' ')
                      : styles[t.id]
                  }
                />
              ))}
            </MenuItem>
          </Menu>
          <Button onClick={handleRefresh} icon="refresh">
            {localeTicket[currentLocale].btnRefresh}
          </Button>
          <Button onClick={(): void => handleEdit(data)}>
            {localeTicket[currentLocale].tickets.grid.menu.edit}
          </Button>
          {ticketSystemType.current !== 'local' && (
            <Button onClick={(): void => setConfirmRelocate(true)}>
              {localeTicket[currentLocale].tickets.grid.menu.relocateTicket}
            </Button>
          )}
          {(currentUser?.role === 'super_admin' || currentUser?.role === 'account_admin') && (
            <Button onClick={(): void => setConfirmDel(true)}>
              {localeTicket[currentLocale].tickets.grid.menu.delete}
            </Button>
          )}
        </>
      }
      className="ticketdescription"
    >
      {isConfirmDel && (
        <DeleteConfirmModal
          name={data?.summary || ''}
          type="ticket"
          onClose={(): void => setConfirmDel(false)}
          handleDelete={(): void => hadleDeleteTicket(data?.ticketId || '', setConfirmDel)}
          errorMessage={isErrorDelTicket ? errorMessageDelTicket : undefined}
        />
      )}
      {isConfirmRelocate && (
        <DeleteConfirmModal
          name={data?.summary || ''}
          type="ticketRelocate"
          onClose={(): void => setConfirmRelocate(false)}
          handleDelete={(): void => hadleRelocateTicket(data?.ticketId || '', setConfirmRelocate)}
          title={localeRelocated[currentLocale].relocateTitle}
        />
      )}
      {data && <TicketMetaBlock data={data} />}
      <TabsTicketDescription
        ticketId={ticketId}
        description={description.current}
        type={data?.type}
      />
    </EntityDescription>
  );
}
