/* eslint-disable react/jsx-pascal-case */
// import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Typography } from '@progress/kendo-react-common';
import { loadMessages } from '@progress/kendo-react-intl';
import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { ErrorBoundary } from 'react-error-boundary';
import localeSettings from '../../../../utils/i18n/settingsLocales/settings.json';
import '../Settings.module.scss';
import localeCommonTable from '../../../../utils/i18n/commonLocales/table.json';
import locale from '../../../../utils/i18n/security/vulnerabilities.json';
import { TabSettingLog } from './TabSettingLog';
import { Button } from '../../../common/baseElements/Button';
import { BoundaryErrorComponent } from '../../../common/BoundaryErrorComponent';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;

loadMessages(localeCommonTable[currentLocale], currentLocale);

export function SettingsLogs(): React.ReactElement {
  const queryClient = useQueryClient();

  const handleRefresh = useCallback((): void => {
    queryClient.invalidateQueries('AllTaskLogList');
    queryClient.invalidateQueries('AllUserLogList');
  }, [queryClient]);

  return (
    <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
      <div className="setting-logs">
        <div className="common-header-page">
          <Typography.h3 className="title">
            {localeSettings[currentLocale].logs.title}
          </Typography.h3>
          <div className="topaction">
            <Button icon="refresh" onClick={handleRefresh}>
              {locale[currentLocale].btnRefresh}
            </Button>
          </div>
        </div>
        <TabSettingLog />
      </div>
    </ErrorBoundary>
  );
}
