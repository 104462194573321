/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { ErrorBoundary } from 'react-error-boundary';
import localeTicket from '../../../../utils/i18n/security/vulnerabilities.json';
import { TicketDescriptionActions } from './TicketDescriptionActions';
import { BoundaryErrorComponent } from '../../../common/BoundaryErrorComponent';
import { Logs } from '../../Task/taskDescriptionComponents/Logs';
import styles from './ticket.module.scss';

export function TabsTicketDescription({
  ticketId,
  description,
  type,
}: {
  ticketId: string;
  description: string | null;
  type: 'fix_vulnerabilities' | 'custom' | undefined;
}): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeTicket;

  const [selected, setSelected] = useState<number>(0);

  const handleSelect = (e: TabStripSelectEventArguments): void => {
    setSelected(e.selected);
  };

  const [maxHeightDescription, setMaxHeightDescription] = useState(false);

  const [heightValue, setHeightValue] = useState(0);

  return (
    <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
      <div className={styles.tabsTicketDescription}>
        <div className={styles.leftTabGridWrap}>
          <TabStrip selected={selected} onSelect={handleSelect}>
            {type === 'fix_vulnerabilities' && (
              <TabStripTab title={localeTicket[currentLocale].tickets.description.tabs.actions}>
                <TicketDescriptionActions setHeightValue={setHeightValue} />
              </TabStripTab>
            )}
            <TabStripTab title={localeTicket[currentLocale].tickets.description.tabs.eventLog}>
              <Logs additionalParam={ticketId} setHeightValue={setHeightValue} />
            </TabStripTab>
          </TabStrip>
        </div>
        <div className={styles.rightWrap}>
          <div
            className={
              description && description.length > heightValue + heightValue * 2.6
                ? [styles.descriptionWrap, styles.cursorPointer].join(' ')
                : [styles.descriptionWrap, styles.cursorInherit].join(' ')
            }
            onClick={
              description && description.length > heightValue + heightValue * 2.6
                ? (): void => setMaxHeightDescription(!maxHeightDescription)
                : undefined
            }
            // style={{
            //   cursor:
            //     description && description.length > heightValue + heightValue * 2.6
            //       ? 'pointer'
            //       : 'inherit',
            // }}
          >
            <TabStrip
              selected={0}
              style={{
                minHeight: `${heightValue + 92}px`,
                // height: maxHeightDescription && description ? 'auto' : '',
                maxHeight: '640px',
              }}
            >
              <TabStripTab
                title={localeTicket[currentLocale].tickets.description.tabs.description}
                contentClassName={
                  maxHeightDescription && description
                    ? [styles.tabCommon, styles.tabMaxHeigh].join(' ')
                    : !maxHeightDescription && description
                    ? [styles.tabCommon, styles.tabMinHeigh].join(' ')
                    : [styles.tabCommon, styles.noDescription].join(' ')
                }
              >
                {description && (
                  <>
                    <div>
                      {!maxHeightDescription ? (
                        <span>
                          {description.length <= heightValue + heightValue * 2.6
                            ? description
                            : `${description.slice(0, heightValue + heightValue * 2.6)}...`}
                        </span>
                      ) : (
                        <span>{description}</span>
                      )}
                    </div>
                    {description && description.length > heightValue + heightValue * 2.6 && (
                      <div>
                        {!maxHeightDescription ? (
                          <div className={styles.arrowWrap}>
                            <span className="k-icon k-i-arrow-60-down" />
                          </div>
                        ) : (
                          <div className={styles.arrowWrap}>
                            <span
                              className="k-icon k-i-chevron-up k-i-arrow-chevron-up k-i-arrowhead-n"
                              onClick={(): void => setMaxHeightDescription(!maxHeightDescription)}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
                {!description && (
                  <div>
                    <span className={styles.noDescription}>
                      {localeTicket[currentLocale].tickets.description.tabs.noDescription}
                    </span>
                  </div>
                )}
              </TabStripTab>
            </TabStrip>
          </div>
          {/* <div>
            <TabStrip selected={0}>
              <TabStripTab title={localeTicket[currentLocale].tickets.description.tabs.comments}>
                <div>
                  <span>{testDescription}</span>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <span className="k-icon k-i-arrow-60-down" />
                </div>
              </TabStripTab>
            </TabStrip>
          </div> */}
        </div>
      </div>
    </ErrorBoundary>
  );
}
