/* eslint-disable react/jsx-no-useless-fragment */
import localeTicket from '../../utils/i18n/security/vulnerabilities.json';
import { useTicketContext } from '../../hooks/useTicketContext';
import styles from './errorMutationTicketStatus.module.scss';

export function ErrorMutationTicketStatus({
  inlineStyle,
  ticketId,
}: {
  inlineStyle: React.CSSProperties;
  ticketId: string;
}): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeTicket;

  const { errorMutationTicketStatus } = useTicketContext();

  return (
    <div
      style={inlineStyle}
      className={`${styles.errorMutationTicketStatusWrap} common-tooltip-wrap`}
    >
      <span>{localeTicket[currentLocale].tickets.grid.error}</span>
      {errorMutationTicketStatus && errorMutationTicketStatus.filter((f) => f.id === ticketId) && (
        <span>{errorMutationTicketStatus.filter((f) => f.id === ticketId)[0].message}</span>
      )}
    </div>
  );
}
