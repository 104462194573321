import type { GridPageChangeEvent } from '@progress/kendo-react-grid';
import type { TreeListPageChangeEvent } from '@progress/kendo-react-treelist';
import type { IUrlParamsCredential } from '../../utils/helpers/credential-helper';
import type { IUrlParamsHosts } from '../../utils/helpers/host-list-helper';
import type { IUrlParamsSecVulnsList } from '../../utils/helpers/security-vulns-helper';
import type { IUrlParamsTaskHistory } from '../../utils/helpers/task-history-helper';
import type { IUrlParamsTaskList } from '../../utils/helpers/task-list-helper';
import type { IDataHooks, IPageState } from '../../utils/helpers/types';

export function usePageChange(
  setPage: (value: React.SetStateAction<IPageState>) => void,
  urlParams:
    | IUrlParamsTaskList
    | IUrlParamsTaskHistory
    | IUrlParamsHosts
    | IUrlParamsSecVulnsList
    | IUrlParamsCredential,
  dataForHooks: IDataHooks,
): (event: GridPageChangeEvent) => void {
  const pageCallback = (event: GridPageChangeEvent): void => {
    setPage(event.page);
    if (urlParams.pageLimitGrid) {
      const pageLimit = {
        ...urlParams.pageLimitGrid,
        [dataForHooks.componentName]: String(event.page.take),
      };
      localStorage.setItem('pageLimitGrid', JSON.stringify(pageLimit));
    } else {
      const pageLimit = {
        [dataForHooks.componentName]: String(event.page.take),
      };
      localStorage.setItem('pageLimitGrid', JSON.stringify(pageLimit));
    }
  };

  return pageCallback;
}

export function useTreePageChange(
  setPage: (value: React.SetStateAction<IPageState>) => void,
  urlParams:
    | IUrlParamsTaskList
    | IUrlParamsTaskHistory
    | IUrlParamsHosts
    | IUrlParamsSecVulnsList
    | IUrlParamsCredential,
  dataForHooks: IDataHooks,
): (event: TreeListPageChangeEvent) => void {
  const pageCallback = (event: TreeListPageChangeEvent): void => {
    setPage({ skip: event.skip, take: event.take });
    if (urlParams.pageLimitGrid) {
      const pageLimit = {
        ...urlParams.pageLimitGrid,
        [dataForHooks.componentName]: String(event.take),
      };
      localStorage.setItem('pageLimitGrid', JSON.stringify(pageLimit));
    } else {
      const pageLimit = {
        [dataForHooks.componentName]: String(event.take),
      };
      localStorage.setItem('pageLimitGrid', JSON.stringify(pageLimit));
    }
  };

  return pageCallback;
}
