import type { GridCellProps } from '@progress/kendo-react-grid';
import type { TicketDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/ticketsDashboardResponse.v1';

export function CellTicketDeadlineDate(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { deadlineDate }: TicketDashboardResponse = dataItem;

  return (
    <td>
      {deadlineDate ? (
        <span>{`${
          new Date(deadlineDate).getDate() < 10
            ? `0${new Date(deadlineDate).getDate()}`
            : new Date(deadlineDate).getDate()
        }.${
          new Date(deadlineDate).getMonth() + 1 < 10
            ? `0${new Date(deadlineDate).getMonth() + 1}`
            : new Date(deadlineDate).getMonth() + 1
        }.${new Date(deadlineDate).getUTCFullYear()}`}</span>
      ) : (
        <span>-</span>
      )}
    </td>
  );
}
