/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import type { ReactElement } from 'react';
import { nanoid } from 'nanoid';
import { useHistory } from 'react-router';
import type { Tag } from '../../../types/__generated/on-premise-solution/api/assetsImagesDashboardResponse.v1';
import { useTagContext } from '../../../hooks/useTagContext';
import styles from './Tag.module.scss';
import { Button } from '../baseElements/Button';
import { EditTagBlock } from './EditTagBlock';
import { useTagActions } from '../../../hooks/components/useTagActions';
import { useHostModalContext } from '../../../hooks/useHostModalContext';
import { useHostActions } from '../../../hooks/components/useHostActions';
import { useSensorActions } from '../../../hooks/components/useSensorActions';
import { ChangeTagColor } from './ChangeTagColor';
import { AssetTagContextMenu } from '../contextMenu/AssetTagContextMenu';

export function TagList({
  entityId,
  entityType,
  tags,
  tagIds,
  componentName,
  domainNameLength,
  updateEntityTagList,
}: {
  entityId: string;
  entityType: 'host' | 'sensor';
  tags: Tag[] | null | undefined;
  tagIds: string[] | null | undefined;
  componentName?: string;
  domainNameLength?: number;
  updateEntityTagList?: () => void;
}): ReactElement {
  const history = useHistory();

  const { deleTagFromHost } = useHostActions();
  const { deleteTagFromSensor } = useSensorActions();

  const {
    editTagData,
    hovered,
    setHovered,
    editTagColor,
    keyValue,
    setTagName,
    isTagMenu,
    setTagMenu,
    setEditTagData,
    isShowAddTag,
    setShowAddTag,
    setEditTagColor,
    setKeyValue,
  } = useTagContext();
  const { getTextColor } = useTagActions();

  const { setTagListInHostList } = useHostModalContext();

  const toggleHover = (val: string): void => setHovered(val);

  const showTagMenu = (keyVal: number): void => {
    if (editTagData) setEditTagData(null);

    if (editTagColor) setEditTagColor(null);

    if (isShowAddTag) setShowAddTag(false);

    setKeyValue(keyVal);

    setTagMenu(true);
  };

  const showAddTagComponent = (e: React.MouseEvent, name: string): void => {
    e.stopPropagation();
    const { target } = e;
    if (componentName === 'HostList' || componentName === 'HostListAllTag') {
      if (setTagListInHostList) setTagListInHostList('');
      history.push(
        `/lk/assets/hosts?skip=0&limit=15&sortField=latestAudit.maxCvssScore&sortOrder=desc&tags.name[$regex]=${name}`,
      );
      setTagName(name);
    } else if (componentName === 'SensorClassicList' || componentName === 'SensorListAllTag') {
      if (setTagListInHostList) setTagListInHostList('');
      history.push(`/lk/sensors?skip=0&limit=15&sortOrder=asc&tags.name[$regex]=${name}`);
      setTagName(name);
    } else if (
      target instanceof HTMLElement &&
      (target.className.includes('tagImg') || target.className.includes('tagTitle'))
    ) {
      setShowAddTag(true);
      setEditTagData(null);
      setTagMenu(false);
    }
  };

  return (
    <>
      {tags
        ?.sort((e, n) => {
          if (e.name < n.name) {
            return -1;
          }
          if (e.name > n.name) {
            return 1;
          }

          return 0;
        })
        .map((t, i) => {
          if (editTagData?.indexEl === i)
            return (
              <EditTagBlock
                entityId={entityId}
                tags={tags}
                setHovered={setHovered}
                keyValue={keyValue}
                currentTagId={t.tagId}
                beforeEditVal={t.name}
                entityType={entityType}
                updateEntityTagList={updateEntityTagList}
              />
            );
          if (
            componentName &&
            (componentName === 'HostList' ||
              componentName === 'SensorClassicList' ||
              componentName === 'SensorTreeList') &&
            i === 0 &&
            domainNameLength
          )
            return (
              <div
                className={`${styles.tagActionsWrap} test`}
                onMouseEnter={(): void => toggleHover(String(i))}
                onMouseLeave={(): void => toggleHover('')}
                onClick={(e): void => showAddTagComponent(e, t.name)}
                key={nanoid()}
              >
                <div
                  key={nanoid()}
                  className={styles.tagContentWrap}
                  style={{ backgroundColor: t.color }}
                  title={t.name && t.name.length >= 30 ? t.name : ''}
                >
                  <span
                    className={styles.tagTitle}
                    style={{ color: getTextColor(t.color) }}
                    title={t.name}
                  >
                    {domainNameLength + t.name.length > 48 ? `${t.name.slice(0, 20)}...` : t.name}
                  </span>
                </div>
                {editTagColor &&
                  editTagColor.indexEl === i &&
                  (componentName === 'HostList' ||
                    componentName === 'SensorClassicList' ||
                    componentName === 'SensorTreeList') && <ChangeTagColor />}
              </div>
            );

          if (
            componentName &&
            (componentName === 'HostListAllTag' || componentName === 'SensorListAllTag') &&
            i !== 0
          )
            return (
              <div
                className={styles.tagActionsWrap}
                onMouseEnter={(): void => toggleHover(String(i))}
                onMouseLeave={(): void => toggleHover('')}
                onClick={(e): void => showAddTagComponent(e, t.name)}
                key={nanoid()}
              >
                <div
                  key={nanoid()}
                  className={styles.tagContentWrap}
                  style={{ backgroundColor: t.color }}
                  title={t.name && t.name.length >= 30 ? t.name : ''}
                >
                  <span className={styles.tagTitle} style={{ color: getTextColor(t.color) }}>
                    {t.name}
                  </span>
                </div>
                {editTagColor &&
                  editTagColor.indexEl === i &&
                  componentName === 'HostListAllTag' && <ChangeTagColor />}
              </div>
            );

          if (
            !(
              componentName === 'HostList' ||
              componentName === 'SensorClassicList' ||
              componentName === 'SensorTreeList'
            ) &&
            !(componentName === 'HostListAllTag' || componentName === 'SensorListAllTag')
          )
            return (
              <div
                className={styles.tagActionsWrap}
                onMouseEnter={(): void => toggleHover(String(i))}
                onMouseLeave={(): void => toggleHover('')}
                onClick={(e): void => showAddTagComponent(e, t.name)}
                key={nanoid()}
              >
                <div
                  key={nanoid()}
                  className={styles.tagContentWrap}
                  style={{ backgroundColor: t.color }}
                  title={t.name && t.name.length > 50 ? t.name : ''}
                >
                  <span
                    className={styles.tagImg}
                    style={{ backgroundColor: getTextColor(t.color) }}
                  />
                  <span className={styles.tagTitle} style={{ color: getTextColor(t.color) }}>
                    {t.name}
                  </span>
                </div>
                {editTagColor && editTagColor.indexEl === i && componentName !== 'AddTag' && (
                  <ChangeTagColor />
                )}
                {hovered !== '' && hovered === String(i) && (
                  <div
                    className={
                      componentName !== 'AddTag'
                        ? styles.hoveredTagActionBlock
                        : styles.hoveredTagActionBlockAdd
                    }
                    style={{ backgroundColor: t.color }}
                  >
                    {componentName !== 'AddTag' && (
                      <Button
                        className="button-noborder"
                        onClick={(): void => showTagMenu(i)}
                        style={{ backgroundColor: t.color, color: getTextColor(t.color) }}
                      >
                        ...
                      </Button>
                    )}
                    <Button
                      className="button-noborder"
                      onClick={(): void => {
                        if (entityType === 'sensor')
                          deleteTagFromSensor(t.tagId, tags, tagIds, entityId);
                        else if (entityType === 'host')
                          deleTagFromHost(t.tagId, tags, tagIds, entityId);
                      }}
                      style={{ backgroundColor: t.color, color: getTextColor(t.color) }}
                    >
                      x
                    </Button>
                  </div>
                )}
                {isTagMenu && keyValue === i && (
                  <AssetTagContextMenu
                    tag={t}
                    setHovered={setHovered}
                    indexEl={i}
                    entityType={entityType}
                  />
                )}
              </div>
            );

          return null;
        })}
    </>
  );
}
