/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import type { GridHeaderCellProps } from '@progress/kendo-react-grid';
import localeTask from '../../../../../../utils/i18n/taskLocale/task.json';
import { CommonTooltip } from '../../../../../common/CommonTooltip';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeTask;

export function CellScheduleHeader(props: GridHeaderCellProps): React.ReactElement {
  const { title } = props;

  return (
    <div
      style={{
        display: 'flex',
        gap: '0 8px',
        alignItems: 'center',
      }}
    >
      {title}
      <CommonTooltip
        hiddenStyle={{
          width: '400px',
        }}
      >
        {localeTask[currentLocale].headerTooltip}
      </CommonTooltip>
    </div>
  );
}
