import type { SortDescriptor } from '@progress/kendo-data-query';
import type { IReferencesByDomain } from '../../components/common/modal/vulnModal/VulnReferenceList';
import { INITIAL_SORT_SECURITY_VULNS_LIST, INITIAL_PAGING } from './constants';
import type { IPageState, ISecurityVulns } from './types';
import type { DashboardBulletinsResponse } from '../../types/__generated/on-premise-solution/api/dashboardBulletinsResponse.v1';
import type { IExploits } from '../../components/common/modal/vulnModal/VulnExploitsList';

export interface IFilterUrlDataSecVulnsList {
  SecurityVulnsList?: string;
  accountId?: string;
}
export interface IUrlParamsSecVulnsList {
  sort: SortDescriptor[];
  page: IPageState;
  pageLimitGrid: { [key: string]: string } | null;
  filterUrlData: IFilterUrlDataSecVulnsList;
}

export function getUrlSecVulnsList(pageLimitGridKey: string): IUrlParamsSecVulnsList {
  const pageLimitGrid: IUrlParamsSecVulnsList['pageLimitGrid'] =
    localStorage.getItem('pageLimitGrid') &&
    JSON.parse(localStorage.getItem('pageLimitGrid') || '');

  const locationUrlData: { [name: string]: string } | null = {};
  if (document.location.search) {
    const locationSearch = document.location.search.slice(1).split('&');
    locationSearch.forEach((val) => {
      const value = val.split('=');
      if (value[0] === 'accountId' || value[0] === 'accountId[$eq]') {
        locationUrlData.accountId = decodeURI(value[1]) || '';
      }
      locationUrlData[value[0]] = value[1] || '';
    });
  }

  const { sortField, sortOrder, skip } = locationUrlData;
  const sort: SortDescriptor[] =
    sortField && sortOrder && (sortOrder === 'asc' || sortOrder === 'desc')
      ? [{ field: sortField, dir: sortOrder }]
      : INITIAL_SORT_SECURITY_VULNS_LIST;

  const page: IPageState = {
    skip: Number(skip) || INITIAL_PAGING.skip,
    take:
      pageLimitGrid && Number(pageLimitGrid[pageLimitGridKey])
        ? Number(pageLimitGrid[pageLimitGridKey])
        : INITIAL_PAGING.take,
  };

  const filterUrlData = {} as IFilterUrlDataSecVulnsList;

  if (locationUrlData.accountId) {
    filterUrlData.accountId = locationUrlData.accountId;
  }

  return {
    sort,
    page,
    pageLimitGrid,
    filterUrlData,
  };
}

export function sortReferencesByDomain(
  references: string[] | null | undefined,
): IReferencesByDomain[] {
  const referencedByDomain = [] as IReferencesByDomain[];

  references?.forEach((el, i) => {
    const url = references[i - 1] ? new URL(references[i - 1]) : new URL(references[i]);

    if (new URL(el).host !== url.host) {
      const byDomain = references?.filter((e) => new URL(e).host === url.host);
      const referenceByDomain = {
        article: url.host,
        arr: byDomain || undefined,
      };
      if (!referencedByDomain.find((r) => r.article === referenceByDomain.article)) {
        referencedByDomain.push(referenceByDomain);
      }
    }
    if (new URL(el).host === url.host && references && i + 1 === references.length) {
      const byDomain = references?.filter((e) => new URL(e).host === url.host);
      const referenceByDomain = {
        article: new URL(el).host,
        arr: byDomain || undefined,
      };
      if (!referencedByDomain.find((r) => r.article === referenceByDomain.article)) {
        referencedByDomain.push(referenceByDomain);
      }
    }
    if (new URL(el).host !== url.host && references && i + 1 === references.length) {
      const byDomain = [el];
      const referenceByDomain = {
        article: new URL(el).host,
        arr: byDomain || undefined,
      };
      if (!referencedByDomain.find((r) => r.article === referenceByDomain.article)) {
        referencedByDomain.push(referenceByDomain);
      }
    }
  });

  return referencedByDomain;
}

interface IExploidData {
  source: string;
  data: IExploits[] | undefined;
}

export function sortExploitsBySource(exploits: IExploits[] | null): IExploidData[] | undefined {
  let source = '';

  let changedExploitsData = [] as IExploidData[];

  exploits?.forEach((exploit) => {
    source = exploit.source;

    if (changedExploitsData.length === 0) {
      changedExploitsData.push({ source, data: exploits.filter((v) => v.source === source) });
    } else if (
      changedExploitsData &&
      changedExploitsData.length > 0 &&
      changedExploitsData.find((v) => v.source === source)
    ) {
      changedExploitsData = [
        ...changedExploitsData.filter((v) => v.source !== source),
        {
          source,
          data: exploits?.filter((expl) => expl.source === source),
        },
      ];
    } else if (
      changedExploitsData &&
      changedExploitsData.length > 0 &&
      !changedExploitsData.find((v) => v.source === source)
    ) {
      changedExploitsData.push({ source, data: exploits.filter((v) => v.source === source) });
    }
  });

  return changedExploitsData;
}

export function collectSecurityVulns(
  dataResult: DashboardBulletinsResponse | undefined,
): ISecurityVulns[] | undefined {
  const data = dataResult?.data.map((o: any) => {
    let maxScore: number | null = null;

    let sourceMaxScore = '';

    let severityMaxScore: string | undefined = '';

    const scores = [] as number[];

    let cwe = '-' as string | { id: string; name: string };

    let vector = '-';

    let cweName = '';

    if (o.metrics) {
      o.metrics.forEach((m: any) => {
        if (m.cvss && 'score' in m.cvss) scores.push(Number(m?.cvss?.score));
      });
    }

    const tempScore = Math.max(...scores);

    if (!maxScore || maxScore < tempScore) maxScore = tempScore;

    o.metrics?.every((m: any) => {
      if (m.cvss && 'score' in m.cvss && Number(m.cvss.score) === maxScore) {
        vector = m.cvss.vector;
        if ('source' in m && 'severity' in m) {
          sourceMaxScore = m.source;
          severityMaxScore = m.severity;
        }

        if ('cwe' in m && m.cwe && m.cwe.length > 0) [cwe] = m.cwe;

        return false;
      }

      return true;
    });

    o.metrics?.forEach((m: any) => {
      if (typeof cwe === 'string' && m.cwe && m.cwe[cwe]) cweName = m.cwe[cwe].name;
    });

    return {
      ...o,
      id: o.bulletinId,
      maxScore,
      sourceMaxScore,
      severityMaxScore,
      cwe,
      cweName,
      vector,
    };
  });

  return data;
}
