/* eslint-disable no-nested-ternary */
import type { GridCellProps } from '@progress/kendo-react-grid';
import { ticketPriorityLocale } from '../../../../../utils/helpers/constants';
import styles from '../ticket.module.scss';
import type { TicketDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/ticketsDashboardResponse.v1';

export function CellTicketPriority(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { priority }: TicketDashboardResponse = dataItem;

  return (
    <td>
      {priority && typeof priority === 'string' && priority in ticketPriorityLocale ? (
        <div title={ticketPriorityLocale[priority]}>
          <svg viewBox="0 0 512 512" width={16} height={16} className={`${styles[priority]}`}>
            <path d="M256 32 0 480h512zm-32 160h64v160h-64zm0 256v-64h64v64z" />
          </svg>
        </div>
      ) : !priority ? (
        <span>-</span>
      ) : (
        <span>{priority}</span>
      )}
    </td>
  );
}
