/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect, useState, type ReactElement } from 'react';
import locale from '../../../utils/i18n/commonLocales/confirmDeleteForm.json';
import { createInfoWhenDelTs } from '../../../utils/helpers/ticket-helper';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function DeleteConfirmModalTicketSystem({
  type,
  dataItemEl,
}: {
  type: string;
  dataItemEl?: string;
}): ReactElement {
  const [tickets, setTickets] = useState<{
    notClosedTickets: number;
    allTickets: number;
  } | null>(null);

  useEffect(() => {
    if (type === 'ticketSystem' && dataItemEl) {
      createInfoWhenDelTs(dataItemEl, setTickets);
    }
  }, []);

  return (
    <>
      {tickets ? (
        <div className="ticket-info-wrap" style={{ marginBottom: '-12px' }}>
          <div className="ticket-info-title">
            <span>{locale[currentLocale].tickets.delTitle}</span>
          </div>
          <div
            className="ticket-info-content-wrap"
            style={{ marginLeft: '16px', fontWeight: 'bold' }}
          >
            <div>
              <span>{locale[currentLocale].tickets.allTickets}</span>
              <span>{tickets?.allTickets}</span>
            </div>
            <div>
              <span>{locale[currentLocale].tickets.notClosedTickets}</span>
              <span>{tickets?.notClosedTickets}</span>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
