/* eslint-disable react/jsx-props-no-spreading */
import {
  Filter,
  filterBy,
  FilterChangeEvent,
  Pager,
  PagerProps,
} from '@progress/kendo-react-data-tools';
import { memo, useEffect, useRef, useState, type ReactElement } from 'react';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { useQuery } from 'react-query';
import type { CompositeFilterDescriptor, SortDescriptor, State } from '@progress/kendo-data-query';
import {
  extendDataItem,
  mapTree,
  TreeList,
  TreeListColumnProps,
  TreeListExpandChangeEvent,
  TreeListPageChangeEvent,
  TreeListSortChangeEvent,
} from '@progress/kendo-react-treelist';
import type { ExcelExport } from '@progress/kendo-react-excel-export';
import localeFilters from '../../../utils/i18n/commonLocales/filters.json';
import localeSoftwareList from '../../../utils/i18n/software/software.json';
import type { IDataHooks, IPageState } from '../../../utils/helpers/types';
import { getUrlParamsAddHosts } from '../../../utils/helpers/add-host-list-helper';
import {
  FILTER_INITIAL,
  INITIAL_IMAGE_LIST,
  PAGEABLE_DATA,
} from '../../../utils/helpers/constants';
import {
  FILTER_ALL_SOFTWARELIST,
  FILTER_VULNS_SOFTWARELIST,
} from '../../../utils/helpers/constant-serv-filter-host';
import { getUserData } from '../../../services/local-storage-service';
import { Input } from '../../common/baseElements/Input';
import type { VulnerableSoftwareListGroupedByNameDashboardResponse } from '../../../types/__generated/on-premise-solution/api/vulnerableSoftwareListGroupedByNameDashboardResponse.v1';
import type { InstalledSoftwareListGroupedByNameDashboardResponse } from '../../../types/__generated/on-premise-solution/api/installedSoftwareListGroupedByNameDashboardResponse.v1';
import {
  getInstalledSoftwareListGroups,
  getVulnerableSoftwareListGroups,
} from '../../../services/softwares-service';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
import { useNotificationContext } from '../../../hooks/useNotificationContext';
import { handleFilteringTest, IFilterVal } from '../../../utils/filtering-in-table-test';
import { useFilter } from '../../../hooks/components/useFilter';
import { useCustomSearch } from '../../../hooks/components/useCustomSearch';
import { handleError } from '../../../utils/errors';
import { useTreePageChange } from '../../../hooks/components/usePageChange';
import { LoadingPanel } from '../../common/tableLoader/LoadingPanel';
import { useSoftwaresContext } from '../../../hooks/useSoftwaresContext';
import { ScoreCvssCell } from '../../common/cells/ScoreCvssCell';
import { CellTotalVulnerabilities } from './cells/CellTotalVulnerabilities';
import { CellTotalAssetsByPackage } from './cells/CellTotalAssetsByPackage';
import { CellSoftwareNameOrVersion } from './cells/CellSoftwareNameOrVersion';
import { useLocalExcelExport } from '../../../hooks/components/useLocalExcelExport';
import { handleSort } from '../../../utils/sorting-in-table';
import { StandartCell } from '../../common/baseElements/StandartCell';
import { CellTotalAssetsByPackageModal } from './cells/CellTotalAssetsByPackageModal';
import localeGridTable from '../../../utils/i18n/commonLocales/table.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeFilters;

function setLastItems(
  softwareTree:
    | VulnerableSoftwareListGroupedByNameDashboardResponse['data']
    | InstalledSoftwareListGroupedByNameDashboardResponse['data']
    | undefined,
):
  | (VulnerableSoftwareListGroupedByNameDashboardResponse['data'] & { isLastElement?: boolean[] })
  | (InstalledSoftwareListGroupedByNameDashboardResponse['data'] & { isLastElement?: boolean[] }) {
  return (
    softwareTree?.map((software, i) => {
      const isParentLast = i === softwareTree.length - 1;
      const newData = software.data.map((s, j) => ({
        ...s,
        isLastElement: [isParentLast, j === software.data.length - 1],
      }));

      return { ...software, data: newData, isLastElement: [isParentLast] };
    }) || []
  );
}

function getSoftareWithChildren(
  softTree:
    | VulnerableSoftwareListGroupedByNameDashboardResponse['data']
    | InstalledSoftwareListGroupedByNameDashboardResponse['data'],
): string[] {
  const mp = [];
  for (let i = 0; i < softTree.length; i++) {
    if (softTree[i].data.length) mp.push(softTree[i].name);
  }

  return mp;
}

export function SoftwareListTree({
  isVulnerable,
  osType,
}: {
  isVulnerable?: boolean;
  osType?: 'linux' | 'windows';
}): ReactElement {
  const currentUser = getUserData();
  const urlParams = getUrlParamsAddHosts('SoftwareListGroups', INITIAL_IMAGE_LIST);

  const dataForHooks: IDataHooks = {
    name: 'name',
    componentName: isVulnerable ? 'SoftwareVulnerableListGroups' : 'SoftwaresInstalledListGroups',
  };

  const { createNotification } = useNotificationContext();

  const [page, setPage] = useState<IPageState>({ skip: 0, take: 15 });
  const [sort, setSort] = useState<SortDescriptor[]>([{ field: 'name', dir: 'asc' }]);
  const [filter, setFilter] = useState<CompositeFilterDescriptor>(FILTER_INITIAL);
  const [filterVal, setFilterVal] = useState<IFilterVal[]>([]);
  const getPage = useTreePageChange(setPage, urlParams, dataForHooks);
  const [filterStatus, setFilterStatus] = useState(false);
  const [customSearch, setCustomSearch] = useState('');
  const filterValue = useRef<CompositeFilterDescriptor | null>(null);
  const filterRef = useRef<CompositeFilterDescriptor>(filter);

  const { changedAccountId } = useSoftwaresContext();

  const query = useQuery<
    | InstalledSoftwareListGroupedByNameDashboardResponse
    | VulnerableSoftwareListGroupedByNameDashboardResponse,
    ApiError
  >(
    [
      isVulnerable ? 'VulnerableSoftwareListGroup' : 'InstalledSoftwareListGroup',
      page,
      filterVal,
      sort,
      osType,
      changedAccountId,
    ],
    () =>
      isVulnerable
        ? getVulnerableSoftwareListGroups(page, filterVal, sort, osType, changedAccountId)
        : getInstalledSoftwareListGroups(page, filterVal, sort, osType, changedAccountId),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  const pageChange = (event: TreeListPageChangeEvent): void => {
    getPage(event);
  };

  const TreeListPager = memo((props: PagerProps): ReactElement => {
    return (
      <LocalizationProvider language={currentLocale}>
        <Pager
          {...props}
          pageSizes={PAGEABLE_DATA.pageSizes}
          take={page.take}
          previousNext
          buttonCount={PAGEABLE_DATA.buttonCount}
          total={query.data?.total || 0}
          messagesMap={(messageKey: string): { defaultMessage: string; messageKey: string } => ({
            messageKey,
            defaultMessage: localeGridTable[currentLocale].grid[messageKey as 'pager.firstPage'],
          })}
        />
      </LocalizationProvider>
    );
  });

  useEffect(() => {
    let delaySearch: NodeJS.Timeout;

    if (filter?.filters?.length > 0) {
      filterRef.current = filter;
      delaySearch = setTimeout(() => {
        handleFilteringTest(filterRef.current, setFilterVal);
        setFilterStatus(false);
      }, 300);
    }

    return () => clearTimeout(delaySearch);
  }, [filter.filters, page.skip, location]);

  useEffect(() => {
    if (query.error) {
      handleError(query.error, createNotification);
    }
  }, [query.isError, query.error]);

  const debouncedCustomSearch = useCustomSearch(
    setFilterStatus,
    setCustomSearch,
    filter,
    setFilterVal,
    setPage,
    page,
    dataForHooks,
  );

  const filterSearch = useFilter(
    filterValue,
    setFilter,
    customSearch,
    setCustomSearch,
    setFilterVal,
    filterRef,
    filter,
    setPage,
    page,
    dataForHooks,
  );

  const onFilterChange = (event: FilterChangeEvent): void => {
    filterSearch(event);
  };

  const handleCustomSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    debouncedCustomSearch(e);
  };

  const [state, setState] = useState({
    data: setLastItems(query.data?.data),
    filter: [],
    expanded: query.data?.data.length ? getSoftareWithChildren(query.data?.data) : [],
  });

  const subItemsField = 'data';
  const expandField = 'expanded';

  const columns: TreeListColumnProps[] = [
    {
      field: 'name',
      title:
        osType === 'windows'
          ? localeSoftwareList[currentLocale].tables.softwareNameAndVersion
          : localeSoftwareList[currentLocale].tables.packageNameAndVersion,
      cell: CellSoftwareNameOrVersion,
    },
    {
      field: 'maxMetric.cvss.score',
      title: 'CVSS',
      cell: ScoreCvssCell,
      width: 76,
    },
    // {
    //   field: 'fix',
    //   title: localeSoftwareList[currentLocale].tables.fixes,
    //   cell: CellFixes,
    //   width: 80,
    // },
    {
      cell: CellTotalVulnerabilities,
      title: localeSoftwareList[currentLocale].tables.totalVulnerabilities,
      width: 148,
      sortable: false,
    },
    {
      field: 'totalHostAssets',
      title: localeSoftwareList[currentLocale].tables.totalHosts,
      width: 100,
      cell: CellTotalAssetsByPackageModal,
      sortable: false,
    },
    {
      field: 'totalImageAssets',
      title: localeSoftwareList[currentLocale].tables.totalImages,
      width: 100,
      cell: CellTotalAssetsByPackageModal,
      sortable: false,
    },
  ];

  if (osType === 'linux')
    columns.splice(2, 0, {
      field: 'arch',
      title: localeSoftwareList[currentLocale].tables.arch,
      width: 128,
      cell: StandartCell,
      sortable: false,
    });

  const onExpandChange = (e: TreeListExpandChangeEvent): void => {
    setState({
      ...state,
      expanded: e.value
        ? state.expanded?.filter((name: string) => name !== e.dataItem.name)
        : [...state.expanded, e.dataItem.name],
    });
  };

  const addExpandField = (
    dataTree:
      | VulnerableSoftwareListGroupedByNameDashboardResponse['data']
      | InstalledSoftwareListGroupedByNameDashboardResponse['data'],
  ): Array<
    | (VulnerableSoftwareListGroupedByNameDashboardResponse['data'][number] & {
        [expandField]: string[];
      })
    | (InstalledSoftwareListGroupedByNameDashboardResponse['data'][number] & {
        [expandField]: string[];
      })
  > => {
    const { expanded } = state;

    return mapTree(
      dataTree,
      subItemsField,
      (
        item:
          | VulnerableSoftwareListGroupedByNameDashboardResponse['data'][number]
          | InstalledSoftwareListGroupedByNameDashboardResponse['data'][number],
      ) =>
        extendDataItem(item, subItemsField, {
          [expandField]: expanded.includes(item.name),
        }),
    );
  };

  const processData = (): Array<
    | (VulnerableSoftwareListGroupedByNameDashboardResponse['data'][number] & {
        [expandField]: string[];
      })
    | (InstalledSoftwareListGroupedByNameDashboardResponse['data'][number] & {
        [expandField]: string[];
      })
  > => {
    const { data } = state;
    const filteredData:
      | VulnerableSoftwareListGroupedByNameDashboardResponse['data']
      | InstalledSoftwareListGroupedByNameDashboardResponse['data'] = filterBy(
      isVulnerable
        ? data.map((d) => ({
            isVulnerableFlag: true,
            ...d,
            data: d.data.map((d2) => ({ isVulnerableFlag: true, ...d2 })),
          }))
        : data,
      state.filter,
      subItemsField,
    );

    return addExpandField(filteredData);
  };

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      data: setLastItems(query.data?.data),
    }));
  }, [query.data?.data]);

  const pageLimitGrid: { [key: string]: string } =
    localStorage.getItem('pageLimitGrid') &&
    JSON.parse(localStorage.getItem('pageLimitGrid') || '');

  const initialDataState: State = {
    sort: [{ field: 'maxScore', dir: 'desc' }],
    take: pageLimitGrid && pageLimitGrid.VulnsSoft ? Number(pageLimitGrid.VulnsSoft) : 10,
    skip: 0,
    filter: FILTER_INITIAL,
  };
  const [dataState, setDataState] = useState<State>(initialDataState);

  const _export = useRef<ExcelExport | null>(null);
  const excelExport = useLocalExcelExport(
    dataState,
    _export,
    [{ id: 'test', name: 'tt' }],
    setDataState,
  );

  return (
    <div>
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          {(query.isLoading || query.isFetching) && <LoadingPanel />}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
          >
            <div className="filter-line" style={{ flex: 'fit-content' }}>
              <Filter
                value={filter}
                onChange={onFilterChange}
                fields={
                  currentUser?.role === 'super_admin'
                    ? FILTER_VULNS_SOFTWARELIST[currentLocale]
                    : FILTER_ALL_SOFTWARELIST[currentLocale]
                }
              />
              {filter.filters.length === 0 && (
                <Input
                  name="customSearch"
                  placeholder={`${localeFilters[currentLocale].customSerachBy} ${localeFilters[currentLocale].customSearchFields.software}`}
                  value={customSearch}
                  onChange={(e): void => handleCustomSearch(e)}
                />
              )}
            </div>
            {/* <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Button excel fill="flat" onClick={excelExport}>
                {localeAuditTask[currentLocale].vulnsSoft.table.exportToExcel}
                Excel
              </Button>
            </div> */}
          </div>
          {/* <ExcelExport ref={_export} fileName="test.xlsx"> */}

          <TreeList
            sortable
            data={processData()}
            expandField={expandField}
            subItemsField={subItemsField}
            columns={columns}
            onExpandChange={onExpandChange}
            className="no-pageable-k-grid table"
            sort={sort}
            onSortChange={
              setSort ? (e: TreeListSortChangeEvent): void => handleSort(e, setSort) : undefined
            }
            skip={page.skip}
            // take={getGridRowCount(query.data?.data)}
            pager={TreeListPager}
            onPageChange={pageChange}
          />
        </IntlProvider>
      </LocalizationProvider>
    </div>
  );
}
